import React from "react";
export default function Circle(props = {}) {
    return (
        <svg
            className="svg-inline--fa fa-circle fa-w-16"
            aria-hidden="true"
            data-prefix="fas"
            data-icon="circle"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            data-fa-i2svg=""
        >
            <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
            />
        </svg>
    )
}