import React from "react";
export default function ModalCheckIcon(props = {}) {
    const svgData = { width: 70, height: 70, fill: "none", ...props }
    return (
        <svg
            viewBox="0 0 100 100"
            {...svgData}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M49.9999 91.6666C72.9166 91.6666 91.6666 72.9166 91.6666 49.9999C91.6666 27.0833 72.9166 8.33325 49.9999 8.33325C27.0833 8.33325 8.33325 27.0833 8.33325 49.9999C8.33325 72.9166 27.0833 91.6666 49.9999 91.6666Z"
                fill="white"
                stroke="#0A4F73"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.2917 49.9996L44.0834 61.7913L67.7084 38.208"
                stroke="#0A4F73"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}