import React from "react";
import config from "../../../config/emrok.config";
import { Link } from "react-router-dom";
export default function AdminFooter() {
    return (
        <>
            <footer className="footer--admin">
                <div className="container-fluid">
                    <div className="d-flex flex-md-row flex-column justify-content-between align-items-center">
                        <div>
                            <p className="m-0 footer-copyright-text">
                                Copyright © <span className="updateYear" /> Wockhardt Limited. All
                                Rights Reserved.
                            </p>
                        </div>
                        <div>
                            <div className="d-flex">
                                <Link to="/customer/privacy-policy" className="privacy-policy-text me-2">Privacy Policy</Link>
                                <a href={`${config.serviceUrl}/images/product_img/tems_condition.pdf`} className="privacy-policy-text" target="_blank">Terms &amp; Conditions</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}