import {
    SET_MR,
    LOGOUT,
    SET_BU_STATE_DETAILS,
    SET_NSM_STATE_DETAILS
    } from '../types/Mr.type'
    class MrAction {
      setMr = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_MR, payload: data }
      }
      
      logout = ()=>{
        return { type: LOGOUT, payload: null }
      }

      setBUStateDetails = (data) =>{
        return { type: SET_BU_STATE_DETAILS, payload: data }
      }
      setNSMStateDetails = (data) =>{
        return { type: SET_NSM_STATE_DETAILS, payload: data }
      }
    }
    export default new MrAction()