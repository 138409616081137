import {
    SET_ADMIN,
    CLEAR_STATE,
    SET_ACTIVE_MENU,
    STORE_SEARCH_VALUE

    } from '../types/Admin.type'
    class AdminAction {
      setAdmin = (data) => {
        console.log(">>>>>>>>>>>>>>>>",data)
        return { type: SET_ADMIN, payload: data } 
      }
      logout = ()=>{
        return { type: CLEAR_STATE, payload: null }
      }

      setActiveMenuItem  = (data) =>{
        // alert(data)
        return { type: SET_ACTIVE_MENU, payload: data } 
      }

      setSearchValue  = (data) =>{
        // alert(data)
        return { type: STORE_SEARCH_VALUE, payload: data } 
      }
     
      
    }
    export default new AdminAction()