import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
class QrCodeGeneration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            quantity1: 0,
            quantity2: 0,
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",
            doctorList: [],
            selectedDoctor: "",
            searchDoctor: "",
            currentPage: 1,
            itemsPerPage: 20
        }

    }


    componentDidMount() {

    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    goBackPrevious = () => {
        const usertype = this.props.CommonReducer.userType;



        this.props.navigate('/admin/order-managment');

    };


    downloadQR = async (event) => {

        let payload = {
            doctor_id: event.target.id
        };
        const {doctorList} = this.state;
        console.log(doctorList,'666666')
        const selectedDoctorDetails = await doctorList.filter(item => item._id == payload.doctor_id)
        console.log(selectedDoctorDetails,"666666")
        AdminService.downloadQrCode(payload).then(res => {
            console.log(res.data.data, 88888899)
            if(res.data.data && res.data.data.startsWith('data:image/png;base64')){
               const name = selectedDoctorDetails?`${selectedDoctorDetails[0].name}.png`:`doc_id_${payload.doctor_id}.png`;
                
                this.downloadBase64Image(res.data.data,name)
            }
            // const downloadUrl = config.serviceUrl + "/images/invoice/" + event.target.id + ".pdf"; // 

        }).catch(e => {

        })


        // const downloadUrl = config.serviceUrl + "/images/invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
        // try {
        //     const response = await fetch(downloadUrl);
        //     if (response.status === 200) {
        //         window.open(downloadUrl, '_blank');
        //         console.log("present")
        //     } else {
        //         // alert("not present")
        //         this.props.hoc.customAlert("Invoice not yet generated.", false)
        //         console.log("not present")
        //     }
        // } catch (error) {
        //     window.open(downloadUrl, '_blank');
        //     // Handle network or other errors
        //     console.log("error");
        // }


        // alert(order_id);
    }

    searchDoctor = () => {


    }
    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({ searchDoctor: value });
    }
    searchDoctor = (event) => {
        const { searchDoctor } = this.state;

        if (searchDoctor == "") {

            this.props.hoc.customAlert('Please provide a doctor name.', false)
            return false
        } else {
            let payload = {
                search: searchDoctor
            }
            this.searchDoctorService(payload);


        }
        // alert(searchDoctor);
    }

    searchDoctorService = async (payload) => {
        try {
            this.setState({ doctorList: [] })

            let res = await AdminService.searchByDoctor(payload)
            console.log(res, 123333)
            if (res.data.success) {
                console.log(res.data.data.data.length, "lasjdhjkash");
                this.setState({ doctorList: res.data.data.data });
            }

        } catch (error) {

        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                doctor_name: data[i].name,
                state: data[i].address.state,
                doctor_code: data[i].doctor_code


            })
        }
        return resp
    }

    clearSearch = (event) => {

        this.setState({ doctorList: [], searchDoctor: "" })

    }

    downloadBase64Image = (str, fileName = "test.png") => {
        try {
            const base64String = str.split(",")[1]
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error, "iopopop");
        }
    }

    render = () => {
        const { doctorList, searchDoctor } = this.state;
        let _data = this.setOrderHistoryData(doctorList)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-5'>
                                    <h5 className='mb-20'>Generate Doctor QR Codes</h5>
                                    </div>
                                    {/* Row end */}
                                    <div className="row">
                                        <form className="mt-3 registration-form">
                                            <div className='row mb-5'>
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Search Doctors <span className='requiredFld'> * </span></label>
                                                    <input className='form-control' onChange={(event) => this.changeVal(event)} value={searchDoctor} name='searchDoctor' />
                                                    {/* {
                                                        doctorList.length ?
                                                            <DropdownDoctorRuntime
                                                                items={doctorList}
                                                                onSelect={(item) => {
                                                                    if (item) {
                                                                      
                                                                    }
                                                                }}
                                                                value={selectedDoctor}
                                                            /> :
                                                            <DropdownDoctorRuntime
                                                                items={doctorList}
                                                                value={selectedDoctor}
                                                            />
                                                    } */}
                                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                                </div>
                                                <div className="col-md-1 mt-4">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.searchDoctor(event)}>Search</button>
                                                </div>
                                                <div className="col-md-2 mt-4">
                                                    <button className='btn btn-primary' type='button' onClick={(event) => this.clearSearch(event)}>Clear Search</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="table-responsive">


                                                    {
                                                        _data && _data.length ?
                                                            <CustomDatatable
                                                                pagination={true}
                                                                columns={[
                                                                    {
                                                                        name: 'Doctor Name',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'Doctor Code',
                                                                        // selector: row => row.mr_name,

                                                                    },
                                                                    {
                                                                        name: 'State',
                                                                        // selector: row => row.mr_name,

                                                                    },

                                                                    {
                                                                        name: 'Action',
                                                                        // selector: row => row.createdAt,

                                                                    }
                                                                ]}
                                                                data={this.setOrderHistoryData(doctorList)}
                                                                pageName="qrcodegeneration"
                                                                downloadQR={this.downloadQR}
                                                            />
                                                            : <p className="norecord"><strong>No Records Found</strong></p>
                                                    }
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(QrCodeGeneration)))