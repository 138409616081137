import React, { Component } from 'react'

class PageNotFound extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render = () => {
    return (
      <div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <p>PAGE NOT FOUND</p>
      </div>
    )
  }
}

export default PageNotFound