import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  MR_LOGIN_API, 
  MR_DETAIL, 
  MR_INFO,
  GET_DOCTOR_BY_MR,
  USER_DETAIL,
  USER_INFO,
  GET_USERS,
  GET_ALL_PRODUCTS

} from './Slugs'
import MrAction from '../redux/actions/Mr.action';
// import config from '../../config/knorish.config';
// import AuthAction from '../redux/actions/Auth.action'
// import Validation from '../models/index.model'
// import { STRINGS } from '../utils/strings';
// import TenantInfoAction from '../redux/actions/TenantInfo.action';

class MrService extends Base {
  constructor(props) {
    super(props)
  }

  mrLogin(data) {
    return this.post(MR_LOGIN_API, data);
  }

  getmrDetails(data) {
    return this.post(MR_DETAIL,data);
  }

  getmrInfo(data) {
    return this.post(MR_INFO,data);
  }

  getuserDetails(data) {
    return this.post(USER_DETAIL,data);
  }

  getuserInfo(data) {
    return this.post(USER_INFO,data);
  }

  getDoctorsByMr(data) {
    return this.post(GET_DOCTOR_BY_MR,data);
  }

  getusers(data){
    return this.post(GET_USERS,data)
  }

  getProductList(){
    return this.get(GET_ALL_PRODUCTS)
  }

}

export default new MrService()