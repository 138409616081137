import React, { useEffect, useState, useImperativeHandle } from "react";

import IncrementIcon from "../../assets/Svg/IncrementIcon";
import DecrementIcon from "../../assets/Svg/DecrementIcon";
import config from "../../config/emrok.config";
export default function ProductList({ products = [], productIncrement, productDecrement, paymentOrder, product_quantities }, ref) {
    const { serviceUrl } = config;
    console.log(products, "hgghhghghg");
    const renderJsx = (_product_quantities) => {
        // return products.map((product, i) => {
        return (
            <>
                <div className="product-dtls-content-box mt-24">
                    <div className="row">

                        {products.map((product, i) =>

                            <div className="col-2 col-md-2 ">
                                <div className="product-dtls-image-content product-dtls-image-content-new">
                                    <div className="product-dtls-image-box">
                                        <img src={`${serviceUrl}/images/${product.poduct_img}`} alt="Product-1" />
                                    </div>
                                </div>
                                <div className="product-dtls-add-product">
                                    <h4 className="product-dtls-add-product-title">
                                        {/* EMROK O - Tablets 500mg */}
                                        {product.name}
                                    </h4>
                                    {/* <p className="product-desc">
                                        { product.description }
                                    </p> */}
                                    <div className="d-flex align-items-center">
                                        <h4 className="product-value pe-2">
                                            ₹<span className="add-product-value">{product.price}</span>
                                        </h4>
                                        <div className="product-increement-decreement-btn">
                                            <button
                                                type="button"
                                                className="decreement-icon"
                                                onClick={() => {
                                                    console.log("test")
                                                    productDecrement(product._id);
                                                }}
                                            >
                                                <DecrementIcon />
                                            </button>
                                            <span>{_product_quantities[i]?.quantity}</span>
                                            {/* <span>{product.quantity}</span> */}
                                            <button
                                                type="button"
                                                className="increement-icon"
                                                onClick={() => {
                                                    console.log("test>>>>>>")
                                                    productIncrement(product._id);
                                                }}
                                            >
                                                <IncrementIcon />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
        // })
    }
    console.log(product_quantities, "kjsdkjksaj");
    return (
        <>
            <div className="title-wrap">
                {/* <h6 className="sm-title text-uppercase mb-15">Medicine</h6>
                <h1 className="lg-title text-capitalize mb-20">EMROK</h1> */}
                <h5 className="md-title">Product Details</h5>
            </div>
            {/* Title end */}
            {renderJsx(product_quantities)}
            {/* Product details content box end*/}
            {/* <div className="product-notes">
                 <p className="notes-1">Partial payment Min 20% (₹{paymentOrder.partialMinPayment ? parseFloat(+paymentOrder.partialMinPayment).toFixed(2) : 0})</p>
                 <p className="notes-2">Payment Delivery 80% (₹{paymentOrder.deliveryPayment ? parseFloat(+paymentOrder.deliveryPayment).toFixed(2) : 0})</p>
                 <p className="notes-3">Complete Payment 100% (₹{paymentOrder.totalPayment ? parseFloat(+paymentOrder.totalPayment).toFixed(2) : 0})</p>
            </div> */}
            <div className="know-more-text">
                {/* <a
                    href={config.knowMoreLink}
                    className="kow-more-link text-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Know more
                </a> */}
            </div>

        </>
    )
}