import React from "react";
import { useNavigate } from 'react-router-dom';
export default function BackButton({className,onClick}) {
    const navigate = useNavigate();
    const navigateToPrevious = ()=>{
        navigate(-1);
    }
    return (
        <button type="submit" onClick={()=>onClick?onClick():navigateToPrevious()} className={className}>
            Back
        </button>
    )
}