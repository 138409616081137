import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  ADMIN_INFO,
  USER_LIST,
  SEARCH_BY_DOCTOR,
  DOWNLOAD_QR,
  GET_ALL_CALL_CENTER,
  GET_ALL_ORDER_DETAILS,
  GET_CUSTOMER_COUNT,
  UPDATE_CALL_CENTER,
  CREATE_CALL_CENTER,
  REMOVE_CALL_CENTER,
  GET_ALL_STOCKIST,
  UPDATE_STOCKIST

} from './Slugs'
class AdminService extends Base {
  constructor(props) {
    super(props)
  }

  getAdminInfo(data) {
    return this.post(ADMIN_INFO,data);
  }
  getuserListByRole(data){
    return this.post(USER_LIST,data);
  }
  searchByDoctor(data){
    return this.post(SEARCH_BY_DOCTOR,data);
  }
  downloadQrCode(data){
    return this.post(DOWNLOAD_QR,data); 
  }

  getallCallCenter(){
    return this.get(GET_ALL_CALL_CENTER);
  }

  getOrderCountDetails(){
    return this.get(GET_ALL_ORDER_DETAILS);
  }

  getCustomerCount(){
    return this.get(GET_CUSTOMER_COUNT);
  }
  updateCallCenter(data){
    return this.post(UPDATE_CALL_CENTER,data);
  }
  createCallCenter(data){
    return this.post(CREATE_CALL_CENTER,data);
  }
  removeCallCenter(data){
    return this.post(REMOVE_CALL_CENTER,data);
  }

  getallStockist(){
    return this.get(GET_ALL_STOCKIST);
  }

  updateStockist(data){
    return this.post(UPDATE_STOCKIST,data);
  }


}

export default new AdminService()