import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import OrderService from '../../../services/Order.service';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import OrderListAction from "../../../redux/actions/OrderList.action";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import OrderAction from "../../../redux/actions/Order.action";
import OtherService from "../../../services/OtherService";
import moment from "moment";
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import DatePicker from 'react-datepicker';
import CommonAction from "../../../redux/actions/Common.action";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import Summary from "../../../components/Summary/Summary.component";
import { ExportToExcel } from '../../../components/Excel/ExportToExcel'
class MrDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mrdetails: null,
            mrid: "",
            mrDoctorList: [],
            mrinfo: "",
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            summaryCols: ["No. of Total Dr. Prescribed", "Total Rx Generated", "Total Rx Fulfilled", "Total Rx Under Process"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "MR_Data",
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            mrdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
        }
    }

    getSummary = async (mr_id = null) => {
        if (mr_id) {
            // let payload = {
            //     type: "MR",
            //     id: mr_id
            // }
            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');
    
            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');
    
    
            let payload = {
                id: mr_id,
                type: "MR",
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus
            };
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        const mrdetails = this.props.mrdetails;
        let payload = {};
        if (mrdetails) {
            // let payload = await {
            //     mr_id: mrdetails._id
            // }
            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

            const selectedMomentFrom = moment(fromdate);
            const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
            const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');
    
            const selectedMomentTo = moment(todate);
            const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
            // Calculate the next day using moment
            const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
            const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');
    
            if(index>1){

                payload = {
                    mr_id: mrdetails._id,
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor,
                    orderStatus: setorderStatus
                };
            }
           
            if (index === 0 || index === 1) {

                payload = {
                    mr_id: mrdetails._id,
                    fromDate: "",
                    toDate: "",
                    stateName: "",
                    town: "",
                    doctor_id: "",
                    orderStatus: ""
                };
            }

           
            // else if (index === 2) {
            //     payload["orderStatus"] = await "cancelled"
            //     this.setState({ setorderStatus: "cancelled" })
            // }
            else if (index === 2) {
                payload["orderStatus"] = await "complete"
                this.setState({ setorderStatus: "complete" })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "pending"
                this.setState({ setorderStatus: "pending" })
            }

            console.log(payload,"22222");
            this.getmrDetails(payload)
        }

    }

    formatData(auditdata) {

        console.log(auditdata, auditdata.length, "auditdata")

        let formatedArr = [];
       
        for (let i = 0; i < auditdata.length; i++) {

            const materials = auditdata[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');
        
            formatedArr.push({

                "Order No": auditdata[i].order_id,
                "Doctor Name": auditdata[i].doctor.name, 
                "Stockist name":auditdata[i].stockist.name,
                "Patient State": auditdata[i].customer.address.state, 
                "Patient City": auditdata[i].customer.address.town,
                "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                "Shipment Date": auditdata[i].GRN_DATE ? moment(auditdata[i].GRN_DATE).format('DD-MM-YYYY') : "",
                "Delivery Date": auditdata[i].deliveryDate ? moment(auditdata[i].deliveryDate).format('DD-MM-YYYY') : "",
                "Order Value": auditdata[i].total,   
                "Product": materialString,
                "Status": auditdata[i].orderStatus,
            })
        }

        console.log(auditdata, auditdata.length, "auditdata")
        return formatedArr;


    }

    getmrDetails = (details) => {
        console.log(details,'1233333');
        this.setState({ mrDoctorList: [] }, () => {
            MrService.getmrDetails(details).then(data => {
                console.log(data.data.data.mrdetails, "mr details");
                if (data.data.success) {
                    console.log(data.data.data.mrdetails, "data.data.data.mrdetails")

                    this.setState({ mrDoctorList: data.data.data.mrdetails });
                    const formatedData = this.formatData(data.data.data.mrdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "MR_Data" + currentDateTime;
                    this.setState({ filename: filename });
                    // if(!this.state.isSearch){
                    
                    // }
                }



            }).catch(err => {

                console.log(err, "err")
            })
        });
    }

    componentDidMount() {

        const mrdetails = this.props.mrdetails;
        if (mrdetails) {
            const mr_id = mrdetails._id;
            let details = {
                mr_id: mr_id
            };
            console.log(details, "mr details",);
            this.getSummary(mr_id)
            this.getmrDetails(details)


            MrService.getmrInfo(details).then(data => {
                console.log(data.data, "mr info");

                if (data.data.success && data.data.data.mrinfo !== '') {
                    console.log(data.data.data.mrinfo[0], "okkkkk");

                    this.setState({ mrinfo: data.data.data.mrinfo[0] });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            let detailsBody = {
                id: mr_id,
                type: "mr",
            };
            MrService.getDoctorsByMr(detailsBody).then(data => {
                console.log(data.data, "doctordetailsbymr123");

                if (data.data.success && data.data.data.doctorinfo !== '') {
                    console.log(data.data, "doctorokkk1233");
                    this.getDoctors(data.data.data.doctorinfo);

                }

                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);



        } else {


        }



    }
    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/mr/track-info-order');
                    this.props.setUserType('mr');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }
    componentWillUnmount() {
        // if (this.dataTable) {
        //     this.dataTable.destroy(true);
        // }
    }
    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {

            const materials = data[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');
            resp.push({
                _id: data[i]._id,
                stockist_name: data[i].stockist.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_state: data[i].customer.address.state,
                customer_town: data[i].customer.address.town,
                customer_mobile: data[i].customer.mobileno,
                order_date: data[i].createdAt ? data[i].createdAt : "",
                grn_date: data[i].GRN_DATE ? data[i].GRN_DATE : "",
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                order_id: data[i].order_id ? data[i].order_id : "",
                total: data[i].total ? data[i].total : "",
                product_code: materialString,
                delivery_date: data[i].deliveryDate ? data[i].deliveryDate : ""

            })
        }
        return resp
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.selectedState || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })

                    setTimeout(() => {
                        let stateDetails = this.state.states;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.states, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ states: optionsWithAll })
                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {

                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption1 = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll1 = [allOption1, ...stateDetails];
                        console.log(optionsWithAll1, "details state all")
                        this.setState({ towns: optionsWithAll1 })
                    }, 500);
                }
                // }, 2000);
            })
        }
    }


    getDoctors = (doctors) => {
        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });

        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    changeValFromDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });


    }
    changeValToDate = async (date) => {

        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });


    }

    searchFilter = (event) => {
        this.setState({ mrDoctorList: [] })
        const mrdetails = this.props.mrdetails;
        const mr_id = mrdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

        const selectedMomentFrom = moment(fromdate);
        const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
        const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

        const selectedMomentTo = moment(todate);
        const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
        // Calculate the next day using moment
        const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
        const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');


        let details = {
            mr_id: mr_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus
        };

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            console.log(details, "details 1234455")
            MrService.getmrDetails(details).then(data => {
                console.log(data.data, "mr details after filter");

                if (data.data.success && data.data.data.mrdetails !== '') {
                    console.log(data.data, "data after filter");
                    // setTimeout(() => {
                    //this.setOrderHistoryData(data.data.data.mrdetails)
                    const formatedData = this.formatData(data.data.data.mrdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "Callcenter_Data" + currentDateTime;
                    this.setState({ filename: filename });


                    this.setState({ mrDoctorList: data.data.data.mrdetails });
                    this.setState({ isSearch: true })
                    // this.populateStates(countryObj.isoCode)	
                    // }, 500);
                    this.getSummary(mr_id)

                }else{
                    this.getSummary(mr_id)
                }

            }).catch(err => {

                console.log(err, "err")
            })
        }

        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
    }



    render = () => {

        let _data = this.setOrderHistoryData(this.state.mrDoctorList)
        console.log(_data, "_data")
        const { exportData, fileName,countries, states, search, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, selectedDoctor, doctorList, summaryCols, summaryDetails } = this.state;

        console.log(exportData,"exportData")
        return (

            <>

                <section className="landing-page-pt">
                    <div className="container">
                        <MrHeader />
                        <div className="mr-landing-page">
                            <div className="landing-head-pt">
                                {/* <h2>MR Landing page</h2> */}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="landing-profl-detls pt-5">

                    <div className="container">

                        <div className="landing-form-dtls">
                            <div className="row mb-25">
                                <div className="col-md-6">
                                <h5 className="title">Welcome {this.state.mrinfo.name} to Emrok Application (MR)</h5>
                                </div>
                               
                               
                                <div className='col-md-6'>
                                    
                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>
                               

                            </div>


                            <div className="row mt-30 mb-20 ">
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order From Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order To Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={this.state.fromdate}
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // let _registration = this.state.registration
                                                        // _registration.address["state"] = item.name
                                                        // _registration.address["town"] = ""
                                                        this.setState({ selectedState: item.name })
                                                        //console.log(item, "item");
                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Doctors </label>
                                    {/* <select name="doctorname" className="form-control name-pt-ft" onChange={(event) => this.changeDoctor(event)} value={selectedDoctor}>
                                        <option value="">Please Select</option>
                                        {doctorList.length > 0 ? doctorList.map(doctor => (
                                            <option value={doctor._id}>{doctor.name}</option>

                                        )) : <option></option>}

                                    </select> */}

                                    {
                                        doctorList.length ?
                                            <DropdownDoctor
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedDoctor: item.id })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <DropdownDoctor
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }

                                </div>
                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                        <button type="button" className="landing-clear-btn" onClick={(event) => window.location.reload()}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            {/* Summary table start */}
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    prescriptionCount: summaryDetails ? summaryDetails.prescriptionCount || 0 : 0,
                                    totalOrderCount: summaryDetails ? summaryDetails.totalOrderCount || 0 : 0,
                                    // cancelledOrderCount: summaryDetails ? summaryDetails.cancelledOrderCount || 0 : 0,
                                    completeOrderCount: summaryDetails ? summaryDetails.completeOrderCount || 0 : 0,
                                    pendingOrderCount: summaryDetails ? summaryDetails.pendingOrderCount || 0 : 0
                                }]}
                                clickFn={this.clickFn}
                            />
                            {/* <div className="summary-table mt-32 mb-4">
                                <h5 className="mb-20">Summary</h5>
                                <div className="table-responsive">
                                    <table className="table table-bordered-custom">
                                        <thead>
                                            <tr>
                                                <th scope="col">No of Total Dr Prescribed</th>
                                                <th scope="col">Total Rx generated</th>
                                                <th scope="col">Total Rx Cancel</th>
                                                <th scope="col">Total Rx Fulfilled</th>
                                                <th scope="col">Total Rx Under process</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                                <td>Lorem</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div> */}
                            {/* Summary table end */}
                            <h5 className="mb-20">Details</h5>
                            <div className="table-responsive">

                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No'
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Stockist Name',
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Patient State',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Patient City',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.total,

                                                },
                                                // {
                                                //     name: 'Shipment',
                                                //     // selector: row => row.total,

                                                // },
                                                {
                                                    name: 'Delivery Date',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Order Value',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Product',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.orderStatus,

                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.mrDoctorList)}
                                            handleTrackOrder={this.handleTrackOrder}
                                            pageName="mrlanding"

                                        /> : <p className="text-center">No Records Found</p>
                                }



                                {/* <table
                                    id="example"
                                    className="table table-striped tbl-chng-pt table-responsive"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th width="25%">Doctor Name</th>
                                            <th width="25%">No. of Prescription</th>
                                            <th width="25%">No. of Fulfill</th>
                                            <th width="25%">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.mrDoctorList.map((item, index) => (
                                        <tr>
                                            <td>{item.doctorDetails.name}</td>
                                            <td>{item.prescriptionCount}</td>
                                            <td></td>
                                            <td>
                                                <button type="button" className="landing-success-btn">
                                                    Successful
                                                </button>
                                            </td>
                                        </tr>

                                    ))}
                                        
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, MrReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { mrdetails } = MrReducer
    const { summaryDetails } = CommonReducer
    console.log(customer, 123);
    console.log(mrdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        mrdetails,
        summaryDetails
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(MrDetails)))