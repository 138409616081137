export const CUSTOMER_LOGIN_API = "customer/customer-login";
export const GENERATE_OTP = "/save-otp";
export const MATCH_OTP = "/match-otp";
export const CUSTOMER_DETAIL = "customer/customer-detail";
export const UPDATE_CUSTOMER = "customer/update-customer";
export const GET_PRODUCT = "product/get";
export const CREATE_ORDER = "order/create";
export const UPLOAD_PRESCRIPTION = "/customer/prescription-upload";
export const UPLOAD_PRESCRIPTION_UPDATE_ORDER = "/customer/prescription-upload-update-order";
export const GET_PRESCRIPTION = "/customer/get-prescription";
export const ORDER_LIST_PATH = "/order/order-list";
export const UPDATE_ORDER_STATUS = "/order/update-satatus";
export const GET_CUSTOMER_OUT = "/sap/make-order-customer-xlsx";
export const SEND_WHATSAPP_MESSAGE = "/send-delivery-message";
export const GENERATE_SMS_SERVICE = "/send-place-order-msg";
export const MR_LOGIN_API = "/mr/login";
export const MR_DETAIL = "/mr/list";
export const MR_INFO = "/mr/info";
export const USER_DETAIL = "/mr/user-list";
export const USER_INFO = "/mr/user-info";
export const GET_DOCTOR_BY_MR = "/mr/get-doctor-by-mr";
export const CALL_CENTER_INFO = "/callcenter/info";
export const CALL_CENTER_DETAIL = "/callcenter/list";
export const STOCKIST_INFO = "/stockist/info";
export const STOCKIST_DETAIL = "/stockist/list";
export const GET_CUSTOMER_DETAILS_OUT = "/sap/make-customer-xlsx";
export const GET_ORDER_DETAILS_OUT = "/sap/make-order-xlsx";
export const CREATE_FEEDBACK = "/customer/create-feedback";
export const FEEDBACK_LIST = "/customer/feedback-list";
export const INITIATE_PAYMENT = "/ccavRequestHandler";
export const GET_ORDERS = "/customer/get-orders";
export const GET_ENC_REQUEST = "/get-enc-request";
export const USER_LOGIN_API = "/user-login-emrok";
export const CUSTOMER_LOGIN_TRACKING_API = "/customer-login-tracking";
export const GET_ORDER_DETAILS_BY_ID = "/get-order-details-by-id";
export const DOCTOR_LOGIN_API = "/doctor/login";
export const DOCTOR_DETAIL = "/doctor/details";
export const DOCTOR_INFO = "/doctor/info";
export const GENERATE_CHECKSUM = "/payment/generate-checksum"
export const GET_TRANSACTION = "/payment/get-transaction"
export const CANCEL_ORDER = "order/cancel"
export const UPDATE_ORDER_PAYMENT_TYPE = "order/update-payment-type"
export const GET_TRANSACTION_DETAILS = "order/get-transaction"
export const GET_SUMMARY = "/default/summary"
export const APPROVED_PRESCRIPTION = "stockist/approved-prescription"
export const GET_DISCOUNT_VALUE = "order/fetch-discount"
export const GET_COUPON_VALUE = "/order/fetch-coupon"
export const GET_AUDIT_LIST = "/callcenter/fetch-audit-list"
export const GET_USERS = "/mr/get-user"
export const GET_ALL_PRODUCTS = "/product/get"
export const GET_PRODUCT_BY_DOCTOR = '/product/get-product-by-doctor'
export const GET_STOCKIST_CODE = "/product/stockist-code"
export const GET_PRODUCT_BY_PLANT_CODE = "/product/get-product-by-plant-code"
export const GET_STATUS_TRACK = "/order/get-status-track"
export const ADMIN_INFO = "/admin/info"
export const USER_LIST = "/admin/user-list"
export const SEARCH_BY_DOCTOR = "/admin/search-by-doctor"
export const DOWNLOAD_QR = "/admin/download-qr"
export const GET_ALL_CALL_CENTER = "/admin/get-all-call-center"
export const GET_ALL_ORDER_DETAILS = "/admin/get-all-order-count"
export const GET_CUSTOMER_COUNT = "/admin/get-customer-count"
export const UPDATE_CALL_CENTER = "/admin/update-call-center"
export const CREATE_CALL_CENTER = "/admin/create-call-center"
export const REMOVE_CALL_CENTER = "/admin/remove-call-center"
export const GET_ALL_STOCKIST = "/admin/get-all-stockist"
export const UPDATE_STOCKIST = "/admin/update-stockist"
export const GET_ALL_PRODUCTS_BY_MATERIAL = "/product/get-product-by-material-emrok"
export const UPLOAD_PRODUCT_IMAGE = "/product/change-image"
export const PAYMENT_REFUND = "/payment/get-refund"


//ayan
export const GET_ALL_PRODUCT = "/product/get-all-products"
export const ADD_PRODUCT = "/product/add-product"

