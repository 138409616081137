import {
  SAVE_PRESCRIPTION,
    SET_CUSTOMER,SET_CUSTOMER_TOKEN,SET_CONSENT_CHECKBOX,CLEAR_CONSENT_CHECKBOX,  CLEAR_CUSTOMER_STATE,
  } from '../types/Customer.type'
  class CustomerAction {
    setCustomer = (data) => {
      console.log("jsdlkfjsdlkjsd",data)
      return { type: SET_CUSTOMER, payload: data }
    }

    savePrescription = (data) => {
      return { type: SAVE_PRESCRIPTION, payload: data }
    }

    setConsentCheckbox = (data) => {
      console.log(">>>>>>>>>>>>>>>>",data)
      return { type: SET_CONSENT_CHECKBOX, payload: data }
    }
    clearConsentCheckbox = () => {
     
      return { type: CLEAR_CONSENT_CHECKBOX, payload: null }
    }
    clearState = () => {
      return { type: CLEAR_CUSTOMER_STATE, payload: null }
    }
    
  }
  export default new CustomerAction()