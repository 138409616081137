import {
    SET_CALL_CENTER,
    CLEAR_STATE,
    SET_CALL_CENTER_CUSTOMER,
    STORE_SEARCH_VALUE
} from '../types/CallCenter.type'

const initState = {
    callcenterdetails: {},
    callcentercustomer: {},
    token: null,
    storeSearchData: {
        callcenterdetails: null,
        scallcenterid: "",
        totalRevenue: 0,
        storeSearchData: null,
        callcenterList: [],
        callcenterinfo: "",
        callcenterdetailsList: [],
        countries: [],
        states: [],
        towns: [],
        showTowns: false,
        selectedState: "",
        selectedTown: "",
        selectedDoctor: "",
        fromdate: "",
        todate: "",
        currentDate: "",
        doctorList: [],
        isSearch: false,
        isShowCancelModal: false,
        cancelOrderId: null,
        cancelReason: "",
        mobileno: "",
        summaryCols: ["Total Rx Generated", "Total Rx Fulfilled", "Total Rx Under Process", "Order Pending", "Pickup Initiated", "Order Delivered","Total Revenue"],
        summaryDetails: null,
        setorderStatus: "",
        exportData: null,
        fileName: "Callcenter_Data",
    }
}

export default (state = initState, action) => {
    const { type, payload } = action
    console.log(action, 565656);
    switch (type) {
        case SET_CALL_CENTER:
            return Object.assign({}, state, { callcenterdetails: { ...payload } })
        case SET_CALL_CENTER_CUSTOMER:
            return Object.assign({}, state, { callcentercustomer: { ...payload } })
        case STORE_SEARCH_VALUE:
            return Object.assign({}, state, { storeSearchData: payload })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}