import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import moment from 'moment';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import DropdownDoctorRuntime from '../../../components/SearchableDropdown/DropdownDoctorRuntime.component';
import AdminService from '../../../services/Admin.service';
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import CallCenterAction from '../../../redux/actions/CallCenter.action';
class CallCenterManagmentDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            callCenterList: []
        }

    }


    componentDidMount() {
        this.getCallCenterDetails();
    }

    getCallCenterDetails = async () => {
        try {
            this.setState({ callCenterList: [] })

            let res = await AdminService.getallCallCenter()
            console.log(res, 123333)
            if (res.data.success) {
                console.log(res.data.data.data, "lasjdhjkash");
                this.setState({ callCenterList: res.data.data.data });
            }

        } catch (error) {

        }
    }

    handleEditCallCenter = async (event) =>{
        event.preventDefault();
        const {callCenterList} = this.state;
        const callcenter_id = event.target.id;
        const selectedCallcenter = await callCenterList.filter(item => item._id == callcenter_id)
        console.log(selectedCallcenter, "selectedCallcenter");
        if (selectedCallcenter.length) {

            // let order_details
            //  selectedPatientDetails[0].customer['order_id'] = selectedPatientDetails[0].order_i

            // console.log(this.props.callcenterdetails, "callcender reducer")
            // this.props.setCallCenter(callCenterDetails);
            this.props.setCallCenter(selectedCallcenter[0])
        } else {
            // this.props.setCustomer(selectedPatientDetails)
        }

         this.props.navigate('/admin/user-managment/callcenter/details');
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            resp.push({
                _id: data[i]._id,
                name: data[i].name,
                email: data[i].email,
                mobileno: data[i].mobileno,
            })
        }
        return resp
    }
    handleAdd = () =>{
        this.props.navigate('/admin/user-managment/callcenter/add');
    }

    removeCallCenter = (event) =>{

        console.log(event.target.id);
        if(event.target.id!=null){
            this.props.hoc.customAlert('Are you sure you want to remove?', false, "", true, true, async ()  => {
                let payload = {
                    id:event.target.id
                }
                let res = await AdminService.removeCallCenter(payload)
                console.log(res, 123333)
                if (res.data.success) {
                    this.props.hoc.customAlert('Record deleted successfully', true);
                    this.getCallCenterDetails();
                }    
            }, () => {
    
            })
        }else{
            this.props.hoc.customAlert('Something went wrong', false)
        }


    }

    render = () => {
        const { callCenterList } = this.state;
        let _data = this.setOrderHistoryData(callCenterList)
        console.log(_data, "aksdhklsah");
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className='col-md-5'>
                                        <h5 className='mb-20'>Call Center Management</h5>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.handleAdd}>
                                                Add
                                            </a>
                                        </div>
                                    </div>
                                    {/* Row end */}
                                    <div className="row g-4">

                                        <div className="table-responsive">


                                            {
                                                _data && _data.length ?
                                                    <CustomDatatable
                                                        pagination={true}
                                                        columns={[
                                                            {
                                                                name: 'Name',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Email',
                                                                // selector: row => row.mr_name,

                                                            },
                                                            {
                                                                name: 'Mobile No.',
                                                                // selector: row => row.mr_name,

                                                            },

                                                            {
                                                                name: 'Action',
                                                                // selector: row => row.createdAt,

                                                            }
                                                        ]}
                                                        data={this.setOrderHistoryData(callCenterList)}
                                                        pageName="callcentermanagment"
                                                        handleEditCallCenter={this.handleEditCallCenter}
                                                        removeCallCenter={this.removeCallCenter}
                                                        
                                                       
                                                    />
                                                    : <p className="norecord"><strong>No Records Found</strong></p>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter />
                    </div>
                </div>

            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCallCenter: (callcenterdetails) => dispatch(CallCenterAction.setCallCenter(callcenterdetails))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        CallCenterReducer: state.CallCenterReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(CallCenterManagmentDashboard)))