import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  CALL_CENTER_DETAIL,
  CALL_CENTER_INFO,
  GET_AUDIT_LIST
} from './Slugs'
class CallCenterService extends Base {
  constructor(props) {
    super(props)
  }

 
  getCallCenterDetails(data) {
    return this.post(CALL_CENTER_DETAIL,data);
  }
  getCallCenterInfo(data) {
    return this.post(CALL_CENTER_INFO,data);
  }

  getAuditList(data){
    return this.post(GET_AUDIT_LIST,data);
  }

}

export default new CallCenterService()