import {
    SET_MR,
    LOGOUT,
    SET_BU_STATE_DETAILS,
    SET_NSM_STATE_DETAILS
} from '../types/Mr.type'

const initState = {
    mrdetails: {},
    token: null,
    bustateDetails: {
        mrdetails: null,
        mrid: "",
        mrDoctorList: [],
        mrinfo: "",
        states: [],
        towns: [],
        showTowns: false,
        selectedState: "",
        selectedTown: "",
        selectedDoctor: "",
        fromdate: "",
        todate: "",
        currentDate: "",
        doctorList: [],
        isSearch: false,
        summaryCols: ["No. of Total Dr. Prescribed", "Total Rx Generated", "Total Rx Fulfilled", "Total Rx Under Process"],
        summaryDetails: null,
        setorderStatus: "",
        exportData: null,
        fileName: "BU_Data",
        nsmList: [],
        zmList: [],
        rmList: [],
        mrList: [],
        productList: [],
        selectedNSM: "",
        selectedZM: "",
        selectedRM: "",
        selectedMR: "",
        selectedProduct: ""
    },
    nsmStateDetails: {
        mrdetails: null,
        mrid: "",
        mrDoctorList: [],
        mrinfo: "",
        states: [],
        towns: [],
        showTowns: false,
        selectedState: "",
        selectedTown: "",
        selectedDoctor: "",
        fromdate: "",
        todate: "",
        currentDate: "",
        doctorList: [],
        isSearch: false,
        summaryCols: ["No. of Total Dr. Prescribed", "Total Rx Generated", "Total Rx Fulfilled", "Total Rx Under Process"],
        summaryDetails: null,
        setorderStatus: "",
        exportData: null,
        fileName: "NSM_Data",
        nsmList: [],
        zmList: [],
        rmList: [],
        mrList: [],
        productList: [],
        selectedNSM: "",
        selectedZM: "",
        selectedRM: "",
        selectedMR: "",
        selectedProduct: ""
    },
    
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_MR:
            return Object.assign({}, state, { mrdetails: { ...payload } })
        case SET_BU_STATE_DETAILS:
            return Object.assign({}, state, { bustateDetails: payload })
        case SET_BU_STATE_DETAILS:
            return Object.assign({}, state, { SET_NSM_STATE_DETAILS: payload })
        case LOGOUT:
            return initState;
        default:
            return state
    }
}