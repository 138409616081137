import React, { useEffect } from "react";
import ModalCheckIcon from "../../assets/Svg/ModalCheckIcon";
import { useNavigate } from 'react-router-dom';
import $ from "jquery"
export default function OrderCancelModal({ message,handleOkClick,handleRadioClick,cancelReasonData }) {

    console.log(message, "message data");


    return (
        <>
            <div
                className="modal modal-xs fade"
                id="orderCanceledModal"
                aria-hidden="true"
                aria-labelledby="paymentSuccessModalLabel"
                tabIndex={-1}
                data-keyboard="false"
                backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered modal-payment-success">
                    <div className="modal-content border-0">
                        <div className="modal-check-icon">
                            {/* <ModalCheckIcon /> */}
                        </div>
                        <div className="modal-header border-0 pt-5 mx-auto">
                            <h1
                                className="modal-title fs-5 text-center fw-bold"
                                id="paymentSuccessModalLabel"
                            >
                                {message}
                            </h1>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body pd_lft_rad">
                            {/* <p className="text-center">
                                You’ll get confirmation Message soon. Your item are now ready for
                                shipment.
                            </p> */}
                            <p className="red-text">Please choose reason</p>
                            
                            <p><input type="radio" id="html" name="cancel_reason" value="Invalid Prescription" onClick={handleRadioClick}></input>
                            <label htmlFor="html"  className="rad_opt" >Invalid Prescription</label></p> 
                            <p><input type="radio" id="html1" name="cancel_reason" value="Others" onClick={handleRadioClick}></input>
                            <label htmlFor="html1"  className="rad_opt" >Others</label></p> 
                                

                           

                        </div>
                        <div className="modal-footer border-0">
                            {cancelReasonData!=""?<button className="btn bg-primary w-100" data-bs-dismiss="modal" onClick={handleOkClick}>Ok</button>:<button className="btn bg-primary w-100">Ok</button>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}