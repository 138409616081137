// import React, { Component } from 'react'
import React, { Component, useState, useRef } from 'react';
import { connect } from "react-redux";
import OtherService from '../../../services/OtherService';
import style from "./Login.style";
import MrService from "../../../services/Mr.service";
import { withRouter } from "../../../components/Reusable/WithRouter";
import MrAction from "../../../redux/actions/Mr.action";
import CommonAction from '../../../redux/actions/Common.action';
import Common from '../../../hoc/Common.hoc';
import TimerComponent from '../../../components/Timer/LoginTimer';
import { error } from 'jquery';
import config from '../../../config/emrok.config';

class LoginMR extends Component {
    constructor(props) {
        super(props)
        this.firstRef = React.createRef();
        this.secondRef = React.createRef();
        this.thirdRef = React.createRef();
        this.fourthRef = React.createRef();
        // this.inputRefs = []; // Array to store input field references

        this.state = {
            mobileno: "",
            isMobileVerified: false,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            // currentInputIndex: 0,
            combineOtpValidLength: ""
        }
        console.log(props)
        // alert("mr login")
    }



    componentDidMount() {

        const { userType } = this.props;
        console.log(this.props)
        console.log(userType,"mrlogin");
    }
    /**
     * 
     * @param {Object} event 
     */
    setNumber = (event) => {
        const val = event.target.value;
        const reg = new RegExp('^[0-9]+$');
        if (!val || (reg.test(val) && val.length <= 10)) {
            this.setState({ mobileno: val })
        }
    }

    verifyMobileSubmit = (e) => {
        e.preventDefault();
        const { mobileno } = this.state;
        const data = { mobileno }
        if (mobileno.length == 10) {
            OtherService.generateOtp(data).then(res => {
                console.log(res)
                if (res && res.data && res.data.success) {
                    this.setState({ isMobileVerified: true })
                }
            }).catch(err => {
                console.log(err)
            });
        }

    }

    mobileVerify = () => {
        const { mobileno } = this.state;
        const isValid = mobileno.length == 10;
        return (
            <section className="login-part-st">
                <div className="container">

                    <div className="login-form">
                        <div className="muown-logo">
                            <img src={require("../../../assets/images/logo-image.png")} />
                        </div>

                        <h2 className="pb-3">Welcome to EMROK</h2>

                        <div className="mobile-inpt-pt pt-3">
                            <form onSubmit={e => { e.preventDefault(); }}>
                                <div className="cl-inpt-part-st"><input onChange={(event) => {
                                    this.setNumber(event)
                                }} value={mobileno} type="text" className="form-control mbl-inpt-part" placeholder="Enter Mobile Number" /></div>
                            </form>
                        </div>

                        <div className="know-mr-btn pt-3">
                            <p><a href={config.knowMoreLink} target="_blank" rel="noopener noreferrer">Know more</a></p>
                        </div>

                        <div className="pt-3">
                            <a type="button" disabled={true} style={{ cursor: isValid ? "pointer" : "default" }} className={isValid ? "login-btn-usr" : "login-btn-usr login-disable"} onClick={this.verifyMobileSubmit}>Login</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    combineOtp = () => {
        const { otp1, otp2, otp3, otp4 } = this.state;
        const otp = otp1 + otp2 + otp3 + otp4;
        return otp;
    }
    changeOtp = (event, ref) => {
        const { name, value } = event.target;
        console.log(this.props.hoc.numbericValidator(value), name, value, 5555555)
        if(!this.props.hoc.numbericValidator(value)) {
            this.setState({ [name]: "" })
            return false
        }
        else {
            const callBack = () => {
                const otp = this.combineOtp();
                if (otp.length == 4) {
                    this.setState({ combineOtpValidLength: true })
                }
                else {
                    this.setState({ combineOtpValidLength: false })
                }
                if (ref && value) {
                    ref.focus()
                }
            };
            if (value.length <= 1) {
                this.setState({ [name]: value }, callBack)
                if (value === "" && ref && ref.current) {
                    const prev = ref.current.previousSibling;
                    if (prev) {
                      prev.focus();
                    }
                  }
            }
        }
    }

    verifyOtpSubmit = () => {
        const otp = this.combineOtp();
        const mobileno = this.state.mobileno
        const data = {
            mobileno, otp
        }
        const mrLogin = () => {

            MrService.mrLogin({
                mobileno
            }).then(async (res) => {
                console.log(res.data.data, 111);
                if (res && res.data && res.data.success && res.data.data) {
                    this.props.setMr(res.data.data)
                    this.props.setToken(res.data.data.token)
                    this.props.navigate("/mr/list")
                }
            }).catch(err => {
                this.props.hoc.customAlert("User not found.", false)
                console.log(err.message || err)
            })


        }
        OtherService.verifyOtp(data).then(res => {
            console.log(res, 7878);

            if (res && res.data && res.data.success) {
                // const drId = this.props.param.dr_id;
                mrLogin();

            }
            else if (res && res.data && !res.data.success) {
                this.props.hoc.customAlert(res.data?.message || "Something went wrong.", false)
            }
        }).catch(err => {
            console.log(err)
        })


    }


    // inputfocus = (elmnt) => {
    //     if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
    //         console.log(elmnt.target.value)
    //         const tabindex = elmnt.target.tabIndex;

    //       const prev = elmnt.target.tabIndex - 2;
    //       console.log(prev);
    //       if (prev > -1) {

    //         elmnt.target.form.elements[prev].focus()
    //       }
    //     }
    //     else {
    //       console.log("next");

    //         const next = elmnt.target.tabIndex-1;
    //         console.log(next);
    //         if (next < 4) {
    //           elmnt.target.form.elements[next].focus()
    //         }
    //     }
    // }


    // handleKeyDown = (event) => {
    //     if (event.key === 'Backspace' && event.target.value === '') {
    //        // alert(1)
    //     //   event.preventDefault();
    //       const prevInputIndex = this.state.currentInputIndex - 1;
    //       alert(this.state.currentInputIndex)
    //       if (prevInputIndex <= 0) {
    //         this.setState({ currentInputIndex: prevInputIndex }, () => {
    //           this.inputRefs[prevInputIndex].focus();
    //         });
    //       }
    //     }
    //   };

    otpVerify = () => {
        const { mobileno, otp1, otp2, otp3, otp4, combineOtpValidLength } = this.state;
        const resendOtp = () => {
            
            const { mobileno } = this.state;
            const data = { mobileno }
            if (mobileno.length == 10) {
                OtherService.generateOtp(data).then(res => {
                    console.log(res)
                    if (res && res.data && res.data.success) {
                        this.setState({ isMobileVerified: true })
                    }
                }).catch(err => {
                    console.log(err)
                });
            }
        }

        const inputfocus = (elmnt) => {
            if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
                console.log(elmnt.target.value)
                const tabindex = elmnt.target.tabIndex;

                const prev = elmnt.target.tabIndex - 2;
                console.log(prev);
                if (prev > -1) {

                    elmnt.target.form.elements[prev].focus()
                }
            }
            else {
                console.log("next");

                const next = elmnt.target.tabIndex - 1;
                console.log(next);
                if (next < 4) {
                    elmnt.target.form.elements[next].focus()
                }
            }
        }
        return (
            <section className="login-part-st">
                <div className="container">

                    <div className="login-form">
                        <div className="muown-logo">
                            <img src={require("../../../assets/images/logo-image.png")} />
                        </div>

                        <h2 className="pb-3">Welcome to EMROK</h2>
                        <h1 className="otp-part-pt">OTP SENT</h1>
                        <p className="otp-text-pt">Enter the OTP sent to you</p>

                        <form onSubmit={e => { e.preventDefault(); }}>
                            <div className="otp-clm-gap d-flex mb-3 mt-3">
                                <div className="">
                                    <input type="text" ref={input => { this.firstRef = input }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event, this.secondRef)} value={otp1} name="otp1" tabIndex="1" />
                                </div>
                                <div className="">
                                    <input type="text" ref={input => { this.secondRef = input; }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event, this.thirdRef)} value={otp2} name="otp2" tabIndex="2" />
                                </div>
                                <div>
                                    <input type="text" ref={input => { this.thirdRef = input; }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event, this.fourthRef)} value={otp3} name="otp3" tabIndex="3" />
                                </div>
                                <div className="">
                                    <input type="text" ref={input => { this.fourthRef = input; }} id="otp-transpr-pt" onChange={(event) => this.changeOtp(event)} value={otp4} name="otp4" tabIndex="4" />
                                </div>

                            </div>

                            <div className="resend-otp-fr-login">
                                <span> <TimerComponent  resendOtp={resendOtp} /> </span>
                            </div>
                            <div className="mb-3 mt-4">
                                <a disabled={combineOtpValidLength ? false : true} style={combineOtpValidLength ? { ...style.btn } : null} className="form-control login-btn-usr" id="" onClick={() => this.verifyOtpSubmit()}> Done</a>
                            </div>
                        </form>

                    </div>
                </div>
            </section>
        )
    }

    render = () => {

        const { isMobileVerified } = this.state;
        return (

            <>

                {
                    !isMobileVerified ? this.mobileVerify() : this.otpVerify()
                }
            </>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails)),
        setToken: (token) => dispatch(CommonAction.setToken(token))
    }
}

export default connect(null, mapDispatchToProps)(withRouter(Common(LoginMR)))