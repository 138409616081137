export const states = [
  {
      "name": "Andaman and Nicobar Islands",
      "isoCode": "AN",
      "countryCode": "IN",
      "latitude": "11.74008670",
      "longitude": "92.65864010",
      "label": "Andaman and Nicobar Islands",
      "value": "Andaman and Nicobar Islands"
  },
  {
      "name": "Andhra Pradesh",
      "isoCode": "AP",
      "countryCode": "IN",
      "latitude": "15.91289980",
      "longitude": "79.73998750",
      "label": "Andhra Pradesh",
      "value": "Andhra Pradesh"
  },
  {
      "name": "Arunachal Pradesh",
      "isoCode": "AR",
      "countryCode": "IN",
      "latitude": "28.21799940",
      "longitude": "94.72775280",
      "label": "Arunachal Pradesh",
      "value": "Arunachal Pradesh"
  },
  {
      "name": "Assam",
      "isoCode": "AS",
      "countryCode": "IN",
      "latitude": "26.20060430",
      "longitude": "92.93757390",
      "label": "Assam",
      "value": "Assam"
  },
  {
      "name": "Bihar",
      "isoCode": "BR",
      "countryCode": "IN",
      "latitude": "25.09607420",
      "longitude": "85.31311940",
      "label": "Bihar",
      "value": "Bihar"
  },
  {
      "name": "Chandigarh",
      "isoCode": "CH",
      "countryCode": "IN",
      "latitude": "30.73331480",
      "longitude": "76.77941790",
      "label": "Chandigarh",
      "value": "Chandigarh"
  },
  {
      "name": "Chhattisgarh",
      "isoCode": "CT",
      "countryCode": "IN",
      "latitude": "21.27865670",
      "longitude": "81.86614420",
      "label": "Chhattisgarh",
      "value": "Chhattisgarh"
  },
  {
      "name": "Dadra and Nagar Haveli and Daman and Diu",
      "isoCode": "DH",
      "countryCode": "IN",
      "latitude": "20.39737360",
      "longitude": "72.83279910",
      "label": "Dadra and Nagar Haveli and Daman and Diu",
      "value": "Dadra and Nagar Haveli and Daman and Diu"
  },
  {
      "name": "Delhi",
      "isoCode": "DL",
      "countryCode": "IN",
      "latitude": "28.70405920",
      "longitude": "77.10249020",
      "label": "Delhi",
      "value": "Delhi"
  },
  {
      "name": "Goa",
      "isoCode": "GA",
      "countryCode": "IN",
      "latitude": "15.29932650",
      "longitude": "74.12399600",
      "label": "Goa",
      "value": "Goa"
  },
  {
      "name": "Gujarat",
      "isoCode": "GJ",
      "countryCode": "IN",
      "latitude": "22.25865200",
      "longitude": "71.19238050",
      "label": "Gujarat",
      "value": "Gujarat"
  },
  {
      "name": "Haryana",
      "isoCode": "HR",
      "countryCode": "IN",
      "latitude": "29.05877570",
      "longitude": "76.08560100",
      "label": "Haryana",
      "value": "Haryana"
  },
  {
      "name": "Himachal Pradesh",
      "isoCode": "HP",
      "countryCode": "IN",
      "latitude": "31.10482940",
      "longitude": "77.17339010",
      "label": "Himachal Pradesh",
      "value": "Himachal Pradesh"
  },
  {
      "name": "Jammu and Kashmir",
      "isoCode": "JK",
      "countryCode": "IN",
      "latitude": "33.27783900",
      "longitude": "75.34121790",
      "label": "Jammu and Kashmir",
      "value": "Jammu and Kashmir"
  },
  {
      "name": "Jharkhand",
      "isoCode": "JH",
      "countryCode": "IN",
      "latitude": "23.61018080",
      "longitude": "85.27993540",
      "label": "Jharkhand",
      "value": "Jharkhand"
  },
  {
      "name": "Karnataka",
      "isoCode": "KA",
      "countryCode": "IN",
      "latitude": "15.31727750",
      "longitude": "75.71388840",
      "label": "Karnataka",
      "value": "Karnataka"
  },
  {
      "name": "Kerala",
      "isoCode": "KL",
      "countryCode": "IN",
      "latitude": "10.85051590",
      "longitude": "76.27108330",
      "label": "Kerala",
      "value": "Kerala"
  },
  {
      "name": "Ladakh",
      "isoCode": "LA",
      "countryCode": "IN",
      "latitude": "34.22684750",
      "longitude": "77.56194190",
      "label": "Ladakh",
      "value": "Ladakh"
  },
  {
      "name": "Lakshadweep",
      "isoCode": "LD",
      "countryCode": "IN",
      "latitude": "10.32802650",
      "longitude": "72.78463360",
      "label": "Lakshadweep",
      "value": "Lakshadweep"
  },
  {
      "name": "Madhya Pradesh",
      "isoCode": "MP",
      "countryCode": "IN",
      "latitude": "22.97342290",
      "longitude": "78.65689420",
      "label": "Madhya Pradesh",
      "value": "Madhya Pradesh"
  },
  {
      "name": "Maharashtra",
      "isoCode": "MH",
      "countryCode": "IN",
      "latitude": "19.75147980",
      "longitude": "75.71388840",
      "label": "Maharashtra",
      "value": "Maharashtra"
  },
  {
      "name": "Manipur",
      "isoCode": "MN",
      "countryCode": "IN",
      "latitude": "24.66371730",
      "longitude": "93.90626880",
      "label": "Manipur",
      "value": "Manipur"
  },
  {
      "name": "Meghalaya",
      "isoCode": "ML",
      "countryCode": "IN",
      "latitude": "25.46703080",
      "longitude": "91.36621600",
      "label": "Meghalaya",
      "value": "Meghalaya"
  },
  {
      "name": "Mizoram",
      "isoCode": "MZ",
      "countryCode": "IN",
      "latitude": "23.16454300",
      "longitude": "92.93757390",
      "label": "Mizoram",
      "value": "Mizoram"
  },
  {
      "name": "Nagaland",
      "isoCode": "NL",
      "countryCode": "IN",
      "latitude": "26.15843540",
      "longitude": "94.56244260",
      "label": "Nagaland",
      "value": "Nagaland"
  },
  {
      "name": "Odisha",
      "isoCode": "OR",
      "countryCode": "IN",
      "latitude": "20.95166580",
      "longitude": "85.09852360",
      "label": "Odisha",
      "value": "Odisha"
  },
  {
      "name": "Puducherry",
      "isoCode": "PY",
      "countryCode": "IN",
      "latitude": "11.94159150",
      "longitude": "79.80831330",
      "label": "Puducherry",
      "value": "Puducherry"
  },
  {
      "name": "Punjab",
      "isoCode": "PB",
      "countryCode": "IN",
      "latitude": "31.14713050",
      "longitude": "75.34121790",
      "label": "Punjab",
      "value": "Punjab"
  },
  {
      "name": "Rajasthan",
      "isoCode": "RJ",
      "countryCode": "IN",
      "latitude": "27.02380360",
      "longitude": "74.21793260",
      "label": "Rajasthan",
      "value": "Rajasthan"
  },
  {
      "name": "Sikkim",
      "isoCode": "SK",
      "countryCode": "IN",
      "latitude": "27.53297180",
      "longitude": "88.51221780",
      "label": "Sikkim",
      "value": "Sikkim"
  },
  {
      "name": "Tamil Nadu",
      "isoCode": "TN",
      "countryCode": "IN",
      "latitude": "11.12712250",
      "longitude": "78.65689420",
      "label": "Tamil Nadu",
      "value": "Tamil Nadu"
  },
  {
      "name": "Telangana",
      "isoCode": "TG",
      "countryCode": "IN",
      "latitude": "18.11243720",
      "longitude": "79.01929970",
      "label": "Telangana",
      "value": "Telangana"
  },
  {
      "name": "Tripura",
      "isoCode": "TR",
      "countryCode": "IN",
      "latitude": "23.94084820",
      "longitude": "91.98815270",
      "label": "Tripura",
      "value": "Tripura"
  },
  {
      "name": "Uttar Pradesh",
      "isoCode": "UP",
      "countryCode": "IN",
      "latitude": "26.84670880",
      "longitude": "80.94615920",
      "label": "Uttar Pradesh",
      "value": "Uttar Pradesh"
  },
  {
      "name": "Uttarakhand",
      "isoCode": "UT",
      "countryCode": "IN",
      "latitude": "30.06675300",
      "longitude": "79.01929970",
      "label": "Uttarakhand",
      "value": "Uttarakhand"
  },
  {
      "name": "West Bengal",
      "isoCode": "WB",
      "countryCode": "IN",
      "latitude": "22.98675690",
      "longitude": "87.85497550",
      "label": "West Bengal",
      "value": "West Bengal"
  }
]

export const cities =
[
    {
        "name": "Bamboo Flat",
        "countryCode": "IN",
        "stateCode": "AN",
        "latitude": "11.70000000",
        "longitude": "92.71667000",
        "label": "Bamboo Flat",
        "value": "Bamboo Flat"
    },
    {
        "name": "Nicobar",
        "countryCode": "IN",
        "stateCode": "AN",
        "latitude": "7.03002000",
        "longitude": "93.79028000",
        "label": "Nicobar",
        "value": "Nicobar"
    },
    {
        "name": "Port Blair",
        "countryCode": "IN",
        "stateCode": "AN",
        "latitude": "11.66613000",
        "longitude": "92.74635000",
        "label": "Port Blair",
        "value": "Port Blair"
    },
    {
        "name": "South Andaman",
        "countryCode": "IN",
        "stateCode": "AN",
        "latitude": "10.75776000",
        "longitude": "92.52136000",
        "label": "South Andaman",
        "value": "South Andaman"
    }
,{
        "name": "Addanki",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.81061000",
        "longitude": "79.97338000",
        "label": "Addanki",
        "value": "Addanki"
    },
    {
        "name": "Adoni",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.62788000",
        "longitude": "77.27495000",
        "label": "Adoni",
        "value": "Adoni"
    },
    {
        "name": "Akasahebpet",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.50455000",
        "longitude": "82.56597000",
        "label": "Akasahebpet",
        "value": "Akasahebpet"
    },
    {
        "name": "Akividu",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.58225000",
        "longitude": "81.38112000",
        "label": "Akividu",
        "value": "Akividu"
    },
    {
        "name": "Akkarampalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.65000000",
        "longitude": "79.42000000",
        "label": "Akkarampalle",
        "value": "Akkarampalle"
    },
    {
        "name": "Amalapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.57868000",
        "longitude": "82.00609000",
        "label": "Amalapuram",
        "value": "Amalapuram"
    },
    {
        "name": "Amudalavalasa",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.41025000",
        "longitude": "83.90295000",
        "label": "Amudalavalasa",
        "value": "Amudalavalasa"
    },
    {
        "name": "Anakapalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.69134000",
        "longitude": "83.00395000",
        "label": "Anakapalle",
        "value": "Anakapalle"
    },
    {
        "name": "Anantapur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.55000000",
        "longitude": "77.41667000",
        "label": "Anantapur",
        "value": "Anantapur"
    },
    {
        "name": "Atmakur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.88109000",
        "longitude": "78.58704000",
        "label": "Atmakur",
        "value": "Atmakur"
    },
    {
        "name": "Attili",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.70000000",
        "longitude": "81.60000000",
        "label": "Attili",
        "value": "Attili"
    },
    {
        "name": "Avanigadda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.02148000",
        "longitude": "80.91808000",
        "label": "Avanigadda",
        "value": "Avanigadda"
    },
    {
        "name": "Badvel",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.74510000",
        "longitude": "79.06288000",
        "label": "Badvel",
        "value": "Badvel"
    },
    {
        "name": "Banganapalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.31771000",
        "longitude": "78.22669000",
        "label": "Banganapalle",
        "value": "Banganapalle"
    },
    {
        "name": "Bapatla",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.90422000",
        "longitude": "80.46743000",
        "label": "Bapatla",
        "value": "Bapatla"
    },
    {
        "name": "Betamcherla",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.45144000",
        "longitude": "78.14797000",
        "label": "Betamcherla",
        "value": "Betamcherla"
    },
    {
        "name": "Bhattiprolu",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.10260000",
        "longitude": "80.78074000",
        "label": "Bhattiprolu",
        "value": "Bhattiprolu"
    },
    {
        "name": "Bhimavaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.54078000",
        "longitude": "81.52322000",
        "label": "Bhimavaram",
        "value": "Bhimavaram"
    },
    {
        "name": "Bhimunipatnam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.89017000",
        "longitude": "83.45203000",
        "label": "Bhimunipatnam",
        "value": "Bhimunipatnam"
    },
    {
        "name": "Bobbili",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.57366000",
        "longitude": "83.35925000",
        "label": "Bobbili",
        "value": "Bobbili"
    },
    {
        "name": "Challapalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.11756000",
        "longitude": "80.93139000",
        "label": "Challapalle",
        "value": "Challapalle"
    },
    {
        "name": "Chemmumiahpet",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.89794000",
        "longitude": "79.32129000",
        "label": "Chemmumiahpet",
        "value": "Chemmumiahpet"
    },
    {
        "name": "Chilakalurupet",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.08987000",
        "longitude": "80.16705000",
        "label": "Chilakalurupet",
        "value": "Chilakalurupet"
    },
    {
        "name": "Chinnachowk",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.47516000",
        "longitude": "78.83540000",
        "label": "Chinnachowk",
        "value": "Chinnachowk"
    },
    {
        "name": "Chipurupalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.31142000",
        "longitude": "83.56846000",
        "label": "Chipurupalle",
        "value": "Chipurupalle"
    },
    {
        "name": "Chirala",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.82385000",
        "longitude": "80.35219000",
        "label": "Chirala",
        "value": "Chirala"
    },
    {
        "name": "Chittoor",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.41667000",
        "longitude": "79.00000000",
        "label": "Chittoor",
        "value": "Chittoor"
    },
    {
        "name": "Chodavaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.82884000",
        "longitude": "82.93526000",
        "label": "Chodavaram",
        "value": "Chodavaram"
    },
    {
        "name": "Cuddapah",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.41667000",
        "longitude": "78.75000000",
        "label": "Cuddapah",
        "value": "Cuddapah"
    },
    {
        "name": "Cumbum",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.58171000",
        "longitude": "79.11059000",
        "label": "Cumbum",
        "value": "Cumbum"
    },
    {
        "name": "Darsi",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.76978000",
        "longitude": "79.67939000",
        "label": "Darsi",
        "value": "Darsi"
    },
    {
        "name": "Dharmavaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.41435000",
        "longitude": "77.72035000",
        "label": "Dharmavaram",
        "value": "Dharmavaram"
    },
    {
        "name": "Dhone",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.39520000",
        "longitude": "77.87150000",
        "label": "Dhone",
        "value": "Dhone"
    },
    {
        "name": "Diguvametta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.39507000",
        "longitude": "78.82930000",
        "label": "Diguvametta",
        "value": "Diguvametta"
    },
    {
        "name": "East Godavari",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.83333000",
        "longitude": "81.83333000",
        "label": "East Godavari",
        "value": "East Godavari"
    },
    {
        "name": "Elamanchili",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.54907000",
        "longitude": "82.85749000",
        "label": "Elamanchili",
        "value": "Elamanchili"
    },
    {
        "name": "Ellore",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.71311000",
        "longitude": "81.10437000",
        "label": "Ellore",
        "value": "Ellore"
    },
    {
        "name": "Emmiganur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.77203000",
        "longitude": "77.48345000",
        "label": "Emmiganur",
        "value": "Emmiganur"
    },
    {
        "name": "Erraguntla",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.63853000",
        "longitude": "78.53974000",
        "label": "Erraguntla",
        "value": "Erraguntla"
    },
    {
        "name": "Etikoppaka",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.50000000",
        "longitude": "82.73333000",
        "label": "Etikoppaka",
        "value": "Etikoppaka"
    },
    {
        "name": "Gajuwaka",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.70000000",
        "longitude": "83.21667000",
        "label": "Gajuwaka",
        "value": "Gajuwaka"
    },
    {
        "name": "Ganguvada",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.66667000",
        "longitude": "84.11667000",
        "label": "Ganguvada",
        "value": "Ganguvada"
    },
    {
        "name": "Gannavaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.54092000",
        "longitude": "80.80213000",
        "label": "Gannavaram",
        "value": "Gannavaram"
    },
    {
        "name": "Giddalur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.37439000",
        "longitude": "78.92609000",
        "label": "Giddalur",
        "value": "Giddalur"
    },
    {
        "name": "Gokavaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.25823000",
        "longitude": "81.84985000",
        "label": "Gokavaram",
        "value": "Gokavaram"
    },
    {
        "name": "Gorantla",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.98411000",
        "longitude": "77.77224000",
        "label": "Gorantla",
        "value": "Gorantla"
    },
    {
        "name": "Govindapuram,Chilakaluripet,Guntur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.15477000",
        "longitude": "80.10279000",
        "label": "Govindapuram,Chilakaluripet,Guntur",
        "value": "Govindapuram,Chilakaluripet,Guntur"
    },
    {
        "name": "Gudivada",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.43547000",
        "longitude": "80.99555000",
        "label": "Gudivada",
        "value": "Gudivada"
    },
    {
        "name": "Gudlavalleru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.35000000",
        "longitude": "81.05000000",
        "label": "Gudlavalleru",
        "value": "Gudlavalleru"
    },
    {
        "name": "Gudur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.15093000",
        "longitude": "79.85210000",
        "label": "Gudur",
        "value": "Gudur"
    },
    {
        "name": "Guntakal Junction",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.17112000",
        "longitude": "77.36244000",
        "label": "Guntakal Junction",
        "value": "Guntakal Junction"
    },
    {
        "name": "Guntur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.29974000",
        "longitude": "80.45729000",
        "label": "Guntur",
        "value": "Guntur"
    },
    {
        "name": "Hindupur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.82807000",
        "longitude": "77.49143000",
        "label": "Hindupur",
        "value": "Hindupur"
    },
    {
        "name": "Ichchapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "19.11393000",
        "longitude": "84.68721000",
        "label": "Ichchapuram",
        "value": "Ichchapuram"
    },
    {
        "name": "Jaggayyapeta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.89380000",
        "longitude": "80.09807000",
        "label": "Jaggayyapeta",
        "value": "Jaggayyapeta"
    },
    {
        "name": "Jammalamadugu",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.84677000",
        "longitude": "78.38314000",
        "label": "Jammalamadugu",
        "value": "Jammalamadugu"
    },
    {
        "name": "Kadiri",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.11168000",
        "longitude": "78.15982000",
        "label": "Kadiri",
        "value": "Kadiri"
    },
    {
        "name": "Kaikalur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.55154000",
        "longitude": "81.21400000",
        "label": "Kaikalur",
        "value": "Kaikalur"
    },
    {
        "name": "Kakinada",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.96036000",
        "longitude": "82.23809000",
        "label": "Kakinada",
        "value": "Kakinada"
    },
    {
        "name": "Kalyandurg",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.54519000",
        "longitude": "77.10552000",
        "label": "Kalyandurg",
        "value": "Kalyandurg"
    },
    {
        "name": "Kamalapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.59830000",
        "longitude": "78.66948000",
        "label": "Kamalapuram",
        "value": "Kamalapuram"
    },
    {
        "name": "Kandukur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.21542000",
        "longitude": "79.90390000",
        "label": "Kandukur",
        "value": "Kandukur"
    },
    {
        "name": "Kanigiri",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.40555000",
        "longitude": "79.50694000",
        "label": "Kanigiri",
        "value": "Kanigiri"
    },
    {
        "name": "Kankipadu",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.43530000",
        "longitude": "80.76715000",
        "label": "Kankipadu",
        "value": "Kankipadu"
    },
    {
        "name": "Kanuru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.28584000",
        "longitude": "81.25464000",
        "label": "Kanuru",
        "value": "Kanuru"
    },
    {
        "name": "Kavali",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.91630000",
        "longitude": "79.99449000",
        "label": "Kavali",
        "value": "Kavali"
    },
    {
        "name": "Kolanukonda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.45392000",
        "longitude": "80.61046000",
        "label": "Kolanukonda",
        "value": "Kolanukonda"
    },
    {
        "name": "Kondapalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.61989000",
        "longitude": "80.54244000",
        "label": "Kondapalle",
        "value": "Kondapalle"
    },
    {
        "name": "Korukollu",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.50000000",
        "longitude": "81.25000000",
        "label": "Korukollu",
        "value": "Korukollu"
    },
    {
        "name": "Kosigi",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.85510000",
        "longitude": "77.24463000",
        "label": "Kosigi",
        "value": "Kosigi"
    },
    {
        "name": "Kovvur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.01620000",
        "longitude": "81.72934000",
        "label": "Kovvur",
        "value": "Kovvur"
    },
    {
        "name": "Krishna",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.66667000",
        "longitude": "81.00000000",
        "label": "Krishna",
        "value": "Krishna"
    },
    {
        "name": "Kuppam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "12.74931000",
        "longitude": "78.34189000",
        "label": "Kuppam",
        "value": "Kuppam"
    },
    {
        "name": "Kurnool",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.58333000",
        "longitude": "78.33333000",
        "label": "Kurnool",
        "value": "Kurnool"
    },
    {
        "name": "Macherla",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.47635000",
        "longitude": "79.43533000",
        "label": "Macherla",
        "value": "Macherla"
    },
    {
        "name": "Machilipatnam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.18747000",
        "longitude": "81.13888000",
        "label": "Machilipatnam",
        "value": "Machilipatnam"
    },
    {
        "name": "Madanapalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.55030000",
        "longitude": "78.50288000",
        "label": "Madanapalle",
        "value": "Madanapalle"
    },
    {
        "name": "Madugula",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.91589000",
        "longitude": "82.81578000",
        "label": "Madugula",
        "value": "Madugula"
    },
    {
        "name": "Mandapeta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.86254000",
        "longitude": "81.92921000",
        "label": "Mandapeta",
        "value": "Mandapeta"
    },
    {
        "name": "Mandasa",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.86830000",
        "longitude": "84.46296000",
        "label": "Mandasa",
        "value": "Mandasa"
    },
    {
        "name": "Mangalagiri",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.43083000",
        "longitude": "80.56815000",
        "label": "Mangalagiri",
        "value": "Mangalagiri"
    },
    {
        "name": "Markapur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.73534000",
        "longitude": "79.26848000",
        "label": "Markapur",
        "value": "Markapur"
    },
    {
        "name": "Nagari",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.32139000",
        "longitude": "79.58557000",
        "label": "Nagari",
        "value": "Nagari"
    },
    {
        "name": "Nagireddipalli",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.27005000",
        "longitude": "79.10131000",
        "label": "Nagireddipalli",
        "value": "Nagireddipalli"
    },
    {
        "name": "Nandigama",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.77170000",
        "longitude": "80.28596000",
        "label": "Nandigama",
        "value": "Nandigama"
    },
    {
        "name": "Nandikotkur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.85668000",
        "longitude": "78.26569000",
        "label": "Nandikotkur",
        "value": "Nandikotkur"
    },
    {
        "name": "Nandyal",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.47799000",
        "longitude": "78.48360000",
        "label": "Nandyal",
        "value": "Nandyal"
    },
    {
        "name": "Narasannapeta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.41428000",
        "longitude": "84.04463000",
        "label": "Narasannapeta",
        "value": "Narasannapeta"
    },
    {
        "name": "Narasapur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.43425000",
        "longitude": "81.69845000",
        "label": "Narasapur",
        "value": "Narasapur"
    },
    {
        "name": "Narasaraopet",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.23488000",
        "longitude": "80.04927000",
        "label": "Narasaraopet",
        "value": "Narasaraopet"
    },
    {
        "name": "Narasingapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.60759000",
        "longitude": "79.31652000",
        "label": "Narasingapuram",
        "value": "Narasingapuram"
    },
    {
        "name": "Narayanavanam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.42565000",
        "longitude": "79.58881000",
        "label": "Narayanavanam",
        "value": "Narayanavanam"
    },
    {
        "name": "Narsipatnam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.66709000",
        "longitude": "82.61245000",
        "label": "Narsipatnam",
        "value": "Narsipatnam"
    },
    {
        "name": "Nayudupet",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.90742000",
        "longitude": "79.89465000",
        "label": "Nayudupet",
        "value": "Nayudupet"
    },
    {
        "name": "Nellore",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.08333000",
        "longitude": "79.58333000",
        "label": "Nellore",
        "value": "Nellore"
    },
    {
        "name": "Nidadavole",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.90572000",
        "longitude": "81.67222000",
        "label": "Nidadavole",
        "value": "Nidadavole"
    },
    {
        "name": "Nuzvid",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.78854000",
        "longitude": "80.84593000",
        "label": "Nuzvid",
        "value": "Nuzvid"
    },
    {
        "name": "Ongole",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.50357000",
        "longitude": "80.04454000",
        "label": "Ongole",
        "value": "Ongole"
    },
    {
        "name": "Pakala",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.44903000",
        "longitude": "79.11493000",
        "label": "Pakala",
        "value": "Pakala"
    },
    {
        "name": "Palakollu",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.51670000",
        "longitude": "81.73000000",
        "label": "Palakollu",
        "value": "Palakollu"
    },
    {
        "name": "Palasa",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.77257000",
        "longitude": "84.41012000",
        "label": "Palasa",
        "value": "Palasa"
    },
    {
        "name": "Palkonda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.60374000",
        "longitude": "83.75568000",
        "label": "Palkonda",
        "value": "Palkonda"
    },
    {
        "name": "Pallevada",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.57790000",
        "longitude": "81.29463000",
        "label": "Pallevada",
        "value": "Pallevada"
    },
    {
        "name": "Palmaner",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.20000000",
        "longitude": "78.74725000",
        "label": "Palmaner",
        "value": "Palmaner"
    },
    {
        "name": "Parlakimidi",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.78113000",
        "longitude": "84.08836000",
        "label": "Parlakimidi",
        "value": "Parlakimidi"
    },
    {
        "name": "Parvatipuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.78392000",
        "longitude": "83.42569000",
        "label": "Parvatipuram",
        "value": "Parvatipuram"
    },
    {
        "name": "Pavuluru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.85292000",
        "longitude": "80.16468000",
        "label": "Pavuluru",
        "value": "Pavuluru"
    },
    {
        "name": "Pedana",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.25582000",
        "longitude": "81.14378000",
        "label": "Pedana",
        "value": "Pedana"
    },
    {
        "name": "Peddapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.07701000",
        "longitude": "82.13836000",
        "label": "Peddapuram",
        "value": "Peddapuram"
    },
    {
        "name": "Penugonda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.65363000",
        "longitude": "81.74550000",
        "label": "Penugonda",
        "value": "Penugonda"
    },
    {
        "name": "Penukonda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.08286000",
        "longitude": "77.59473000",
        "label": "Penukonda",
        "value": "Penukonda"
    },
    {
        "name": "Phirangipuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.29078000",
        "longitude": "80.26233000",
        "label": "Phirangipuram",
        "value": "Phirangipuram"
    },
    {
        "name": "Pippara",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.71667000",
        "longitude": "81.55000000",
        "label": "Pippara",
        "value": "Pippara"
    },
    {
        "name": "Pithapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.11680000",
        "longitude": "82.25284000",
        "label": "Pithapuram",
        "value": "Pithapuram"
    },
    {
        "name": "Polavaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.24754000",
        "longitude": "81.64372000",
        "label": "Polavaram",
        "value": "Polavaram"
    },
    {
        "name": "Ponnur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.07114000",
        "longitude": "80.54944000",
        "label": "Ponnur",
        "value": "Ponnur"
    },
    {
        "name": "Ponnuru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.06547000",
        "longitude": "80.55203000",
        "label": "Ponnuru",
        "value": "Ponnuru"
    },
    {
        "name": "Prakasam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.50000000",
        "longitude": "79.50000000",
        "label": "Prakasam",
        "value": "Prakasam"
    },
    {
        "name": "Proddatur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.75020000",
        "longitude": "78.54813000",
        "label": "Proddatur",
        "value": "Proddatur"
    },
    {
        "name": "Pulivendla",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.42139000",
        "longitude": "78.22502000",
        "label": "Pulivendla",
        "value": "Pulivendla"
    },
    {
        "name": "Punganuru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.36672000",
        "longitude": "78.57186000",
        "label": "Punganuru",
        "value": "Punganuru"
    },
    {
        "name": "Puttaparthi",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.16520000",
        "longitude": "77.81170000",
        "label": "Puttaparthi",
        "value": "Puttaparthi"
    },
    {
        "name": "Puttur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.44189000",
        "longitude": "79.55314000",
        "label": "Puttur",
        "value": "Puttur"
    },
    {
        "name": "Rajahmundry",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.00517000",
        "longitude": "81.77784000",
        "label": "Rajahmundry",
        "value": "Rajahmundry"
    },
    {
        "name": "Ramachandrapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.83636000",
        "longitude": "82.02871000",
        "label": "Ramachandrapuram",
        "value": "Ramachandrapuram"
    },
    {
        "name": "Ramanayyapeta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.94516000",
        "longitude": "82.23850000",
        "label": "Ramanayyapeta",
        "value": "Ramanayyapeta"
    },
    {
        "name": "Ramapuram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.28749000",
        "longitude": "77.86722000",
        "label": "Ramapuram",
        "value": "Ramapuram"
    },
    {
        "name": "Rampachodavaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.44088000",
        "longitude": "81.77558000",
        "label": "Rampachodavaram",
        "value": "Rampachodavaram"
    },
    {
        "name": "Rayachoti",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.05723000",
        "longitude": "78.75056000",
        "label": "Rayachoti",
        "value": "Rayachoti"
    },
    {
        "name": "Rayadrug",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.69971000",
        "longitude": "76.85241000",
        "label": "Rayadrug",
        "value": "Rayadrug"
    },
    {
        "name": "Razam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.44909000",
        "longitude": "83.65957000",
        "label": "Razam",
        "value": "Razam"
    },
    {
        "name": "Razampeta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.19544000",
        "longitude": "79.15896000",
        "label": "Razampeta",
        "value": "Razampeta"
    },
    {
        "name": "Razole",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.47608000",
        "longitude": "81.83912000",
        "label": "Razole",
        "value": "Razole"
    },
    {
        "name": "Renigunta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.65143000",
        "longitude": "79.51256000",
        "label": "Renigunta",
        "value": "Renigunta"
    },
    {
        "name": "Repalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.01840000",
        "longitude": "80.82958000",
        "label": "Repalle",
        "value": "Repalle"
    },
    {
        "name": "Salur",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.51716000",
        "longitude": "83.20548000",
        "label": "Salur",
        "value": "Salur"
    },
    {
        "name": "Samalkot",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.05675000",
        "longitude": "82.17639000",
        "label": "Samalkot",
        "value": "Samalkot"
    },
    {
        "name": "Sattenapalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.39381000",
        "longitude": "80.15221000",
        "label": "Sattenapalle",
        "value": "Sattenapalle"
    },
    {
        "name": "Singarayakonda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.23046000",
        "longitude": "80.02794000",
        "label": "Singarayakonda",
        "value": "Singarayakonda"
    },
    {
        "name": "Sompeta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.94419000",
        "longitude": "84.58449000",
        "label": "Sompeta",
        "value": "Sompeta"
    },
    {
        "name": "Srikakulam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.29890000",
        "longitude": "83.89751000",
        "label": "Srikakulam",
        "value": "Srikakulam"
    },
    {
        "name": "Srisailain",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.07217000",
        "longitude": "78.86816000",
        "label": "Srisailain",
        "value": "Srisailain"
    },
    {
        "name": "Suluru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.70000000",
        "longitude": "80.01667000",
        "label": "Suluru",
        "value": "Suluru"
    },
    {
        "name": "Tadepalle",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.48333000",
        "longitude": "80.60000000",
        "label": "Tadepalle",
        "value": "Tadepalle"
    },
    {
        "name": "Tadepallegudem",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.81467000",
        "longitude": "81.52717000",
        "label": "Tadepallegudem",
        "value": "Tadepallegudem"
    },
    {
        "name": "Tadpatri",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.90832000",
        "longitude": "78.01031000",
        "label": "Tadpatri",
        "value": "Tadpatri"
    },
    {
        "name": "Tanuku",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.75438000",
        "longitude": "81.68143000",
        "label": "Tanuku",
        "value": "Tanuku"
    },
    {
        "name": "Tekkali",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.60570000",
        "longitude": "84.23546000",
        "label": "Tekkali",
        "value": "Tekkali"
    },
    {
        "name": "Tirumala",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.68333000",
        "longitude": "79.35000000",
        "label": "Tirumala",
        "value": "Tirumala"
    },
    {
        "name": "Tirupati",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.63551000",
        "longitude": "79.41989000",
        "label": "Tirupati",
        "value": "Tirupati"
    },
    {
        "name": "Tuni",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.35905000",
        "longitude": "82.54607000",
        "label": "Tuni",
        "value": "Tuni"
    },
    {
        "name": "Uravakonda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.94348000",
        "longitude": "77.25494000",
        "label": "Uravakonda",
        "value": "Uravakonda"
    },
    {
        "name": "Vadlapudi",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "14.31119000",
        "longitude": "79.80430000",
        "label": "Vadlapudi",
        "value": "Vadlapudi"
    },
    {
        "name": "Venkatagiri",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "13.96005000",
        "longitude": "79.58032000",
        "label": "Venkatagiri",
        "value": "Venkatagiri"
    },
    {
        "name": "Vepagunta",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.77844000",
        "longitude": "83.21577000",
        "label": "Vepagunta",
        "value": "Vepagunta"
    },
    {
        "name": "Vetapalem",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.78502000",
        "longitude": "80.30663000",
        "label": "Vetapalem",
        "value": "Vetapalem"
    },
    {
        "name": "Vijayawada",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.50745000",
        "longitude": "80.64660000",
        "label": "Vijayawada",
        "value": "Vijayawada"
    },
    {
        "name": "Vinukonda",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.05310000",
        "longitude": "79.73964000",
        "label": "Vinukonda",
        "value": "Vinukonda"
    },
    {
        "name": "Visakhapatnam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.68009000",
        "longitude": "83.20161000",
        "label": "Visakhapatnam",
        "value": "Visakhapatnam"
    },
    {
        "name": "Vizianagaram",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.11692000",
        "longitude": "83.41148000",
        "label": "Vizianagaram",
        "value": "Vizianagaram"
    },
    {
        "name": "Vizianagaram District",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "18.41102000",
        "longitude": "83.37677000",
        "label": "Vizianagaram District",
        "value": "Vizianagaram District"
    },
    {
        "name": "Vuyyuru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.36307000",
        "longitude": "80.84406000",
        "label": "Vuyyuru",
        "value": "Vuyyuru"
    },
    {
        "name": "West Godavari",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.00000000",
        "longitude": "81.16667000",
        "label": "West Godavari",
        "value": "West Godavari"
    },
    {
        "name": "Yanam",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.73308000",
        "longitude": "82.21364000",
        "label": "Yanam",
        "value": "Yanam"
    },
    {
        "name": "Yanamalakuduru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "16.48531000",
        "longitude": "80.66746000",
        "label": "Yanamalakuduru",
        "value": "Yanamalakuduru"
    },
    {
        "name": "Yarada",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.65872000",
        "longitude": "83.27419000",
        "label": "Yarada",
        "value": "Yarada"
    },
    {
        "name": "pedda nakkalapalem",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "15.86680000",
        "longitude": "80.16202000",
        "label": "pedda nakkalapalem",
        "value": "pedda nakkalapalem"
    },
    {
        "name": "vadlamuru",
        "countryCode": "IN",
        "stateCode": "AP",
        "latitude": "17.09545000",
        "longitude": "82.16565000",
        "label": "vadlamuru",
        "value": "vadlamuru"
    }
,{
        "name": "Along",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.16951000",
        "longitude": "94.80060000",
        "label": "Along",
        "value": "Along"
    },
    {
        "name": "Anjaw",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.06549000",
        "longitude": "96.82878000",
        "label": "Anjaw",
        "value": "Anjaw"
    },
    {
        "name": "Basar",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.99008000",
        "longitude": "94.69451000",
        "label": "Basar",
        "value": "Basar"
    },
    {
        "name": "Bomdila",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.26475000",
        "longitude": "92.42472000",
        "label": "Bomdila",
        "value": "Bomdila"
    },
    {
        "name": "Changlang",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.36265000",
        "longitude": "96.34518000",
        "label": "Changlang",
        "value": "Changlang"
    },
    {
        "name": "Dibang Valley",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.70000000",
        "longitude": "95.70000000",
        "label": "Dibang Valley",
        "value": "Dibang Valley"
    },
    {
        "name": "East Kameng",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.30000000",
        "longitude": "93.05000000",
        "label": "East Kameng",
        "value": "East Kameng"
    },
    {
        "name": "East Siang",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.12379000",
        "longitude": "95.16339000",
        "label": "East Siang",
        "value": "East Siang"
    },
    {
        "name": "Hayuliang",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.07301000",
        "longitude": "96.54305000",
        "label": "Hayuliang",
        "value": "Hayuliang"
    },
    {
        "name": "Itanagar",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.08694000",
        "longitude": "93.60987000",
        "label": "Itanagar",
        "value": "Itanagar"
    },
    {
        "name": "Khonsa",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.01667000",
        "longitude": "95.56667000",
        "label": "Khonsa",
        "value": "Khonsa"
    },
    {
        "name": "Kurung Kumey",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.99983000",
        "longitude": "93.39240000",
        "label": "Kurung Kumey",
        "value": "Kurung Kumey"
    },
    {
        "name": "Lohit District",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.84012000",
        "longitude": "96.19521000",
        "label": "Lohit District",
        "value": "Lohit District"
    },
    {
        "name": "Lower Dibang Valley",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.37258000",
        "longitude": "95.88040000",
        "label": "Lower Dibang Valley",
        "value": "Lower Dibang Valley"
    },
    {
        "name": "Lower Subansiri",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.62554000",
        "longitude": "93.93908000",
        "label": "Lower Subansiri",
        "value": "Lower Subansiri"
    },
    {
        "name": "Margherita",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.28482000",
        "longitude": "95.66796000",
        "label": "Margherita",
        "value": "Margherita"
    },
    {
        "name": "Naharlagun",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.10467000",
        "longitude": "93.69518000",
        "label": "Naharlagun",
        "value": "Naharlagun"
    },
    {
        "name": "Pasighat",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.06631000",
        "longitude": "95.32678000",
        "label": "Pasighat",
        "value": "Pasighat"
    },
    {
        "name": "Tawang",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.57417000",
        "longitude": "91.92437000",
        "label": "Tawang",
        "value": "Tawang"
    },
    {
        "name": "Tezu",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.91256000",
        "longitude": "96.12882000",
        "label": "Tezu",
        "value": "Tezu"
    },
    {
        "name": "Tirap",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.01917000",
        "longitude": "95.51788000",
        "label": "Tirap",
        "value": "Tirap"
    },
    {
        "name": "Upper Siang",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.83355000",
        "longitude": "94.91806000",
        "label": "Upper Siang",
        "value": "Upper Siang"
    },
    {
        "name": "Upper Subansiri",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.30000000",
        "longitude": "94.00000000",
        "label": "Upper Subansiri",
        "value": "Upper Subansiri"
    },
    {
        "name": "West Kameng",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.40000000",
        "longitude": "92.35000000",
        "label": "West Kameng",
        "value": "West Kameng"
    },
    {
        "name": "West Siang",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "28.40000000",
        "longitude": "94.55000000",
        "label": "West Siang",
        "value": "West Siang"
    },
    {
        "name": "Ziro",
        "countryCode": "IN",
        "stateCode": "AR",
        "latitude": "27.59497000",
        "longitude": "93.83854000",
        "label": "Ziro",
        "value": "Ziro"
    }
,{
        "name": "Abhayapuri",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.32255000",
        "longitude": "90.68526000",
        "label": "Abhayapuri",
        "value": "Abhayapuri"
    },
    {
        "name": "Amguri",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.81482000",
        "longitude": "94.52614000",
        "label": "Amguri",
        "value": "Amguri"
    },
    {
        "name": "Badarpur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "24.86852000",
        "longitude": "92.59606000",
        "label": "Badarpur",
        "value": "Badarpur"
    },
    {
        "name": "Baksa",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.69804000",
        "longitude": "91.15142000",
        "label": "Baksa",
        "value": "Baksa"
    },
    {
        "name": "Barpathar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.28709000",
        "longitude": "93.88844000",
        "label": "Barpathar",
        "value": "Barpathar"
    },
    {
        "name": "Barpeta",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.47104000",
        "longitude": "91.03080000",
        "label": "Barpeta",
        "value": "Barpeta"
    },
    {
        "name": "Barpeta Road",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.50284000",
        "longitude": "90.96937000",
        "label": "Barpeta Road",
        "value": "Barpeta Road"
    },
    {
        "name": "Basugaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.46742000",
        "longitude": "90.41951000",
        "label": "Basugaon",
        "value": "Basugaon"
    },
    {
        "name": "Bihpuriagaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.01718000",
        "longitude": "93.91673000",
        "label": "Bihpuriagaon",
        "value": "Bihpuriagaon"
    },
    {
        "name": "Bijni",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.49588000",
        "longitude": "90.70298000",
        "label": "Bijni",
        "value": "Bijni"
    },
    {
        "name": "Bilasipara",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.23285000",
        "longitude": "90.23410000",
        "label": "Bilasipara",
        "value": "Bilasipara"
    },
    {
        "name": "Bokajan",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.02131000",
        "longitude": "93.77945000",
        "label": "Bokajan",
        "value": "Bokajan"
    },
    {
        "name": "Bokakhat",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.64018000",
        "longitude": "93.60052000",
        "label": "Bokakhat",
        "value": "Bokakhat"
    },
    {
        "name": "Bongaigaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.46030000",
        "longitude": "90.64640000",
        "label": "Bongaigaon",
        "value": "Bongaigaon"
    },
    {
        "name": "Cachar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "24.78213000",
        "longitude": "92.85771000",
        "label": "Cachar",
        "value": "Cachar"
    },
    {
        "name": "Chabua",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.48253000",
        "longitude": "95.17451000",
        "label": "Chabua",
        "value": "Chabua"
    },
    {
        "name": "Chapar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.27266000",
        "longitude": "90.44556000",
        "label": "Chapar",
        "value": "Chapar"
    },
    {
        "name": "Chirang",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.52527000",
        "longitude": "90.49066000",
        "label": "Chirang",
        "value": "Chirang"
    },
    {
        "name": "Darrang",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.51195000",
        "longitude": "92.16843000",
        "label": "Darrang",
        "value": "Darrang"
    },
    {
        "name": "Dergaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.70000000",
        "longitude": "93.96667000",
        "label": "Dergaon",
        "value": "Dergaon"
    },
    {
        "name": "Dhekiajuli",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.70367000",
        "longitude": "92.47808000",
        "label": "Dhekiajuli",
        "value": "Dhekiajuli"
    },
    {
        "name": "Dhemaji",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.60910000",
        "longitude": "94.79420000",
        "label": "Dhemaji",
        "value": "Dhemaji"
    },
    {
        "name": "Dhing",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.46793000",
        "longitude": "92.47336000",
        "label": "Dhing",
        "value": "Dhing"
    },
    {
        "name": "Dhubri",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.06749000",
        "longitude": "90.02238000",
        "label": "Dhubri",
        "value": "Dhubri"
    },
    {
        "name": "Dibrugarh",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.50000000",
        "longitude": "95.00000000",
        "label": "Dibrugarh",
        "value": "Dibrugarh"
    },
    {
        "name": "Digboi",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.39321000",
        "longitude": "95.61839000",
        "label": "Digboi",
        "value": "Digboi"
    },
    {
        "name": "Dima Hasao District",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "25.50000000",
        "longitude": "93.00000000",
        "label": "Dima Hasao District",
        "value": "Dima Hasao District"
    },
    {
        "name": "Diphu",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "25.84341000",
        "longitude": "93.43116000",
        "label": "Diphu",
        "value": "Diphu"
    },
    {
        "name": "Dispur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.13564000",
        "longitude": "91.80069000",
        "label": "Dispur",
        "value": "Dispur"
    },
    {
        "name": "Duliagaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.37227000",
        "longitude": "95.30754000",
        "label": "Duliagaon",
        "value": "Duliagaon"
    },
    {
        "name": "Dum Duma",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.56884000",
        "longitude": "95.55664000",
        "label": "Dum Duma",
        "value": "Dum Duma"
    },
    {
        "name": "Gauripur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.08334000",
        "longitude": "89.96118000",
        "label": "Gauripur",
        "value": "Gauripur"
    },
    {
        "name": "Goalpara",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.12791000",
        "longitude": "90.60974000",
        "label": "Goalpara",
        "value": "Goalpara"
    },
    {
        "name": "Gohpur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.88184000",
        "longitude": "93.61560000",
        "label": "Gohpur",
        "value": "Gohpur"
    },
    {
        "name": "Golaghat",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.40920000",
        "longitude": "93.91193000",
        "label": "Golaghat",
        "value": "Golaghat"
    },
    {
        "name": "Golakganj",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.10216000",
        "longitude": "89.82275000",
        "label": "Golakganj",
        "value": "Golakganj"
    },
    {
        "name": "Goshaingaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.43946000",
        "longitude": "89.96307000",
        "label": "Goshaingaon",
        "value": "Goshaingaon"
    },
    {
        "name": "Guwahati",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.18440000",
        "longitude": "91.74580000",
        "label": "Guwahati",
        "value": "Guwahati"
    },
    {
        "name": "Haflong",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "25.16478000",
        "longitude": "93.01744000",
        "label": "Haflong",
        "value": "Haflong"
    },
    {
        "name": "Hailakandi",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "24.50170000",
        "longitude": "92.60069000",
        "label": "Hailakandi",
        "value": "Hailakandi"
    },
    {
        "name": "Hajo",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.24520000",
        "longitude": "91.52525000",
        "label": "Hajo",
        "value": "Hajo"
    },
    {
        "name": "Hojai",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.00281000",
        "longitude": "92.85605000",
        "label": "Hojai",
        "value": "Hojai"
    },
    {
        "name": "Howli",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.42237000",
        "longitude": "90.98004000",
        "label": "Howli",
        "value": "Howli"
    },
    {
        "name": "Jogighopa",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.22646000",
        "longitude": "90.57247000",
        "label": "Jogighopa",
        "value": "Jogighopa"
    },
    {
        "name": "Jorhat",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.80000000",
        "longitude": "94.26000000",
        "label": "Jorhat",
        "value": "Jorhat"
    },
    {
        "name": "Kamrup",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.22322000",
        "longitude": "91.65344000",
        "label": "Kamrup",
        "value": "Kamrup"
    },
    {
        "name": "Kamrup Metropolitan",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.05375000",
        "longitude": "92.00763000",
        "label": "Kamrup Metropolitan",
        "value": "Kamrup Metropolitan"
    },
    {
        "name": "Karbi Anglong",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.00000000",
        "longitude": "93.50000000",
        "label": "Karbi Anglong",
        "value": "Karbi Anglong"
    },
    {
        "name": "Karimganj",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "24.60000000",
        "longitude": "92.40000000",
        "label": "Karimganj",
        "value": "Karimganj"
    },
    {
        "name": "Kharupatia",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.51839000",
        "longitude": "92.14722000",
        "label": "Kharupatia",
        "value": "Kharupatia"
    },
    {
        "name": "Kokrajhar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.60000000",
        "longitude": "90.20000000",
        "label": "Kokrajhar",
        "value": "Kokrajhar"
    },
    {
        "name": "Lakhimpur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.35000000",
        "longitude": "94.25000000",
        "label": "Lakhimpur",
        "value": "Lakhimpur"
    },
    {
        "name": "Lakhipur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "24.79281000",
        "longitude": "93.00910000",
        "label": "Lakhipur",
        "value": "Lakhipur"
    },
    {
        "name": "Lala",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "24.55418000",
        "longitude": "92.61592000",
        "label": "Lala",
        "value": "Lala"
    },
    {
        "name": "Lumding Railway Colony",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "25.74903000",
        "longitude": "93.16998000",
        "label": "Lumding Railway Colony",
        "value": "Lumding Railway Colony"
    },
    {
        "name": "Mahur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "25.18305000",
        "longitude": "93.11342000",
        "label": "Mahur",
        "value": "Mahur"
    },
    {
        "name": "Maibong",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "25.30125000",
        "longitude": "93.13811000",
        "label": "Maibong",
        "value": "Maibong"
    },
    {
        "name": "Makum",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.48652000",
        "longitude": "95.43646000",
        "label": "Makum",
        "value": "Makum"
    },
    {
        "name": "Mangaldai",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.44212000",
        "longitude": "92.03047000",
        "label": "Mangaldai",
        "value": "Mangaldai"
    },
    {
        "name": "Mariani",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.65725000",
        "longitude": "94.31529000",
        "label": "Mariani",
        "value": "Mariani"
    },
    {
        "name": "Moranha",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.18735000",
        "longitude": "94.91557000",
        "label": "Moranha",
        "value": "Moranha"
    },
    {
        "name": "Morigaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.25213000",
        "longitude": "92.34238000",
        "label": "Morigaon",
        "value": "Morigaon"
    },
    {
        "name": "Nagaon",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.35037000",
        "longitude": "92.69225000",
        "label": "Nagaon",
        "value": "Nagaon"
    },
    {
        "name": "Nahorkatiya",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.28912000",
        "longitude": "95.34180000",
        "label": "Nahorkatiya",
        "value": "Nahorkatiya"
    },
    {
        "name": "Nalbari",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.50000000",
        "longitude": "91.40000000",
        "label": "Nalbari",
        "value": "Nalbari"
    },
    {
        "name": "Namrup",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.19395000",
        "longitude": "95.31929000",
        "label": "Namrup",
        "value": "Namrup"
    },
    {
        "name": "Nazira",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.91649000",
        "longitude": "94.73611000",
        "label": "Nazira",
        "value": "Nazira"
    },
    {
        "name": "North Guwahati",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.19749000",
        "longitude": "91.72020000",
        "label": "North Guwahati",
        "value": "North Guwahati"
    },
    {
        "name": "North Lakhimpur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.23517000",
        "longitude": "94.10357000",
        "label": "North Lakhimpur",
        "value": "North Lakhimpur"
    },
    {
        "name": "Numaligarh",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.62249000",
        "longitude": "93.72225000",
        "label": "Numaligarh",
        "value": "Numaligarh"
    },
    {
        "name": "Palasbari",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.12388000",
        "longitude": "91.53974000",
        "label": "Palasbari",
        "value": "Palasbari"
    },
    {
        "name": "Raha",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.23333000",
        "longitude": "92.51667000",
        "label": "Raha",
        "value": "Raha"
    },
    {
        "name": "Rangapara",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.83772000",
        "longitude": "92.66876000",
        "label": "Rangapara",
        "value": "Rangapara"
    },
    {
        "name": "Rangia",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.44931000",
        "longitude": "91.61356000",
        "label": "Rangia",
        "value": "Rangia"
    },
    {
        "name": "Sapatgram",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.33732000",
        "longitude": "90.12360000",
        "label": "Sapatgram",
        "value": "Sapatgram"
    },
    {
        "name": "Sarupathar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.20600000",
        "longitude": "96.81000000",
        "label": "Sarupathar",
        "value": "Sarupathar"
    },
    {
        "name": "Sibsagar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.98427000",
        "longitude": "94.63784000",
        "label": "Sibsagar",
        "value": "Sibsagar"
    },
    {
        "name": "Silapathar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.59441000",
        "longitude": "94.72402000",
        "label": "Silapathar",
        "value": "Silapathar"
    },
    {
        "name": "Silchar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "24.82733000",
        "longitude": "92.79787000",
        "label": "Silchar",
        "value": "Silchar"
    },
    {
        "name": "Soalkuchi",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.16806000",
        "longitude": "91.57111000",
        "label": "Soalkuchi",
        "value": "Soalkuchi"
    },
    {
        "name": "Sonari",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.02462000",
        "longitude": "95.01629000",
        "label": "Sonari",
        "value": "Sonari"
    },
    {
        "name": "Sonitpur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.76748000",
        "longitude": "92.96425000",
        "label": "Sonitpur",
        "value": "Sonitpur"
    },
    {
        "name": "Sorbhog",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.48612000",
        "longitude": "90.88590000",
        "label": "Sorbhog",
        "value": "Sorbhog"
    },
    {
        "name": "Tezpur",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.63333000",
        "longitude": "92.80000000",
        "label": "Tezpur",
        "value": "Tezpur"
    },
    {
        "name": "Tinsukia",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "27.60000000",
        "longitude": "95.60000000",
        "label": "Tinsukia",
        "value": "Tinsukia"
    },
    {
        "name": "Titabar",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.60140000",
        "longitude": "94.20386000",
        "label": "Titabar",
        "value": "Titabar"
    },
    {
        "name": "Udalguri",
        "countryCode": "IN",
        "stateCode": "AS",
        "latitude": "26.75367000",
        "longitude": "92.10215000",
        "label": "Udalguri",
        "value": "Udalguri"
    }
,{
        "name": "Amarpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.03967000",
        "longitude": "86.90247000",
        "label": "Amarpur",
        "value": "Amarpur"
    },
    {
        "name": "Araria",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.20000000",
        "longitude": "87.40000000",
        "label": "Araria",
        "value": "Araria"
    },
    {
        "name": "Arrah",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.55629000",
        "longitude": "84.66335000",
        "label": "Arrah",
        "value": "Arrah"
    },
    {
        "name": "Arwal",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.16158000",
        "longitude": "84.69040000",
        "label": "Arwal",
        "value": "Arwal"
    },
    {
        "name": "Asarganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.15046000",
        "longitude": "86.68639000",
        "label": "Asarganj",
        "value": "Asarganj"
    },
    {
        "name": "Aurangabad",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.75204000",
        "longitude": "84.37420000",
        "label": "Aurangabad",
        "value": "Aurangabad"
    },
    {
        "name": "Bagaha",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "27.09918000",
        "longitude": "84.09003000",
        "label": "Bagaha",
        "value": "Bagaha"
    },
    {
        "name": "Bahadurganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.26172000",
        "longitude": "87.82443000",
        "label": "Bahadurganj",
        "value": "Bahadurganj"
    },
    {
        "name": "Bairagnia",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.74063000",
        "longitude": "85.27323000",
        "label": "Bairagnia",
        "value": "Bairagnia"
    },
    {
        "name": "Baisi",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.86302000",
        "longitude": "87.74487000",
        "label": "Baisi",
        "value": "Baisi"
    },
    {
        "name": "Bakhtiyarpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.46179000",
        "longitude": "85.53179000",
        "label": "Bakhtiyarpur",
        "value": "Bakhtiyarpur"
    },
    {
        "name": "Bangaon",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.86728000",
        "longitude": "86.51152000",
        "label": "Bangaon",
        "value": "Bangaon"
    },
    {
        "name": "Banka",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.89214000",
        "longitude": "86.98425000",
        "label": "Banka",
        "value": "Banka"
    },
    {
        "name": "Banmankhi",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.88857000",
        "longitude": "87.19421000",
        "label": "Banmankhi",
        "value": "Banmankhi"
    },
    {
        "name": "Bar Bigha",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.21855000",
        "longitude": "85.73320000",
        "label": "Bar Bigha",
        "value": "Bar Bigha"
    },
    {
        "name": "Barauli",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.38109000",
        "longitude": "84.58648000",
        "label": "Barauli",
        "value": "Barauli"
    },
    {
        "name": "Barh",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.48339000",
        "longitude": "85.70928000",
        "label": "Barh",
        "value": "Barh"
    },
    {
        "name": "Barhiya",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.28814000",
        "longitude": "86.02055000",
        "label": "Barhiya",
        "value": "Barhiya"
    },
    {
        "name": "Bariarpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.28791000",
        "longitude": "86.57643000",
        "label": "Bariarpur",
        "value": "Bariarpur"
    },
    {
        "name": "Baruni",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.47509000",
        "longitude": "85.96813000",
        "label": "Baruni",
        "value": "Baruni"
    },
    {
        "name": "Begusarai",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.41853000",
        "longitude": "86.13389000",
        "label": "Begusarai",
        "value": "Begusarai"
    },
    {
        "name": "Belsand",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.44365000",
        "longitude": "85.40076000",
        "label": "Belsand",
        "value": "Belsand"
    },
    {
        "name": "Bettiah",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.80229000",
        "longitude": "84.50311000",
        "label": "Bettiah",
        "value": "Bettiah"
    },
    {
        "name": "Bhabhua",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.04049000",
        "longitude": "83.60749000",
        "label": "Bhabhua",
        "value": "Bhabhua"
    },
    {
        "name": "Bhagalpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.29023000",
        "longitude": "87.06665000",
        "label": "Bhagalpur",
        "value": "Bhagalpur"
    },
    {
        "name": "Bhagirathpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.26950000",
        "longitude": "86.06346000",
        "label": "Bhagirathpur",
        "value": "Bhagirathpur"
    },
    {
        "name": "Bhawanipur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.45352000",
        "longitude": "87.02744000",
        "label": "Bhawanipur",
        "value": "Bhawanipur"
    },
    {
        "name": "Bhojpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.30886000",
        "longitude": "84.44504000",
        "label": "Bhojpur",
        "value": "Bhojpur"
    },
    {
        "name": "Bihar Sharif",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.20084000",
        "longitude": "85.52389000",
        "label": "Bihar Sharif",
        "value": "Bihar Sharif"
    },
    {
        "name": "Bihariganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.73415000",
        "longitude": "86.98837000",
        "label": "Bihariganj",
        "value": "Bihariganj"
    },
    {
        "name": "Bikramganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.21073000",
        "longitude": "84.25508000",
        "label": "Bikramganj",
        "value": "Bikramganj"
    },
    {
        "name": "Birpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.50823000",
        "longitude": "87.01194000",
        "label": "Birpur",
        "value": "Birpur"
    },
    {
        "name": "Buddh Gaya",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.69808000",
        "longitude": "84.98690000",
        "label": "Buddh Gaya",
        "value": "Buddh Gaya"
    },
    {
        "name": "Buxar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.50000000",
        "longitude": "84.10000000",
        "label": "Buxar",
        "value": "Buxar"
    },
    {
        "name": "Chakia",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.41598000",
        "longitude": "85.04665000",
        "label": "Chakia",
        "value": "Chakia"
    },
    {
        "name": "Chapra",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.78031000",
        "longitude": "84.74709000",
        "label": "Chapra",
        "value": "Chapra"
    },
    {
        "name": "Chhatapur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.21965000",
        "longitude": "87.00479000",
        "label": "Chhatapur",
        "value": "Chhatapur"
    },
    {
        "name": "Colgong",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.26328000",
        "longitude": "87.23264000",
        "label": "Colgong",
        "value": "Colgong"
    },
    {
        "name": "Dalsingh Sarai",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.66795000",
        "longitude": "85.83636000",
        "label": "Dalsingh Sarai",
        "value": "Dalsingh Sarai"
    },
    {
        "name": "Darbhanga",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.00000000",
        "longitude": "86.00000000",
        "label": "Darbhanga",
        "value": "Darbhanga"
    },
    {
        "name": "Daudnagar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.03473000",
        "longitude": "84.40095000",
        "label": "Daudnagar",
        "value": "Daudnagar"
    },
    {
        "name": "Dehri",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.90247000",
        "longitude": "84.18217000",
        "label": "Dehri",
        "value": "Dehri"
    },
    {
        "name": "Dhaka",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.67479000",
        "longitude": "85.16698000",
        "label": "Dhaka",
        "value": "Dhaka"
    },
    {
        "name": "Dighwara",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.74434000",
        "longitude": "85.01003000",
        "label": "Dighwara",
        "value": "Dighwara"
    },
    {
        "name": "Dinapore",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.63705000",
        "longitude": "85.04794000",
        "label": "Dinapore",
        "value": "Dinapore"
    },
    {
        "name": "Dumra",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.56708000",
        "longitude": "85.52040000",
        "label": "Dumra",
        "value": "Dumra"
    },
    {
        "name": "Dumraon",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.55265000",
        "longitude": "84.15149000",
        "label": "Dumraon",
        "value": "Dumraon"
    },
    {
        "name": "Fatwa",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.50958000",
        "longitude": "85.30504000",
        "label": "Fatwa",
        "value": "Fatwa"
    },
    {
        "name": "Forbesganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.30253000",
        "longitude": "87.26556000",
        "label": "Forbesganj",
        "value": "Forbesganj"
    },
    {
        "name": "Gaya",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.79686000",
        "longitude": "85.00385000",
        "label": "Gaya",
        "value": "Gaya"
    },
    {
        "name": "Ghoga",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.21738000",
        "longitude": "87.15710000",
        "label": "Ghoga",
        "value": "Ghoga"
    },
    {
        "name": "Gopalganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.50000000",
        "longitude": "84.33333000",
        "label": "Gopalganj",
        "value": "Gopalganj"
    },
    {
        "name": "Hajipur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.68544000",
        "longitude": "85.20981000",
        "label": "Hajipur",
        "value": "Hajipur"
    },
    {
        "name": "Hilsa",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.31642000",
        "longitude": "85.28234000",
        "label": "Hilsa",
        "value": "Hilsa"
    },
    {
        "name": "Hisua",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.83360000",
        "longitude": "85.41729000",
        "label": "Hisua",
        "value": "Hisua"
    },
    {
        "name": "Islampur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.14075000",
        "longitude": "85.20587000",
        "label": "Islampur",
        "value": "Islampur"
    },
    {
        "name": "Jagdispur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.46811000",
        "longitude": "84.41939000",
        "label": "Jagdispur",
        "value": "Jagdispur"
    },
    {
        "name": "Jahanabad",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.21368000",
        "longitude": "84.98710000",
        "label": "Jahanabad",
        "value": "Jahanabad"
    },
    {
        "name": "Jamalpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.31258000",
        "longitude": "86.48888000",
        "label": "Jamalpur",
        "value": "Jamalpur"
    },
    {
        "name": "Jamui",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.92082000",
        "longitude": "86.17538000",
        "label": "Jamui",
        "value": "Jamui"
    },
    {
        "name": "Jaynagar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.59048000",
        "longitude": "86.13791000",
        "label": "Jaynagar",
        "value": "Jaynagar"
    },
    {
        "name": "Jehanabad",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.20701000",
        "longitude": "84.99573000",
        "label": "Jehanabad",
        "value": "Jehanabad"
    },
    {
        "name": "Jha-Jha",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.77107000",
        "longitude": "86.37888000",
        "label": "Jha-Jha",
        "value": "Jha-Jha"
    },
    {
        "name": "Jhanjharpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.26467000",
        "longitude": "86.27993000",
        "label": "Jhanjharpur",
        "value": "Jhanjharpur"
    },
    {
        "name": "Jogbani",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.39905000",
        "longitude": "87.26525000",
        "label": "Jogbani",
        "value": "Jogbani"
    },
    {
        "name": "Kaimur District",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.05077000",
        "longitude": "83.58261000",
        "label": "Kaimur District",
        "value": "Kaimur District"
    },
    {
        "name": "Kasba",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.85643000",
        "longitude": "87.53836000",
        "label": "Kasba",
        "value": "Kasba"
    },
    {
        "name": "Katihar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.50000000",
        "longitude": "87.60000000",
        "label": "Katihar",
        "value": "Katihar"
    },
    {
        "name": "Khagaria",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.50220000",
        "longitude": "86.46708000",
        "label": "Khagaria",
        "value": "Khagaria"
    },
    {
        "name": "Khagaul",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.57898000",
        "longitude": "85.04564000",
        "label": "Khagaul",
        "value": "Khagaul"
    },
    {
        "name": "Kharagpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.12446000",
        "longitude": "86.55578000",
        "label": "Kharagpur",
        "value": "Kharagpur"
    },
    {
        "name": "Khusropur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.48174000",
        "longitude": "85.38492000",
        "label": "Khusropur",
        "value": "Khusropur"
    },
    {
        "name": "Kishanganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.30000000",
        "longitude": "88.00000000",
        "label": "Kishanganj",
        "value": "Kishanganj"
    },
    {
        "name": "Koath",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.32643000",
        "longitude": "84.25983000",
        "label": "Koath",
        "value": "Koath"
    },
    {
        "name": "Koelwar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.58055000",
        "longitude": "84.79751000",
        "label": "Koelwar",
        "value": "Koelwar"
    },
    {
        "name": "Lakhisarai",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.20000000",
        "longitude": "86.20000000",
        "label": "Lakhisarai",
        "value": "Lakhisarai"
    },
    {
        "name": "Lalganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.86894000",
        "longitude": "85.17394000",
        "label": "Lalganj",
        "value": "Lalganj"
    },
    {
        "name": "Luckeesarai",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.17650000",
        "longitude": "86.09470000",
        "label": "Luckeesarai",
        "value": "Luckeesarai"
    },
    {
        "name": "Madhepura",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.80000000",
        "longitude": "87.00000000",
        "label": "Madhepura",
        "value": "Madhepura"
    },
    {
        "name": "Madhipura",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.92127000",
        "longitude": "86.79271000",
        "label": "Madhipura",
        "value": "Madhipura"
    },
    {
        "name": "Madhubani",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.35367000",
        "longitude": "86.07169000",
        "label": "Madhubani",
        "value": "Madhubani"
    },
    {
        "name": "Maharajgani",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.11017000",
        "longitude": "84.50365000",
        "label": "Maharajgani",
        "value": "Maharajgani"
    },
    {
        "name": "Mairwa",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.23218000",
        "longitude": "84.16349000",
        "label": "Mairwa",
        "value": "Mairwa"
    },
    {
        "name": "Maner",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.64602000",
        "longitude": "84.87291000",
        "label": "Maner",
        "value": "Maner"
    },
    {
        "name": "Manihari",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.33891000",
        "longitude": "87.61998000",
        "label": "Manihari",
        "value": "Manihari"
    },
    {
        "name": "Marhaura",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.97349000",
        "longitude": "84.86796000",
        "label": "Marhaura",
        "value": "Marhaura"
    },
    {
        "name": "Masaurhi Buzurg",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.35417000",
        "longitude": "85.03195000",
        "label": "Masaurhi Buzurg",
        "value": "Masaurhi Buzurg"
    },
    {
        "name": "Mohiuddinnagar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.57374000",
        "longitude": "85.66944000",
        "label": "Mohiuddinnagar",
        "value": "Mohiuddinnagar"
    },
    {
        "name": "Mokameh",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.39662000",
        "longitude": "85.92190000",
        "label": "Mokameh",
        "value": "Mokameh"
    },
    {
        "name": "Monghyr",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.37459000",
        "longitude": "86.47455000",
        "label": "Monghyr",
        "value": "Monghyr"
    },
    {
        "name": "Mothihari",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.64862000",
        "longitude": "84.91656000",
        "label": "Mothihari",
        "value": "Mothihari"
    },
    {
        "name": "Munger",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.36099000",
        "longitude": "86.46515000",
        "label": "Munger",
        "value": "Munger"
    },
    {
        "name": "Murliganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.89690000",
        "longitude": "86.99577000",
        "label": "Murliganj",
        "value": "Murliganj"
    },
    {
        "name": "Muzaffarpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.16667000",
        "longitude": "85.41667000",
        "label": "Muzaffarpur",
        "value": "Muzaffarpur"
    },
    {
        "name": "Nabinagar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.60681000",
        "longitude": "84.12624000",
        "label": "Nabinagar",
        "value": "Nabinagar"
    },
    {
        "name": "Nalanda",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.25000000",
        "longitude": "85.58333000",
        "label": "Nalanda",
        "value": "Nalanda"
    },
    {
        "name": "Nasriganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.05140000",
        "longitude": "84.32839000",
        "label": "Nasriganj",
        "value": "Nasriganj"
    },
    {
        "name": "Naugachhia",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.38807000",
        "longitude": "87.09906000",
        "label": "Naugachhia",
        "value": "Naugachhia"
    },
    {
        "name": "Nawada",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.75000000",
        "longitude": "85.50000000",
        "label": "Nawada",
        "value": "Nawada"
    },
    {
        "name": "Nirmali",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.31397000",
        "longitude": "86.58537000",
        "label": "Nirmali",
        "value": "Nirmali"
    },
    {
        "name": "Pashchim Champaran",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "27.00000000",
        "longitude": "84.50000000",
        "label": "Pashchim Champaran",
        "value": "Pashchim Champaran"
    },
    {
        "name": "Patna",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.41667000",
        "longitude": "85.16667000",
        "label": "Patna",
        "value": "Patna"
    },
    {
        "name": "Piro",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.33218000",
        "longitude": "84.40454000",
        "label": "Piro",
        "value": "Piro"
    },
    {
        "name": "Pupri",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.47079000",
        "longitude": "85.70311000",
        "label": "Pupri",
        "value": "Pupri"
    },
    {
        "name": "Purba Champaran",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.58333000",
        "longitude": "84.83333000",
        "label": "Purba Champaran",
        "value": "Purba Champaran"
    },
    {
        "name": "Purnia",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.81614000",
        "longitude": "87.40708000",
        "label": "Purnia",
        "value": "Purnia"
    },
    {
        "name": "Rafiganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.81757000",
        "longitude": "84.63445000",
        "label": "Rafiganj",
        "value": "Rafiganj"
    },
    {
        "name": "Raghunathpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.64492000",
        "longitude": "87.91762000",
        "label": "Raghunathpur",
        "value": "Raghunathpur"
    },
    {
        "name": "Rajgir",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.02828000",
        "longitude": "85.42079000",
        "label": "Rajgir",
        "value": "Rajgir"
    },
    {
        "name": "Ramnagar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "27.16371000",
        "longitude": "84.32342000",
        "label": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "name": "Raxaul",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.97982000",
        "longitude": "84.85065000",
        "label": "Raxaul",
        "value": "Raxaul"
    },
    {
        "name": "Revelganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.78976000",
        "longitude": "84.63596000",
        "label": "Revelganj",
        "value": "Revelganj"
    },
    {
        "name": "Rohtas",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.97941000",
        "longitude": "84.02774000",
        "label": "Rohtas",
        "value": "Rohtas"
    },
    {
        "name": "Rusera",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.75355000",
        "longitude": "86.02597000",
        "label": "Rusera",
        "value": "Rusera"
    },
    {
        "name": "Sagauli",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.76390000",
        "longitude": "84.74341000",
        "label": "Sagauli",
        "value": "Sagauli"
    },
    {
        "name": "Saharsa",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.87498000",
        "longitude": "86.59611000",
        "label": "Saharsa",
        "value": "Saharsa"
    },
    {
        "name": "Samastipur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.75000000",
        "longitude": "85.91667000",
        "label": "Samastipur",
        "value": "Samastipur"
    },
    {
        "name": "Saran",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.91667000",
        "longitude": "84.75000000",
        "label": "Saran",
        "value": "Saran"
    },
    {
        "name": "Shahbazpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.30511000",
        "longitude": "87.28865000",
        "label": "Shahbazpur",
        "value": "Shahbazpur"
    },
    {
        "name": "Shahpur",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.60293000",
        "longitude": "84.40412000",
        "label": "Shahpur",
        "value": "Shahpur"
    },
    {
        "name": "Sheikhpura",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.13073000",
        "longitude": "85.78176000",
        "label": "Sheikhpura",
        "value": "Sheikhpura"
    },
    {
        "name": "Sheohar",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.50000000",
        "longitude": "85.30000000",
        "label": "Sheohar",
        "value": "Sheohar"
    },
    {
        "name": "Sherghati",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.55950000",
        "longitude": "84.79162000",
        "label": "Sherghati",
        "value": "Sherghati"
    },
    {
        "name": "Silao",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.08358000",
        "longitude": "85.42804000",
        "label": "Silao",
        "value": "Silao"
    },
    {
        "name": "Sitamarhi",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.66667000",
        "longitude": "85.50000000",
        "label": "Sitamarhi",
        "value": "Sitamarhi"
    },
    {
        "name": "Siwan",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.22096000",
        "longitude": "84.35609000",
        "label": "Siwan",
        "value": "Siwan"
    },
    {
        "name": "Supaul",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.25000000",
        "longitude": "86.80000000",
        "label": "Supaul",
        "value": "Supaul"
    },
    {
        "name": "Teghra",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.49043000",
        "longitude": "85.94001000",
        "label": "Teghra",
        "value": "Teghra"
    },
    {
        "name": "Tekari",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "24.94253000",
        "longitude": "84.84265000",
        "label": "Tekari",
        "value": "Tekari"
    },
    {
        "name": "Thakurganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "26.42742000",
        "longitude": "88.13112000",
        "label": "Thakurganj",
        "value": "Thakurganj"
    },
    {
        "name": "Vaishali",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.75000000",
        "longitude": "85.41667000",
        "label": "Vaishali",
        "value": "Vaishali"
    },
    {
        "name": "Waris Aliganj",
        "countryCode": "IN",
        "stateCode": "BR",
        "latitude": "25.01720000",
        "longitude": "85.64047000",
        "label": "Waris Aliganj",
        "value": "Waris Aliganj"
    }
, {
        "name": "Chandigarh",
        "countryCode": "IN",
        "stateCode": "CH",
        "latitude": "30.73629000",
        "longitude": "76.78840000",
        "label": "Chandigarh",
        "value": "Chandigarh"
    }
,{
        "name": "Akaltara",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.02463000",
        "longitude": "82.42641000",
        "label": "Akaltara",
        "value": "Akaltara"
    },
    {
        "name": "Ambagarh Chauki",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.77644000",
        "longitude": "80.74608000",
        "label": "Ambagarh Chauki",
        "value": "Ambagarh Chauki"
    },
    {
        "name": "Ambikapur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "23.11892000",
        "longitude": "83.19537000",
        "label": "Ambikapur",
        "value": "Ambikapur"
    },
    {
        "name": "Arang",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.19639000",
        "longitude": "81.96912000",
        "label": "Arang",
        "value": "Arang"
    },
    {
        "name": "Baikunthpur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "23.26206000",
        "longitude": "82.56051000",
        "label": "Baikunthpur",
        "value": "Baikunthpur"
    },
    {
        "name": "Balod",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.73081000",
        "longitude": "81.20578000",
        "label": "Balod",
        "value": "Balod"
    },
    {
        "name": "Baloda",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.13890000",
        "longitude": "82.48171000",
        "label": "Baloda",
        "value": "Baloda"
    },
    {
        "name": "Baloda Bazar",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.65678000",
        "longitude": "82.16062000",
        "label": "Baloda Bazar",
        "value": "Baloda Bazar"
    },
    {
        "name": "Basna",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.27885000",
        "longitude": "82.82670000",
        "label": "Basna",
        "value": "Basna"
    },
    {
        "name": "Bastar",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "19.26794000",
        "longitude": "81.73828000",
        "label": "Bastar",
        "value": "Bastar"
    },
    {
        "name": "Bemetara",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.71556000",
        "longitude": "81.53423000",
        "label": "Bemetara",
        "value": "Bemetara"
    },
    {
        "name": "Bhanpuri",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.09190000",
        "longitude": "80.93218000",
        "label": "Bhanpuri",
        "value": "Bhanpuri"
    },
    {
        "name": "Bhatapara",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.73500000",
        "longitude": "81.94711000",
        "label": "Bhatapara",
        "value": "Bhatapara"
    },
    {
        "name": "Bhatgaon",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.15000000",
        "longitude": "81.70000000",
        "label": "Bhatgaon",
        "value": "Bhatgaon"
    },
    {
        "name": "Bhilai",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.20919000",
        "longitude": "81.42850000",
        "label": "Bhilai",
        "value": "Bhilai"
    },
    {
        "name": "Bijapur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "18.84322000",
        "longitude": "80.77610000",
        "label": "Bijapur",
        "value": "Bijapur"
    },
    {
        "name": "Bilaspur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.38333000",
        "longitude": "82.13333000",
        "label": "Bilaspur",
        "value": "Bilaspur"
    },
    {
        "name": "Champa",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.03532000",
        "longitude": "82.64234000",
        "label": "Champa",
        "value": "Champa"
    },
    {
        "name": "Chhuikhadan",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.52316000",
        "longitude": "80.99788000",
        "label": "Chhuikhadan",
        "value": "Chhuikhadan"
    },
    {
        "name": "Deori",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.45000000",
        "longitude": "82.61667000",
        "label": "Deori",
        "value": "Deori"
    },
    {
        "name": "Dhamtari",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.70718000",
        "longitude": "81.54874000",
        "label": "Dhamtari",
        "value": "Dhamtari"
    },
    {
        "name": "Dongargaon",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.97172000",
        "longitude": "80.85077000",
        "label": "Dongargaon",
        "value": "Dongargaon"
    },
    {
        "name": "Dongargarh",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.18893000",
        "longitude": "80.75459000",
        "label": "Dongargarh",
        "value": "Dongargarh"
    },
    {
        "name": "Durg",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.15000000",
        "longitude": "81.40000000",
        "label": "Durg",
        "value": "Durg"
    },
    {
        "name": "Gandai",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.66667000",
        "longitude": "81.10013000",
        "label": "Gandai",
        "value": "Gandai"
    },
    {
        "name": "Gariaband",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.63323000",
        "longitude": "82.06221000",
        "label": "Gariaband",
        "value": "Gariaband"
    },
    {
        "name": "Gaurela",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.75449000",
        "longitude": "81.90107000",
        "label": "Gaurela",
        "value": "Gaurela"
    },
    {
        "name": "Gharghoda",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.17427000",
        "longitude": "83.35170000",
        "label": "Gharghoda",
        "value": "Gharghoda"
    },
    {
        "name": "Gidam",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "18.97431000",
        "longitude": "81.39894000",
        "label": "Gidam",
        "value": "Gidam"
    },
    {
        "name": "Jagdalpur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "19.08136000",
        "longitude": "82.02131000",
        "label": "Jagdalpur",
        "value": "Jagdalpur"
    },
    {
        "name": "Janjgir",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.00922000",
        "longitude": "82.57780000",
        "label": "Janjgir",
        "value": "Janjgir"
    },
    {
        "name": "Janjgir-Champa",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.90000000",
        "longitude": "82.70000000",
        "label": "Janjgir-Champa",
        "value": "Janjgir-Champa"
    },
    {
        "name": "Jashpur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.78495000",
        "longitude": "83.84573000",
        "label": "Jashpur",
        "value": "Jashpur"
    },
    {
        "name": "Jashpurnagar",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.88783000",
        "longitude": "84.13864000",
        "label": "Jashpurnagar",
        "value": "Jashpurnagar"
    },
    {
        "name": "Junagarh",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "19.85993000",
        "longitude": "82.93385000",
        "label": "Junagarh",
        "value": "Junagarh"
    },
    {
        "name": "Kabeerdham",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.10000000",
        "longitude": "81.20000000",
        "label": "Kabeerdham",
        "value": "Kabeerdham"
    },
    {
        "name": "Kanker",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.27193000",
        "longitude": "81.49177000",
        "label": "Kanker",
        "value": "Kanker"
    },
    {
        "name": "Katghora",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.50247000",
        "longitude": "82.54279000",
        "label": "Katghora",
        "value": "Katghora"
    },
    {
        "name": "Kawardha",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.00853000",
        "longitude": "81.23148000",
        "label": "Kawardha",
        "value": "Kawardha"
    },
    {
        "name": "Khairagarh",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.41859000",
        "longitude": "80.97942000",
        "label": "Khairagarh",
        "value": "Khairagarh"
    },
    {
        "name": "Khamharia",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.97600000",
        "longitude": "82.25116000",
        "label": "Khamharia",
        "value": "Khamharia"
    },
    {
        "name": "Kharod",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.74420000",
        "longitude": "82.57880000",
        "label": "Kharod",
        "value": "Kharod"
    },
    {
        "name": "Kharsia",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.98953000",
        "longitude": "83.10476000",
        "label": "Kharsia",
        "value": "Kharsia"
    },
    {
        "name": "Kirandul",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "18.63649000",
        "longitude": "81.25827000",
        "label": "Kirandul",
        "value": "Kirandul"
    },
    {
        "name": "Kondagaon",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "19.59083000",
        "longitude": "81.66400000",
        "label": "Kondagaon",
        "value": "Kondagaon"
    },
    {
        "name": "Korba",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.50000000",
        "longitude": "82.60000000",
        "label": "Korba",
        "value": "Korba"
    },
    {
        "name": "Koriya",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "23.48326000",
        "longitude": "82.15037000",
        "label": "Koriya",
        "value": "Koriya"
    },
    {
        "name": "Kota",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.29507000",
        "longitude": "82.02366000",
        "label": "Kota",
        "value": "Kota"
    },
    {
        "name": "Kotaparh",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "19.14256000",
        "longitude": "82.32536000",
        "label": "Kotaparh",
        "value": "Kotaparh"
    },
    {
        "name": "Kumhari",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.26667000",
        "longitude": "81.51667000",
        "label": "Kumhari",
        "value": "Kumhari"
    },
    {
        "name": "Kurud",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.83073000",
        "longitude": "81.72212000",
        "label": "Kurud",
        "value": "Kurud"
    },
    {
        "name": "Lormi",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.27434000",
        "longitude": "81.70181000",
        "label": "Lormi",
        "value": "Lormi"
    },
    {
        "name": "Mahasamund",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.20000000",
        "longitude": "82.50000000",
        "label": "Mahasamund",
        "value": "Mahasamund"
    },
    {
        "name": "Mungeli",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.06566000",
        "longitude": "81.68543000",
        "label": "Mungeli",
        "value": "Mungeli"
    },
    {
        "name": "Narayanpur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "19.60426000",
        "longitude": "81.08119000",
        "label": "Narayanpur",
        "value": "Narayanpur"
    },
    {
        "name": "Narharpur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.44892000",
        "longitude": "81.62004000",
        "label": "Narharpur",
        "value": "Narharpur"
    },
    {
        "name": "Pandaria",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.22495000",
        "longitude": "81.40994000",
        "label": "Pandaria",
        "value": "Pandaria"
    },
    {
        "name": "Pandatarai",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.18714000",
        "longitude": "81.32815000",
        "label": "Pandatarai",
        "value": "Pandatarai"
    },
    {
        "name": "Pasan",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.84412000",
        "longitude": "82.19823000",
        "label": "Pasan",
        "value": "Pasan"
    },
    {
        "name": "Patan",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.03333000",
        "longitude": "81.53333000",
        "label": "Patan",
        "value": "Patan"
    },
    {
        "name": "Pathalgaon",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.55656000",
        "longitude": "83.46355000",
        "label": "Pathalgaon",
        "value": "Pathalgaon"
    },
    {
        "name": "Pendra",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.77548000",
        "longitude": "81.95968000",
        "label": "Pendra",
        "value": "Pendra"
    },
    {
        "name": "Pithora",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.25021000",
        "longitude": "82.51707000",
        "label": "Pithora",
        "value": "Pithora"
    },
    {
        "name": "Raigarh",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.08582000",
        "longitude": "83.30603000",
        "label": "Raigarh",
        "value": "Raigarh"
    },
    {
        "name": "Raipur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.25621000",
        "longitude": "81.69022000",
        "label": "Raipur",
        "value": "Raipur"
    },
    {
        "name": "Raj Nandgaon",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.16667000",
        "longitude": "81.00000000",
        "label": "Raj Nandgaon",
        "value": "Raj Nandgaon"
    },
    {
        "name": "Raj-Nandgaon",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.09687000",
        "longitude": "81.02890000",
        "label": "Raj-Nandgaon",
        "value": "Raj-Nandgaon"
    },
    {
        "name": "Ramanuj Ganj",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "23.80637000",
        "longitude": "83.69981000",
        "label": "Ramanuj Ganj",
        "value": "Ramanuj Ganj"
    },
    {
        "name": "Ratanpur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.28660000",
        "longitude": "82.16823000",
        "label": "Ratanpur",
        "value": "Ratanpur"
    },
    {
        "name": "Sakti",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.02662000",
        "longitude": "82.96091000",
        "label": "Sakti",
        "value": "Sakti"
    },
    {
        "name": "Saraipali",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.31530000",
        "longitude": "83.00629000",
        "label": "Saraipali",
        "value": "Saraipali"
    },
    {
        "name": "Sarangarh",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.58614000",
        "longitude": "83.07850000",
        "label": "Sarangarh",
        "value": "Sarangarh"
    },
    {
        "name": "Seorinarayan",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.72055000",
        "longitude": "82.59344000",
        "label": "Seorinarayan",
        "value": "Seorinarayan"
    },
    {
        "name": "Simga",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "21.62810000",
        "longitude": "81.70376000",
        "label": "Simga",
        "value": "Simga"
    },
    {
        "name": "Surguja",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.89624000",
        "longitude": "83.09631000",
        "label": "Surguja",
        "value": "Surguja"
    },
    {
        "name": "Takhatpur",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "22.12915000",
        "longitude": "81.86959000",
        "label": "Takhatpur",
        "value": "Takhatpur"
    },
    {
        "name": "Umarkot",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "19.66529000",
        "longitude": "82.20629000",
        "label": "Umarkot",
        "value": "Umarkot"
    },
    {
        "name": "Uttar Bastar Kanker",
        "countryCode": "IN",
        "stateCode": "CT",
        "latitude": "20.20000000",
        "longitude": "81.10000000",
        "label": "Uttar Bastar Kanker",
        "value": "Uttar Bastar Kanker"
    }
,{
        "name": "Amli",
        "countryCode": "IN",
        "stateCode": "DH",
        "latitude": "20.28333000",
        "longitude": "73.01667000",
        "label": "Amli",
        "value": "Amli"
    },
    {
        "name": "Dadra",
        "countryCode": "IN",
        "stateCode": "DH",
        "latitude": "20.32504000",
        "longitude": "72.96618000",
        "label": "Dadra",
        "value": "Dadra"
    },
    {
        "name": "Dadra & Nagar Haveli",
        "countryCode": "IN",
        "stateCode": "DH",
        "latitude": "20.20651000",
        "longitude": "73.00811000",
        "label": "Dadra & Nagar Haveli",
        "value": "Dadra & Nagar Haveli"
    },
    {
        "name": "Daman",
        "countryCode": "IN",
        "stateCode": "DH",
        "latitude": "20.41431000",
        "longitude": "72.83236000",
        "label": "Daman",
        "value": "Daman"
    },
    {
        "name": "Daman District",
        "countryCode": "IN",
        "stateCode": "DH",
        "latitude": "20.41667000",
        "longitude": "72.88333000",
        "label": "Daman District",
        "value": "Daman District"
    },
    {
        "name": "Diu",
        "countryCode": "IN",
        "stateCode": "DH",
        "latitude": "20.72081000",
        "longitude": "70.93989000",
        "label": "Diu",
        "value": "Diu"
    },
    {
        "name": "Silvassa",
        "countryCode": "IN",
        "stateCode": "DH",
        "latitude": "20.27386000",
        "longitude": "72.99673000",
        "label": "Silvassa",
        "value": "Silvassa"
    }
,{
        "name": "Alipur",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.79862000",
        "longitude": "77.13314000",
        "label": "Alipur",
        "value": "Alipur"
    },
    {
        "name": "Bawana",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.79820000",
        "longitude": "77.03431000",
        "label": "Bawana",
        "value": "Bawana"
    },
    {
        "name": "Central Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.64857000",
        "longitude": "77.21895000",
        "label": "Central Delhi",
        "value": "Central Delhi"
    },
    {
        "name": "Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.65195000",
        "longitude": "77.23149000",
        "label": "Delhi",
        "value": "Delhi"
    },
    {
        "name": "Deoli",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.50254000",
        "longitude": "77.23117000",
        "label": "Deoli",
        "value": "Deoli"
    },
    {
        "name": "East Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.66242000",
        "longitude": "77.29122000",
        "label": "East Delhi",
        "value": "East Delhi"
    },
    {
        "name": "Karol Bagh",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.65136000",
        "longitude": "77.19072000",
        "label": "Karol Bagh",
        "value": "Karol Bagh"
    },
    {
        "name": "Najafgarh",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.60922000",
        "longitude": "76.97982000",
        "label": "Najafgarh",
        "value": "Najafgarh"
    },
    {
        "name": "Nangloi Jat",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.67957000",
        "longitude": "77.06799000",
        "label": "Nangloi Jat",
        "value": "Nangloi Jat"
    },
    {
        "name": "Narela",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.85267000",
        "longitude": "77.09288000",
        "label": "Narela",
        "value": "Narela"
    },
    {
        "name": "New Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.63576000",
        "longitude": "77.22445000",
        "label": "New Delhi",
        "value": "New Delhi"
    },
    {
        "name": "North Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.66920000",
        "longitude": "77.22273000",
        "label": "North Delhi",
        "value": "North Delhi"
    },
    {
        "name": "North East Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.68690000",
        "longitude": "77.30195000",
        "label": "North East Delhi",
        "value": "North East Delhi"
    },
    {
        "name": "North West Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.70113000",
        "longitude": "77.10154000",
        "label": "North West Delhi",
        "value": "North West Delhi"
    },
    {
        "name": "Pitampura",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.68964000",
        "longitude": "77.13126000",
        "label": "Pitampura",
        "value": "Pitampura"
    },
    {
        "name": "Rohini",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.74322000",
        "longitude": "77.06778000",
        "label": "Rohini",
        "value": "Rohini"
    },
    {
        "name": "South Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.53009000",
        "longitude": "77.25174000",
        "label": "South Delhi",
        "value": "South Delhi"
    },
    {
        "name": "South West Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.58060000",
        "longitude": "77.06720000",
        "label": "South West Delhi",
        "value": "South West Delhi"
    },
    {
        "name": "West Delhi",
        "countryCode": "IN",
        "stateCode": "DL",
        "latitude": "28.65655000",
        "longitude": "77.10068000",
        "label": "West Delhi",
        "value": "West Delhi"
    }
,{
        "name": "Aldona",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.59337000",
        "longitude": "73.87482000",
        "label": "Aldona",
        "value": "Aldona"
    },
    {
        "name": "Arambol",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.68681000",
        "longitude": "73.70449000",
        "label": "Arambol",
        "value": "Arambol"
    },
    {
        "name": "Baga",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.56517000",
        "longitude": "73.75517000",
        "label": "Baga",
        "value": "Baga"
    },
    {
        "name": "Bambolim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.46361000",
        "longitude": "73.85310000",
        "label": "Bambolim",
        "value": "Bambolim"
    },
    {
        "name": "Bandora",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.40823000",
        "longitude": "73.98129000",
        "label": "Bandora",
        "value": "Bandora"
    },
    {
        "name": "Benaulim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.26435000",
        "longitude": "73.92812000",
        "label": "Benaulim",
        "value": "Benaulim"
    },
    {
        "name": "Calangute",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.54390000",
        "longitude": "73.75530000",
        "label": "Calangute",
        "value": "Calangute"
    },
    {
        "name": "Candolim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.51807000",
        "longitude": "73.76259000",
        "label": "Candolim",
        "value": "Candolim"
    },
    {
        "name": "Carapur",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.56588000",
        "longitude": "73.98713000",
        "label": "Carapur",
        "value": "Carapur"
    },
    {
        "name": "Cavelossim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.17255000",
        "longitude": "73.94194000",
        "label": "Cavelossim",
        "value": "Cavelossim"
    },
    {
        "name": "Chicalim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.39835000",
        "longitude": "73.84216000",
        "label": "Chicalim",
        "value": "Chicalim"
    },
    {
        "name": "Chinchinim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.21447000",
        "longitude": "73.97555000",
        "label": "Chinchinim",
        "value": "Chinchinim"
    },
    {
        "name": "Colovale",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.63522000",
        "longitude": "73.82426000",
        "label": "Colovale",
        "value": "Colovale"
    },
    {
        "name": "Colva",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.27976000",
        "longitude": "73.92285000",
        "label": "Colva",
        "value": "Colva"
    },
    {
        "name": "Cortalim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.40247000",
        "longitude": "73.90881000",
        "label": "Cortalim",
        "value": "Cortalim"
    },
    {
        "name": "Cuncolim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.17730000",
        "longitude": "73.99392000",
        "label": "Cuncolim",
        "value": "Cuncolim"
    },
    {
        "name": "Curchorem",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.26349000",
        "longitude": "74.10875000",
        "label": "Curchorem",
        "value": "Curchorem"
    },
    {
        "name": "Curti",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.41667000",
        "longitude": "74.01667000",
        "label": "Curti",
        "value": "Curti"
    },
    {
        "name": "Davorlim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.27221000",
        "longitude": "73.99242000",
        "label": "Davorlim",
        "value": "Davorlim"
    },
    {
        "name": "Dicholi",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.59319000",
        "longitude": "73.94571000",
        "label": "Dicholi",
        "value": "Dicholi"
    },
    {
        "name": "Goa Velha",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.44384000",
        "longitude": "73.88572000",
        "label": "Goa Velha",
        "value": "Goa Velha"
    },
    {
        "name": "Guirim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.57552000",
        "longitude": "73.80722000",
        "label": "Guirim",
        "value": "Guirim"
    },
    {
        "name": "Jua",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.53070000",
        "longitude": "73.95047000",
        "label": "Jua",
        "value": "Jua"
    },
    {
        "name": "Kankon",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.02698000",
        "longitude": "74.04617000",
        "label": "Kankon",
        "value": "Kankon"
    },
    {
        "name": "Madgaon",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.27501000",
        "longitude": "73.95786000",
        "label": "Madgaon",
        "value": "Madgaon"
    },
    {
        "name": "Mapuca",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.59154000",
        "longitude": "73.80898000",
        "label": "Mapuca",
        "value": "Mapuca"
    },
    {
        "name": "Morjim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.63097000",
        "longitude": "73.73903000",
        "label": "Morjim",
        "value": "Morjim"
    },
    {
        "name": "Mormugao",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.38914000",
        "longitude": "73.81491000",
        "label": "Mormugao",
        "value": "Mormugao"
    },
    {
        "name": "Navelim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.53333000",
        "longitude": "73.98333000",
        "label": "Navelim",
        "value": "Navelim"
    },
    {
        "name": "North Goa",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.53397000",
        "longitude": "73.96408000",
        "label": "North Goa",
        "value": "North Goa"
    },
    {
        "name": "Palle",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.46667000",
        "longitude": "74.08333000",
        "label": "Palle",
        "value": "Palle"
    },
    {
        "name": "Panaji",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.49574000",
        "longitude": "73.82624000",
        "label": "Panaji",
        "value": "Panaji"
    },
    {
        "name": "Pernem",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.72300000",
        "longitude": "73.79511000",
        "label": "Pernem",
        "value": "Pernem"
    },
    {
        "name": "Ponda",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.40341000",
        "longitude": "74.01519000",
        "label": "Ponda",
        "value": "Ponda"
    },
    {
        "name": "Quepem",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.21280000",
        "longitude": "74.07720000",
        "label": "Quepem",
        "value": "Quepem"
    },
    {
        "name": "Queula",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.39011000",
        "longitude": "73.98557000",
        "label": "Queula",
        "value": "Queula"
    },
    {
        "name": "Raia",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.30499000",
        "longitude": "73.97096000",
        "label": "Raia",
        "value": "Raia"
    },
    {
        "name": "Saligao",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.55359000",
        "longitude": "73.79036000",
        "label": "Saligao",
        "value": "Saligao"
    },
    {
        "name": "Sancoale",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.37794000",
        "longitude": "73.90352000",
        "label": "Sancoale",
        "value": "Sancoale"
    },
    {
        "name": "Sanguem",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.22901000",
        "longitude": "74.15149000",
        "label": "Sanguem",
        "value": "Sanguem"
    },
    {
        "name": "Sanquelim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.56422000",
        "longitude": "74.00799000",
        "label": "Sanquelim",
        "value": "Sanquelim"
    },
    {
        "name": "Sanvordem",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.26269000",
        "longitude": "74.11965000",
        "label": "Sanvordem",
        "value": "Sanvordem"
    },
    {
        "name": "Serula",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.54774000",
        "longitude": "73.84329000",
        "label": "Serula",
        "value": "Serula"
    },
    {
        "name": "Solim",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.61521000",
        "longitude": "73.76740000",
        "label": "Solim",
        "value": "Solim"
    },
    {
        "name": "South Goa",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.20425000",
        "longitude": "74.16733000",
        "label": "South Goa",
        "value": "South Goa"
    },
    {
        "name": "Taleigao",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.46915000",
        "longitude": "73.83285000",
        "label": "Taleigao",
        "value": "Taleigao"
    },
    {
        "name": "Vagator",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.59766000",
        "longitude": "73.74496000",
        "label": "Vagator",
        "value": "Vagator"
    },
    {
        "name": "Valpoy",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.53239000",
        "longitude": "74.13671000",
        "label": "Valpoy",
        "value": "Valpoy"
    },
    {
        "name": "Varca",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.23237000",
        "longitude": "73.94311000",
        "label": "Varca",
        "value": "Varca"
    },
    {
        "name": "Vasco da Gama",
        "countryCode": "IN",
        "stateCode": "GA",
        "latitude": "15.39585000",
        "longitude": "73.81568000",
        "label": "Vasco da Gama",
        "value": "Vasco da Gama"
    }
,{
        "name": "Abrama",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.85865000",
        "longitude": "72.90648000",
        "label": "Abrama",
        "value": "Abrama"
    },
    {
        "name": "Adalaj",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.16453000",
        "longitude": "72.58107000",
        "label": "Adalaj",
        "value": "Adalaj"
    },
    {
        "name": "Agol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.15000000",
        "longitude": "72.26666667",
        "label": "Agol",
        "value": "Agol"
    },
    {
        "name": "Ahmedabad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.02579000",
        "longitude": "72.58727000",
        "label": "Ahmedabad",
        "value": "Ahmedabad"
    },
    {
        "name": "Ahwa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.75718000",
        "longitude": "73.68626000",
        "label": "Ahwa",
        "value": "Ahwa"
    },
    {
        "name": "Akrund",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.28333333",
        "longitude": "73.11666667",
        "label": "Akrund",
        "value": "Akrund"
    },
    {
        "name": "Amod",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.99317000",
        "longitude": "72.87047000",
        "label": "Amod",
        "value": "Amod"
    },
    {
        "name": "Amod,",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.99100000",
        "longitude": "72.87100000",
        "label": "Amod,",
        "value": "Amod,"
    },
    {
        "name": "Amreli",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.50789000",
        "longitude": "71.18323000",
        "label": "Amreli",
        "value": "Amreli"
    },
    {
        "name": "Amroli",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.25084000",
        "longitude": "72.83878000",
        "label": "Amroli",
        "value": "Amroli"
    },
    {
        "name": "Anand",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.40000000",
        "longitude": "72.75000000",
        "label": "Anand",
        "value": "Anand"
    },
    {
        "name": "Anjar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.11316000",
        "longitude": "70.02671000",
        "label": "Anjar",
        "value": "Anjar"
    },
    {
        "name": "Ankleshwar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.63236000",
        "longitude": "72.99001000",
        "label": "Ankleshwar",
        "value": "Ankleshwar"
    },
    {
        "name": "Babra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.84577000",
        "longitude": "71.30544000",
        "label": "Babra",
        "value": "Babra"
    },
    {
        "name": "Bagasara",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.48333333",
        "longitude": "70.95000000",
        "label": "Bagasara",
        "value": "Bagasara"
    },
    {
        "name": "Bagasra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.48719000",
        "longitude": "70.95516000",
        "label": "Bagasra",
        "value": "Bagasra"
    },
    {
        "name": "Bakharla",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.73151700",
        "longitude": "69.63529600",
        "label": "Bakharla",
        "value": "Bakharla"
    },
    {
        "name": "Balagam",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.36666667",
        "longitude": "70.10000000",
        "label": "Balagam",
        "value": "Balagam"
    },
    {
        "name": "Balasinor",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.95589100",
        "longitude": "73.33649900",
        "label": "Balasinor",
        "value": "Balasinor"
    },
    {
        "name": "Balisana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.81643600",
        "longitude": "72.25753600",
        "label": "Balisana",
        "value": "Balisana"
    },
    {
        "name": "Bamanbore",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.41666667",
        "longitude": "71.01666667",
        "label": "Bamanbore",
        "value": "Bamanbore"
    },
    {
        "name": "Banas Kantha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.25000000",
        "longitude": "72.50000000",
        "label": "Banas Kantha",
        "value": "Banas Kantha"
    },
    {
        "name": "Bandia",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.39604000",
        "longitude": "69.01155000",
        "label": "Bandia",
        "value": "Bandia"
    },
    {
        "name": "Bantva",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.48815000",
        "longitude": "70.07576000",
        "label": "Bantva",
        "value": "Bantva"
    },
    {
        "name": "Bardoli",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.12297000",
        "longitude": "73.11151000",
        "label": "Bardoli",
        "value": "Bardoli"
    },
    {
        "name": "Bedi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.50143000",
        "longitude": "70.04363000",
        "label": "Bedi",
        "value": "Bedi"
    },
    {
        "name": "Bhachau",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.29858000",
        "longitude": "70.34279000",
        "label": "Bhachau",
        "value": "Bhachau"
    },
    {
        "name": "Bhadran",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.35930000",
        "longitude": "72.90050000",
        "label": "Bhadran",
        "value": "Bhadran"
    },
    {
        "name": "Bhandu",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.70000000",
        "longitude": "72.36666667",
        "label": "Bhandu",
        "value": "Bhandu"
    },
    {
        "name": "Bhanvad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.93053000",
        "longitude": "69.78081000",
        "label": "Bhanvad",
        "value": "Bhanvad"
    },
    {
        "name": "Bharuch",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.69482000",
        "longitude": "72.98050000",
        "label": "Bharuch",
        "value": "Bharuch"
    },
    {
        "name": "Bhatha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.18333333",
        "longitude": "72.76666667",
        "label": "Bhatha",
        "value": "Bhatha"
    },
    {
        "name": "Bhavnagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.76287000",
        "longitude": "72.15331000",
        "label": "Bhavnagar",
        "value": "Bhavnagar"
    },
    {
        "name": "Bhayavadar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.85523000",
        "longitude": "70.24791000",
        "label": "Bhayavadar",
        "value": "Bhayavadar"
    },
    {
        "name": "Bhildi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.18333333",
        "longitude": "72.03333333",
        "label": "Bhildi",
        "value": "Bhildi"
    },
    {
        "name": "Bhojpur Dharampur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.25000000",
        "longitude": "69.67000000",
        "label": "Bhojpur Dharampur",
        "value": "Bhojpur Dharampur"
    },
    {
        "name": "Bhuj",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.25397000",
        "longitude": "69.66928000",
        "label": "Bhuj",
        "value": "Bhuj"
    },
    {
        "name": "Bilimora",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.76957000",
        "longitude": "72.96134000",
        "label": "Bilimora",
        "value": "Bilimora"
    },
    {
        "name": "Bilkha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.44150000",
        "longitude": "70.60063000",
        "label": "Bilkha",
        "value": "Bilkha"
    },
    {
        "name": "Borsad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.40788000",
        "longitude": "72.89817000",
        "label": "Borsad",
        "value": "Borsad"
    },
    {
        "name": "Botad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.16917000",
        "longitude": "71.66671000",
        "label": "Botad",
        "value": "Botad"
    },
    {
        "name": "Chaklasi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.65320000",
        "longitude": "72.94497000",
        "label": "Chaklasi",
        "value": "Chaklasi"
    },
    {
        "name": "Chalala",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.41073000",
        "longitude": "71.16621000",
        "label": "Chalala",
        "value": "Chalala"
    },
    {
        "name": "Chaloda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.80000000",
        "longitude": "72.45000000",
        "label": "Chaloda",
        "value": "Chaloda"
    },
    {
        "name": "Champaner",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.48590000",
        "longitude": "73.53710000",
        "label": "Champaner",
        "value": "Champaner"
    },
    {
        "name": "Chanasma",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.71472000",
        "longitude": "72.11279000",
        "label": "Chanasma",
        "value": "Chanasma"
    },
    {
        "name": "Chhala",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.30779000",
        "longitude": "72.77404000",
        "label": "Chhala",
        "value": "Chhala"
    },
    {
        "name": "Chhota Udepur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.30401000",
        "longitude": "74.01580000",
        "label": "Chhota Udepur",
        "value": "Chhota Udepur"
    },
    {
        "name": "Chikhli",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.75751000",
        "longitude": "73.06268000",
        "label": "Chikhli",
        "value": "Chikhli"
    },
    {
        "name": "Chotila",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.42347000",
        "longitude": "71.19641000",
        "label": "Chotila",
        "value": "Chotila"
    },
    {
        "name": "Chuda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.48333333",
        "longitude": "71.68333333",
        "label": "Chuda",
        "value": "Chuda"
    },
    {
        "name": "Dabhoda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.16666667",
        "longitude": "72.73333333",
        "label": "Dabhoda",
        "value": "Dabhoda"
    },
    {
        "name": "Dabhoi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.18333000",
        "longitude": "73.43333000",
        "label": "Dabhoi",
        "value": "Dabhoi"
    },
    {
        "name": "Dahegam",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.16903000",
        "longitude": "72.82161000",
        "label": "Dahegam",
        "value": "Dahegam"
    },
    {
        "name": "Dahod",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.52000000",
        "longitude": "74.15000000",
        "label": "Dahod",
        "value": "Dahod"
    },
    {
        "name": "Dakor",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.75268000",
        "longitude": "73.14967000",
        "label": "Dakor",
        "value": "Dakor"
    },
    {
        "name": "Damnagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.69232000",
        "longitude": "71.51747000",
        "label": "Damnagar",
        "value": "Damnagar"
    },
    {
        "name": "Dandi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.32988000",
        "longitude": "72.62484000",
        "label": "Dandi",
        "value": "Dandi"
    },
    {
        "name": "Dangs (India)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.75000000",
        "longitude": "73.75000000",
        "label": "Dangs (India)",
        "value": "Dangs (India)"
    },
    {
        "name": "Danta",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.18861111",
        "longitude": "72.76583333",
        "label": "Danta",
        "value": "Danta"
    },
    {
        "name": "Dayapar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.63371000",
        "longitude": "68.90192000",
        "label": "Dayapar",
        "value": "Dayapar"
    },
    {
        "name": "Delvada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.77544000",
        "longitude": "71.04646000",
        "label": "Delvada",
        "value": "Delvada"
    },
    {
        "name": "Delwada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.78330000",
        "longitude": "71.05000000",
        "label": "Delwada",
        "value": "Delwada"
    },
    {
        "name": "Detroj",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.33333333",
        "longitude": "72.18333333",
        "label": "Detroj",
        "value": "Detroj"
    },
    {
        "name": "Devbhumi Dwarka",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.20253000",
        "longitude": "69.65498000",
        "label": "Devbhumi Dwarka",
        "value": "Devbhumi Dwarka"
    },
    {
        "name": "Devgadh Bariya",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.70517000",
        "longitude": "73.90882000",
        "label": "Devgadh Bariya",
        "value": "Devgadh Bariya"
    },
    {
        "name": "Dhandhuka",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.38185000",
        "longitude": "71.98664000",
        "label": "Dhandhuka",
        "value": "Dhandhuka"
    },
    {
        "name": "Dhanera",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.50967000",
        "longitude": "72.02343000",
        "label": "Dhanera",
        "value": "Dhanera"
    },
    {
        "name": "Dhansura",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.35000000",
        "longitude": "73.20000000",
        "label": "Dhansura",
        "value": "Dhansura"
    },
    {
        "name": "Dharampur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.53693000",
        "longitude": "73.17368000",
        "label": "Dharampur",
        "value": "Dharampur"
    },
    {
        "name": "Dharasana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.68333333",
        "longitude": "72.91666667",
        "label": "Dharasana",
        "value": "Dharasana"
    },
    {
        "name": "Dhari",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.32855000",
        "longitude": "71.02645000",
        "label": "Dhari",
        "value": "Dhari"
    },
    {
        "name": "Dhasa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.80000000",
        "longitude": "71.51666667",
        "label": "Dhasa",
        "value": "Dhasa"
    },
    {
        "name": "Dhola",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.88129000",
        "longitude": "71.77269000",
        "label": "Dhola",
        "value": "Dhola"
    },
    {
        "name": "Dholera",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.24800000",
        "longitude": "72.19500000",
        "label": "Dholera",
        "value": "Dholera"
    },
    {
        "name": "Dholka",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.72732000",
        "longitude": "72.44128000",
        "label": "Dholka",
        "value": "Dholka"
    },
    {
        "name": "Dhoraji",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.73359000",
        "longitude": "70.45004000",
        "label": "Dhoraji",
        "value": "Dhoraji"
    },
    {
        "name": "Dhrangadhra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.99167000",
        "longitude": "71.46793000",
        "label": "Dhrangadhra",
        "value": "Dhrangadhra"
    },
    {
        "name": "Dhrol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.56700000",
        "longitude": "70.41769000",
        "label": "Dhrol",
        "value": "Dhrol"
    },
    {
        "name": "Dhuwaran",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.23779000",
        "longitude": "72.75910000",
        "label": "Dhuwaran",
        "value": "Dhuwaran"
    },
    {
        "name": "Disa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.25612000",
        "longitude": "72.17928000",
        "label": "Disa",
        "value": "Disa"
    },
    {
        "name": "Dohad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.90000000",
        "longitude": "74.00000000",
        "label": "Dohad",
        "value": "Dohad"
    },
    {
        "name": "Dumkhal",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.73960000",
        "longitude": "73.84490000",
        "label": "Dumkhal",
        "value": "Dumkhal"
    },
    {
        "name": "Dungarpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.28777000",
        "longitude": "71.75560000",
        "label": "Dungarpur",
        "value": "Dungarpur"
    },
    {
        "name": "Dwarka",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.23944000",
        "longitude": "68.96778000",
        "label": "Dwarka",
        "value": "Dwarka"
    },
    {
        "name": "Gadhada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.96957000",
        "longitude": "71.57828000",
        "label": "Gadhada",
        "value": "Gadhada"
    },
    {
        "name": "Gandevi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.81214000",
        "longitude": "72.99811000",
        "label": "Gandevi",
        "value": "Gandevi"
    },
    {
        "name": "Gandhidham",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.08333000",
        "longitude": "70.13333000",
        "label": "Gandhidham",
        "value": "Gandhidham"
    },
    {
        "name": "Gandhinagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.21667000",
        "longitude": "72.68333000",
        "label": "Gandhinagar",
        "value": "Gandhinagar"
    },
    {
        "name": "Gariadhar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.53889000",
        "longitude": "71.57737000",
        "label": "Gariadhar",
        "value": "Gariadhar"
    },
    {
        "name": "Ghodasar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.45000000",
        "longitude": "71.85000000",
        "label": "Ghodasar",
        "value": "Ghodasar"
    },
    {
        "name": "Ghogha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.68813000",
        "longitude": "72.27630000",
        "label": "Ghogha",
        "value": "Ghogha"
    },
    {
        "name": "Gir Somnath",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.91287000",
        "longitude": "70.36710000",
        "label": "Gir Somnath",
        "value": "Gir Somnath"
    },
    {
        "name": "Godhra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.77547000",
        "longitude": "73.61488000",
        "label": "Godhra",
        "value": "Godhra"
    },
    {
        "name": "Gondal",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.96074000",
        "longitude": "70.80255000",
        "label": "Gondal",
        "value": "Gondal"
    },
    {
        "name": "Gorwa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.33010000",
        "longitude": "73.16110000",
        "label": "Gorwa",
        "value": "Gorwa"
    },
    {
        "name": "Halenda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.08818500",
        "longitude": "71.05171000",
        "label": "Halenda",
        "value": "Halenda"
    },
    {
        "name": "Halol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.50321000",
        "longitude": "73.47242000",
        "label": "Halol",
        "value": "Halol"
    },
    {
        "name": "Halvad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.01516000",
        "longitude": "71.18029000",
        "label": "Halvad",
        "value": "Halvad"
    },
    {
        "name": "Hansot",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.58496000",
        "longitude": "72.80764000",
        "label": "Hansot",
        "value": "Hansot"
    },
    {
        "name": "Harij",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.69356000",
        "longitude": "71.90700000",
        "label": "Harij",
        "value": "Harij"
    },
    {
        "name": "Harsol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.36000000",
        "longitude": "73.02000000",
        "label": "Harsol",
        "value": "Harsol"
    },
    {
        "name": "Hathuran",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.50000000",
        "longitude": "72.97000000",
        "label": "Hathuran",
        "value": "Hathuran"
    },
    {
        "name": "Himatnagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.59893000",
        "longitude": "72.96602000",
        "label": "Himatnagar",
        "value": "Himatnagar"
    },
    {
        "name": "Idar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.83000000",
        "longitude": "73.00000000",
        "label": "Idar",
        "value": "Idar"
    },
    {
        "name": "Jakhau",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.21861111",
        "longitude": "68.71694444",
        "label": "Jakhau",
        "value": "Jakhau"
    },
    {
        "name": "Jalalpore",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.94910000",
        "longitude": "72.91360000",
        "label": "Jalalpore",
        "value": "Jalalpore"
    },
    {
        "name": "Jalalpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.94896000",
        "longitude": "72.89829000",
        "label": "Jalalpur",
        "value": "Jalalpur"
    },
    {
        "name": "Jalia",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.80000000",
        "longitude": "70.25000000",
        "label": "Jalia",
        "value": "Jalia"
    },
    {
        "name": "Jambuda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.51666667",
        "longitude": "70.21666667",
        "label": "Jambuda",
        "value": "Jambuda"
    },
    {
        "name": "Jambusar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.05236000",
        "longitude": "72.80074000",
        "label": "Jambusar",
        "value": "Jambusar"
    },
    {
        "name": "Jamnagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.47292000",
        "longitude": "70.06673000",
        "label": "Jamnagar",
        "value": "Jamnagar"
    },
    {
        "name": "Jarod,",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.43300000",
        "longitude": "73.33300000",
        "label": "Jarod,",
        "value": "Jarod,"
    },
    {
        "name": "Jasdan",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.03709000",
        "longitude": "71.20794000",
        "label": "Jasdan",
        "value": "Jasdan"
    },
    {
        "name": "Jetalpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.88333333",
        "longitude": "72.60000000",
        "label": "Jetalpur",
        "value": "Jetalpur"
    },
    {
        "name": "Jetalsar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.70891000",
        "longitude": "70.57695000",
        "label": "Jetalsar",
        "value": "Jetalsar"
    },
    {
        "name": "Jetpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.75482000",
        "longitude": "70.62347000",
        "label": "Jetpur",
        "value": "Jetpur"
    },
    {
        "name": "Jetpur (Navagadh)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.75482000",
        "longitude": "70.62347000",
        "label": "Jetpur (Navagadh)",
        "value": "Jetpur (Navagadh)"
    },
    {
        "name": "Jhalod",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.10027778",
        "longitude": "74.15611111",
        "label": "Jhalod",
        "value": "Jhalod"
    },
    {
        "name": "Jhulasan",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.32860000",
        "longitude": "72.47314000",
        "label": "Jhulasan",
        "value": "Jhulasan"
    },
    {
        "name": "Jodhpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.90174000",
        "longitude": "70.03270000",
        "label": "Jodhpur",
        "value": "Jodhpur"
    },
    {
        "name": "Jodhpur (Ahmedabad)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.88000000",
        "longitude": "70.03000000",
        "label": "Jodhpur (Ahmedabad)",
        "value": "Jodhpur (Ahmedabad)"
    },
    {
        "name": "Jodia",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.71667000",
        "longitude": "70.28333000",
        "label": "Jodia",
        "value": "Jodia"
    },
    {
        "name": "Jodiya Bandar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.71667000",
        "longitude": "70.28333000",
        "label": "Jodiya Bandar",
        "value": "Jodiya Bandar"
    },
    {
        "name": "Junagadh",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.25000000",
        "longitude": "70.33333000",
        "label": "Junagadh",
        "value": "Junagadh"
    },
    {
        "name": "Kachchh",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.58333000",
        "longitude": "70.00000000",
        "label": "Kachchh",
        "value": "Kachchh"
    },
    {
        "name": "Kachholi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.83333333",
        "longitude": "72.96666667",
        "label": "Kachholi",
        "value": "Kachholi"
    },
    {
        "name": "Kadi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.29908000",
        "longitude": "72.33362000",
        "label": "Kadi",
        "value": "Kadi"
    },
    {
        "name": "Kadod",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.21717000",
        "longitude": "73.21972000",
        "label": "Kadod",
        "value": "Kadod"
    },
    {
        "name": "Kalavad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.20789000",
        "longitude": "70.38343000",
        "label": "Kalavad",
        "value": "Kalavad"
    },
    {
        "name": "Kalol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.60777000",
        "longitude": "73.46272000",
        "label": "Kalol",
        "value": "Kalol"
    },
    {
        "name": "Kandla",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.03333000",
        "longitude": "70.21667000",
        "label": "Kandla",
        "value": "Kandla"
    },
    {
        "name": "Kandla port",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.03000000",
        "longitude": "70.22000000",
        "label": "Kandla port",
        "value": "Kandla port"
    },
    {
        "name": "Kanodar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.08932000",
        "longitude": "72.39354000",
        "label": "Kanodar",
        "value": "Kanodar"
    },
    {
        "name": "Kapadvanj",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.02302000",
        "longitude": "73.07113000",
        "label": "Kapadvanj",
        "value": "Kapadvanj"
    },
    {
        "name": "Karamsad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.54243000",
        "longitude": "72.90392000",
        "label": "Karamsad",
        "value": "Karamsad"
    },
    {
        "name": "Kariana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.88333333",
        "longitude": "71.35000000",
        "label": "Kariana",
        "value": "Kariana"
    },
    {
        "name": "Karjan",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.05304167",
        "longitude": "73.12351389",
        "label": "Karjan",
        "value": "Karjan"
    },
    {
        "name": "Kathor",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.28854000",
        "longitude": "72.94070000",
        "label": "Kathor",
        "value": "Kathor"
    },
    {
        "name": "Katpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.05869000",
        "longitude": "71.79457000",
        "label": "Katpur",
        "value": "Katpur"
    },
    {
        "name": "Kavant",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.09282000",
        "longitude": "74.05078000",
        "label": "Kavant",
        "value": "Kavant"
    },
    {
        "name": "Kawant",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.09282000",
        "longitude": "74.05078000",
        "label": "Kawant",
        "value": "Kawant"
    },
    {
        "name": "Kayavarohan",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.06700000",
        "longitude": "73.25000000",
        "label": "Kayavarohan",
        "value": "Kayavarohan"
    },
    {
        "name": "Kerwada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.90000000",
        "longitude": "72.85000000",
        "label": "Kerwada",
        "value": "Kerwada"
    },
    {
        "name": "Keshod",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.30328000",
        "longitude": "70.24861000",
        "label": "Keshod",
        "value": "Keshod"
    },
    {
        "name": "Khambhalia",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.20000000",
        "longitude": "69.65000000",
        "label": "Khambhalia",
        "value": "Khambhalia"
    },
    {
        "name": "Khambhat",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.31744000",
        "longitude": "72.61916000",
        "label": "Khambhat",
        "value": "Khambhat"
    },
    {
        "name": "Khavda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.85000000",
        "longitude": "69.72000000",
        "label": "Khavda",
        "value": "Khavda"
    },
    {
        "name": "Kheda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.75000000",
        "longitude": "72.83333000",
        "label": "Kheda",
        "value": "Kheda"
    },
    {
        "name": "Khedbrahma",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.02990000",
        "longitude": "73.04632000",
        "label": "Khedbrahma",
        "value": "Khedbrahma"
    },
    {
        "name": "Khedoi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.05927778",
        "longitude": "69.91895556",
        "label": "Khedoi",
        "value": "Khedoi"
    },
    {
        "name": "Kherali",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.68333333",
        "longitude": "71.60000000",
        "label": "Kherali",
        "value": "Kherali"
    },
    {
        "name": "Kheralu",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.88534000",
        "longitude": "72.61869000",
        "label": "Kheralu",
        "value": "Kheralu"
    },
    {
        "name": "Kodinar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.79393000",
        "longitude": "70.70216000",
        "label": "Kodinar",
        "value": "Kodinar"
    },
    {
        "name": "Kosamba",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.46202000",
        "longitude": "72.95842000",
        "label": "Kosamba",
        "value": "Kosamba"
    },
    {
        "name": "Kothara",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.13300000",
        "longitude": "68.93200000",
        "label": "Kothara",
        "value": "Kothara"
    },
    {
        "name": "Kotharia",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.23000000",
        "longitude": "70.81000000",
        "label": "Kotharia",
        "value": "Kotharia"
    },
    {
        "name": "Kukarmunda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.51666667",
        "longitude": "74.13333333",
        "label": "Kukarmunda",
        "value": "Kukarmunda"
    },
    {
        "name": "Kukma",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.21782200",
        "longitude": "69.77792200",
        "label": "Kukma",
        "value": "Kukma"
    },
    {
        "name": "Kundla",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.34222000",
        "longitude": "71.30633000",
        "label": "Kundla",
        "value": "Kundla"
    },
    {
        "name": "Kutch district",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.91500000",
        "longitude": "70.36700000",
        "label": "Kutch district",
        "value": "Kutch district"
    },
    {
        "name": "Kutiyana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.62410000",
        "longitude": "69.98494000",
        "label": "Kutiyana",
        "value": "Kutiyana"
    },
    {
        "name": "Ladol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.61666667",
        "longitude": "72.73333333",
        "label": "Ladol",
        "value": "Ladol"
    },
    {
        "name": "Lakhpat",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.82611111",
        "longitude": "68.77694444",
        "label": "Lakhpat",
        "value": "Lakhpat"
    },
    {
        "name": "Lakhtar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.85683000",
        "longitude": "71.78844000",
        "label": "Lakhtar",
        "value": "Lakhtar"
    },
    {
        "name": "Lalpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.19073000",
        "longitude": "69.96351000",
        "label": "Lalpur",
        "value": "Lalpur"
    },
    {
        "name": "Langhnaj",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.45000000",
        "longitude": "72.48333333",
        "label": "Langhnaj",
        "value": "Langhnaj"
    },
    {
        "name": "Lathi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.72310000",
        "longitude": "71.38843000",
        "label": "Lathi",
        "value": "Lathi"
    },
    {
        "name": "Limbdi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.56507000",
        "longitude": "71.81076000",
        "label": "Limbdi",
        "value": "Limbdi"
    },
    {
        "name": "Limkheda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.81666667",
        "longitude": "73.98333333",
        "label": "Limkheda",
        "value": "Limkheda"
    },
    {
        "name": "Lunavada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.12841000",
        "longitude": "73.61043000",
        "label": "Lunavada",
        "value": "Lunavada"
    },
    {
        "name": "Madhavpur Ghed",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.29929167",
        "longitude": "70.02513889",
        "label": "Madhavpur Ghed",
        "value": "Madhavpur Ghed"
    },
    {
        "name": "Madhi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.10000000",
        "longitude": "69.10000000",
        "label": "Madhi",
        "value": "Madhi"
    },
    {
        "name": "Mahemdavad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.82359000",
        "longitude": "72.75551000",
        "label": "Mahemdavad",
        "value": "Mahemdavad"
    },
    {
        "name": "Mahesana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.66667000",
        "longitude": "72.50000000",
        "label": "Mahesana",
        "value": "Mahesana"
    },
    {
        "name": "Mahisa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.85000000",
        "longitude": "73.05000000",
        "label": "Mahisa",
        "value": "Mahisa"
    },
    {
        "name": "Mahudha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.82082000",
        "longitude": "72.94032000",
        "label": "Mahudha",
        "value": "Mahudha"
    },
    {
        "name": "Mahuva",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.08330000",
        "longitude": "71.80000000",
        "label": "Mahuva",
        "value": "Mahuva"
    },
    {
        "name": "Mahuva (Surat)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.02000000",
        "longitude": "73.15000000",
        "label": "Mahuva (Surat)",
        "value": "Mahuva (Surat)"
    },
    {
        "name": "Malpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.36035000",
        "longitude": "73.46595000",
        "label": "Malpur",
        "value": "Malpur"
    },
    {
        "name": "Manavadar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.49813000",
        "longitude": "70.13775000",
        "label": "Manavadar",
        "value": "Manavadar"
    },
    {
        "name": "Mandal",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.28865000",
        "longitude": "71.91854000",
        "label": "Mandal",
        "value": "Mandal"
    },
    {
        "name": "Mandvi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.83282000",
        "longitude": "69.35237000",
        "label": "Mandvi",
        "value": "Mandvi"
    },
    {
        "name": "Mandvi (Surat)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.25526000",
        "longitude": "73.30412000",
        "label": "Mandvi (Surat)",
        "value": "Mandvi (Surat)"
    },
    {
        "name": "Mangrol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.12268000",
        "longitude": "70.11484000",
        "label": "Mangrol",
        "value": "Mangrol"
    },
    {
        "name": "Mangrol (Junagadh)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.12000000",
        "longitude": "70.12000000",
        "label": "Mangrol (Junagadh)",
        "value": "Mangrol (Junagadh)"
    },
    {
        "name": "Mansa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.42564000",
        "longitude": "72.65739000",
        "label": "Mansa",
        "value": "Mansa"
    },
    {
        "name": "Meghraj",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.49805000",
        "longitude": "73.51352000",
        "label": "Meghraj",
        "value": "Meghraj"
    },
    {
        "name": "Mehsana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.60000000",
        "longitude": "72.40000000",
        "label": "Mehsana",
        "value": "Mehsana"
    },
    {
        "name": "Mendarda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.32112000",
        "longitude": "70.44078000",
        "label": "Mendarda",
        "value": "Mendarda"
    },
    {
        "name": "Mithapur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.41000000",
        "longitude": "69.00000000",
        "label": "Mithapur",
        "value": "Mithapur"
    },
    {
        "name": "Modasa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.46253000",
        "longitude": "73.29857000",
        "label": "Modasa",
        "value": "Modasa"
    },
    {
        "name": "Morbi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.81731000",
        "longitude": "70.83770000",
        "label": "Morbi",
        "value": "Morbi"
    },
    {
        "name": "Morva (Hadaf)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.90469000",
        "longitude": "73.83912000",
        "label": "Morva (Hadaf)",
        "value": "Morva (Hadaf)"
    },
    {
        "name": "Morwa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.90469000",
        "longitude": "73.83912000",
        "label": "Morwa",
        "value": "Morwa"
    },
    {
        "name": "Mundra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.83918000",
        "longitude": "69.72190000",
        "label": "Mundra",
        "value": "Mundra"
    },
    {
        "name": "Nadiad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.69385000",
        "longitude": "72.86157000",
        "label": "Nadiad",
        "value": "Nadiad"
    },
    {
        "name": "Nadiad,",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.70000000",
        "longitude": "72.87000000",
        "label": "Nadiad,",
        "value": "Nadiad,"
    },
    {
        "name": "Nagwa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "25.69621000",
        "longitude": "84.23590000",
        "label": "Nagwa",
        "value": "Nagwa"
    },
    {
        "name": "Naldhara",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.96666667",
        "longitude": "73.16666667",
        "label": "Naldhara",
        "value": "Naldhara"
    },
    {
        "name": "Naliya",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.26058000",
        "longitude": "68.82655000",
        "label": "Naliya",
        "value": "Naliya"
    },
    {
        "name": "Nargol",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.23300000",
        "longitude": "72.75000000",
        "label": "Nargol",
        "value": "Nargol"
    },
    {
        "name": "Narmada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.87377000",
        "longitude": "73.49527000",
        "label": "Narmada",
        "value": "Narmada"
    },
    {
        "name": "Naroda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.07041000",
        "longitude": "72.65702000",
        "label": "Naroda",
        "value": "Naroda"
    },
    {
        "name": "Navsari",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.95000000",
        "longitude": "72.92000000",
        "label": "Navsari",
        "value": "Navsari"
    },
    {
        "name": "Nikora",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.78586000",
        "longitude": "73.13968000",
        "label": "Nikora",
        "value": "Nikora"
    },
    {
        "name": "Nizar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.47727000",
        "longitude": "74.19595000",
        "label": "Nizar",
        "value": "Nizar"
    },
    {
        "name": "Odadar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.56666667",
        "longitude": "69.66666667",
        "label": "Odadar",
        "value": "Odadar"
    },
    {
        "name": "Okha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.46756000",
        "longitude": "69.07002000",
        "label": "Okha",
        "value": "Okha"
    },
    {
        "name": "Olpad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.33649000",
        "longitude": "72.75161000",
        "label": "Olpad",
        "value": "Olpad"
    },
    {
        "name": "Paddhari",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.43654000",
        "longitude": "70.60162000",
        "label": "Paddhari",
        "value": "Paddhari"
    },
    {
        "name": "Padra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.23980000",
        "longitude": "73.08451000",
        "label": "Padra",
        "value": "Padra"
    },
    {
        "name": "Palanpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.17128000",
        "longitude": "72.43827000",
        "label": "Palanpur",
        "value": "Palanpur"
    },
    {
        "name": "Palanswa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.46666667",
        "longitude": "70.93333333",
        "label": "Palanswa",
        "value": "Palanswa"
    },
    {
        "name": "Palitana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.52519000",
        "longitude": "71.82309000",
        "label": "Palitana",
        "value": "Palitana"
    },
    {
        "name": "Paliyad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.25757000",
        "longitude": "71.56024000",
        "label": "Paliyad",
        "value": "Paliyad"
    },
    {
        "name": "Paliyad (Bhavnagar)",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.25757000",
        "longitude": "71.56024000",
        "label": "Paliyad (Bhavnagar)",
        "value": "Paliyad (Bhavnagar)"
    },
    {
        "name": "Palsana",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.08000000",
        "longitude": "72.98000000",
        "label": "Palsana",
        "value": "Palsana"
    },
    {
        "name": "Panch Mahals",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.75000000",
        "longitude": "73.60000000",
        "label": "Panch Mahals",
        "value": "Panch Mahals"
    },
    {
        "name": "Panchmahal district",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.75000000",
        "longitude": "73.60000000",
        "label": "Panchmahal district",
        "value": "Panchmahal district"
    },
    {
        "name": "Pardi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.50870000",
        "longitude": "72.94569000",
        "label": "Pardi",
        "value": "Pardi"
    },
    {
        "name": "Parnera",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.56101000",
        "longitude": "72.94846000",
        "label": "Parnera",
        "value": "Parnera"
    },
    {
        "name": "Patan",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.70000000",
        "longitude": "71.80000000",
        "label": "Patan",
        "value": "Patan"
    },
    {
        "name": "Pavi Jetpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.34472000",
        "longitude": "73.84093000",
        "label": "Pavi Jetpur",
        "value": "Pavi Jetpur"
    },
    {
        "name": "Petlad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.47681000",
        "longitude": "72.79995000",
        "label": "Petlad",
        "value": "Petlad"
    },
    {
        "name": "Pipavav",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.96666667",
        "longitude": "71.56666667",
        "label": "Pipavav",
        "value": "Pipavav"
    },
    {
        "name": "Piplod",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.81666667",
        "longitude": "73.90000000",
        "label": "Piplod",
        "value": "Piplod"
    },
    {
        "name": "Porbandar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.64219000",
        "longitude": "69.60929000",
        "label": "Porbandar",
        "value": "Porbandar"
    },
    {
        "name": "Prabhas Patan",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.88808000",
        "longitude": "70.40129000",
        "label": "Prabhas Patan",
        "value": "Prabhas Patan"
    },
    {
        "name": "Prantij",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.43841944",
        "longitude": "72.85718056",
        "label": "Prantij",
        "value": "Prantij"
    },
    {
        "name": "Radhanpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.83238000",
        "longitude": "71.60470000",
        "label": "Radhanpur",
        "value": "Radhanpur"
    },
    {
        "name": "Rajkot",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.33333000",
        "longitude": "70.83333000",
        "label": "Rajkot",
        "value": "Rajkot"
    },
    {
        "name": "Rajpipla",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.86667000",
        "longitude": "73.50000000",
        "label": "Rajpipla",
        "value": "Rajpipla"
    },
    {
        "name": "Rajula",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.03854000",
        "longitude": "71.44345000",
        "label": "Rajula",
        "value": "Rajula"
    },
    {
        "name": "Ranavav",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.68734000",
        "longitude": "69.74485000",
        "label": "Ranavav",
        "value": "Ranavav"
    },
    {
        "name": "Ranpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.36670000",
        "longitude": "71.75000000",
        "label": "Ranpur",
        "value": "Ranpur"
    },
    {
        "name": "Rapar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.57267000",
        "longitude": "70.64718000",
        "label": "Rapar",
        "value": "Rapar"
    },
    {
        "name": "Reha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.15172500",
        "longitude": "69.75008600",
        "label": "Reha",
        "value": "Reha"
    },
    {
        "name": "Roha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.19646000",
        "longitude": "69.27076000",
        "label": "Roha",
        "value": "Roha"
    },
    {
        "name": "Sabar Kantha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.62974000",
        "longitude": "73.00197000",
        "label": "Sabar Kantha",
        "value": "Sabar Kantha"
    },
    {
        "name": "Sachin",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.08718000",
        "longitude": "72.88153000",
        "label": "Sachin",
        "value": "Sachin"
    },
    {
        "name": "Salaya",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.31038000",
        "longitude": "69.60376000",
        "label": "Salaya",
        "value": "Salaya"
    },
    {
        "name": "Samakhiali",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.30340000",
        "longitude": "70.50688000",
        "label": "Samakhiali",
        "value": "Samakhiali"
    },
    {
        "name": "Sanand",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.99227000",
        "longitude": "72.38177000",
        "label": "Sanand",
        "value": "Sanand"
    },
    {
        "name": "Sankheda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.17021000",
        "longitude": "73.57820000",
        "label": "Sankheda",
        "value": "Sankheda"
    },
    {
        "name": "Sarbhon",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.05000000",
        "longitude": "73.08330000",
        "label": "Sarbhon",
        "value": "Sarbhon"
    },
    {
        "name": "Sardoi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.56670000",
        "longitude": "73.26670000",
        "label": "Sardoi",
        "value": "Sardoi"
    },
    {
        "name": "Sarkhej",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.98297000",
        "longitude": "72.50196000",
        "label": "Sarkhej",
        "value": "Sarkhej"
    },
    {
        "name": "Sathamba",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.16912500",
        "longitude": "73.32661667",
        "label": "Sathamba",
        "value": "Sathamba"
    },
    {
        "name": "Savarkundla",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.33726000",
        "longitude": "71.30350000",
        "label": "Savarkundla",
        "value": "Savarkundla"
    },
    {
        "name": "Savli",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.56666667",
        "longitude": "73.21666667",
        "label": "Savli",
        "value": "Savli"
    },
    {
        "name": "Sayla",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.54925000",
        "longitude": "71.48324000",
        "label": "Sayla",
        "value": "Sayla"
    },
    {
        "name": "Shahpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.15611000",
        "longitude": "70.77068000",
        "label": "Shahpur",
        "value": "Shahpur"
    },
    {
        "name": "Shivrajpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.42319000",
        "longitude": "73.60865000",
        "label": "Shivrajpur",
        "value": "Shivrajpur"
    },
    {
        "name": "Siddhpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.91670000",
        "longitude": "72.38330000",
        "label": "Siddhpur",
        "value": "Siddhpur"
    },
    {
        "name": "Sihor",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.71134000",
        "longitude": "71.96179000",
        "label": "Sihor",
        "value": "Sihor"
    },
    {
        "name": "Sikka",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.43218000",
        "longitude": "69.84158000",
        "label": "Sikka",
        "value": "Sikka"
    },
    {
        "name": "Sinor",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.91117000",
        "longitude": "73.33974000",
        "label": "Sinor",
        "value": "Sinor"
    },
    {
        "name": "Sojitra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.53884000",
        "longitude": "72.71984000",
        "label": "Sojitra",
        "value": "Sojitra"
    },
    {
        "name": "Songadh",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.16966000",
        "longitude": "73.56357000",
        "label": "Songadh",
        "value": "Songadh"
    },
    {
        "name": "Supedi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.76200000",
        "longitude": "70.37800000",
        "label": "Supedi",
        "value": "Supedi"
    },
    {
        "name": "Surat",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.17801000",
        "longitude": "72.81189000",
        "label": "Surat",
        "value": "Surat"
    },
    {
        "name": "Surendranagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.72706000",
        "longitude": "71.64856000",
        "label": "Surendranagar",
        "value": "Surendranagar"
    },
    {
        "name": "Sutrapada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.89280000",
        "longitude": "70.46500000",
        "label": "Sutrapada",
        "value": "Sutrapada"
    },
    {
        "name": "Talaja",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.35270000",
        "longitude": "72.03524000",
        "label": "Talaja",
        "value": "Talaja"
    },
    {
        "name": "Tankara",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.65622000",
        "longitude": "70.74945000",
        "label": "Tankara",
        "value": "Tankara"
    },
    {
        "name": "Tapi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.12000000",
        "longitude": "73.40000000",
        "label": "Tapi",
        "value": "Tapi"
    },
    {
        "name": "Than",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.57422000",
        "longitude": "71.19942000",
        "label": "Than",
        "value": "Than"
    },
    {
        "name": "Thangadh",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.56666667",
        "longitude": "71.18333333",
        "label": "Thangadh",
        "value": "Thangadh"
    },
    {
        "name": "Tharad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.39597000",
        "longitude": "71.62577000",
        "label": "Tharad",
        "value": "Tharad"
    },
    {
        "name": "Thasra",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.79831000",
        "longitude": "73.21174000",
        "label": "Thasra",
        "value": "Thasra"
    },
    {
        "name": "The Dangs",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.75000000",
        "longitude": "73.75000000",
        "label": "The Dangs",
        "value": "The Dangs"
    },
    {
        "name": "Umarpada",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.45000000",
        "longitude": "73.50000000",
        "label": "Umarpada",
        "value": "Umarpada"
    },
    {
        "name": "Umrala",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.84353000",
        "longitude": "71.80305000",
        "label": "Umrala",
        "value": "Umrala"
    },
    {
        "name": "Umreth",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.69881000",
        "longitude": "73.11561000",
        "label": "Umreth",
        "value": "Umreth"
    },
    {
        "name": "Un",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.88745000",
        "longitude": "71.76975000",
        "label": "Un",
        "value": "Un"
    },
    {
        "name": "Una",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.82318000",
        "longitude": "71.03795000",
        "label": "Una",
        "value": "Una"
    },
    {
        "name": "Unjha",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.80366000",
        "longitude": "72.39101000",
        "label": "Unjha",
        "value": "Unjha"
    },
    {
        "name": "Upleta",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.74015000",
        "longitude": "70.28256000",
        "label": "Upleta",
        "value": "Upleta"
    },
    {
        "name": "Utran",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.23333000",
        "longitude": "72.86667000",
        "label": "Utran",
        "value": "Utran"
    },
    {
        "name": "Vadgam",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "24.08333333",
        "longitude": "72.48333333",
        "label": "Vadgam",
        "value": "Vadgam"
    },
    {
        "name": "Vadnagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.78593000",
        "longitude": "72.63893000",
        "label": "Vadnagar",
        "value": "Vadnagar"
    },
    {
        "name": "Vadodara",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.29941000",
        "longitude": "73.20812000",
        "label": "Vadodara",
        "value": "Vadodara"
    },
    {
        "name": "Vaghodia",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.30505000",
        "longitude": "73.40016000",
        "label": "Vaghodia",
        "value": "Vaghodia"
    },
    {
        "name": "Vaghodia INA",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.30000000",
        "longitude": "73.38330000",
        "label": "Vaghodia INA",
        "value": "Vaghodia INA"
    },
    {
        "name": "Vallabh Vidyanagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.53333000",
        "longitude": "72.90000000",
        "label": "Vallabh Vidyanagar",
        "value": "Vallabh Vidyanagar"
    },
    {
        "name": "Vallabhipur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.88780000",
        "longitude": "71.87950000",
        "label": "Vallabhipur",
        "value": "Vallabhipur"
    },
    {
        "name": "Valsad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.50000000",
        "longitude": "73.08333000",
        "label": "Valsad",
        "value": "Valsad"
    },
    {
        "name": "Vanala",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.45000000",
        "longitude": "71.98333333",
        "label": "Vanala",
        "value": "Vanala"
    },
    {
        "name": "Vansda",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.45000000",
        "longitude": "73.22000000",
        "label": "Vansda",
        "value": "Vansda"
    },
    {
        "name": "Vanthli",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.48330000",
        "longitude": "70.33330000",
        "label": "Vanthli",
        "value": "Vanthli"
    },
    {
        "name": "Vapi",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.37175000",
        "longitude": "72.90493000",
        "label": "Vapi",
        "value": "Vapi"
    },
    {
        "name": "Vartej",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.73947000",
        "longitude": "72.06553000",
        "label": "Vartej",
        "value": "Vartej"
    },
    {
        "name": "Vasa",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.66079000",
        "longitude": "72.75519000",
        "label": "Vasa",
        "value": "Vasa"
    },
    {
        "name": "Vasavad",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.82657000",
        "longitude": "71.02436000",
        "label": "Vasavad",
        "value": "Vasavad"
    },
    {
        "name": "Vaso",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.66079000",
        "longitude": "72.75519000",
        "label": "Vaso",
        "value": "Vaso"
    },
    {
        "name": "Vataman",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.53000000",
        "longitude": "72.42000000",
        "label": "Vataman",
        "value": "Vataman"
    },
    {
        "name": "Vejalpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.69021000",
        "longitude": "73.56299000",
        "label": "Vejalpur",
        "value": "Vejalpur"
    },
    {
        "name": "Veraval",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.90770000",
        "longitude": "70.36786000",
        "label": "Veraval",
        "value": "Veraval"
    },
    {
        "name": "Vijapur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.56230000",
        "longitude": "72.74848000",
        "label": "Vijapur",
        "value": "Vijapur"
    },
    {
        "name": "Vinchhiya",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.21027000",
        "longitude": "71.37967000",
        "label": "Vinchhiya",
        "value": "Vinchhiya"
    },
    {
        "name": "Vinchia",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.21027000",
        "longitude": "71.37967000",
        "label": "Vinchia",
        "value": "Vinchia"
    },
    {
        "name": "Viramgam",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.12000000",
        "longitude": "72.03000000",
        "label": "Viramgam",
        "value": "Viramgam"
    },
    {
        "name": "Virpur",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.18920000",
        "longitude": "73.47987000",
        "label": "Virpur",
        "value": "Virpur"
    },
    {
        "name": "Visavadar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.33954000",
        "longitude": "70.74966000",
        "label": "Visavadar",
        "value": "Visavadar"
    },
    {
        "name": "Visnagar",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "23.69855000",
        "longitude": "72.55210000",
        "label": "Visnagar",
        "value": "Visnagar"
    },
    {
        "name": "Vyara",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "21.11079000",
        "longitude": "73.39365000",
        "label": "Vyara",
        "value": "Vyara"
    },
    {
        "name": "Wadhai",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.76666667",
        "longitude": "73.48333333",
        "label": "Wadhai",
        "value": "Wadhai"
    },
    {
        "name": "Wadhwan",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.70000000",
        "longitude": "71.68333333",
        "label": "Wadhwan",
        "value": "Wadhwan"
    },
    {
        "name": "Waghai",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "20.77048000",
        "longitude": "73.50074000",
        "label": "Waghai",
        "value": "Waghai"
    },
    {
        "name": "Wankaner",
        "countryCode": "IN",
        "stateCode": "GJ",
        "latitude": "22.61198000",
        "longitude": "70.94379000",
        "label": "Wankaner",
        "value": "Wankaner"
    }
,{
        "name": "Ambala",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.32854000",
        "longitude": "76.94220000",
        "label": "Ambala",
        "value": "Ambala"
    },
    {
        "name": "Asandh",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.52119000",
        "longitude": "76.60552000",
        "label": "Asandh",
        "value": "Asandh"
    },
    {
        "name": "Ateli Mandi",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.10080000",
        "longitude": "76.25980000",
        "label": "Ateli Mandi",
        "value": "Ateli Mandi"
    },
    {
        "name": "Bahadurgarh",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.69287000",
        "longitude": "76.93555000",
        "label": "Bahadurgarh",
        "value": "Bahadurgarh"
    },
    {
        "name": "Bara Uchana",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.46747000",
        "longitude": "76.17798000",
        "label": "Bara Uchana",
        "value": "Bara Uchana"
    },
    {
        "name": "Barwala",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.36747000",
        "longitude": "75.90809000",
        "label": "Barwala",
        "value": "Barwala"
    },
    {
        "name": "Bawal",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.07184000",
        "longitude": "76.58312000",
        "label": "Bawal",
        "value": "Bawal"
    },
    {
        "name": "Beri Khas",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.70146000",
        "longitude": "76.57708000",
        "label": "Beri Khas",
        "value": "Beri Khas"
    },
    {
        "name": "Bhiwani",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.75000000",
        "longitude": "76.16667000",
        "label": "Bhiwani",
        "value": "Bhiwani"
    },
    {
        "name": "Bilaspur",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.30450000",
        "longitude": "77.30424000",
        "label": "Bilaspur",
        "value": "Bilaspur"
    },
    {
        "name": "Buriya",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.15911000",
        "longitude": "77.35814000",
        "label": "Buriya",
        "value": "Buriya"
    },
    {
        "name": "Charkhi Dadri",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.59166000",
        "longitude": "76.27161000",
        "label": "Charkhi Dadri",
        "value": "Charkhi Dadri"
    },
    {
        "name": "Chhachhrauli",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.24492000",
        "longitude": "77.36027000",
        "label": "Chhachhrauli",
        "value": "Chhachhrauli"
    },
    {
        "name": "Dabwali",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.94906000",
        "longitude": "74.73832000",
        "label": "Dabwali",
        "value": "Dabwali"
    },
    {
        "name": "Dharuhera",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.20553000",
        "longitude": "76.79691000",
        "label": "Dharuhera",
        "value": "Dharuhera"
    },
    {
        "name": "Ellenabad",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.45282000",
        "longitude": "74.66122000",
        "label": "Ellenabad",
        "value": "Ellenabad"
    },
    {
        "name": "Faridabad",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.41124000",
        "longitude": "77.31316000",
        "label": "Faridabad",
        "value": "Faridabad"
    },
    {
        "name": "Faridabad District",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.46292000",
        "longitude": "77.37160000",
        "label": "Faridabad District",
        "value": "Faridabad District"
    },
    {
        "name": "Farrukhnagar",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.44745000",
        "longitude": "76.82391000",
        "label": "Farrukhnagar",
        "value": "Farrukhnagar"
    },
    {
        "name": "Fatehabad",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.51525000",
        "longitude": "75.45554000",
        "label": "Fatehabad",
        "value": "Fatehabad"
    },
    {
        "name": "Fatehabad District",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.31000000",
        "longitude": "75.27000000",
        "label": "Fatehabad District",
        "value": "Fatehabad District"
    },
    {
        "name": "Firozpur Jhirka",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "27.78853000",
        "longitude": "76.94496000",
        "label": "Firozpur Jhirka",
        "value": "Firozpur Jhirka"
    },
    {
        "name": "Gharaunda",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.53692000",
        "longitude": "76.97142000",
        "label": "Gharaunda",
        "value": "Gharaunda"
    },
    {
        "name": "Gohana",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.13777000",
        "longitude": "76.70247000",
        "label": "Gohana",
        "value": "Gohana"
    },
    {
        "name": "Gorakhpur",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.44768000",
        "longitude": "75.67206000",
        "label": "Gorakhpur",
        "value": "Gorakhpur"
    },
    {
        "name": "Gurgaon",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.43891000",
        "longitude": "77.00592000",
        "label": "Gurgaon",
        "value": "Gurgaon"
    },
    {
        "name": "Hansi",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.10239000",
        "longitude": "75.96253000",
        "label": "Hansi",
        "value": "Hansi"
    },
    {
        "name": "Hasanpur",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "27.96944000",
        "longitude": "77.49544000",
        "label": "Hasanpur",
        "value": "Hasanpur"
    },
    {
        "name": "Hisar",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.15394000",
        "longitude": "75.72294000",
        "label": "Hisar",
        "value": "Hisar"
    },
    {
        "name": "Hodal",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "27.89196000",
        "longitude": "77.36744000",
        "label": "Hodal",
        "value": "Hodal"
    },
    {
        "name": "Inda Chhoi",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.64042000",
        "longitude": "75.79041000",
        "label": "Inda Chhoi",
        "value": "Inda Chhoi"
    },
    {
        "name": "Indri",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.87999000",
        "longitude": "77.05972000",
        "label": "Indri",
        "value": "Indri"
    },
    {
        "name": "Jagadhri",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.16719000",
        "longitude": "77.30367000",
        "label": "Jagadhri",
        "value": "Jagadhri"
    },
    {
        "name": "Jakhal",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.79627000",
        "longitude": "75.82392000",
        "label": "Jakhal",
        "value": "Jakhal"
    },
    {
        "name": "Jhajjar",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.60630000",
        "longitude": "76.65650000",
        "label": "Jhajjar",
        "value": "Jhajjar"
    },
    {
        "name": "Jind",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.31577000",
        "longitude": "76.31502000",
        "label": "Jind",
        "value": "Jind"
    },
    {
        "name": "Kaithal",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.80153000",
        "longitude": "76.39959000",
        "label": "Kaithal",
        "value": "Kaithal"
    },
    {
        "name": "Kalanaur",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.82823000",
        "longitude": "76.39550000",
        "label": "Kalanaur",
        "value": "Kalanaur"
    },
    {
        "name": "Kalanwali",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.83573000",
        "longitude": "74.97170000",
        "label": "Kalanwali",
        "value": "Kalanwali"
    },
    {
        "name": "Kanina Khas",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.33093000",
        "longitude": "76.31099000",
        "label": "Kanina Khas",
        "value": "Kanina Khas"
    },
    {
        "name": "Karnal",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.66667000",
        "longitude": "76.83333000",
        "label": "Karnal",
        "value": "Karnal"
    },
    {
        "name": "Kharkhauda",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.87870000",
        "longitude": "76.91069000",
        "label": "Kharkhauda",
        "value": "Kharkhauda"
    },
    {
        "name": "Kheri Sampla",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.77810000",
        "longitude": "76.77560000",
        "label": "Kheri Sampla",
        "value": "Kheri Sampla"
    },
    {
        "name": "Kurukshetra",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.00000000",
        "longitude": "76.75000000",
        "label": "Kurukshetra",
        "value": "Kurukshetra"
    },
    {
        "name": "Ladwa",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.99350000",
        "longitude": "77.04563000",
        "label": "Ladwa",
        "value": "Ladwa"
    },
    {
        "name": "Loharu",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.42993000",
        "longitude": "75.80779000",
        "label": "Loharu",
        "value": "Loharu"
    },
    {
        "name": "Maham",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.96912000",
        "longitude": "76.29495000",
        "label": "Maham",
        "value": "Maham"
    },
    {
        "name": "Mahendragarh",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.25000000",
        "longitude": "76.16667000",
        "label": "Mahendragarh",
        "value": "Mahendragarh"
    },
    {
        "name": "Mandholi Kalan",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.70850000",
        "longitude": "75.68296000",
        "label": "Mandholi Kalan",
        "value": "Mandholi Kalan"
    },
    {
        "name": "Mustafabad",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.20220000",
        "longitude": "77.14873000",
        "label": "Mustafabad",
        "value": "Mustafabad"
    },
    {
        "name": "Narayangarh",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.47798000",
        "longitude": "77.12804000",
        "label": "Narayangarh",
        "value": "Narayangarh"
    },
    {
        "name": "Narnaul",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.04444000",
        "longitude": "76.10833000",
        "label": "Narnaul",
        "value": "Narnaul"
    },
    {
        "name": "Narnaund",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.22047000",
        "longitude": "76.14278000",
        "label": "Narnaund",
        "value": "Narnaund"
    },
    {
        "name": "Narwana",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.59903000",
        "longitude": "76.11927000",
        "label": "Narwana",
        "value": "Narwana"
    },
    {
        "name": "Nilokheri",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.83671000",
        "longitude": "76.93191000",
        "label": "Nilokheri",
        "value": "Nilokheri"
    },
    {
        "name": "Nuh",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.10296000",
        "longitude": "77.00144000",
        "label": "Nuh",
        "value": "Nuh"
    },
    {
        "name": "Palwal",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.14469000",
        "longitude": "77.32546000",
        "label": "Palwal",
        "value": "Palwal"
    },
    {
        "name": "Panchkula",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.72883000",
        "longitude": "76.94716000",
        "label": "Panchkula",
        "value": "Panchkula"
    },
    {
        "name": "Panipat",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.33259000",
        "longitude": "76.92634000",
        "label": "Panipat",
        "value": "Panipat"
    },
    {
        "name": "Pataudi",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.32547000",
        "longitude": "76.77858000",
        "label": "Pataudi",
        "value": "Pataudi"
    },
    {
        "name": "Pehowa",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.97897000",
        "longitude": "76.58249000",
        "label": "Pehowa",
        "value": "Pehowa"
    },
    {
        "name": "Pinjaur",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.79873000",
        "longitude": "76.91822000",
        "label": "Pinjaur",
        "value": "Pinjaur"
    },
    {
        "name": "Punahana",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "27.86371000",
        "longitude": "77.20432000",
        "label": "Punahana",
        "value": "Punahana"
    },
    {
        "name": "Pundri",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.76096000",
        "longitude": "76.56034000",
        "label": "Pundri",
        "value": "Pundri"
    },
    {
        "name": "Radaur",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.02706000",
        "longitude": "77.15177000",
        "label": "Radaur",
        "value": "Radaur"
    },
    {
        "name": "Rania",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.52454000",
        "longitude": "74.83689000",
        "label": "Rania",
        "value": "Rania"
    },
    {
        "name": "Ratia",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.69029000",
        "longitude": "75.57688000",
        "label": "Ratia",
        "value": "Ratia"
    },
    {
        "name": "Rewari",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.19900000",
        "longitude": "76.61830000",
        "label": "Rewari",
        "value": "Rewari"
    },
    {
        "name": "Rewari District",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.19613000",
        "longitude": "76.61607000",
        "label": "Rewari District",
        "value": "Rewari District"
    },
    {
        "name": "Rohtak",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.83333000",
        "longitude": "76.66667000",
        "label": "Rohtak",
        "value": "Rohtak"
    },
    {
        "name": "Safidon",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.40596000",
        "longitude": "76.67042000",
        "label": "Safidon",
        "value": "Safidon"
    },
    {
        "name": "Samalkha",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.23552000",
        "longitude": "77.01273000",
        "label": "Samalkha",
        "value": "Samalkha"
    },
    {
        "name": "Shadipur Julana",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.12368000",
        "longitude": "76.40516000",
        "label": "Shadipur Julana",
        "value": "Shadipur Julana"
    },
    {
        "name": "Shahabad",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.16776000",
        "longitude": "76.87046000",
        "label": "Shahabad",
        "value": "Shahabad"
    },
    {
        "name": "Sirsa",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.53489000",
        "longitude": "75.02898000",
        "label": "Sirsa",
        "value": "Sirsa"
    },
    {
        "name": "Sohna",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.24737000",
        "longitude": "77.06544000",
        "label": "Sohna",
        "value": "Sohna"
    },
    {
        "name": "Sonipat",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.00000000",
        "longitude": "76.91667000",
        "label": "Sonipat",
        "value": "Sonipat"
    },
    {
        "name": "Taoru",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.21173000",
        "longitude": "76.94984000",
        "label": "Taoru",
        "value": "Taoru"
    },
    {
        "name": "Thanesar",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.97323000",
        "longitude": "76.83214000",
        "label": "Thanesar",
        "value": "Thanesar"
    },
    {
        "name": "Tohana",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.71332000",
        "longitude": "75.90441000",
        "label": "Tohana",
        "value": "Tohana"
    },
    {
        "name": "Tosham",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "28.86993000",
        "longitude": "75.91650000",
        "label": "Tosham",
        "value": "Tosham"
    },
    {
        "name": "Uklana",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "29.51124000",
        "longitude": "75.87823000",
        "label": "Uklana",
        "value": "Uklana"
    },
    {
        "name": "Yamunanagar",
        "countryCode": "IN",
        "stateCode": "HR",
        "latitude": "30.23644000",
        "longitude": "77.30498000",
        "label": "Yamunanagar",
        "value": "Yamunanagar"
    }
,{
        "name": "Arki",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.15196000",
        "longitude": "76.96675000",
        "label": "Arki",
        "value": "Arki"
    },
    {
        "name": "Baddi",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.95783000",
        "longitude": "76.79136000",
        "label": "Baddi",
        "value": "Baddi"
    },
    {
        "name": "Banjar",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.63900000",
        "longitude": "77.34055000",
        "label": "Banjar",
        "value": "Banjar"
    },
    {
        "name": "Bilaspur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.33027000",
        "longitude": "76.75663000",
        "label": "Bilaspur",
        "value": "Bilaspur"
    },
    {
        "name": "Chamba",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.57147000",
        "longitude": "76.10229000",
        "label": "Chamba",
        "value": "Chamba"
    },
    {
        "name": "Chaupal",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.94647000",
        "longitude": "77.58840000",
        "label": "Chaupal",
        "value": "Chaupal"
    },
    {
        "name": "Chowari",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.43190000",
        "longitude": "76.01200000",
        "label": "Chowari",
        "value": "Chowari"
    },
    {
        "name": "Chuari Khas",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.43058000",
        "longitude": "76.01428000",
        "label": "Chuari Khas",
        "value": "Chuari Khas"
    },
    {
        "name": "Dagshai",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.88431000",
        "longitude": "77.05228000",
        "label": "Dagshai",
        "value": "Dagshai"
    },
    {
        "name": "Dalhousie",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.55219000",
        "longitude": "75.94663000",
        "label": "Dalhousie",
        "value": "Dalhousie"
    },
    {
        "name": "Daulatpur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.78871000",
        "longitude": "75.99154000",
        "label": "Daulatpur",
        "value": "Daulatpur"
    },
    {
        "name": "Dera Gopipur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.87919000",
        "longitude": "76.21871000",
        "label": "Dera Gopipur",
        "value": "Dera Gopipur"
    },
    {
        "name": "Dharamsala",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.22006000",
        "longitude": "76.32013000",
        "label": "Dharamsala",
        "value": "Dharamsala"
    },
    {
        "name": "Gagret",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.65846000",
        "longitude": "76.06144000",
        "label": "Gagret",
        "value": "Gagret"
    },
    {
        "name": "Ghumarwin",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.44166000",
        "longitude": "76.71509000",
        "label": "Ghumarwin",
        "value": "Ghumarwin"
    },
    {
        "name": "Hamirpur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.75000000",
        "longitude": "76.50000000",
        "label": "Hamirpur",
        "value": "Hamirpur"
    },
    {
        "name": "Jawala Mukhi",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.87456000",
        "longitude": "76.32013000",
        "label": "Jawala Mukhi",
        "value": "Jawala Mukhi"
    },
    {
        "name": "Jogindarnagar",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.98727000",
        "longitude": "76.78906000",
        "label": "Jogindarnagar",
        "value": "Jogindarnagar"
    },
    {
        "name": "Jubbal",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.10923000",
        "longitude": "77.65085000",
        "label": "Jubbal",
        "value": "Jubbal"
    },
    {
        "name": "Jutogh",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.10000000",
        "longitude": "77.11667000",
        "label": "Jutogh",
        "value": "Jutogh"
    },
    {
        "name": "Kalka",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.83982000",
        "longitude": "76.94065000",
        "label": "Kalka",
        "value": "Kalka"
    },
    {
        "name": "Kangar",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.09135000",
        "longitude": "76.26267000",
        "label": "Kangar",
        "value": "Kangar"
    },
    {
        "name": "Kangra",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.16667000",
        "longitude": "76.25000000",
        "label": "Kangra",
        "value": "Kangra"
    },
    {
        "name": "Kasauli",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.89856000",
        "longitude": "76.96587000",
        "label": "Kasauli",
        "value": "Kasauli"
    },
    {
        "name": "Kinnaur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.58333000",
        "longitude": "78.41667000",
        "label": "Kinnaur",
        "value": "Kinnaur"
    },
    {
        "name": "Kotkhai",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.11728000",
        "longitude": "77.53936000",
        "label": "Kotkhai",
        "value": "Kotkhai"
    },
    {
        "name": "Kotla",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.25000000",
        "longitude": "76.03333000",
        "label": "Kotla",
        "value": "Kotla"
    },
    {
        "name": "Kulu",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.00000000",
        "longitude": "77.25000000",
        "label": "Kulu",
        "value": "Kulu"
    },
    {
        "name": "Kyelang",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.57170000",
        "longitude": "77.02448000",
        "label": "Kyelang",
        "value": "Kyelang"
    },
    {
        "name": "Lahul and Spiti",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.50000000",
        "longitude": "77.83333000",
        "label": "Lahul and Spiti",
        "value": "Lahul and Spiti"
    },
    {
        "name": "Manali",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.25740000",
        "longitude": "77.17481000",
        "label": "Manali",
        "value": "Manali"
    },
    {
        "name": "Mandi",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.71194000",
        "longitude": "76.93273000",
        "label": "Mandi",
        "value": "Mandi"
    },
    {
        "name": "Nadaun",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.78303000",
        "longitude": "76.34310000",
        "label": "Nadaun",
        "value": "Nadaun"
    },
    {
        "name": "Nagar",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.13808000",
        "longitude": "77.17393000",
        "label": "Nagar",
        "value": "Nagar"
    },
    {
        "name": "Nagrota",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.05710000",
        "longitude": "76.09139000",
        "label": "Nagrota",
        "value": "Nagrota"
    },
    {
        "name": "Nahan",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.56029000",
        "longitude": "77.29426000",
        "label": "Nahan",
        "value": "Nahan"
    },
    {
        "name": "Nalagarh",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.04168000",
        "longitude": "76.72285000",
        "label": "Nalagarh",
        "value": "Nalagarh"
    },
    {
        "name": "Palampur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.11453000",
        "longitude": "76.55681000",
        "label": "Palampur",
        "value": "Palampur"
    },
    {
        "name": "Pandoh",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.66902000",
        "longitude": "77.05359000",
        "label": "Pandoh",
        "value": "Pandoh"
    },
    {
        "name": "Paonta Sahib",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.43666000",
        "longitude": "77.62462000",
        "label": "Paonta Sahib",
        "value": "Paonta Sahib"
    },
    {
        "name": "Parwanoo",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.83716000",
        "longitude": "76.96143000",
        "label": "Parwanoo",
        "value": "Parwanoo"
    },
    {
        "name": "Rajgarh",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.85142000",
        "longitude": "77.30066000",
        "label": "Rajgarh",
        "value": "Rajgarh"
    },
    {
        "name": "Rampur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.44943000",
        "longitude": "77.63087000",
        "label": "Rampur",
        "value": "Rampur"
    },
    {
        "name": "Rohru",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.20269000",
        "longitude": "77.75484000",
        "label": "Rohru",
        "value": "Rohru"
    },
    {
        "name": "Sabathu",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.97494000",
        "longitude": "76.99137000",
        "label": "Sabathu",
        "value": "Sabathu"
    },
    {
        "name": "Santokhgarh",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.35205000",
        "longitude": "76.31775000",
        "label": "Santokhgarh",
        "value": "Santokhgarh"
    },
    {
        "name": "Sarahan",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.50988000",
        "longitude": "77.79395000",
        "label": "Sarahan",
        "value": "Sarahan"
    },
    {
        "name": "Sarka Ghat",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.69887000",
        "longitude": "76.73529000",
        "label": "Sarka Ghat",
        "value": "Sarka Ghat"
    },
    {
        "name": "Seoni",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.24188000",
        "longitude": "77.12362000",
        "label": "Seoni",
        "value": "Seoni"
    },
    {
        "name": "Shimla",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.16667000",
        "longitude": "77.58333000",
        "label": "Shimla",
        "value": "Shimla"
    },
    {
        "name": "Sirmaur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "30.75000000",
        "longitude": "77.50000000",
        "label": "Sirmaur",
        "value": "Sirmaur"
    },
    {
        "name": "Solan",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.08333000",
        "longitude": "76.83333000",
        "label": "Solan",
        "value": "Solan"
    },
    {
        "name": "Sundarnagar",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.53523000",
        "longitude": "76.90500000",
        "label": "Sundarnagar",
        "value": "Sundarnagar"
    },
    {
        "name": "Theog",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.12155000",
        "longitude": "77.35838000",
        "label": "Theog",
        "value": "Theog"
    },
    {
        "name": "Tira Sujanpur",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.83364000",
        "longitude": "76.50539000",
        "label": "Tira Sujanpur",
        "value": "Tira Sujanpur"
    },
    {
        "name": "Una",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "31.46493000",
        "longitude": "76.26914000",
        "label": "Una",
        "value": "Una"
    },
    {
        "name": "Yol",
        "countryCode": "IN",
        "stateCode": "HP",
        "latitude": "32.16423000",
        "longitude": "76.19622000",
        "label": "Yol",
        "value": "Yol"
    }
,{
        "name": "Akhnur",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.86667000",
        "longitude": "74.73333000",
        "label": "Akhnur",
        "value": "Akhnur"
    },
    {
        "name": "Anantnag",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.73068000",
        "longitude": "75.15418000",
        "label": "Anantnag",
        "value": "Anantnag"
    },
    {
        "name": "Awantipur",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.91978000",
        "longitude": "75.01515000",
        "label": "Awantipur",
        "value": "Awantipur"
    },
    {
        "name": "Badgam",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.89001000",
        "longitude": "74.66297000",
        "label": "Badgam",
        "value": "Badgam"
    },
    {
        "name": "Bandipore",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.50404000",
        "longitude": "74.82832000",
        "label": "Bandipore",
        "value": "Bandipore"
    },
    {
        "name": "Bandipura",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.41728000",
        "longitude": "74.64308000",
        "label": "Bandipura",
        "value": "Bandipura"
    },
    {
        "name": "Banihal",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.43647000",
        "longitude": "75.19684000",
        "label": "Banihal",
        "value": "Banihal"
    },
    {
        "name": "Baramula",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.19287000",
        "longitude": "74.36920000",
        "label": "Baramula",
        "value": "Baramula"
    },
    {
        "name": "Batoti",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.11826000",
        "longitude": "75.30889000",
        "label": "Batoti",
        "value": "Batoti"
    },
    {
        "name": "Bhadarwah",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.97941000",
        "longitude": "75.71723000",
        "label": "Bhadarwah",
        "value": "Bhadarwah"
    },
    {
        "name": "Bijbehara",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.79378000",
        "longitude": "75.10700000",
        "label": "Bijbehara",
        "value": "Bijbehara"
    },
    {
        "name": "Bishnah",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.61060000",
        "longitude": "74.85557000",
        "label": "Bishnah",
        "value": "Bishnah"
    },
    {
        "name": "Doda",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.14916000",
        "longitude": "75.54746000",
        "label": "Doda",
        "value": "Doda"
    },
    {
        "name": "Gandarbal",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.22619000",
        "longitude": "74.77478000",
        "label": "Gandarbal",
        "value": "Gandarbal"
    },
    {
        "name": "Ganderbal",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.29467000",
        "longitude": "75.19996000",
        "label": "Ganderbal",
        "value": "Ganderbal"
    },
    {
        "name": "Gho Brahmanan de",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.55590000",
        "longitude": "74.95390000",
        "label": "Gho Brahmanan de",
        "value": "Gho Brahmanan de"
    },
    {
        "name": "Hajan",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.29895000",
        "longitude": "74.61681000",
        "label": "Hajan",
        "value": "Hajan"
    },
    {
        "name": "Hiranagar",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.45493000",
        "longitude": "75.27187000",
        "label": "Hiranagar",
        "value": "Hiranagar"
    },
    {
        "name": "Jammu",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.75000000",
        "longitude": "74.83333000",
        "label": "Jammu",
        "value": "Jammu"
    },
    {
        "name": "Jaurian",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.83255000",
        "longitude": "74.57612000",
        "label": "Jaurian",
        "value": "Jaurian"
    },
    {
        "name": "Kathua",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.58333000",
        "longitude": "75.50000000",
        "label": "Kathua",
        "value": "Kathua"
    },
    {
        "name": "Katra",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.99167000",
        "longitude": "74.93195000",
        "label": "Katra",
        "value": "Katra"
    },
    {
        "name": "Khaur",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.60270000",
        "longitude": "74.80918000",
        "label": "Khaur",
        "value": "Khaur"
    },
    {
        "name": "Kishtwar",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.52958000",
        "longitude": "76.01462000",
        "label": "Kishtwar",
        "value": "Kishtwar"
    },
    {
        "name": "Kud",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.07246000",
        "longitude": "75.28727000",
        "label": "Kud",
        "value": "Kud"
    },
    {
        "name": "Kulgam",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.64456000",
        "longitude": "75.01923000",
        "label": "Kulgam",
        "value": "Kulgam"
    },
    {
        "name": "Kupwara",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.53193000",
        "longitude": "74.26605000",
        "label": "Kupwara",
        "value": "Kupwara"
    },
    {
        "name": "Ladakh",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.33333000",
        "longitude": "77.41667000",
        "label": "Ladakh",
        "value": "Ladakh"
    },
    {
        "name": "Magam",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.09256000",
        "longitude": "74.59016000",
        "label": "Magam",
        "value": "Magam"
    },
    {
        "name": "Nawanshahr",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.76505000",
        "longitude": "74.52772000",
        "label": "Nawanshahr",
        "value": "Nawanshahr"
    },
    {
        "name": "Noria",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.52095000",
        "longitude": "74.79845000",
        "label": "Noria",
        "value": "Noria"
    },
    {
        "name": "Padam",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.46659000",
        "longitude": "76.88488000",
        "label": "Padam",
        "value": "Padam"
    },
    {
        "name": "Pahlgam",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.01592000",
        "longitude": "75.31899000",
        "label": "Pahlgam",
        "value": "Pahlgam"
    },
    {
        "name": "Parol",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.34598000",
        "longitude": "75.43441000",
        "label": "Parol",
        "value": "Parol"
    },
    {
        "name": "Pattan",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.16125000",
        "longitude": "74.55634000",
        "label": "Pattan",
        "value": "Pattan"
    },
    {
        "name": "Pulwama",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.87405000",
        "longitude": "74.89955000",
        "label": "Pulwama",
        "value": "Pulwama"
    },
    {
        "name": "Punch",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.70178000",
        "longitude": "74.19916000",
        "label": "Punch",
        "value": "Punch"
    },
    {
        "name": "Qazigund",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.63828000",
        "longitude": "75.14261000",
        "label": "Qazigund",
        "value": "Qazigund"
    },
    {
        "name": "Rajaori",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.37526000",
        "longitude": "74.30920000",
        "label": "Rajaori",
        "value": "Rajaori"
    },
    {
        "name": "Rajauri",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.25000000",
        "longitude": "74.25000000",
        "label": "Rajauri",
        "value": "Rajauri"
    },
    {
        "name": "Ramban",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.32301000",
        "longitude": "75.18610000",
        "label": "Ramban",
        "value": "Ramban"
    },
    {
        "name": "Ramgarh",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.40379000",
        "longitude": "74.22388000",
        "label": "Ramgarh",
        "value": "Ramgarh"
    },
    {
        "name": "Ramnagar",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.80728000",
        "longitude": "75.31119000",
        "label": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "name": "Riasi",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.08115000",
        "longitude": "74.83242000",
        "label": "Riasi",
        "value": "Riasi"
    },
    {
        "name": "Samba",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "32.57523000",
        "longitude": "75.10929000",
        "label": "Samba",
        "value": "Samba"
    },
    {
        "name": "Shupiyan",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.73067000",
        "longitude": "74.81869000",
        "label": "Shupiyan",
        "value": "Shupiyan"
    },
    {
        "name": "Sopur",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.28671000",
        "longitude": "74.47228000",
        "label": "Sopur",
        "value": "Sopur"
    },
    {
        "name": "Soyibug",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.07677000",
        "longitude": "74.70570000",
        "label": "Soyibug",
        "value": "Soyibug"
    },
    {
        "name": "Srinagar",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.08565000",
        "longitude": "74.80555000",
        "label": "Srinagar",
        "value": "Srinagar"
    },
    {
        "name": "Sumbal",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.23072000",
        "longitude": "74.64720000",
        "label": "Sumbal",
        "value": "Sumbal"
    },
    {
        "name": "Thang",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.92740000",
        "longitude": "76.79336000",
        "label": "Thang",
        "value": "Thang"
    },
    {
        "name": "Thanna Mandi",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.54204000",
        "longitude": "74.38100000",
        "label": "Thanna Mandi",
        "value": "Thanna Mandi"
    },
    {
        "name": "Tral",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.92708000",
        "longitude": "75.11585000",
        "label": "Tral",
        "value": "Tral"
    },
    {
        "name": "Tsrar Sharif",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.86319000",
        "longitude": "74.76524000",
        "label": "Tsrar Sharif",
        "value": "Tsrar Sharif"
    },
    {
        "name": "Udhampur",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "33.00000000",
        "longitude": "75.16667000",
        "label": "Udhampur",
        "value": "Udhampur"
    },
    {
        "name": "Uri",
        "countryCode": "IN",
        "stateCode": "JK",
        "latitude": "34.08064000",
        "longitude": "74.05088000",
        "label": "Uri",
        "value": "Uri"
    },
{
        "name": "Bagra",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.73333000",
        "longitude": "86.31667000",
        "label": "Bagra",
        "value": "Bagra"
    },
    {
        "name": "Barka Kana",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.62118000",
        "longitude": "85.46748000",
        "label": "Barka Kana",
        "value": "Barka Kana"
    },
    {
        "name": "Barki Saria",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.17594000",
        "longitude": "85.88938000",
        "label": "Barki Saria",
        "value": "Barki Saria"
    },
    {
        "name": "Barwadih",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.84780000",
        "longitude": "84.11049000",
        "label": "Barwadih",
        "value": "Barwadih"
    },
    {
        "name": "Bhojudih",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.63962000",
        "longitude": "86.44105000",
        "label": "Bhojudih",
        "value": "Bhojudih"
    },
    {
        "name": "Bokaro",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.68562000",
        "longitude": "85.99026000",
        "label": "Bokaro",
        "value": "Bokaro"
    },
    {
        "name": "Bundu",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.16095000",
        "longitude": "85.59007000",
        "label": "Bundu",
        "value": "Bundu"
    },
    {
        "name": "Chaibasa",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.55038000",
        "longitude": "85.80249000",
        "label": "Chaibasa",
        "value": "Chaibasa"
    },
    {
        "name": "Chakradharpur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.67611000",
        "longitude": "85.62892000",
        "label": "Chakradharpur",
        "value": "Chakradharpur"
    },
    {
        "name": "Chakulia",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.48301000",
        "longitude": "86.71793000",
        "label": "Chakulia",
        "value": "Chakulia"
    },
    {
        "name": "Chandil",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.95745000",
        "longitude": "86.05331000",
        "label": "Chandil",
        "value": "Chandil"
    },
    {
        "name": "Chas",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.63556000",
        "longitude": "86.16712000",
        "label": "Chas",
        "value": "Chas"
    },
    {
        "name": "Chatra",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.20645000",
        "longitude": "84.87085000",
        "label": "Chatra",
        "value": "Chatra"
    },
    {
        "name": "Chiria",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.31093000",
        "longitude": "85.27601000",
        "label": "Chiria",
        "value": "Chiria"
    },
    {
        "name": "Daltonganj",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.03971000",
        "longitude": "84.06580000",
        "label": "Daltonganj",
        "value": "Daltonganj"
    },
    {
        "name": "Deogarh",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.44382000",
        "longitude": "86.72607000",
        "label": "Deogarh",
        "value": "Deogarh"
    },
    {
        "name": "Dhanbad",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.80199000",
        "longitude": "86.44324000",
        "label": "Dhanbad",
        "value": "Dhanbad"
    },
    {
        "name": "Dhanwar",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.41074000",
        "longitude": "85.98183000",
        "label": "Dhanwar",
        "value": "Dhanwar"
    },
    {
        "name": "Dugda",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.74516000",
        "longitude": "86.17175000",
        "label": "Dugda",
        "value": "Dugda"
    },
    {
        "name": "Dumka",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.30000000",
        "longitude": "87.25000000",
        "label": "Dumka",
        "value": "Dumka"
    },
    {
        "name": "Garhwa",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.07494000",
        "longitude": "83.71023000",
        "label": "Garhwa",
        "value": "Garhwa"
    },
    {
        "name": "Ghatsila",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.58531000",
        "longitude": "86.47682000",
        "label": "Ghatsila",
        "value": "Ghatsila"
    },
    {
        "name": "Giridih",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.25000000",
        "longitude": "85.91667000",
        "label": "Giridih",
        "value": "Giridih"
    },
    {
        "name": "Gobindpur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.63393000",
        "longitude": "86.07162000",
        "label": "Gobindpur",
        "value": "Gobindpur"
    },
    {
        "name": "Godda",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.83333000",
        "longitude": "87.21667000",
        "label": "Godda",
        "value": "Godda"
    },
    {
        "name": "Gomoh",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.87355000",
        "longitude": "86.15160000",
        "label": "Gomoh",
        "value": "Gomoh"
    },
    {
        "name": "Gopinathpur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.66301000",
        "longitude": "86.07500000",
        "label": "Gopinathpur",
        "value": "Gopinathpur"
    },
    {
        "name": "Gua",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.21361000",
        "longitude": "85.38774000",
        "label": "Gua",
        "value": "Gua"
    },
    {
        "name": "Gumia",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.79750000",
        "longitude": "85.82523000",
        "label": "Gumia",
        "value": "Gumia"
    },
    {
        "name": "Gumla",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.08055000",
        "longitude": "84.53834000",
        "label": "Gumla",
        "value": "Gumla"
    },
    {
        "name": "Hazaribag",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.00000000",
        "longitude": "85.25000000",
        "label": "Hazaribag",
        "value": "Hazaribag"
    },
    {
        "name": "Hazaribagh",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.99241000",
        "longitude": "85.36162000",
        "label": "Hazaribagh",
        "value": "Hazaribagh"
    },
    {
        "name": "Hesla",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.06313000",
        "longitude": "85.87905000",
        "label": "Hesla",
        "value": "Hesla"
    },
    {
        "name": "Husainabad",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.52849000",
        "longitude": "84.00000000",
        "label": "Husainabad",
        "value": "Husainabad"
    },
    {
        "name": "Jagannathpur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.22115000",
        "longitude": "85.63917000",
        "label": "Jagannathpur",
        "value": "Jagannathpur"
    },
    {
        "name": "Jamadoba",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.71667000",
        "longitude": "86.40000000",
        "label": "Jamadoba",
        "value": "Jamadoba"
    },
    {
        "name": "Jamshedpur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.80278000",
        "longitude": "86.18545000",
        "label": "Jamshedpur",
        "value": "Jamshedpur"
    },
    {
        "name": "Jamtara",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.00000000",
        "longitude": "86.85000000",
        "label": "Jamtara",
        "value": "Jamtara"
    },
    {
        "name": "Jasidih",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.51379000",
        "longitude": "86.64576000",
        "label": "Jasidih",
        "value": "Jasidih"
    },
    {
        "name": "Jharia",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.74079000",
        "longitude": "86.41456000",
        "label": "Jharia",
        "value": "Jharia"
    },
    {
        "name": "Jugsalai",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.77668000",
        "longitude": "86.18351000",
        "label": "Jugsalai",
        "value": "Jugsalai"
    },
    {
        "name": "Jumri Tilaiya",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.43490000",
        "longitude": "85.52951000",
        "label": "Jumri Tilaiya",
        "value": "Jumri Tilaiya"
    },
    {
        "name": "Kalikapur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.61662000",
        "longitude": "86.28810000",
        "label": "Kalikapur",
        "value": "Kalikapur"
    },
    {
        "name": "Kandra",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.85170000",
        "longitude": "86.05192000",
        "label": "Kandra",
        "value": "Kandra"
    },
    {
        "name": "Kanke",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.43478000",
        "longitude": "85.32059000",
        "label": "Kanke",
        "value": "Kanke"
    },
    {
        "name": "Katras",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.79752000",
        "longitude": "86.29834000",
        "label": "Katras",
        "value": "Katras"
    },
    {
        "name": "Kenduadih",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.77574000",
        "longitude": "86.37609000",
        "label": "Kenduadih",
        "value": "Kenduadih"
    },
    {
        "name": "Kharsawan",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.79093000",
        "longitude": "85.83102000",
        "label": "Kharsawan",
        "value": "Kharsawan"
    },
    {
        "name": "Khunti",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.07602000",
        "longitude": "85.27818000",
        "label": "Khunti",
        "value": "Khunti"
    },
    {
        "name": "Kodarma",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.46753000",
        "longitude": "85.59397000",
        "label": "Kodarma",
        "value": "Kodarma"
    },
    {
        "name": "Kuju",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.72536000",
        "longitude": "85.51023000",
        "label": "Kuju",
        "value": "Kuju"
    },
    {
        "name": "Latehar",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.75000000",
        "longitude": "84.40000000",
        "label": "Latehar",
        "value": "Latehar"
    },
    {
        "name": "Lohardaga",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.50000000",
        "longitude": "84.60000000",
        "label": "Lohardaga",
        "value": "Lohardaga"
    },
    {
        "name": "Madhupur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.27419000",
        "longitude": "86.63929000",
        "label": "Madhupur",
        "value": "Madhupur"
    },
    {
        "name": "Malkera",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.78213000",
        "longitude": "86.28767000",
        "label": "Malkera",
        "value": "Malkera"
    },
    {
        "name": "Manoharpur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.37456000",
        "longitude": "85.19234000",
        "label": "Manoharpur",
        "value": "Manoharpur"
    },
    {
        "name": "Mugma",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.77015000",
        "longitude": "86.72746000",
        "label": "Mugma",
        "value": "Mugma"
    },
    {
        "name": "Mushabani",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.51135000",
        "longitude": "86.45713000",
        "label": "Mushabani",
        "value": "Mushabani"
    },
    {
        "name": "Neturhat",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.47457000",
        "longitude": "84.26780000",
        "label": "Neturhat",
        "value": "Neturhat"
    },
    {
        "name": "Nirsa",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.78438000",
        "longitude": "86.70692000",
        "label": "Nirsa",
        "value": "Nirsa"
    },
    {
        "name": "Noamundi",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.16094000",
        "longitude": "85.50416000",
        "label": "Noamundi",
        "value": "Noamundi"
    },
    {
        "name": "Pakur",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.63925000",
        "longitude": "87.84239000",
        "label": "Pakur",
        "value": "Pakur"
    },
    {
        "name": "Palamu",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.91667000",
        "longitude": "84.08333000",
        "label": "Palamu",
        "value": "Palamu"
    },
    {
        "name": "Pashchim Singhbhum",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.50000000",
        "longitude": "85.50000000",
        "label": "Pashchim Singhbhum",
        "value": "Pashchim Singhbhum"
    },
    {
        "name": "Pathardih",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.66580000",
        "longitude": "86.43166000",
        "label": "Pathardih",
        "value": "Pathardih"
    },
    {
        "name": "Purba Singhbhum",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.59238000",
        "longitude": "86.48341000",
        "label": "Purba Singhbhum",
        "value": "Purba Singhbhum"
    },
    {
        "name": "Ramgarh",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.63073000",
        "longitude": "85.56057000",
        "label": "Ramgarh",
        "value": "Ramgarh"
    },
    {
        "name": "Ranchi",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.34316000",
        "longitude": "85.30940000",
        "label": "Ranchi",
        "value": "Ranchi"
    },
    {
        "name": "Ray",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.68430000",
        "longitude": "85.05457000",
        "label": "Ray",
        "value": "Ray"
    },
    {
        "name": "Sahibganj",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.99354000",
        "longitude": "87.67333000",
        "label": "Sahibganj",
        "value": "Sahibganj"
    },
    {
        "name": "Saraikela",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.69963000",
        "longitude": "85.93126000",
        "label": "Saraikela",
        "value": "Saraikela"
    },
    {
        "name": "Sarubera",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.81813000",
        "longitude": "85.99628000",
        "label": "Sarubera",
        "value": "Sarubera"
    },
    {
        "name": "Sijua",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.77617000",
        "longitude": "86.33028000",
        "label": "Sijua",
        "value": "Sijua"
    },
    {
        "name": "Simdega",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.61523000",
        "longitude": "84.50208000",
        "label": "Simdega",
        "value": "Simdega"
    },
    {
        "name": "Sini",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "22.79325000",
        "longitude": "85.94543000",
        "label": "Sini",
        "value": "Sini"
    },
    {
        "name": "Topchanchi",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "23.90381000",
        "longitude": "86.19792000",
        "label": "Topchanchi",
        "value": "Topchanchi"
    },
    {
        "name": "patamda",
        "countryCode": "IN",
        "stateCode": "JH",
        "latitude": "24.30000000",
        "longitude": "85.41667000",
        "label": "patamda",
        "value": "patamda"
    }
,{
        "name": "Afzalpur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.19986000",
        "longitude": "76.36018000",
        "label": "Afzalpur",
        "value": "Afzalpur"
    },
    {
        "name": "Ajjampur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.72794000",
        "longitude": "76.00680000",
        "label": "Ajjampur",
        "value": "Ajjampur"
    },
    {
        "name": "Aland",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.56425000",
        "longitude": "76.56854000",
        "label": "Aland",
        "value": "Aland"
    },
    {
        "name": "Alnavar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.42727000",
        "longitude": "74.74111000",
        "label": "Alnavar",
        "value": "Alnavar"
    },
    {
        "name": "Alur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.97805000",
        "longitude": "75.99094000",
        "label": "Alur",
        "value": "Alur"
    },
    {
        "name": "Anekal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.71110000",
        "longitude": "77.69557000",
        "label": "Anekal",
        "value": "Anekal"
    },
    {
        "name": "Ankola",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.66049000",
        "longitude": "74.30470000",
        "label": "Ankola",
        "value": "Ankola"
    },
    {
        "name": "Annigeri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.42513000",
        "longitude": "75.43350000",
        "label": "Annigeri",
        "value": "Annigeri"
    },
    {
        "name": "Arkalgud",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.76171000",
        "longitude": "76.06035000",
        "label": "Arkalgud",
        "value": "Arkalgud"
    },
    {
        "name": "Arsikere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.31446000",
        "longitude": "76.25704000",
        "label": "Arsikere",
        "value": "Arsikere"
    },
    {
        "name": "Athni",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.72613000",
        "longitude": "75.06421000",
        "label": "Athni",
        "value": "Athni"
    },
    {
        "name": "Aurad",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "18.25397000",
        "longitude": "77.41761000",
        "label": "Aurad",
        "value": "Aurad"
    },
    {
        "name": "Badami",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.91495000",
        "longitude": "75.67683000",
        "label": "Badami",
        "value": "Badami"
    },
    {
        "name": "Bagalkot",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.18000000",
        "longitude": "75.69000000",
        "label": "Bagalkot",
        "value": "Bagalkot"
    },
    {
        "name": "Bagepalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.78338000",
        "longitude": "77.79667000",
        "label": "Bagepalli",
        "value": "Bagepalli"
    },
    {
        "name": "Bail-Hongal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.81370000",
        "longitude": "74.85895000",
        "label": "Bail-Hongal",
        "value": "Bail-Hongal"
    },
    {
        "name": "Ballari",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.15000000",
        "longitude": "76.55000000",
        "label": "Ballari",
        "value": "Ballari"
    },
    {
        "name": "Ballary",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.14205000",
        "longitude": "76.92398000",
        "label": "Ballary",
        "value": "Ballary"
    },
    {
        "name": "Banavar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.41029000",
        "longitude": "76.16314000",
        "label": "Banavar",
        "value": "Banavar"
    },
    {
        "name": "Bangalore Rural",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.22567000",
        "longitude": "77.57501000",
        "label": "Bangalore Rural",
        "value": "Bangalore Rural"
    },
    {
        "name": "Bangalore Urban",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.00000000",
        "longitude": "77.58333000",
        "label": "Bangalore Urban",
        "value": "Bangalore Urban"
    },
    {
        "name": "Bangarapet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.99116000",
        "longitude": "78.17804000",
        "label": "Bangarapet",
        "value": "Bangarapet"
    },
    {
        "name": "Bannur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.33295000",
        "longitude": "76.86201000",
        "label": "Bannur",
        "value": "Bannur"
    },
    {
        "name": "Bantval",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.89050000",
        "longitude": "75.03489000",
        "label": "Bantval",
        "value": "Bantval"
    },
    {
        "name": "Basavakalyan",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.87445000",
        "longitude": "76.94972000",
        "label": "Basavakalyan",
        "value": "Basavakalyan"
    },
    {
        "name": "Basavana Bagevadi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.57278000",
        "longitude": "75.97252000",
        "label": "Basavana Bagevadi",
        "value": "Basavana Bagevadi"
    },
    {
        "name": "Belagavi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.33333000",
        "longitude": "74.75000000",
        "label": "Belagavi",
        "value": "Belagavi"
    },
    {
        "name": "Belluru",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.98140000",
        "longitude": "76.73308000",
        "label": "Belluru",
        "value": "Belluru"
    },
    {
        "name": "Beltangadi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.98333000",
        "longitude": "75.30000000",
        "label": "Beltangadi",
        "value": "Beltangadi"
    },
    {
        "name": "Belur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.16558000",
        "longitude": "75.86519000",
        "label": "Belur",
        "value": "Belur"
    },
    {
        "name": "Bengaluru",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.97194000",
        "longitude": "77.59369000",
        "label": "Bengaluru",
        "value": "Bengaluru"
    },
    {
        "name": "Bhadravati",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.84846000",
        "longitude": "75.70502000",
        "label": "Bhadravati",
        "value": "Bhadravati"
    },
    {
        "name": "Bhalki",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "18.04348000",
        "longitude": "77.20600000",
        "label": "Bhalki",
        "value": "Bhalki"
    },
    {
        "name": "Bhatkal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.98534000",
        "longitude": "74.55531000",
        "label": "Bhatkal",
        "value": "Bhatkal"
    },
    {
        "name": "Bidar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "18.08333000",
        "longitude": "77.33333000",
        "label": "Bidar",
        "value": "Bidar"
    },
    {
        "name": "Bilgi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.34714000",
        "longitude": "75.61804000",
        "label": "Bilgi",
        "value": "Bilgi"
    },
    {
        "name": "Birur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.59723000",
        "longitude": "75.97167000",
        "label": "Birur",
        "value": "Birur"
    },
    {
        "name": "Byadgi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.67325000",
        "longitude": "75.48680000",
        "label": "Byadgi",
        "value": "Byadgi"
    },
    {
        "name": "Byndoor",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.86667000",
        "longitude": "74.63333000",
        "label": "Byndoor",
        "value": "Byndoor"
    },
    {
        "name": "Canacona",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.99590000",
        "longitude": "74.05056000",
        "label": "Canacona",
        "value": "Canacona"
    },
    {
        "name": "Challakere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.31800000",
        "longitude": "76.65165000",
        "label": "Challakere",
        "value": "Challakere"
    },
    {
        "name": "Chamrajnagar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "11.96000000",
        "longitude": "77.09000000",
        "label": "Chamrajnagar",
        "value": "Chamrajnagar"
    },
    {
        "name": "Channagiri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.02399000",
        "longitude": "75.92577000",
        "label": "Channagiri",
        "value": "Channagiri"
    },
    {
        "name": "Channapatna",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.65143000",
        "longitude": "77.20672000",
        "label": "Channapatna",
        "value": "Channapatna"
    },
    {
        "name": "Channarayapatna",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.90642000",
        "longitude": "76.38775000",
        "label": "Channarayapatna",
        "value": "Channarayapatna"
    },
    {
        "name": "Chik Ballapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.43512000",
        "longitude": "77.72787000",
        "label": "Chik Ballapur",
        "value": "Chik Ballapur"
    },
    {
        "name": "Chikkaballapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.55000000",
        "longitude": "77.87000000",
        "label": "Chikkaballapur",
        "value": "Chikkaballapur"
    },
    {
        "name": "Chikkamagaluru",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.49000000",
        "longitude": "75.73000000",
        "label": "Chikkamagaluru",
        "value": "Chikkamagaluru"
    },
    {
        "name": "Chiknayakanhalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.41609000",
        "longitude": "76.62063000",
        "label": "Chiknayakanhalli",
        "value": "Chiknayakanhalli"
    },
    {
        "name": "Chikodi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.42898000",
        "longitude": "74.58591000",
        "label": "Chikodi",
        "value": "Chikodi"
    },
    {
        "name": "Chincholi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.46508000",
        "longitude": "77.41874000",
        "label": "Chincholi",
        "value": "Chincholi"
    },
    {
        "name": "Chintamani",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.40051000",
        "longitude": "78.05172000",
        "label": "Chintamani",
        "value": "Chintamani"
    },
    {
        "name": "Chitapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.12357000",
        "longitude": "77.08240000",
        "label": "Chitapur",
        "value": "Chitapur"
    },
    {
        "name": "Chitradurga",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.20000000",
        "longitude": "76.50000000",
        "label": "Chitradurga",
        "value": "Chitradurga"
    },
    {
        "name": "Closepet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.72181000",
        "longitude": "77.28149000",
        "label": "Closepet",
        "value": "Closepet"
    },
    {
        "name": "Coondapoor",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.63126000",
        "longitude": "74.69020000",
        "label": "Coondapoor",
        "value": "Coondapoor"
    },
    {
        "name": "Dakshina Kannada",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.84000000",
        "longitude": "75.29000000",
        "label": "Dakshina Kannada",
        "value": "Dakshina Kannada"
    },
    {
        "name": "Dandeli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.26667000",
        "longitude": "74.61667000",
        "label": "Dandeli",
        "value": "Dandeli"
    },
    {
        "name": "Davanagere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.43000000",
        "longitude": "75.90000000",
        "label": "Davanagere",
        "value": "Davanagere"
    },
    {
        "name": "Devanhalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.24655000",
        "longitude": "77.71183000",
        "label": "Devanhalli",
        "value": "Devanhalli"
    },
    {
        "name": "Dharwad",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.37000000",
        "longitude": "75.14000000",
        "label": "Dharwad",
        "value": "Dharwad"
    },
    {
        "name": "Dod Ballapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.29452000",
        "longitude": "77.53777000",
        "label": "Dod Ballapur",
        "value": "Dod Ballapur"
    },
    {
        "name": "French Rocks",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.50094000",
        "longitude": "76.67416000",
        "label": "French Rocks",
        "value": "French Rocks"
    },
    {
        "name": "Gadag",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.49835000",
        "longitude": "75.65187000",
        "label": "Gadag",
        "value": "Gadag"
    },
    {
        "name": "Gadag-Betageri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.41670000",
        "longitude": "75.61670000",
        "label": "Gadag-Betageri",
        "value": "Gadag-Betageri"
    },
    {
        "name": "Gajendragarh",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.73628000",
        "longitude": "75.96976000",
        "label": "Gajendragarh",
        "value": "Gajendragarh"
    },
    {
        "name": "Gangawati",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.43130000",
        "longitude": "76.52933000",
        "label": "Gangawati",
        "value": "Gangawati"
    },
    {
        "name": "Gangolli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.65024000",
        "longitude": "74.67072000",
        "label": "Gangolli",
        "value": "Gangolli"
    },
    {
        "name": "Gokak",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.16901000",
        "longitude": "74.82393000",
        "label": "Gokak",
        "value": "Gokak"
    },
    {
        "name": "Gokarna",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.55000000",
        "longitude": "74.31667000",
        "label": "Gokarna",
        "value": "Gokarna"
    },
    {
        "name": "Goribidnur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.61072000",
        "longitude": "77.51738000",
        "label": "Goribidnur",
        "value": "Goribidnur"
    },
    {
        "name": "Gorur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.82297000",
        "longitude": "76.06463000",
        "label": "Gorur",
        "value": "Gorur"
    },
    {
        "name": "Gubbi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.31216000",
        "longitude": "76.94102000",
        "label": "Gubbi",
        "value": "Gubbi"
    },
    {
        "name": "Gudibanda",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.67099000",
        "longitude": "77.70414000",
        "label": "Gudibanda",
        "value": "Gudibanda"
    },
    {
        "name": "Guledagudda",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.05025000",
        "longitude": "75.78997000",
        "label": "Guledagudda",
        "value": "Guledagudda"
    },
    {
        "name": "Gundlupēt",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "11.81004000",
        "longitude": "76.69027000",
        "label": "Gundlupēt",
        "value": "Gundlupēt"
    },
    {
        "name": "Gurmatkal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.86773000",
        "longitude": "77.39088000",
        "label": "Gurmatkal",
        "value": "Gurmatkal"
    },
    {
        "name": "Hadagalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.02048000",
        "longitude": "75.93185000",
        "label": "Hadagalli",
        "value": "Hadagalli"
    },
    {
        "name": "Haliyal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.32864000",
        "longitude": "74.75638000",
        "label": "Haliyal",
        "value": "Haliyal"
    },
    {
        "name": "Hampi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.33520000",
        "longitude": "76.46030000",
        "label": "Hampi",
        "value": "Hampi"
    },
    {
        "name": "Hangal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.76465000",
        "longitude": "75.12460000",
        "label": "Hangal",
        "value": "Hangal"
    },
    {
        "name": "Harihar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.51288000",
        "longitude": "75.80716000",
        "label": "Harihar",
        "value": "Harihar"
    },
    {
        "name": "Harpanahalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.78766000",
        "longitude": "75.98863000",
        "label": "Harpanahalli",
        "value": "Harpanahalli"
    },
    {
        "name": "Hassan",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.95000000",
        "longitude": "76.08333000",
        "label": "Hassan",
        "value": "Hassan"
    },
    {
        "name": "Haveri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.73732000",
        "longitude": "75.41062000",
        "label": "Haveri",
        "value": "Haveri"
    },
    {
        "name": "Heggadadevankote",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.08809000",
        "longitude": "76.32957000",
        "label": "Heggadadevankote",
        "value": "Heggadadevankote"
    },
    {
        "name": "Hirekerur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.45506000",
        "longitude": "75.39520000",
        "label": "Hirekerur",
        "value": "Hirekerur"
    },
    {
        "name": "Hiriyur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.94455000",
        "longitude": "76.61723000",
        "label": "Hiriyur",
        "value": "Hiriyur"
    },
    {
        "name": "Holalkere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.04295000",
        "longitude": "76.18496000",
        "label": "Holalkere",
        "value": "Holalkere"
    },
    {
        "name": "Hole Narsipur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.78635000",
        "longitude": "76.24331000",
        "label": "Hole Narsipur",
        "value": "Hole Narsipur"
    },
    {
        "name": "Homnabad",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.77074000",
        "longitude": "77.12519000",
        "label": "Homnabad",
        "value": "Homnabad"
    },
    {
        "name": "Honavar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.28088000",
        "longitude": "74.44497000",
        "label": "Honavar",
        "value": "Honavar"
    },
    {
        "name": "Honnali",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.23976000",
        "longitude": "75.64507000",
        "label": "Honnali",
        "value": "Honnali"
    },
    {
        "name": "Hosanagara",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.91387000",
        "longitude": "75.06503000",
        "label": "Hosanagara",
        "value": "Hosanagara"
    },
    {
        "name": "Hosangadi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.69756000",
        "longitude": "74.95427000",
        "label": "Hosangadi",
        "value": "Hosangadi"
    },
    {
        "name": "Hosdurga",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.79631000",
        "longitude": "76.28408000",
        "label": "Hosdurga",
        "value": "Hosdurga"
    },
    {
        "name": "Hoskote",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.07070000",
        "longitude": "77.79814000",
        "label": "Hoskote",
        "value": "Hoskote"
    },
    {
        "name": "Hospet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.26954000",
        "longitude": "76.38710000",
        "label": "Hospet",
        "value": "Hospet"
    },
    {
        "name": "Hubballi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.34776000",
        "longitude": "75.13378000",
        "label": "Hubballi",
        "value": "Hubballi"
    },
    {
        "name": "Hukeri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.23082000",
        "longitude": "74.60244000",
        "label": "Hukeri",
        "value": "Hukeri"
    },
    {
        "name": "Hungund",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.06213000",
        "longitude": "76.05860000",
        "label": "Hungund",
        "value": "Hungund"
    },
    {
        "name": "Hunsur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.30359000",
        "longitude": "76.29275000",
        "label": "Hunsur",
        "value": "Hunsur"
    },
    {
        "name": "Ilkal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.95923000",
        "longitude": "76.11351000",
        "label": "Ilkal",
        "value": "Ilkal"
    },
    {
        "name": "Indi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.17735000",
        "longitude": "75.95260000",
        "label": "Indi",
        "value": "Indi"
    },
    {
        "name": "Jagalur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.51957000",
        "longitude": "76.33915000",
        "label": "Jagalur",
        "value": "Jagalur"
    },
    {
        "name": "Jamkhandi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.50461000",
        "longitude": "75.29146000",
        "label": "Jamkhandi",
        "value": "Jamkhandi"
    },
    {
        "name": "Jevargi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.01394000",
        "longitude": "76.77317000",
        "label": "Jevargi",
        "value": "Jevargi"
    },
    {
        "name": "Kadur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.55285000",
        "longitude": "76.01164000",
        "label": "Kadur",
        "value": "Kadur"
    },
    {
        "name": "Kalaburgi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.16667000",
        "longitude": "77.08333000",
        "label": "Kalaburgi",
        "value": "Kalaburgi"
    },
    {
        "name": "Kalghatgi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.18315000",
        "longitude": "74.97099000",
        "label": "Kalghatgi",
        "value": "Kalghatgi"
    },
    {
        "name": "Kampli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.40626000",
        "longitude": "76.60013000",
        "label": "Kampli",
        "value": "Kampli"
    },
    {
        "name": "Kankanhalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.54654000",
        "longitude": "77.42005000",
        "label": "Kankanhalli",
        "value": "Kankanhalli"
    },
    {
        "name": "Karkala",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.21428000",
        "longitude": "74.99234000",
        "label": "Karkala",
        "value": "Karkala"
    },
    {
        "name": "Karwar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.81361000",
        "longitude": "74.12972000",
        "label": "Karwar",
        "value": "Karwar"
    },
    {
        "name": "Kavalur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.28829000",
        "longitude": "75.94330000",
        "label": "Kavalur",
        "value": "Kavalur"
    },
    {
        "name": "Kerur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.01384000",
        "longitude": "75.54631000",
        "label": "Kerur",
        "value": "Kerur"
    },
    {
        "name": "Khanapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.63969000",
        "longitude": "74.50847000",
        "label": "Khanapur",
        "value": "Khanapur"
    },
    {
        "name": "Kodagu",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.41667000",
        "longitude": "75.75000000",
        "label": "Kodagu",
        "value": "Kodagu"
    },
    {
        "name": "Kodigenahalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.72136000",
        "longitude": "77.38629000",
        "label": "Kodigenahalli",
        "value": "Kodigenahalli"
    },
    {
        "name": "Kodlipet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.80087000",
        "longitude": "75.88662000",
        "label": "Kodlipet",
        "value": "Kodlipet"
    },
    {
        "name": "Kolar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.13000000",
        "longitude": "78.23000000",
        "label": "Kolar",
        "value": "Kolar"
    },
    {
        "name": "Kollegal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.15449000",
        "longitude": "77.11051000",
        "label": "Kollegal",
        "value": "Kollegal"
    },
    {
        "name": "Konanur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.63016000",
        "longitude": "76.05037000",
        "label": "Konanur",
        "value": "Konanur"
    },
    {
        "name": "Konnur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.20138000",
        "longitude": "74.74886000",
        "label": "Konnur",
        "value": "Konnur"
    },
    {
        "name": "Koppa",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.53044000",
        "longitude": "75.36329000",
        "label": "Koppa",
        "value": "Koppa"
    },
    {
        "name": "Koppal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.50000000",
        "longitude": "76.20000000",
        "label": "Koppal",
        "value": "Koppal"
    },
    {
        "name": "Koratagere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.52200000",
        "longitude": "77.23730000",
        "label": "Koratagere",
        "value": "Koratagere"
    },
    {
        "name": "Kotturu",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.82442000",
        "longitude": "76.22005000",
        "label": "Kotturu",
        "value": "Kotturu"
    },
    {
        "name": "Krishnarajpet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.66621000",
        "longitude": "76.48770000",
        "label": "Krishnarajpet",
        "value": "Krishnarajpet"
    },
    {
        "name": "Kudachi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.62784000",
        "longitude": "74.85408000",
        "label": "Kudachi",
        "value": "Kudachi"
    },
    {
        "name": "Kudligi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.90500000",
        "longitude": "76.38527000",
        "label": "Kudligi",
        "value": "Kudligi"
    },
    {
        "name": "Kumsi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.05455000",
        "longitude": "75.39992000",
        "label": "Kumsi",
        "value": "Kumsi"
    },
    {
        "name": "Kumta",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.42853000",
        "longitude": "74.41890000",
        "label": "Kumta",
        "value": "Kumta"
    },
    {
        "name": "Kundgol",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.25612000",
        "longitude": "75.24735000",
        "label": "Kundgol",
        "value": "Kundgol"
    },
    {
        "name": "Kunigal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.02319000",
        "longitude": "77.02518000",
        "label": "Kunigal",
        "value": "Kunigal"
    },
    {
        "name": "Kurgunta",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.19321000",
        "longitude": "77.35772000",
        "label": "Kurgunta",
        "value": "Kurgunta"
    },
    {
        "name": "Kushalnagar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.45795000",
        "longitude": "75.95904000",
        "label": "Kushalnagar",
        "value": "Kushalnagar"
    },
    {
        "name": "Kushtagi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.75623000",
        "longitude": "76.19112000",
        "label": "Kushtagi",
        "value": "Kushtagi"
    },
    {
        "name": "Lakshmeshwar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.12689000",
        "longitude": "75.46935000",
        "label": "Lakshmeshwar",
        "value": "Lakshmeshwar"
    },
    {
        "name": "Lingsugur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.15876000",
        "longitude": "76.52174000",
        "label": "Lingsugur",
        "value": "Lingsugur"
    },
    {
        "name": "Londa",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.46907000",
        "longitude": "74.51906000",
        "label": "Londa",
        "value": "Londa"
    },
    {
        "name": "Maddagiri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.66035000",
        "longitude": "77.21239000",
        "label": "Maddagiri",
        "value": "Maddagiri"
    },
    {
        "name": "Maddur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.58283000",
        "longitude": "77.04294000",
        "label": "Maddur",
        "value": "Maddur"
    },
    {
        "name": "Madikeri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.42602000",
        "longitude": "75.73820000",
        "label": "Madikeri",
        "value": "Madikeri"
    },
    {
        "name": "Magadi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.95706000",
        "longitude": "77.22374000",
        "label": "Magadi",
        "value": "Magadi"
    },
    {
        "name": "Mahalingpur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.38880000",
        "longitude": "75.10873000",
        "label": "Mahalingpur",
        "value": "Mahalingpur"
    },
    {
        "name": "Malavalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.38556000",
        "longitude": "77.06045000",
        "label": "Malavalli",
        "value": "Malavalli"
    },
    {
        "name": "Malpe",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.34962000",
        "longitude": "74.70394000",
        "label": "Malpe",
        "value": "Malpe"
    },
    {
        "name": "Malur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.00322000",
        "longitude": "77.93798000",
        "label": "Malur",
        "value": "Malur"
    },
    {
        "name": "Mandya",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.52230000",
        "longitude": "76.89746000",
        "label": "Mandya",
        "value": "Mandya"
    },
    {
        "name": "Mangaluru",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.91723000",
        "longitude": "74.85603000",
        "label": "Mangaluru",
        "value": "Mangaluru"
    },
    {
        "name": "Manipal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.35000000",
        "longitude": "74.78333000",
        "label": "Manipal",
        "value": "Manipal"
    },
    {
        "name": "Manvi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.99126000",
        "longitude": "77.05034000",
        "label": "Manvi",
        "value": "Manvi"
    },
    {
        "name": "Mayakonda",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.28894000",
        "longitude": "76.08305000",
        "label": "Mayakonda",
        "value": "Mayakonda"
    },
    {
        "name": "Melukote",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.66258000",
        "longitude": "76.64861000",
        "label": "Melukote",
        "value": "Melukote"
    },
    {
        "name": "Mudbidri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.06653000",
        "longitude": "74.99525000",
        "label": "Mudbidri",
        "value": "Mudbidri"
    },
    {
        "name": "Muddebihal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.33782000",
        "longitude": "76.13173000",
        "label": "Muddebihal",
        "value": "Muddebihal"
    },
    {
        "name": "Mudgal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.01191000",
        "longitude": "76.44203000",
        "label": "Mudgal",
        "value": "Mudgal"
    },
    {
        "name": "Mudgere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.13353000",
        "longitude": "75.64160000",
        "label": "Mudgere",
        "value": "Mudgere"
    },
    {
        "name": "Mudhol",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.33354000",
        "longitude": "75.28305000",
        "label": "Mudhol",
        "value": "Mudhol"
    },
    {
        "name": "Mulbagal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.16352000",
        "longitude": "78.39346000",
        "label": "Mulbagal",
        "value": "Mulbagal"
    },
    {
        "name": "Mulgund",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.28070000",
        "longitude": "75.52132000",
        "label": "Mulgund",
        "value": "Mulgund"
    },
    {
        "name": "Mulki",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.09101000",
        "longitude": "74.79353000",
        "label": "Mulki",
        "value": "Mulki"
    },
    {
        "name": "Mundargi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.20677000",
        "longitude": "75.88390000",
        "label": "Mundargi",
        "value": "Mundargi"
    },
    {
        "name": "Mundgod",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.97144000",
        "longitude": "75.03658000",
        "label": "Mundgod",
        "value": "Mundgod"
    },
    {
        "name": "Munirabad",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.30928000",
        "longitude": "76.33830000",
        "label": "Munirabad",
        "value": "Munirabad"
    },
    {
        "name": "Murudeshwara",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.09430000",
        "longitude": "74.48450000",
        "label": "Murudeshwara",
        "value": "Murudeshwara"
    },
    {
        "name": "Mysuru",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.23000000",
        "longitude": "76.42000000",
        "label": "Mysuru",
        "value": "Mysuru"
    },
    {
        "name": "Nagamangala",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.81939000",
        "longitude": "76.75456000",
        "label": "Nagamangala",
        "value": "Nagamangala"
    },
    {
        "name": "Nanjangud",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.11764000",
        "longitude": "76.68397000",
        "label": "Nanjangud",
        "value": "Nanjangud"
    },
    {
        "name": "Narasimharajapura",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.61075000",
        "longitude": "75.51200000",
        "label": "Narasimharajapura",
        "value": "Narasimharajapura"
    },
    {
        "name": "Naregal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.57316000",
        "longitude": "75.80805000",
        "label": "Naregal",
        "value": "Naregal"
    },
    {
        "name": "Nargund",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.72299000",
        "longitude": "75.38666000",
        "label": "Nargund",
        "value": "Nargund"
    },
    {
        "name": "Navalgund",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.55877000",
        "longitude": "75.35305000",
        "label": "Navalgund",
        "value": "Navalgund"
    },
    {
        "name": "Nelamangala",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.09978000",
        "longitude": "77.39364000",
        "label": "Nelamangala",
        "value": "Nelamangala"
    },
    {
        "name": "Nyamti",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.14869000",
        "longitude": "75.57641000",
        "label": "Nyamti",
        "value": "Nyamti"
    },
    {
        "name": "Pangala",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.25000000",
        "longitude": "74.75000000",
        "label": "Pangala",
        "value": "Pangala"
    },
    {
        "name": "Pavugada",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.09953000",
        "longitude": "77.28018000",
        "label": "Pavugada",
        "value": "Pavugada"
    },
    {
        "name": "Piriyapatna",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.33497000",
        "longitude": "76.10073000",
        "label": "Piriyapatna",
        "value": "Piriyapatna"
    },
    {
        "name": "Ponnampet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.14473000",
        "longitude": "75.94514000",
        "label": "Ponnampet",
        "value": "Ponnampet"
    },
    {
        "name": "Puttur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.75975000",
        "longitude": "75.20169000",
        "label": "Puttur",
        "value": "Puttur"
    },
    {
        "name": "Rabkavi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.47567000",
        "longitude": "75.11060000",
        "label": "Rabkavi",
        "value": "Rabkavi"
    },
    {
        "name": "Raichur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.16000000",
        "longitude": "76.91000000",
        "label": "Raichur",
        "value": "Raichur"
    },
    {
        "name": "Ramanagara",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.65000000",
        "longitude": "77.35000000",
        "label": "Ramanagara",
        "value": "Ramanagara"
    },
    {
        "name": "Ranibennur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.62239000",
        "longitude": "75.62951000",
        "label": "Ranibennur",
        "value": "Ranibennur"
    },
    {
        "name": "Raybag",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.49178000",
        "longitude": "74.77391000",
        "label": "Raybag",
        "value": "Raybag"
    },
    {
        "name": "Robertsonpet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.95629000",
        "longitude": "78.27539000",
        "label": "Robertsonpet",
        "value": "Robertsonpet"
    },
    {
        "name": "Ron",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.69935000",
        "longitude": "75.73408000",
        "label": "Ron",
        "value": "Ron"
    },
    {
        "name": "Sadalgi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.55870000",
        "longitude": "74.53211000",
        "label": "Sadalgi",
        "value": "Sadalgi"
    },
    {
        "name": "Sagar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.16498000",
        "longitude": "75.02901000",
        "label": "Sagar",
        "value": "Sagar"
    },
    {
        "name": "Sakleshpur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.94119000",
        "longitude": "75.78467000",
        "label": "Sakleshpur",
        "value": "Sakleshpur"
    },
    {
        "name": "Sandur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.08613000",
        "longitude": "76.54692000",
        "label": "Sandur",
        "value": "Sandur"
    },
    {
        "name": "Sanivarsante",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.72824000",
        "longitude": "75.88669000",
        "label": "Sanivarsante",
        "value": "Sanivarsante"
    },
    {
        "name": "Sankeshwar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.25649000",
        "longitude": "74.48195000",
        "label": "Sankeshwar",
        "value": "Sankeshwar"
    },
    {
        "name": "Sargur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "11.99971000",
        "longitude": "76.39611000",
        "label": "Sargur",
        "value": "Sargur"
    },
    {
        "name": "Saundatti",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.76615000",
        "longitude": "75.11778000",
        "label": "Saundatti",
        "value": "Saundatti"
    },
    {
        "name": "Savanur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.97335000",
        "longitude": "75.33724000",
        "label": "Savanur",
        "value": "Savanur"
    },
    {
        "name": "Seram",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.17859000",
        "longitude": "77.28998000",
        "label": "Seram",
        "value": "Seram"
    },
    {
        "name": "Shahabad",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.13070000",
        "longitude": "76.94361000",
        "label": "Shahabad",
        "value": "Shahabad"
    },
    {
        "name": "Shahpur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.69605000",
        "longitude": "76.84220000",
        "label": "Shahpur",
        "value": "Shahpur"
    },
    {
        "name": "Shiggaon",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.99053000",
        "longitude": "75.22499000",
        "label": "Shiggaon",
        "value": "Shiggaon"
    },
    {
        "name": "Shikarpur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.26980000",
        "longitude": "75.35643000",
        "label": "Shikarpur",
        "value": "Shikarpur"
    },
    {
        "name": "Shimoga",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.05000000",
        "longitude": "75.16000000",
        "label": "Shimoga",
        "value": "Shimoga"
    },
    {
        "name": "Shirhatti",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.23352000",
        "longitude": "75.57996000",
        "label": "Shirhatti",
        "value": "Shirhatti"
    },
    {
        "name": "Shorapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.52100000",
        "longitude": "76.75738000",
        "label": "Shorapur",
        "value": "Shorapur"
    },
    {
        "name": "Shrirangapattana",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.42264000",
        "longitude": "76.68439000",
        "label": "Shrirangapattana",
        "value": "Shrirangapattana"
    },
    {
        "name": "Siddapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.34322000",
        "longitude": "74.89400000",
        "label": "Siddapur",
        "value": "Siddapur"
    },
    {
        "name": "Sidlaghatta",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.38896000",
        "longitude": "77.86444000",
        "label": "Sidlaghatta",
        "value": "Sidlaghatta"
    },
    {
        "name": "Sindgi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.91883000",
        "longitude": "76.23368000",
        "label": "Sindgi",
        "value": "Sindgi"
    },
    {
        "name": "Sindhnur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.76983000",
        "longitude": "76.75581000",
        "label": "Sindhnur",
        "value": "Sindhnur"
    },
    {
        "name": "Sira",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.74155000",
        "longitude": "76.90430000",
        "label": "Sira",
        "value": "Sira"
    },
    {
        "name": "Sirsi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.62072000",
        "longitude": "74.83554000",
        "label": "Sirsi",
        "value": "Sirsi"
    },
    {
        "name": "Siruguppa",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.63000000",
        "longitude": "76.89217000",
        "label": "Siruguppa",
        "value": "Siruguppa"
    },
    {
        "name": "Someshwar",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.49112000",
        "longitude": "75.06646000",
        "label": "Someshwar",
        "value": "Someshwar"
    },
    {
        "name": "Somvarpet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.59698000",
        "longitude": "75.84957000",
        "label": "Somvarpet",
        "value": "Somvarpet"
    },
    {
        "name": "Sorab",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.38144000",
        "longitude": "75.09183000",
        "label": "Sorab",
        "value": "Sorab"
    },
    {
        "name": "Sravana Belgola",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.85737000",
        "longitude": "76.48886000",
        "label": "Sravana Belgola",
        "value": "Sravana Belgola"
    },
    {
        "name": "Sringeri",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.41698000",
        "longitude": "75.25271000",
        "label": "Sringeri",
        "value": "Sringeri"
    },
    {
        "name": "Srinivaspur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.33914000",
        "longitude": "78.21175000",
        "label": "Srinivaspur",
        "value": "Srinivaspur"
    },
    {
        "name": "Sulya",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.56100000",
        "longitude": "75.38741000",
        "label": "Sulya",
        "value": "Sulya"
    },
    {
        "name": "Suntikoppa",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.45594000",
        "longitude": "75.82970000",
        "label": "Suntikoppa",
        "value": "Suntikoppa"
    },
    {
        "name": "Talikota",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.47311000",
        "longitude": "76.31085000",
        "label": "Talikota",
        "value": "Talikota"
    },
    {
        "name": "Tarikere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.70954000",
        "longitude": "75.81382000",
        "label": "Tarikere",
        "value": "Tarikere"
    },
    {
        "name": "Tekkalakote",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.53444000",
        "longitude": "76.87703000",
        "label": "Tekkalakote",
        "value": "Tekkalakote"
    },
    {
        "name": "Terdal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.49379000",
        "longitude": "75.04667000",
        "label": "Terdal",
        "value": "Terdal"
    },
    {
        "name": "Tiptur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.25630000",
        "longitude": "76.47768000",
        "label": "Tiptur",
        "value": "Tiptur"
    },
    {
        "name": "Tirthahalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.68835000",
        "longitude": "75.24548000",
        "label": "Tirthahalli",
        "value": "Tirthahalli"
    },
    {
        "name": "Tirumakudal Narsipur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.21207000",
        "longitude": "76.90180000",
        "label": "Tirumakudal Narsipur",
        "value": "Tirumakudal Narsipur"
    },
    {
        "name": "Tumakuru",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.50000000",
        "longitude": "77.00000000",
        "label": "Tumakuru",
        "value": "Tumakuru"
    },
    {
        "name": "Turuvekere",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.16374000",
        "longitude": "76.66641000",
        "label": "Turuvekere",
        "value": "Turuvekere"
    },
    {
        "name": "Udupi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.50000000",
        "longitude": "74.87000000",
        "label": "Udupi",
        "value": "Udupi"
    },
    {
        "name": "Ullal",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.80569000",
        "longitude": "74.86058000",
        "label": "Ullal",
        "value": "Ullal"
    },
    {
        "name": "Uttar Kannada",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.88333000",
        "longitude": "74.58333000",
        "label": "Uttar Kannada",
        "value": "Uttar Kannada"
    },
    {
        "name": "Vadigenhalli",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.29724000",
        "longitude": "77.80184000",
        "label": "Vadigenhalli",
        "value": "Vadigenhalli"
    },
    {
        "name": "Vijayapura",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.82442000",
        "longitude": "75.71537000",
        "label": "Vijayapura",
        "value": "Vijayapura"
    },
    {
        "name": "Virarajendrapet",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.19644000",
        "longitude": "75.80512000",
        "label": "Virarajendrapet",
        "value": "Virarajendrapet"
    },
    {
        "name": "Wadi",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "17.05183000",
        "longitude": "76.99048000",
        "label": "Wadi",
        "value": "Wadi"
    },
    {
        "name": "Yadgir",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "16.73000000",
        "longitude": "76.94000000",
        "label": "Yadgir",
        "value": "Yadgir"
    },
    {
        "name": "Yelahanka",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "13.10073000",
        "longitude": "77.59632000",
        "label": "Yelahanka",
        "value": "Yelahanka"
    },
    {
        "name": "Yelandur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "12.04629000",
        "longitude": "77.03034000",
        "label": "Yelandur",
        "value": "Yelandur"
    },
    {
        "name": "Yelbarga",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "15.61545000",
        "longitude": "76.01184000",
        "label": "Yelbarga",
        "value": "Yelbarga"
    },
    {
        "name": "Yellapur",
        "countryCode": "IN",
        "stateCode": "KA",
        "latitude": "14.96370000",
        "longitude": "74.70929000",
        "label": "Yellapur",
        "value": "Yellapur"
    }
,{
        "name": "Adur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.15595000",
        "longitude": "76.73192000",
        "label": "Adur",
        "value": "Adur"
    },
    {
        "name": "Alappuzha",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.49004000",
        "longitude": "76.32640000",
        "label": "Alappuzha",
        "value": "Alappuzha"
    },
    {
        "name": "Aluva",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.10764000",
        "longitude": "76.35158000",
        "label": "Aluva",
        "value": "Aluva"
    },
    {
        "name": "Alwaye",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.10649000",
        "longitude": "76.35484000",
        "label": "Alwaye",
        "value": "Alwaye"
    },
    {
        "name": "Angamali",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.19055000",
        "longitude": "76.38789000",
        "label": "Angamali",
        "value": "Angamali"
    },
    {
        "name": "Aroor",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.86940000",
        "longitude": "76.30498000",
        "label": "Aroor",
        "value": "Aroor"
    },
    {
        "name": "Arukutti",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.86667000",
        "longitude": "76.35000000",
        "label": "Arukutti",
        "value": "Arukutti"
    },
    {
        "name": "Attingal",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.69609000",
        "longitude": "76.81507000",
        "label": "Attingal",
        "value": "Attingal"
    },
    {
        "name": "Avanoor",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.60826000",
        "longitude": "76.17620000",
        "label": "Avanoor",
        "value": "Avanoor"
    },
    {
        "name": "Azhikkal",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.91524000",
        "longitude": "75.34761000",
        "label": "Azhikkal",
        "value": "Azhikkal"
    },
    {
        "name": "Badagara",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.59776000",
        "longitude": "75.58142000",
        "label": "Badagara",
        "value": "Badagara"
    },
    {
        "name": "Beypore",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.17151000",
        "longitude": "75.80611000",
        "label": "Beypore",
        "value": "Beypore"
    },
    {
        "name": "Changanacheri",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.44203000",
        "longitude": "76.53604000",
        "label": "Changanacheri",
        "value": "Changanacheri"
    },
    {
        "name": "Chengannur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.31575000",
        "longitude": "76.61513000",
        "label": "Chengannur",
        "value": "Chengannur"
    },
    {
        "name": "Cherpulassery",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.87655000",
        "longitude": "76.30932000",
        "label": "Cherpulassery",
        "value": "Cherpulassery"
    },
    {
        "name": "Cherthala",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.68444000",
        "longitude": "76.33558000",
        "label": "Cherthala",
        "value": "Cherthala"
    },
    {
        "name": "Chetwayi",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.52885000",
        "longitude": "76.04793000",
        "label": "Chetwayi",
        "value": "Chetwayi"
    },
    {
        "name": "Chittur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.69967000",
        "longitude": "76.74710000",
        "label": "Chittur",
        "value": "Chittur"
    },
    {
        "name": "Chēlakara",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.69289000",
        "longitude": "76.34387000",
        "label": "Chēlakara",
        "value": "Chēlakara"
    },
    {
        "name": "Cochin",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.93988000",
        "longitude": "76.26022000",
        "label": "Cochin",
        "value": "Cochin"
    },
    {
        "name": "Dharmadam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.77538000",
        "longitude": "75.46459000",
        "label": "Dharmadam",
        "value": "Dharmadam"
    },
    {
        "name": "Edakkulam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.61020000",
        "longitude": "76.18352000",
        "label": "Edakkulam",
        "value": "Edakkulam"
    },
    {
        "name": "Elur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.06667000",
        "longitude": "76.28333000",
        "label": "Elur",
        "value": "Elur"
    },
    {
        "name": "Erattupetta",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.68747000",
        "longitude": "76.77891000",
        "label": "Erattupetta",
        "value": "Erattupetta"
    },
    {
        "name": "Ernakulam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.00000000",
        "longitude": "76.50000000",
        "label": "Ernakulam",
        "value": "Ernakulam"
    },
    {
        "name": "Ferokh",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.17989000",
        "longitude": "75.84141000",
        "label": "Ferokh",
        "value": "Ferokh"
    },
    {
        "name": "Guruvayur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.59430000",
        "longitude": "76.04110000",
        "label": "Guruvayur",
        "value": "Guruvayur"
    },
    {
        "name": "Idukki",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.00000000",
        "longitude": "77.00000000",
        "label": "Idukki",
        "value": "Idukki"
    },
    {
        "name": "Iringal",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.55929000",
        "longitude": "75.61663000",
        "label": "Iringal",
        "value": "Iringal"
    },
    {
        "name": "Irinjalakuda",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.34238000",
        "longitude": "76.21124000",
        "label": "Irinjalakuda",
        "value": "Irinjalakuda"
    },
    {
        "name": "Kadakkavoor",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.67921000",
        "longitude": "76.76714000",
        "label": "Kadakkavoor",
        "value": "Kadakkavoor"
    },
    {
        "name": "Kalamassery",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.06140000",
        "longitude": "76.32631000",
        "label": "Kalamassery",
        "value": "Kalamassery"
    },
    {
        "name": "Kalavoor",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.57046000",
        "longitude": "76.32756000",
        "label": "Kalavoor",
        "value": "Kalavoor"
    },
    {
        "name": "Kalpatta",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.60871000",
        "longitude": "76.08343000",
        "label": "Kalpatta",
        "value": "Kalpatta"
    },
    {
        "name": "Kannangad",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.30814000",
        "longitude": "75.10632000",
        "label": "Kannangad",
        "value": "Kannangad"
    },
    {
        "name": "Kannavam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.84450000",
        "longitude": "75.66266000",
        "label": "Kannavam",
        "value": "Kannavam"
    },
    {
        "name": "Kannur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.16667000",
        "longitude": "75.33333000",
        "label": "Kannur",
        "value": "Kannur"
    },
    {
        "name": "Kasaragod",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.49838000",
        "longitude": "74.98959000",
        "label": "Kasaragod",
        "value": "Kasaragod"
    },
    {
        "name": "Kasaragod District",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.49246000",
        "longitude": "74.99062000",
        "label": "Kasaragod District",
        "value": "Kasaragod District"
    },
    {
        "name": "Kattanam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.17614000",
        "longitude": "76.56325000",
        "label": "Kattanam",
        "value": "Kattanam"
    },
    {
        "name": "Kayankulam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.18173000",
        "longitude": "76.50093000",
        "label": "Kayankulam",
        "value": "Kayankulam"
    },
    {
        "name": "Kizhake Chalakudi",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.30067000",
        "longitude": "76.33763000",
        "label": "Kizhake Chalakudi",
        "value": "Kizhake Chalakudi"
    },
    {
        "name": "Kodungallur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.23263000",
        "longitude": "76.19513000",
        "label": "Kodungallur",
        "value": "Kodungallur"
    },
    {
        "name": "Kollam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.88113000",
        "longitude": "76.58469000",
        "label": "Kollam",
        "value": "Kollam"
    },
    {
        "name": "Kotamangalam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.06435000",
        "longitude": "76.62843000",
        "label": "Kotamangalam",
        "value": "Kotamangalam"
    },
    {
        "name": "Kottayam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.66667000",
        "longitude": "76.66667000",
        "label": "Kottayam",
        "value": "Kottayam"
    },
    {
        "name": "Kovalam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.36667000",
        "longitude": "76.99667000",
        "label": "Kovalam",
        "value": "Kovalam"
    },
    {
        "name": "Kozhikode",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.50000000",
        "longitude": "76.00000000",
        "label": "Kozhikode",
        "value": "Kozhikode"
    },
    {
        "name": "Kumbalam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.90630000",
        "longitude": "76.31127000",
        "label": "Kumbalam",
        "value": "Kumbalam"
    },
    {
        "name": "Kunnamangalam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.30459000",
        "longitude": "75.87772000",
        "label": "Kunnamangalam",
        "value": "Kunnamangalam"
    },
    {
        "name": "Kunnamkulam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.64667000",
        "longitude": "76.06695000",
        "label": "Kunnamkulam",
        "value": "Kunnamkulam"
    },
    {
        "name": "Kunnumma",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.35672000",
        "longitude": "76.41343000",
        "label": "Kunnumma",
        "value": "Kunnumma"
    },
    {
        "name": "Kutiatodu",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.80000000",
        "longitude": "76.33333000",
        "label": "Kutiatodu",
        "value": "Kutiatodu"
    },
    {
        "name": "Kuttampuzha",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.15033000",
        "longitude": "76.73544000",
        "label": "Kuttampuzha",
        "value": "Kuttampuzha"
    },
    {
        "name": "Lalam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.71667000",
        "longitude": "76.70000000",
        "label": "Lalam",
        "value": "Lalam"
    },
    {
        "name": "Mahē",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.70172000",
        "longitude": "75.53474000",
        "label": "Mahē",
        "value": "Mahē"
    },
    {
        "name": "Malappuram",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.00000000",
        "longitude": "76.16667000",
        "label": "Malappuram",
        "value": "Malappuram"
    },
    {
        "name": "Manjeri",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.12018000",
        "longitude": "76.11996000",
        "label": "Manjeri",
        "value": "Manjeri"
    },
    {
        "name": "Manjēshvar",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.71287000",
        "longitude": "74.88857000",
        "label": "Manjēshvar",
        "value": "Manjēshvar"
    },
    {
        "name": "Mannarakkat",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.99223000",
        "longitude": "76.46418000",
        "label": "Mannarakkat",
        "value": "Mannarakkat"
    },
    {
        "name": "Marayur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.27641000",
        "longitude": "77.16205000",
        "label": "Marayur",
        "value": "Marayur"
    },
    {
        "name": "Mattanur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.93018000",
        "longitude": "75.57152000",
        "label": "Mattanur",
        "value": "Mattanur"
    },
    {
        "name": "Mavelikara",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.25929000",
        "longitude": "76.55642000",
        "label": "Mavelikara",
        "value": "Mavelikara"
    },
    {
        "name": "Mavoor",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.26667000",
        "longitude": "75.91667000",
        "label": "Mavoor",
        "value": "Mavoor"
    },
    {
        "name": "Muluppilagadu",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.79788000",
        "longitude": "75.45111000",
        "label": "Muluppilagadu",
        "value": "Muluppilagadu"
    },
    {
        "name": "Munnar",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.08818000",
        "longitude": "77.06239000",
        "label": "Munnar",
        "value": "Munnar"
    },
    {
        "name": "Muvattupula",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.98493000",
        "longitude": "76.57728000",
        "label": "Muvattupula",
        "value": "Muvattupula"
    },
    {
        "name": "Muvattupuzha",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.97985000",
        "longitude": "76.57381000",
        "label": "Muvattupuzha",
        "value": "Muvattupuzha"
    },
    {
        "name": "Nadapuram",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.68465000",
        "longitude": "75.65493000",
        "label": "Nadapuram",
        "value": "Nadapuram"
    },
    {
        "name": "Naduvannur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.48772000",
        "longitude": "75.77511000",
        "label": "Naduvannur",
        "value": "Naduvannur"
    },
    {
        "name": "Nedumangad",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.60267000",
        "longitude": "77.00139000",
        "label": "Nedumangad",
        "value": "Nedumangad"
    },
    {
        "name": "Neyyattinkara",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.39854000",
        "longitude": "77.08586000",
        "label": "Neyyattinkara",
        "value": "Neyyattinkara"
    },
    {
        "name": "Nilēshwar",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.25953000",
        "longitude": "75.13520000",
        "label": "Nilēshwar",
        "value": "Nilēshwar"
    },
    {
        "name": "Ottappalam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.77350000",
        "longitude": "76.37758000",
        "label": "Ottappalam",
        "value": "Ottappalam"
    },
    {
        "name": "Palackattumala",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.74356000",
        "longitude": "76.62940000",
        "label": "Palackattumala",
        "value": "Palackattumala"
    },
    {
        "name": "Palakkad district",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.77500000",
        "longitude": "76.65100000",
        "label": "Palakkad district",
        "value": "Palakkad district"
    },
    {
        "name": "Palghat",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.77319000",
        "longitude": "76.65366000",
        "label": "Palghat",
        "value": "Palghat"
    },
    {
        "name": "Panamaram",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.74014000",
        "longitude": "76.07369000",
        "label": "Panamaram",
        "value": "Panamaram"
    },
    {
        "name": "Pappinisshēri",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.95655000",
        "longitude": "75.34034000",
        "label": "Pappinisshēri",
        "value": "Pappinisshēri"
    },
    {
        "name": "Paravur Tekkumbhagam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.79470000",
        "longitude": "76.66798000",
        "label": "Paravur Tekkumbhagam",
        "value": "Paravur Tekkumbhagam"
    },
    {
        "name": "Pariyapuram",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.01667000",
        "longitude": "75.86667000",
        "label": "Pariyapuram",
        "value": "Pariyapuram"
    },
    {
        "name": "Pathanamthitta",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.26667000",
        "longitude": "76.78333000",
        "label": "Pathanamthitta",
        "value": "Pathanamthitta"
    },
    {
        "name": "Pattanamtitta",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.28068000",
        "longitude": "76.86967000",
        "label": "Pattanamtitta",
        "value": "Pattanamtitta"
    },
    {
        "name": "Payyannur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.09350000",
        "longitude": "75.20249000",
        "label": "Payyannur",
        "value": "Payyannur"
    },
    {
        "name": "Perumbavoor",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.10695000",
        "longitude": "76.47366000",
        "label": "Perumbavoor",
        "value": "Perumbavoor"
    },
    {
        "name": "Perumpavur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.11544000",
        "longitude": "76.47611000",
        "label": "Perumpavur",
        "value": "Perumpavur"
    },
    {
        "name": "Perya",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.83334000",
        "longitude": "75.85408000",
        "label": "Perya",
        "value": "Perya"
    },
    {
        "name": "Piravam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.86667000",
        "longitude": "76.50000000",
        "label": "Piravam",
        "value": "Piravam"
    },
    {
        "name": "Ponmana",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.00798000",
        "longitude": "76.52023000",
        "label": "Ponmana",
        "value": "Ponmana"
    },
    {
        "name": "Ponnani",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.76695000",
        "longitude": "75.92523000",
        "label": "Ponnani",
        "value": "Ponnani"
    },
    {
        "name": "Punalur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.01956000",
        "longitude": "76.92261000",
        "label": "Punalur",
        "value": "Punalur"
    },
    {
        "name": "Ramamangalam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.93333000",
        "longitude": "76.50000000",
        "label": "Ramamangalam",
        "value": "Ramamangalam"
    },
    {
        "name": "Shertallai",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.68581000",
        "longitude": "76.33996000",
        "label": "Shertallai",
        "value": "Shertallai"
    },
    {
        "name": "Shōranur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.76181000",
        "longitude": "76.27078000",
        "label": "Shōranur",
        "value": "Shōranur"
    },
    {
        "name": "Talipparamba",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "12.04161000",
        "longitude": "75.35927000",
        "label": "Talipparamba",
        "value": "Talipparamba"
    },
    {
        "name": "Tellicherry",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.74811000",
        "longitude": "75.49290000",
        "label": "Tellicherry",
        "value": "Tellicherry"
    },
    {
        "name": "Thanniyam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.41667000",
        "longitude": "76.13333000",
        "label": "Thanniyam",
        "value": "Thanniyam"
    },
    {
        "name": "Thiruvananthapuram",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.60399000",
        "longitude": "76.98574000",
        "label": "Thiruvananthapuram",
        "value": "Thiruvananthapuram"
    },
    {
        "name": "Thrissur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.51667000",
        "longitude": "76.21667000",
        "label": "Thrissur",
        "value": "Thrissur"
    },
    {
        "name": "Thrissur District",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.52022000",
        "longitude": "76.22040000",
        "label": "Thrissur District",
        "value": "Thrissur District"
    },
    {
        "name": "Tirur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "10.91368000",
        "longitude": "75.92118000",
        "label": "Tirur",
        "value": "Tirur"
    },
    {
        "name": "Tiruvalla",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.38160000",
        "longitude": "76.57489000",
        "label": "Tiruvalla",
        "value": "Tiruvalla"
    },
    {
        "name": "Vaikam",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.74858000",
        "longitude": "76.39637000",
        "label": "Vaikam",
        "value": "Vaikam"
    },
    {
        "name": "Varkala",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.73330000",
        "longitude": "76.71670000",
        "label": "Varkala",
        "value": "Varkala"
    },
    {
        "name": "Vayalar",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "9.71158000",
        "longitude": "76.33888000",
        "label": "Vayalar",
        "value": "Vayalar"
    },
    {
        "name": "Vettur",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "8.71742000",
        "longitude": "76.72582000",
        "label": "Vettur",
        "value": "Vettur"
    },
    {
        "name": "Wayanad",
        "countryCode": "IN",
        "stateCode": "KL",
        "latitude": "11.60500000",
        "longitude": "76.08300000",
        "label": "Wayanad",
        "value": "Wayanad"
    }
,{
        "name": "Kargil",
        "countryCode": "IN",
        "stateCode": "LA",
        "latitude": "34.55765000",
        "longitude": "76.12622000",
        "label": "Kargil",
        "value": "Kargil"
    },
    {
        "name": "Leh",
        "countryCode": "IN",
        "stateCode": "LA",
        "latitude": "34.16504000",
        "longitude": "77.58402000",
        "label": "Leh",
        "value": "Leh"
    }
,{
        "name": "Kavaratti",
        "countryCode": "IN",
        "stateCode": "LD",
        "latitude": "10.56688000",
        "longitude": "72.64203000",
        "label": "Kavaratti",
        "value": "Kavaratti"
    },
    {
        "name": "Lakshadweep",
        "countryCode": "IN",
        "stateCode": "LD",
        "latitude": "11.27333000",
        "longitude": "74.04582000",
        "label": "Lakshadweep",
        "value": "Lakshadweep"
    }
,{
        "name": "Agar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.71177000",
        "longitude": "76.01571000",
        "label": "Agar",
        "value": "Agar"
    },
    {
        "name": "Ajaigarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.89879000",
        "longitude": "80.25921000",
        "label": "Ajaigarh",
        "value": "Ajaigarh"
    },
    {
        "name": "Akodia",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.38027000",
        "longitude": "76.59875000",
        "label": "Akodia",
        "value": "Akodia"
    },
    {
        "name": "Alampur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.02514000",
        "longitude": "78.79697000",
        "label": "Alampur",
        "value": "Alampur"
    },
    {
        "name": "Alirajpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.31384000",
        "longitude": "74.36452000",
        "label": "Alirajpur",
        "value": "Alirajpur"
    },
    {
        "name": "Alot",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.76336000",
        "longitude": "75.55662000",
        "label": "Alot",
        "value": "Alot"
    },
    {
        "name": "Amanganj",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.42664000",
        "longitude": "80.03579000",
        "label": "Amanganj",
        "value": "Amanganj"
    },
    {
        "name": "Amarkantak",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.67486000",
        "longitude": "81.75908000",
        "label": "Amarkantak",
        "value": "Amarkantak"
    },
    {
        "name": "Amarpatan",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.31371000",
        "longitude": "80.97703000",
        "label": "Amarpatan",
        "value": "Amarpatan"
    },
    {
        "name": "Amarwara",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.29780000",
        "longitude": "79.16943000",
        "label": "Amarwara",
        "value": "Amarwara"
    },
    {
        "name": "Ambah",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.70423000",
        "longitude": "78.22678000",
        "label": "Ambah",
        "value": "Ambah"
    },
    {
        "name": "Amla",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.92485000",
        "longitude": "78.12786000",
        "label": "Amla",
        "value": "Amla"
    },
    {
        "name": "Anjad",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.04171000",
        "longitude": "75.05519000",
        "label": "Anjad",
        "value": "Anjad"
    },
    {
        "name": "Antri",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.05804000",
        "longitude": "78.21027000",
        "label": "Antri",
        "value": "Antri"
    },
    {
        "name": "Anuppur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.05674000",
        "longitude": "81.68399000",
        "label": "Anuppur",
        "value": "Anuppur"
    },
    {
        "name": "Aron",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.38109000",
        "longitude": "77.41739000",
        "label": "Aron",
        "value": "Aron"
    },
    {
        "name": "Ashoknagar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.58000000",
        "longitude": "77.73000000",
        "label": "Ashoknagar",
        "value": "Ashoknagar"
    },
    {
        "name": "Ashta",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.01754000",
        "longitude": "76.72208000",
        "label": "Ashta",
        "value": "Ashta"
    },
    {
        "name": "Babai",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.70256000",
        "longitude": "77.93494000",
        "label": "Babai",
        "value": "Babai"
    },
    {
        "name": "Badarwas",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.97516000",
        "longitude": "77.56490000",
        "label": "Badarwas",
        "value": "Badarwas"
    },
    {
        "name": "Badnawar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.02181000",
        "longitude": "75.23268000",
        "label": "Badnawar",
        "value": "Badnawar"
    },
    {
        "name": "Bag",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.35905000",
        "longitude": "74.79052000",
        "label": "Bag",
        "value": "Bag"
    },
    {
        "name": "Bagli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.64124000",
        "longitude": "76.34877000",
        "label": "Bagli",
        "value": "Bagli"
    },
    {
        "name": "Baihar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.10133000",
        "longitude": "80.54967000",
        "label": "Baihar",
        "value": "Baihar"
    },
    {
        "name": "Baikunthpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.72768000",
        "longitude": "81.40975000",
        "label": "Baikunthpur",
        "value": "Baikunthpur"
    },
    {
        "name": "Bakshwaho",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.25106000",
        "longitude": "79.28618000",
        "label": "Bakshwaho",
        "value": "Bakshwaho"
    },
    {
        "name": "Balaghat",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.96667000",
        "longitude": "80.33333000",
        "label": "Balaghat",
        "value": "Balaghat"
    },
    {
        "name": "Baldeogarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.75619000",
        "longitude": "79.06715000",
        "label": "Baldeogarh",
        "value": "Baldeogarh"
    },
    {
        "name": "Bamna",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.09454000",
        "longitude": "74.76164000",
        "label": "Bamna",
        "value": "Bamna"
    },
    {
        "name": "Bamor Kalan",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.89298000",
        "longitude": "78.15105000",
        "label": "Bamor Kalan",
        "value": "Bamor Kalan"
    },
    {
        "name": "Bamora",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.05539000",
        "longitude": "78.08925000",
        "label": "Bamora",
        "value": "Bamora"
    },
    {
        "name": "Banda",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.04488000",
        "longitude": "78.96094000",
        "label": "Banda",
        "value": "Banda"
    },
    {
        "name": "Barela",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.09678000",
        "longitude": "80.05084000",
        "label": "Barela",
        "value": "Barela"
    },
    {
        "name": "Barghat",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.03065000",
        "longitude": "79.73280000",
        "label": "Barghat",
        "value": "Barghat"
    },
    {
        "name": "Bargi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.99138000",
        "longitude": "79.87550000",
        "label": "Bargi",
        "value": "Bargi"
    },
    {
        "name": "Barhi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.90326000",
        "longitude": "80.81516000",
        "label": "Barhi",
        "value": "Barhi"
    },
    {
        "name": "Barwani",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.02485000",
        "longitude": "74.91805000",
        "label": "Barwani",
        "value": "Barwani"
    },
    {
        "name": "Basoda",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.85153000",
        "longitude": "77.93652000",
        "label": "Basoda",
        "value": "Basoda"
    },
    {
        "name": "Begamganj",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.59917000",
        "longitude": "78.34064000",
        "label": "Begamganj",
        "value": "Begamganj"
    },
    {
        "name": "Beohari",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.02423000",
        "longitude": "81.37831000",
        "label": "Beohari",
        "value": "Beohari"
    },
    {
        "name": "Berasia",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.63134000",
        "longitude": "77.43351000",
        "label": "Berasia",
        "value": "Berasia"
    },
    {
        "name": "Betma",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.68653000",
        "longitude": "75.61456000",
        "label": "Betma",
        "value": "Betma"
    },
    {
        "name": "Betul",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.83333000",
        "longitude": "77.83333000",
        "label": "Betul",
        "value": "Betul"
    },
    {
        "name": "Betul Bazar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.85572000",
        "longitude": "77.92913000",
        "label": "Betul Bazar",
        "value": "Betul Bazar"
    },
    {
        "name": "Bhabhra",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.53048000",
        "longitude": "74.32846000",
        "label": "Bhabhra",
        "value": "Bhabhra"
    },
    {
        "name": "Bhainsdehi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.64491000",
        "longitude": "77.63023000",
        "label": "Bhainsdehi",
        "value": "Bhainsdehi"
    },
    {
        "name": "Bhander",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.73581000",
        "longitude": "78.74555000",
        "label": "Bhander",
        "value": "Bhander"
    },
    {
        "name": "Bhanpura",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.51300000",
        "longitude": "75.74690000",
        "label": "Bhanpura",
        "value": "Bhanpura"
    },
    {
        "name": "Bhawaniganj",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.41582000",
        "longitude": "75.83552000",
        "label": "Bhawaniganj",
        "value": "Bhawaniganj"
    },
    {
        "name": "Bhikangaon",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.86764000",
        "longitude": "75.96391000",
        "label": "Bhikangaon",
        "value": "Bhikangaon"
    },
    {
        "name": "Bhind",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.50000000",
        "longitude": "78.75000000",
        "label": "Bhind",
        "value": "Bhind"
    },
    {
        "name": "Bhitarwar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.79216000",
        "longitude": "78.11085000",
        "label": "Bhitarwar",
        "value": "Bhitarwar"
    },
    {
        "name": "Bhopal",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.25469000",
        "longitude": "77.40289000",
        "label": "Bhopal",
        "value": "Bhopal"
    },
    {
        "name": "Biaora",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.92050000",
        "longitude": "76.91074000",
        "label": "Biaora",
        "value": "Biaora"
    },
    {
        "name": "Bijawar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.62351000",
        "longitude": "79.48994000",
        "label": "Bijawar",
        "value": "Bijawar"
    },
    {
        "name": "Bijrauni",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.93296000",
        "longitude": "77.64352000",
        "label": "Bijrauni",
        "value": "Bijrauni"
    },
    {
        "name": "Bodri",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.16524000",
        "longitude": "81.43262000",
        "label": "Bodri",
        "value": "Bodri"
    },
    {
        "name": "Burhanpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.31000000",
        "longitude": "76.23000000",
        "label": "Burhanpur",
        "value": "Burhanpur"
    },
    {
        "name": "Burhar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.21494000",
        "longitude": "81.53204000",
        "label": "Burhar",
        "value": "Burhar"
    },
    {
        "name": "Chanderi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.71312000",
        "longitude": "78.13809000",
        "label": "Chanderi",
        "value": "Chanderi"
    },
    {
        "name": "Chandia",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.65647000",
        "longitude": "80.70911000",
        "label": "Chandia",
        "value": "Chandia"
    },
    {
        "name": "Chandla",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.07148000",
        "longitude": "80.19294000",
        "label": "Chandla",
        "value": "Chandla"
    },
    {
        "name": "Chhatarpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.75000000",
        "longitude": "79.75000000",
        "label": "Chhatarpur",
        "value": "Chhatarpur"
    },
    {
        "name": "Chhindwara",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.05697000",
        "longitude": "78.93958000",
        "label": "Chhindwara",
        "value": "Chhindwara"
    },
    {
        "name": "Chichli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.83363000",
        "longitude": "78.82611000",
        "label": "Chichli",
        "value": "Chichli"
    },
    {
        "name": "Chorhat",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.42743000",
        "longitude": "81.66948000",
        "label": "Chorhat",
        "value": "Chorhat"
    },
    {
        "name": "Daboh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.00239000",
        "longitude": "78.87658000",
        "label": "Daboh",
        "value": "Daboh"
    },
    {
        "name": "Dabra",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.88572000",
        "longitude": "78.33221000",
        "label": "Dabra",
        "value": "Dabra"
    },
    {
        "name": "Damoh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.75000000",
        "longitude": "79.58333000",
        "label": "Damoh",
        "value": "Damoh"
    },
    {
        "name": "Datia",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.75000000",
        "longitude": "78.50000000",
        "label": "Datia",
        "value": "Datia"
    },
    {
        "name": "Deori Khas",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.39017000",
        "longitude": "79.01630000",
        "label": "Deori Khas",
        "value": "Deori Khas"
    },
    {
        "name": "Depalpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.85095000",
        "longitude": "75.54224000",
        "label": "Depalpur",
        "value": "Depalpur"
    },
    {
        "name": "Dewas",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.00000000",
        "longitude": "76.16667000",
        "label": "Dewas",
        "value": "Dewas"
    },
    {
        "name": "Dhamnod",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.20928000",
        "longitude": "75.47057000",
        "label": "Dhamnod",
        "value": "Dhamnod"
    },
    {
        "name": "Dhana",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.74697000",
        "longitude": "78.86234000",
        "label": "Dhana",
        "value": "Dhana"
    },
    {
        "name": "Dhar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.59373000",
        "longitude": "75.29774000",
        "label": "Dhar",
        "value": "Dhar"
    },
    {
        "name": "Dharampuri",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.14951000",
        "longitude": "75.34439000",
        "label": "Dharampuri",
        "value": "Dharampuri"
    },
    {
        "name": "Dindori",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.80000000",
        "longitude": "81.10000000",
        "label": "Dindori",
        "value": "Dindori"
    },
    {
        "name": "Etawa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.18351000",
        "longitude": "78.20289000",
        "label": "Etawa",
        "value": "Etawa"
    },
    {
        "name": "Gadarwara",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.92350000",
        "longitude": "78.78490000",
        "label": "Gadarwara",
        "value": "Gadarwara"
    },
    {
        "name": "Garha Brahman",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.86873000",
        "longitude": "77.35731000",
        "label": "Garha Brahman",
        "value": "Garha Brahman"
    },
    {
        "name": "Garhakota",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.77910000",
        "longitude": "79.14321000",
        "label": "Garhakota",
        "value": "Garhakota"
    },
    {
        "name": "Gautampura",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.98664000",
        "longitude": "75.51921000",
        "label": "Gautampura",
        "value": "Gautampura"
    },
    {
        "name": "Ghansor",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.65976000",
        "longitude": "79.95013000",
        "label": "Ghansor",
        "value": "Ghansor"
    },
    {
        "name": "Gogapur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.55746000",
        "longitude": "75.51665000",
        "label": "Gogapur",
        "value": "Gogapur"
    },
    {
        "name": "Gohadi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.43278000",
        "longitude": "78.44205000",
        "label": "Gohadi",
        "value": "Gohadi"
    },
    {
        "name": "Govindgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.37845000",
        "longitude": "81.29644000",
        "label": "Govindgarh",
        "value": "Govindgarh"
    },
    {
        "name": "Guna",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.55464000",
        "longitude": "77.20082000",
        "label": "Guna",
        "value": "Guna"
    },
    {
        "name": "Gurh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.50265000",
        "longitude": "81.50037000",
        "label": "Gurh",
        "value": "Gurh"
    },
    {
        "name": "Gwalior",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.22983000",
        "longitude": "78.17337000",
        "label": "Gwalior",
        "value": "Gwalior"
    },
    {
        "name": "Harda",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.23406000",
        "longitude": "76.96431000",
        "label": "Harda",
        "value": "Harda"
    },
    {
        "name": "Harda Khas",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.34414000",
        "longitude": "77.09536000",
        "label": "Harda Khas",
        "value": "Harda Khas"
    },
    {
        "name": "Harpalpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.28773000",
        "longitude": "79.33279000",
        "label": "Harpalpur",
        "value": "Harpalpur"
    },
    {
        "name": "Harrai",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.61428000",
        "longitude": "79.22207000",
        "label": "Harrai",
        "value": "Harrai"
    },
    {
        "name": "Harsud",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.09947000",
        "longitude": "76.73423000",
        "label": "Harsud",
        "value": "Harsud"
    },
    {
        "name": "Hatod",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.79378000",
        "longitude": "75.73928000",
        "label": "Hatod",
        "value": "Hatod"
    },
    {
        "name": "Hatta",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.13406000",
        "longitude": "79.60119000",
        "label": "Hatta",
        "value": "Hatta"
    },
    {
        "name": "Hindoria",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.90345000",
        "longitude": "79.56861000",
        "label": "Hindoria",
        "value": "Hindoria"
    },
    {
        "name": "Hoshangabad",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.58827000",
        "longitude": "77.98887000",
        "label": "Hoshangabad",
        "value": "Hoshangabad"
    },
    {
        "name": "Iawar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.00943000",
        "longitude": "76.50070000",
        "label": "Iawar",
        "value": "Iawar"
    },
    {
        "name": "Ichhawar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.02816000",
        "longitude": "77.01729000",
        "label": "Ichhawar",
        "value": "Ichhawar"
    },
    {
        "name": "Iklehra",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.14667000",
        "longitude": "76.39044000",
        "label": "Iklehra",
        "value": "Iklehra"
    },
    {
        "name": "Indore",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.66667000",
        "longitude": "75.75000000",
        "label": "Indore",
        "value": "Indore"
    },
    {
        "name": "Isagarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.83906000",
        "longitude": "77.88274000",
        "label": "Isagarh",
        "value": "Isagarh"
    },
    {
        "name": "Itarsi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.61477000",
        "longitude": "77.76222000",
        "label": "Itarsi",
        "value": "Itarsi"
    },
    {
        "name": "Jabalpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.16000000",
        "longitude": "79.95000000",
        "label": "Jabalpur",
        "value": "Jabalpur"
    },
    {
        "name": "Jaisinghnagar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.68582000",
        "longitude": "81.39085000",
        "label": "Jaisinghnagar",
        "value": "Jaisinghnagar"
    },
    {
        "name": "Jaithari",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.20856000",
        "longitude": "78.61487000",
        "label": "Jaithari",
        "value": "Jaithari"
    },
    {
        "name": "Jamai",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.19644000",
        "longitude": "78.59191000",
        "label": "Jamai",
        "value": "Jamai"
    },
    {
        "name": "Jaora",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.63783000",
        "longitude": "75.12711000",
        "label": "Jaora",
        "value": "Jaora"
    },
    {
        "name": "Jatara",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.00964000",
        "longitude": "79.04869000",
        "label": "Jatara",
        "value": "Jatara"
    },
    {
        "name": "Jawad",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.59916000",
        "longitude": "74.86261000",
        "label": "Jawad",
        "value": "Jawad"
    },
    {
        "name": "Jhabua",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.76772000",
        "longitude": "74.59087000",
        "label": "Jhabua",
        "value": "Jhabua"
    },
    {
        "name": "Jiran",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.30871000",
        "longitude": "74.89087000",
        "label": "Jiran",
        "value": "Jiran"
    },
    {
        "name": "Jobat",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.41599000",
        "longitude": "74.56828000",
        "label": "Jobat",
        "value": "Jobat"
    },
    {
        "name": "Kailaras",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.30498000",
        "longitude": "77.61600000",
        "label": "Kailaras",
        "value": "Kailaras"
    },
    {
        "name": "Kaimori",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.38465000",
        "longitude": "79.74420000",
        "label": "Kaimori",
        "value": "Kaimori"
    },
    {
        "name": "Kannod",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.66764000",
        "longitude": "76.74286000",
        "label": "Kannod",
        "value": "Kannod"
    },
    {
        "name": "Kareli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.91533000",
        "longitude": "79.06378000",
        "label": "Kareli",
        "value": "Kareli"
    },
    {
        "name": "Karera",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.45815000",
        "longitude": "78.13583000",
        "label": "Karera",
        "value": "Karera"
    },
    {
        "name": "Karrapur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.94891000",
        "longitude": "78.86584000",
        "label": "Karrapur",
        "value": "Karrapur"
    },
    {
        "name": "Kasrawad",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.12745000",
        "longitude": "75.61101000",
        "label": "Kasrawad",
        "value": "Kasrawad"
    },
    {
        "name": "Katangi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.77369000",
        "longitude": "79.80513000",
        "label": "Katangi",
        "value": "Katangi"
    },
    {
        "name": "Katni",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.83555000",
        "longitude": "80.39417000",
        "label": "Katni",
        "value": "Katni"
    },
    {
        "name": "Khachrod",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.42322000",
        "longitude": "75.28185000",
        "label": "Khachrod",
        "value": "Khachrod"
    },
    {
        "name": "Khailar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.34127000",
        "longitude": "78.53133000",
        "label": "Khailar",
        "value": "Khailar"
    },
    {
        "name": "Khajuraho Group of Monuments",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.84809000",
        "longitude": "79.93351000",
        "label": "Khajuraho Group of Monuments",
        "value": "Khajuraho Group of Monuments"
    },
    {
        "name": "Khamaria",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.22558000",
        "longitude": "79.88007000",
        "label": "Khamaria",
        "value": "Khamaria"
    },
    {
        "name": "Khandwa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.82427000",
        "longitude": "76.35086000",
        "label": "Khandwa",
        "value": "Khandwa"
    },
    {
        "name": "Khandwa district",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.75000000",
        "longitude": "76.58333000",
        "label": "Khandwa district",
        "value": "Khandwa district"
    },
    {
        "name": "Khargapur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.82300000",
        "longitude": "79.14400000",
        "label": "Khargapur",
        "value": "Khargapur"
    },
    {
        "name": "Khargone",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.82306000",
        "longitude": "75.61028000",
        "label": "Khargone",
        "value": "Khargone"
    },
    {
        "name": "Khategaon",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.59573000",
        "longitude": "76.91330000",
        "label": "Khategaon",
        "value": "Khategaon"
    },
    {
        "name": "Khilchipur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.03943000",
        "longitude": "76.57800000",
        "label": "Khilchipur",
        "value": "Khilchipur"
    },
    {
        "name": "Khirkiyan",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.16732000",
        "longitude": "76.86137000",
        "label": "Khirkiyan",
        "value": "Khirkiyan"
    },
    {
        "name": "Khujner",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.78597000",
        "longitude": "76.61773000",
        "label": "Khujner",
        "value": "Khujner"
    },
    {
        "name": "Khurai",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.04372000",
        "longitude": "78.33014000",
        "label": "Khurai",
        "value": "Khurai"
    },
    {
        "name": "Kolaras",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.21928000",
        "longitude": "77.61167000",
        "label": "Kolaras",
        "value": "Kolaras"
    },
    {
        "name": "Korwai",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.11774000",
        "longitude": "78.04007000",
        "label": "Korwai",
        "value": "Korwai"
    },
    {
        "name": "Kotar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.69802000",
        "longitude": "80.98073000",
        "label": "Kotar",
        "value": "Kotar"
    },
    {
        "name": "Kothi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.75260000",
        "longitude": "80.77751000",
        "label": "Kothi",
        "value": "Kothi"
    },
    {
        "name": "Kotma",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.20383000",
        "longitude": "81.97904000",
        "label": "Kotma",
        "value": "Kotma"
    },
    {
        "name": "Kotwa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.03080000",
        "longitude": "81.31908000",
        "label": "Kotwa",
        "value": "Kotwa"
    },
    {
        "name": "Kukshi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.20677000",
        "longitude": "74.75788000",
        "label": "Kukshi",
        "value": "Kukshi"
    },
    {
        "name": "Kumbhraj",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.37338000",
        "longitude": "77.04841000",
        "label": "Kumbhraj",
        "value": "Kumbhraj"
    },
    {
        "name": "Lahar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.19401000",
        "longitude": "78.94137000",
        "label": "Lahar",
        "value": "Lahar"
    },
    {
        "name": "Lakhnadon",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.60049000",
        "longitude": "79.60094000",
        "label": "Lakhnadon",
        "value": "Lakhnadon"
    },
    {
        "name": "Leteri",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.05979000",
        "longitude": "77.40858000",
        "label": "Leteri",
        "value": "Leteri"
    },
    {
        "name": "Lodhikheda",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.58235000",
        "longitude": "78.85911000",
        "label": "Lodhikheda",
        "value": "Lodhikheda"
    },
    {
        "name": "Machalpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.12767000",
        "longitude": "76.31672000",
        "label": "Machalpur",
        "value": "Machalpur"
    },
    {
        "name": "Madhogarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.56401000",
        "longitude": "80.91126000",
        "label": "Madhogarh",
        "value": "Madhogarh"
    },
    {
        "name": "Maheshwar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.17592000",
        "longitude": "75.58715000",
        "label": "Maheshwar",
        "value": "Maheshwar"
    },
    {
        "name": "Mahgawan",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.49471000",
        "longitude": "78.61593000",
        "label": "Mahgawan",
        "value": "Mahgawan"
    },
    {
        "name": "Maihar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.26594000",
        "longitude": "80.76063000",
        "label": "Maihar",
        "value": "Maihar"
    },
    {
        "name": "Majholi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.50114000",
        "longitude": "79.92396000",
        "label": "Majholi",
        "value": "Majholi"
    },
    {
        "name": "Maksi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.25999000",
        "longitude": "76.14567000",
        "label": "Maksi",
        "value": "Maksi"
    },
    {
        "name": "Malhargarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.28286000",
        "longitude": "74.99024000",
        "label": "Malhargarh",
        "value": "Malhargarh"
    },
    {
        "name": "Manasa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.47764000",
        "longitude": "75.14095000",
        "label": "Manasa",
        "value": "Manasa"
    },
    {
        "name": "Manawar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.23566000",
        "longitude": "75.08917000",
        "label": "Manawar",
        "value": "Manawar"
    },
    {
        "name": "Mandideep",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.08166000",
        "longitude": "77.53328000",
        "label": "Mandideep",
        "value": "Mandideep"
    },
    {
        "name": "Mandla",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.64041000",
        "longitude": "80.51344000",
        "label": "Mandla",
        "value": "Mandla"
    },
    {
        "name": "Mandleshwar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.17598000",
        "longitude": "75.65995000",
        "label": "Mandleshwar",
        "value": "Mandleshwar"
    },
    {
        "name": "Mandsaur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.33333000",
        "longitude": "75.25000000",
        "label": "Mandsaur",
        "value": "Mandsaur"
    },
    {
        "name": "Mangawan",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.66754000",
        "longitude": "81.54644000",
        "label": "Mangawan",
        "value": "Mangawan"
    },
    {
        "name": "Manpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.43151000",
        "longitude": "75.62107000",
        "label": "Manpur",
        "value": "Manpur"
    },
    {
        "name": "Mau",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.26584000",
        "longitude": "78.67108000",
        "label": "Mau",
        "value": "Mau"
    },
    {
        "name": "Mauganj",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.66721000",
        "longitude": "81.87339000",
        "label": "Mauganj",
        "value": "Mauganj"
    },
    {
        "name": "Mihona",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.28373000",
        "longitude": "78.98048000",
        "label": "Mihona",
        "value": "Mihona"
    },
    {
        "name": "Mohgaon",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.63941000",
        "longitude": "78.73638000",
        "label": "Mohgaon",
        "value": "Mohgaon"
    },
    {
        "name": "Morar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.22640000",
        "longitude": "78.22482000",
        "label": "Morar",
        "value": "Morar"
    },
    {
        "name": "Morena",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.16667000",
        "longitude": "77.50000000",
        "label": "Morena",
        "value": "Morena"
    },
    {
        "name": "Multai",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.77463000",
        "longitude": "78.25756000",
        "label": "Multai",
        "value": "Multai"
    },
    {
        "name": "Mundi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.06693000",
        "longitude": "76.49326000",
        "label": "Mundi",
        "value": "Mundi"
    },
    {
        "name": "Mungaoli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.40837000",
        "longitude": "78.09588000",
        "label": "Mungaoli",
        "value": "Mungaoli"
    },
    {
        "name": "Murwara",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.83776000",
        "longitude": "80.39405000",
        "label": "Murwara",
        "value": "Murwara"
    },
    {
        "name": "Nagda",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.45834000",
        "longitude": "75.41759000",
        "label": "Nagda",
        "value": "Nagda"
    },
    {
        "name": "Nagod",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.56924000",
        "longitude": "80.58809000",
        "label": "Nagod",
        "value": "Nagod"
    },
    {
        "name": "Naigarhi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.78686000",
        "longitude": "81.77868000",
        "label": "Naigarhi",
        "value": "Naigarhi"
    },
    {
        "name": "Nainpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.42996000",
        "longitude": "80.10561000",
        "label": "Nainpur",
        "value": "Nainpur"
    },
    {
        "name": "Namli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.46115000",
        "longitude": "75.06036000",
        "label": "Namli",
        "value": "Namli"
    },
    {
        "name": "Naraini",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.19033000",
        "longitude": "80.47500000",
        "label": "Naraini",
        "value": "Naraini"
    },
    {
        "name": "Narayangarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.27083000",
        "longitude": "75.05007000",
        "label": "Narayangarh",
        "value": "Narayangarh"
    },
    {
        "name": "Narsimhapur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.91667000",
        "longitude": "79.16667000",
        "label": "Narsimhapur",
        "value": "Narsimhapur"
    },
    {
        "name": "Narsinghgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.70758000",
        "longitude": "77.09319000",
        "label": "Narsinghgarh",
        "value": "Narsinghgarh"
    },
    {
        "name": "Narwar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.64390000",
        "longitude": "77.91290000",
        "label": "Narwar",
        "value": "Narwar"
    },
    {
        "name": "Nasrullahganj",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.68370000",
        "longitude": "77.27069000",
        "label": "Nasrullahganj",
        "value": "Nasrullahganj"
    },
    {
        "name": "Neemuch",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.47000000",
        "longitude": "74.87000000",
        "label": "Neemuch",
        "value": "Neemuch"
    },
    {
        "name": "Nepanagar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.45380000",
        "longitude": "76.39335000",
        "label": "Nepanagar",
        "value": "Nepanagar"
    },
    {
        "name": "Orchha",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.35192000",
        "longitude": "78.64033000",
        "label": "Orchha",
        "value": "Orchha"
    },
    {
        "name": "Pachmarhi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.46791000",
        "longitude": "78.43312000",
        "label": "Pachmarhi",
        "value": "Pachmarhi"
    },
    {
        "name": "Palera",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.02013000",
        "longitude": "79.22818000",
        "label": "Palera",
        "value": "Palera"
    },
    {
        "name": "Pali",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.36453000",
        "longitude": "81.04374000",
        "label": "Pali",
        "value": "Pali"
    },
    {
        "name": "Panagar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.28539000",
        "longitude": "79.99509000",
        "label": "Panagar",
        "value": "Panagar"
    },
    {
        "name": "Panara",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.20568000",
        "longitude": "78.55093000",
        "label": "Panara",
        "value": "Panara"
    },
    {
        "name": "Pandhana",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.69816000",
        "longitude": "76.22487000",
        "label": "Pandhana",
        "value": "Pandhana"
    },
    {
        "name": "Pandhurna",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.59556000",
        "longitude": "78.52628000",
        "label": "Pandhurna",
        "value": "Pandhurna"
    },
    {
        "name": "Panna",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.50000000",
        "longitude": "80.25000000",
        "label": "Panna",
        "value": "Panna"
    },
    {
        "name": "Pansemal",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.65981000",
        "longitude": "74.69937000",
        "label": "Pansemal",
        "value": "Pansemal"
    },
    {
        "name": "Parasia",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.19130000",
        "longitude": "78.75904000",
        "label": "Parasia",
        "value": "Parasia"
    },
    {
        "name": "Patan",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.28636000",
        "longitude": "79.68962000",
        "label": "Patan",
        "value": "Patan"
    },
    {
        "name": "Patharia",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.89921000",
        "longitude": "79.19393000",
        "label": "Patharia",
        "value": "Patharia"
    },
    {
        "name": "Pawai",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.26635000",
        "longitude": "80.16196000",
        "label": "Pawai",
        "value": "Pawai"
    },
    {
        "name": "Petlawad",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.01102000",
        "longitude": "74.79772000",
        "label": "Petlawad",
        "value": "Petlawad"
    },
    {
        "name": "Piploda",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.35000000",
        "longitude": "75.43333000",
        "label": "Piploda",
        "value": "Piploda"
    },
    {
        "name": "Pithampur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.60197000",
        "longitude": "75.69649000",
        "label": "Pithampur",
        "value": "Pithampur"
    },
    {
        "name": "Porsa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.67444000",
        "longitude": "78.37081000",
        "label": "Porsa",
        "value": "Porsa"
    },
    {
        "name": "Punasa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.23507000",
        "longitude": "76.39335000",
        "label": "Punasa",
        "value": "Punasa"
    },
    {
        "name": "Raghogarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.44318000",
        "longitude": "77.19768000",
        "label": "Raghogarh",
        "value": "Raghogarh"
    },
    {
        "name": "Rahatgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.78968000",
        "longitude": "78.39473000",
        "label": "Rahatgarh",
        "value": "Rahatgarh"
    },
    {
        "name": "Raisen",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.25000000",
        "longitude": "78.08333000",
        "label": "Raisen",
        "value": "Raisen"
    },
    {
        "name": "Rajgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.67821000",
        "longitude": "74.94483000",
        "label": "Rajgarh",
        "value": "Rajgarh"
    },
    {
        "name": "Rajnagar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.88929000",
        "longitude": "79.91178000",
        "label": "Rajnagar",
        "value": "Rajnagar"
    },
    {
        "name": "Rajpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.30393000",
        "longitude": "74.35568000",
        "label": "Rajpur",
        "value": "Rajpur"
    },
    {
        "name": "Rampura",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.46700000",
        "longitude": "75.43996000",
        "label": "Rampura",
        "value": "Rampura"
    },
    {
        "name": "Ranapur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.64704000",
        "longitude": "74.52118000",
        "label": "Ranapur",
        "value": "Ranapur"
    },
    {
        "name": "Ratangarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.81667000",
        "longitude": "75.11667000",
        "label": "Ratangarh",
        "value": "Ratangarh"
    },
    {
        "name": "Ratlam",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.33033000",
        "longitude": "75.04032000",
        "label": "Ratlam",
        "value": "Ratlam"
    },
    {
        "name": "Rehli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.63722000",
        "longitude": "79.06275000",
        "label": "Rehli",
        "value": "Rehli"
    },
    {
        "name": "Rehti",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.73781000",
        "longitude": "77.43399000",
        "label": "Rehti",
        "value": "Rehti"
    },
    {
        "name": "Rewa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.53256000",
        "longitude": "81.29234000",
        "label": "Rewa",
        "value": "Rewa"
    },
    {
        "name": "Sabalgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.24918000",
        "longitude": "77.40786000",
        "label": "Sabalgarh",
        "value": "Sabalgarh"
    },
    {
        "name": "Sagar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.75000000",
        "longitude": "78.75000000",
        "label": "Sagar",
        "value": "Sagar"
    },
    {
        "name": "Sailana",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.46219000",
        "longitude": "74.92318000",
        "label": "Sailana",
        "value": "Sailana"
    },
    {
        "name": "Sanawad",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.17391000",
        "longitude": "76.06993000",
        "label": "Sanawad",
        "value": "Sanawad"
    },
    {
        "name": "Sanchi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.48646000",
        "longitude": "77.73780000",
        "label": "Sanchi",
        "value": "Sanchi"
    },
    {
        "name": "Sanwer",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.97415000",
        "longitude": "75.82710000",
        "label": "Sanwer",
        "value": "Sanwer"
    },
    {
        "name": "Sarangpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.56651000",
        "longitude": "76.47306000",
        "label": "Sarangpur",
        "value": "Sarangpur"
    },
    {
        "name": "Satna",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.50000000",
        "longitude": "81.00000000",
        "label": "Satna",
        "value": "Satna"
    },
    {
        "name": "Satwas",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.53628000",
        "longitude": "76.68452000",
        "label": "Satwas",
        "value": "Satwas"
    },
    {
        "name": "Saugor",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.83877000",
        "longitude": "78.73874000",
        "label": "Saugor",
        "value": "Saugor"
    },
    {
        "name": "Sausar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.65576000",
        "longitude": "78.79669000",
        "label": "Sausar",
        "value": "Sausar"
    },
    {
        "name": "Sehore",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.20000000",
        "longitude": "77.08333000",
        "label": "Sehore",
        "value": "Sehore"
    },
    {
        "name": "Sendhwa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.68562000",
        "longitude": "75.09622000",
        "label": "Sendhwa",
        "value": "Sendhwa"
    },
    {
        "name": "Seondha",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.15422000",
        "longitude": "78.78120000",
        "label": "Seondha",
        "value": "Seondha"
    },
    {
        "name": "Seoni",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.08503000",
        "longitude": "79.55037000",
        "label": "Seoni",
        "value": "Seoni"
    },
    {
        "name": "Seoni Malwa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.45046000",
        "longitude": "77.46650000",
        "label": "Seoni Malwa",
        "value": "Seoni Malwa"
    },
    {
        "name": "Shahdol",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.50000000",
        "longitude": "81.50000000",
        "label": "Shahdol",
        "value": "Shahdol"
    },
    {
        "name": "Shahgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.31365000",
        "longitude": "79.11806000",
        "label": "Shahgarh",
        "value": "Shahgarh"
    },
    {
        "name": "Shahpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.23742000",
        "longitude": "76.22558000",
        "label": "Shahpur",
        "value": "Shahpur"
    },
    {
        "name": "Shahpura",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.13663000",
        "longitude": "79.66402000",
        "label": "Shahpura",
        "value": "Shahpura"
    },
    {
        "name": "Shajapur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.50000000",
        "longitude": "76.25000000",
        "label": "Shajapur",
        "value": "Shajapur"
    },
    {
        "name": "Shamgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.18817000",
        "longitude": "75.63903000",
        "label": "Shamgarh",
        "value": "Shamgarh"
    },
    {
        "name": "Sheopur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.80000000",
        "longitude": "77.00000000",
        "label": "Sheopur",
        "value": "Sheopur"
    },
    {
        "name": "Shivpuri",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.42378000",
        "longitude": "77.66223000",
        "label": "Shivpuri",
        "value": "Shivpuri"
    },
    {
        "name": "Shujalpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.40673000",
        "longitude": "76.70980000",
        "label": "Shujalpur",
        "value": "Shujalpur"
    },
    {
        "name": "Sidhi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.25000000",
        "longitude": "82.00000000",
        "label": "Sidhi",
        "value": "Sidhi"
    },
    {
        "name": "Sihora",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.48710000",
        "longitude": "80.10404000",
        "label": "Sihora",
        "value": "Sihora"
    },
    {
        "name": "Simaria",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.79497000",
        "longitude": "81.15200000",
        "label": "Simaria",
        "value": "Simaria"
    },
    {
        "name": "Singoli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.96667000",
        "longitude": "75.30000000",
        "label": "Singoli",
        "value": "Singoli"
    },
    {
        "name": "Singrauli",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.19973000",
        "longitude": "82.67535000",
        "label": "Singrauli",
        "value": "Singrauli"
    },
    {
        "name": "Sirmaur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.83648000",
        "longitude": "81.36448000",
        "label": "Sirmaur",
        "value": "Sirmaur"
    },
    {
        "name": "Sironj",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.10313000",
        "longitude": "77.69055000",
        "label": "Sironj",
        "value": "Sironj"
    },
    {
        "name": "Sitamau",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.01473000",
        "longitude": "75.35324000",
        "label": "Sitamau",
        "value": "Sitamau"
    },
    {
        "name": "Sohagi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.98181000",
        "longitude": "81.69558000",
        "label": "Sohagi",
        "value": "Sohagi"
    },
    {
        "name": "Sohagpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "22.70055000",
        "longitude": "78.19522000",
        "label": "Sohagpur",
        "value": "Sohagpur"
    },
    {
        "name": "Sultanpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.13812000",
        "longitude": "77.93404000",
        "label": "Sultanpur",
        "value": "Sultanpur"
    },
    {
        "name": "Susner",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.94667000",
        "longitude": "76.08825000",
        "label": "Susner",
        "value": "Susner"
    },
    {
        "name": "Tal",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.71979000",
        "longitude": "75.38514000",
        "label": "Tal",
        "value": "Tal"
    },
    {
        "name": "Talen",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.56949000",
        "longitude": "76.72821000",
        "label": "Talen",
        "value": "Talen"
    },
    {
        "name": "Tarana",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.33383000",
        "longitude": "76.04253000",
        "label": "Tarana",
        "value": "Tarana"
    },
    {
        "name": "Tekanpur",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "25.99401000",
        "longitude": "78.28322000",
        "label": "Tekanpur",
        "value": "Tekanpur"
    },
    {
        "name": "Tendukheda",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.39620000",
        "longitude": "79.53947000",
        "label": "Tendukheda",
        "value": "Tendukheda"
    },
    {
        "name": "Teonthar",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.98207000",
        "longitude": "81.64194000",
        "label": "Teonthar",
        "value": "Teonthar"
    },
    {
        "name": "Thandla",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.00959000",
        "longitude": "74.57747000",
        "label": "Thandla",
        "value": "Thandla"
    },
    {
        "name": "Tikamgarh",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "24.74327000",
        "longitude": "78.83061000",
        "label": "Tikamgarh",
        "value": "Tikamgarh"
    },
    {
        "name": "Tirodi",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.68522000",
        "longitude": "79.71906000",
        "label": "Tirodi",
        "value": "Tirodi"
    },
    {
        "name": "Udaipura",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.07434000",
        "longitude": "78.51108000",
        "label": "Udaipura",
        "value": "Udaipura"
    },
    {
        "name": "Ujjain",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.41667000",
        "longitude": "75.50000000",
        "label": "Ujjain",
        "value": "Ujjain"
    },
    {
        "name": "Ukwa",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.97102000",
        "longitude": "80.46625000",
        "label": "Ukwa",
        "value": "Ukwa"
    },
    {
        "name": "Umaria",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.52473000",
        "longitude": "80.83716000",
        "label": "Umaria",
        "value": "Umaria"
    },
    {
        "name": "Umaria District",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.52874000",
        "longitude": "80.83054000",
        "label": "Umaria District",
        "value": "Umaria District"
    },
    {
        "name": "Umri",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "26.51056000",
        "longitude": "78.93667000",
        "label": "Umri",
        "value": "Umri"
    },
    {
        "name": "Unhel",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.33794000",
        "longitude": "75.55931000",
        "label": "Unhel",
        "value": "Unhel"
    },
    {
        "name": "Vidisha",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "23.91667000",
        "longitude": "78.00000000",
        "label": "Vidisha",
        "value": "Vidisha"
    },
    {
        "name": "Waraseoni",
        "countryCode": "IN",
        "stateCode": "MP",
        "latitude": "21.76184000",
        "longitude": "80.04301000",
        "label": "Waraseoni",
        "value": "Waraseoni"
    }
,{
        "name": "Achalpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.25665000",
        "longitude": "77.51006000",
        "label": "Achalpur",
        "value": "Achalpur"
    },
    {
        "name": "Adawad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.21666667",
        "longitude": "75.45000000",
        "label": "Adawad",
        "value": "Adawad"
    },
    {
        "name": "Agar Panchaitan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.17369200",
        "longitude": "72.98853300",
        "label": "Agar Panchaitan",
        "value": "Agar Panchaitan"
    },
    {
        "name": "Aheri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.41166667",
        "longitude": "80.00388889",
        "label": "Aheri",
        "value": "Aheri"
    },
    {
        "name": "Ahiri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.41386000",
        "longitude": "80.00359000",
        "label": "Ahiri",
        "value": "Ahiri"
    },
    {
        "name": "Ahmadnagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.09457000",
        "longitude": "74.73843000",
        "label": "Ahmadnagar",
        "value": "Ahmadnagar"
    },
    {
        "name": "Ahmadpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.70622000",
        "longitude": "76.93731000",
        "label": "Ahmadpur",
        "value": "Ahmadpur"
    },
    {
        "name": "Ahmednagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.08333333",
        "longitude": "74.73333333",
        "label": "Ahmednagar",
        "value": "Ahmednagar"
    },
    {
        "name": "Airoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.15096000",
        "longitude": "72.99625000",
        "label": "Airoli",
        "value": "Airoli"
    },
    {
        "name": "Ajra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.11601000",
        "longitude": "74.21097000",
        "label": "Ajra",
        "value": "Ajra"
    },
    {
        "name": "Akalkot",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.52532000",
        "longitude": "76.20611000",
        "label": "Akalkot",
        "value": "Akalkot"
    },
    {
        "name": "Akkalkot",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.51666667",
        "longitude": "76.20000000",
        "label": "Akkalkot",
        "value": "Akkalkot"
    },
    {
        "name": "Akluj",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.88333333",
        "longitude": "75.01666667",
        "label": "Akluj",
        "value": "Akluj"
    },
    {
        "name": "Akola",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.50000000",
        "longitude": "77.16667000",
        "label": "Akola",
        "value": "Akola"
    },
    {
        "name": "Akolner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98333333",
        "longitude": "74.66666667",
        "label": "Akolner",
        "value": "Akolner"
    },
    {
        "name": "Akot",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.09630000",
        "longitude": "77.05880000",
        "label": "Akot",
        "value": "Akot"
    },
    {
        "name": "Akrani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.82423611",
        "longitude": "74.21687222",
        "label": "Akrani",
        "value": "Akrani"
    },
    {
        "name": "Alandi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.67756000",
        "longitude": "73.89868000",
        "label": "Alandi",
        "value": "Alandi"
    },
    {
        "name": "Ale",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.17000000",
        "longitude": "74.12000000",
        "label": "Ale",
        "value": "Ale"
    },
    {
        "name": "Alibag",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.64813000",
        "longitude": "72.87579000",
        "label": "Alibag",
        "value": "Alibag"
    },
    {
        "name": "Alkuti",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.05000000",
        "longitude": "74.23000000",
        "label": "Alkuti",
        "value": "Alkuti"
    },
    {
        "name": "Allapalli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.43172000",
        "longitude": "80.06377000",
        "label": "Allapalli",
        "value": "Allapalli"
    },
    {
        "name": "Amalner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.03983000",
        "longitude": "75.05887000",
        "label": "Amalner",
        "value": "Amalner"
    },
    {
        "name": "Amarnath",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.20000000",
        "longitude": "73.16667000",
        "label": "Amarnath",
        "value": "Amarnath"
    },
    {
        "name": "Ambad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.61301000",
        "longitude": "75.78906000",
        "label": "Ambad",
        "value": "Ambad"
    },
    {
        "name": "Ambajogai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.73312000",
        "longitude": "76.38616000",
        "label": "Ambajogai",
        "value": "Ambajogai"
    },
    {
        "name": "Ambarnath",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.20900000",
        "longitude": "73.18600000",
        "label": "Ambarnath",
        "value": "Ambarnath"
    },
    {
        "name": "Ambegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.11666667",
        "longitude": "73.73333333",
        "label": "Ambegaon",
        "value": "Ambegaon"
    },
    {
        "name": "Amgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.65000000",
        "longitude": "79.95000000",
        "label": "Amgaon",
        "value": "Amgaon"
    },
    {
        "name": "Amravati",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.93333000",
        "longitude": "77.75000000",
        "label": "Amravati",
        "value": "Amravati"
    },
    {
        "name": "Amravati Division",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.93483000",
        "longitude": "77.75694000",
        "label": "Amravati Division",
        "value": "Amravati Division"
    },
    {
        "name": "Andheri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.11916667",
        "longitude": "72.84694444",
        "label": "Andheri",
        "value": "Andheri"
    },
    {
        "name": "Andura",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.88333333",
        "longitude": "76.86666667",
        "label": "Andura",
        "value": "Andura"
    },
    {
        "name": "Anjangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.16516000",
        "longitude": "77.30910000",
        "label": "Anjangaon",
        "value": "Anjangaon"
    },
    {
        "name": "Anjarle",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.85000000",
        "longitude": "73.09000000",
        "label": "Anjarle",
        "value": "Anjarle"
    },
    {
        "name": "Anshing",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.04090000",
        "longitude": "77.31501000",
        "label": "Anshing",
        "value": "Anshing"
    },
    {
        "name": "Arag",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.78000000",
        "longitude": "74.80000000",
        "label": "Arag",
        "value": "Arag"
    },
    {
        "name": "Arangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.02681000",
        "longitude": "74.71487000",
        "label": "Arangaon",
        "value": "Arangaon"
    },
    {
        "name": "Ardhapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.28333333",
        "longitude": "77.38333333",
        "label": "Ardhapur",
        "value": "Ardhapur"
    },
    {
        "name": "Argaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.77899900",
        "longitude": "73.64553000",
        "label": "Argaon",
        "value": "Argaon"
    },
    {
        "name": "Artist Village",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.03227000",
        "longitude": "73.04276000",
        "label": "Artist Village",
        "value": "Artist Village"
    },
    {
        "name": "Arvi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.99585000",
        "longitude": "78.22914000",
        "label": "Arvi",
        "value": "Arvi"
    },
    {
        "name": "Ashta",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.94943000",
        "longitude": "74.40936000",
        "label": "Ashta",
        "value": "Ashta"
    },
    {
        "name": "Ashti",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.37671000",
        "longitude": "76.22520000",
        "label": "Ashti",
        "value": "Ashti"
    },
    {
        "name": "Asoda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.03333333",
        "longitude": "75.60000000",
        "label": "Asoda",
        "value": "Asoda"
    },
    {
        "name": "Assaye",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.24512778",
        "longitude": "75.88739722",
        "label": "Assaye",
        "value": "Assaye"
    },
    {
        "name": "Astagaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.66666667",
        "longitude": "74.50000000",
        "label": "Astagaon",
        "value": "Astagaon"
    },
    {
        "name": "Aundh, Satara",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.54583333",
        "longitude": "74.37500000",
        "label": "Aundh, Satara",
        "value": "Aundh, Satara"
    },
    {
        "name": "Aurangabad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.88467000",
        "longitude": "75.33986000",
        "label": "Aurangabad",
        "value": "Aurangabad"
    },
    {
        "name": "Ausa",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.24728000",
        "longitude": "76.49930000",
        "label": "Ausa",
        "value": "Ausa"
    },
    {
        "name": "Badlapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.15516000",
        "longitude": "73.26553000",
        "label": "Badlapur",
        "value": "Badlapur"
    },
    {
        "name": "Badnapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.86666667",
        "longitude": "75.73333333",
        "label": "Badnapur",
        "value": "Badnapur"
    },
    {
        "name": "Badnera",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.85463056",
        "longitude": "77.72931111",
        "label": "Badnera",
        "value": "Badnera"
    },
    {
        "name": "Bagewadi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.29000000",
        "longitude": "74.60000000",
        "label": "Bagewadi",
        "value": "Bagewadi"
    },
    {
        "name": "Balapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.66612000",
        "longitude": "76.77386000",
        "label": "Balapur",
        "value": "Balapur"
    },
    {
        "name": "Balapur, Akola district",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.66612000",
        "longitude": "76.77386000",
        "label": "Balapur, Akola district",
        "value": "Balapur, Akola district"
    },
    {
        "name": "Ballalpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.84696000",
        "longitude": "79.34578000",
        "label": "Ballalpur",
        "value": "Ballalpur"
    },
    {
        "name": "Ballard Estate",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.95000000",
        "longitude": "72.84000000",
        "label": "Ballard Estate",
        "value": "Ballard Estate"
    },
    {
        "name": "Ballarpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.83333333",
        "longitude": "79.35000000",
        "label": "Ballarpur",
        "value": "Ballarpur"
    },
    {
        "name": "Banda, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "15.81273611",
        "longitude": "73.86132778",
        "label": "Banda, Maharashtra",
        "value": "Banda, Maharashtra"
    },
    {
        "name": "Bandra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.05444444",
        "longitude": "72.84055556",
        "label": "Bandra",
        "value": "Bandra"
    },
    {
        "name": "Baner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.56000000",
        "longitude": "73.79027778",
        "label": "Baner",
        "value": "Baner"
    },
    {
        "name": "Bankot",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.98333333",
        "longitude": "73.05000000",
        "label": "Bankot",
        "value": "Bankot"
    },
    {
        "name": "Baramati",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.15174000",
        "longitude": "74.57767000",
        "label": "Baramati",
        "value": "Baramati"
    },
    {
        "name": "Barsi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.23454000",
        "longitude": "75.69275000",
        "label": "Barsi",
        "value": "Barsi"
    },
    {
        "name": "Basmat",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.32872000",
        "longitude": "77.15746000",
        "label": "Basmat",
        "value": "Basmat"
    },
    {
        "name": "Basmath",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.31670000",
        "longitude": "77.16670000",
        "label": "Basmath",
        "value": "Basmath"
    },
    {
        "name": "Bavdhan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.53527778",
        "longitude": "73.78277778",
        "label": "Bavdhan",
        "value": "Bavdhan"
    },
    {
        "name": "Bawanbir",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.08333333",
        "longitude": "76.71666667",
        "label": "Bawanbir",
        "value": "Bawanbir"
    },
    {
        "name": "Beed",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98921000",
        "longitude": "75.75634000",
        "label": "Beed",
        "value": "Beed"
    },
    {
        "name": "Bhadgaon, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.16666667",
        "longitude": "75.23333333",
        "label": "Bhadgaon, Maharashtra",
        "value": "Bhadgaon, Maharashtra"
    },
    {
        "name": "Bhandara",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.18333000",
        "longitude": "80.00000000",
        "label": "Bhandara",
        "value": "Bhandara"
    },
    {
        "name": "Bhandardara",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.52916667",
        "longitude": "73.75138889",
        "label": "Bhandardara",
        "value": "Bhandardara"
    },
    {
        "name": "Bhandup",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.14000000",
        "longitude": "72.93000000",
        "label": "Bhandup",
        "value": "Bhandup"
    },
    {
        "name": "Bhayandar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.30157000",
        "longitude": "72.85107000",
        "label": "Bhayandar",
        "value": "Bhayandar"
    },
    {
        "name": "Bhigvan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.30070000",
        "longitude": "74.76701000",
        "label": "Bhigvan",
        "value": "Bhigvan"
    },
    {
        "name": "Bhiwandi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.30023000",
        "longitude": "73.05881000",
        "label": "Bhiwandi",
        "value": "Bhiwandi"
    },
    {
        "name": "Bhiwapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.83555556",
        "longitude": "79.50111111",
        "label": "Bhiwapur",
        "value": "Bhiwapur"
    },
    {
        "name": "Bhokar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.21780300",
        "longitude": "77.66939200",
        "label": "Bhokar",
        "value": "Bhokar"
    },
    {
        "name": "Bhokardan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.27000000",
        "longitude": "75.77000000",
        "label": "Bhokardan",
        "value": "Bhokardan"
    },
    {
        "name": "Bhoom",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.45972222",
        "longitude": "75.66083333",
        "label": "Bhoom",
        "value": "Bhoom"
    },
    {
        "name": "Bhor",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.14861000",
        "longitude": "73.84336000",
        "label": "Bhor",
        "value": "Bhor"
    },
    {
        "name": "Bhudgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.90742000",
        "longitude": "74.59954000",
        "label": "Bhudgaon",
        "value": "Bhudgaon"
    },
    {
        "name": "Bhugaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.50128400",
        "longitude": "73.75016100",
        "label": "Bhugaon",
        "value": "Bhugaon"
    },
    {
        "name": "Bhum",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.45908000",
        "longitude": "75.65877000",
        "label": "Bhum",
        "value": "Bhum"
    },
    {
        "name": "Bhusaval",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.04365000",
        "longitude": "75.78506000",
        "label": "Bhusaval",
        "value": "Bhusaval"
    },
    {
        "name": "Bhusawal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.05000000",
        "longitude": "75.77000000",
        "label": "Bhusawal",
        "value": "Bhusawal"
    },
    {
        "name": "Bid",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.83333000",
        "longitude": "75.75000000",
        "label": "Bid",
        "value": "Bid"
    },
    {
        "name": "Bijur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "13.84388889",
        "longitude": "74.63972222",
        "label": "Bijur",
        "value": "Bijur"
    },
    {
        "name": "Bilashi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.98333000",
        "longitude": "74.03333000",
        "label": "Bilashi",
        "value": "Bilashi"
    },
    {
        "name": "Biloli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.77385000",
        "longitude": "77.72463000",
        "label": "Biloli",
        "value": "Biloli"
    },
    {
        "name": "Boisar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.80362000",
        "longitude": "72.75598000",
        "label": "Boisar",
        "value": "Boisar"
    },
    {
        "name": "Borgaon Manju",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.71944444",
        "longitude": "77.15277778",
        "label": "Borgaon Manju",
        "value": "Borgaon Manju"
    },
    {
        "name": "Borivali",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.23000000",
        "longitude": "72.86000000",
        "label": "Borivali",
        "value": "Borivali"
    },
    {
        "name": "Borivli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.23496000",
        "longitude": "72.85976000",
        "label": "Borivli",
        "value": "Borivli"
    },
    {
        "name": "Brahmapuri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.60840000",
        "longitude": "79.85590000",
        "label": "Brahmapuri",
        "value": "Brahmapuri"
    },
    {
        "name": "Breach Candy",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.96700000",
        "longitude": "72.80500000",
        "label": "Breach Candy",
        "value": "Breach Candy"
    },
    {
        "name": "Buldana",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.58333000",
        "longitude": "76.41667000",
        "label": "Buldana",
        "value": "Buldana"
    },
    {
        "name": "Buldhana",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.53333333",
        "longitude": "76.18333333",
        "label": "Buldhana",
        "value": "Buldhana"
    },
    {
        "name": "Byculla",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98000000",
        "longitude": "72.83500000",
        "label": "Byculla",
        "value": "Byculla"
    },
    {
        "name": "Chakan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.76059000",
        "longitude": "73.86351000",
        "label": "Chakan",
        "value": "Chakan"
    },
    {
        "name": "Chakur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.51305556",
        "longitude": "76.87527778",
        "label": "Chakur",
        "value": "Chakur"
    },
    {
        "name": "Chalisgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.45781000",
        "longitude": "75.01596000",
        "label": "Chalisgaon",
        "value": "Chalisgaon"
    },
    {
        "name": "Chanda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.95076000",
        "longitude": "79.29523000",
        "label": "Chanda",
        "value": "Chanda"
    },
    {
        "name": "Chandgad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "15.00000000",
        "longitude": "74.00000000",
        "label": "Chandgad",
        "value": "Chandgad"
    },
    {
        "name": "Chandor",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.33060000",
        "longitude": "74.24467000",
        "label": "Chandor",
        "value": "Chandor"
    },
    {
        "name": "Chandrapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.11793000",
        "longitude": "79.44377000",
        "label": "Chandrapur",
        "value": "Chandrapur"
    },
    {
        "name": "Chandur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.73444000",
        "longitude": "79.17167000",
        "label": "Chandur",
        "value": "Chandur"
    },
    {
        "name": "Chandur Bazar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.23853000",
        "longitude": "77.74713000",
        "label": "Chandur Bazar",
        "value": "Chandur Bazar"
    },
    {
        "name": "Chausala",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.70869200",
        "longitude": "75.69159500",
        "label": "Chausala",
        "value": "Chausala"
    },
    {
        "name": "Chembur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.05871111",
        "longitude": "72.89969444",
        "label": "Chembur",
        "value": "Chembur"
    },
    {
        "name": "Chicholi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.46926000",
        "longitude": "79.70151000",
        "label": "Chicholi",
        "value": "Chicholi"
    },
    {
        "name": "Chichondi Patil",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.00000000",
        "longitude": "74.91666667",
        "label": "Chichondi Patil",
        "value": "Chichondi Patil"
    },
    {
        "name": "Chikhli (Buldhana)",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.35046000",
        "longitude": "76.25774000",
        "label": "Chikhli (Buldhana)",
        "value": "Chikhli (Buldhana)"
    },
    {
        "name": "Chikhli (Jalna)",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.02193000",
        "longitude": "75.78544000",
        "label": "Chikhli (Jalna)",
        "value": "Chikhli (Jalna)"
    },
    {
        "name": "Chimur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.49694444",
        "longitude": "79.37666667",
        "label": "Chimur",
        "value": "Chimur"
    },
    {
        "name": "Chinchani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.87458000",
        "longitude": "72.68510000",
        "label": "Chinchani",
        "value": "Chinchani"
    },
    {
        "name": "Chinchpokli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98333333",
        "longitude": "72.83333333",
        "label": "Chinchpokli",
        "value": "Chinchpokli"
    },
    {
        "name": "Chiplun",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.53339000",
        "longitude": "73.50935000",
        "label": "Chiplun",
        "value": "Chiplun"
    },
    {
        "name": "Chopda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.24578000",
        "longitude": "75.29946000",
        "label": "Chopda",
        "value": "Chopda"
    },
    {
        "name": "Colaba",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.91000000",
        "longitude": "72.81000000",
        "label": "Colaba",
        "value": "Colaba"
    },
    {
        "name": "Dabhol",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.58971000",
        "longitude": "73.18001000",
        "label": "Dabhol",
        "value": "Dabhol"
    },
    {
        "name": "Daddi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.06666667",
        "longitude": "74.43333333",
        "label": "Daddi",
        "value": "Daddi"
    },
    {
        "name": "Dahanu",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.96778000",
        "longitude": "72.71263000",
        "label": "Dahanu",
        "value": "Dahanu"
    },
    {
        "name": "Dahivel",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.06666667",
        "longitude": "74.16666667",
        "label": "Dahivel",
        "value": "Dahivel"
    },
    {
        "name": "Dapoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.75888889",
        "longitude": "73.18555556",
        "label": "Dapoli",
        "value": "Dapoli"
    },
    {
        "name": "Darwha",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.31017000",
        "longitude": "77.77257000",
        "label": "Darwha",
        "value": "Darwha"
    },
    {
        "name": "Daryapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.92489000",
        "longitude": "77.32644000",
        "label": "Daryapur",
        "value": "Daryapur"
    },
    {
        "name": "Dattapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.78075000",
        "longitude": "78.14070000",
        "label": "Dattapur",
        "value": "Dattapur"
    },
    {
        "name": "Daulatabad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.93611000",
        "longitude": "75.22148000",
        "label": "Daulatabad",
        "value": "Daulatabad"
    },
    {
        "name": "Daund",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.46515000",
        "longitude": "74.58375000",
        "label": "Daund",
        "value": "Daund"
    },
    {
        "name": "Deccan Gymkhana",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.51840000",
        "longitude": "73.84060000",
        "label": "Deccan Gymkhana",
        "value": "Deccan Gymkhana"
    },
    {
        "name": "Deglur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.54777778",
        "longitude": "77.57722222",
        "label": "Deglur",
        "value": "Deglur"
    },
    {
        "name": "Dehu",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.71851000",
        "longitude": "73.76635000",
        "label": "Dehu",
        "value": "Dehu"
    },
    {
        "name": "Deolali",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.94404000",
        "longitude": "73.83441000",
        "label": "Deolali",
        "value": "Deolali"
    },
    {
        "name": "Deolapar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.58611111",
        "longitude": "79.36944444",
        "label": "Deolapar",
        "value": "Deolapar"
    },
    {
        "name": "Deoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.64920000",
        "longitude": "78.48023000",
        "label": "Deoli",
        "value": "Deoli"
    },
    {
        "name": "Deoni",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.26388889",
        "longitude": "77.08222222",
        "label": "Deoni",
        "value": "Deoni"
    },
    {
        "name": "Deulgaon Raja",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.01757000",
        "longitude": "76.03755000",
        "label": "Deulgaon Raja",
        "value": "Deulgaon Raja"
    },
    {
        "name": "Devrukh",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.06500000",
        "longitude": "73.61583333",
        "label": "Devrukh",
        "value": "Devrukh"
    },
    {
        "name": "Dharangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.01187000",
        "longitude": "75.27407000",
        "label": "Dharangaon",
        "value": "Dharangaon"
    },
    {
        "name": "Dharavi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.05000000",
        "longitude": "72.86667000",
        "label": "Dharavi",
        "value": "Dharavi"
    },
    {
        "name": "Dharmabad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.89116000",
        "longitude": "77.84940000",
        "label": "Dharmabad",
        "value": "Dharmabad"
    },
    {
        "name": "Dharur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.82017000",
        "longitude": "76.10937000",
        "label": "Dharur",
        "value": "Dharur"
    },
    {
        "name": "Dhawalpuri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.16666667",
        "longitude": "74.51666667",
        "label": "Dhawalpuri",
        "value": "Dhawalpuri"
    },
    {
        "name": "Dhule",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.06852000",
        "longitude": "74.58837000",
        "label": "Dhule",
        "value": "Dhule"
    },
    {
        "name": "Dhulia",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.90130000",
        "longitude": "74.77737000",
        "label": "Dhulia",
        "value": "Dhulia"
    },
    {
        "name": "Dighori",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.88620000",
        "longitude": "79.93420000",
        "label": "Dighori",
        "value": "Dighori"
    },
    {
        "name": "Diglur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.54829000",
        "longitude": "77.57695000",
        "label": "Diglur",
        "value": "Diglur"
    },
    {
        "name": "Digras",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.10350000",
        "longitude": "77.71846000",
        "label": "Digras",
        "value": "Digras"
    },
    {
        "name": "Dindori, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.20000000",
        "longitude": "73.83305556",
        "label": "Dindori, Maharashtra",
        "value": "Dindori, Maharashtra"
    },
    {
        "name": "Diveagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.17333333",
        "longitude": "72.99166667",
        "label": "Diveagar",
        "value": "Diveagar"
    },
    {
        "name": "Dombivli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.21667000",
        "longitude": "73.08333000",
        "label": "Dombivli",
        "value": "Dombivli"
    },
    {
        "name": "Dondaicha",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.32360000",
        "longitude": "74.56804000",
        "label": "Dondaicha",
        "value": "Dondaicha"
    },
    {
        "name": "Dongri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.28333333",
        "longitude": "72.78333333",
        "label": "Dongri",
        "value": "Dongri"
    },
    {
        "name": "Dudhani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.35792000",
        "longitude": "76.36688000",
        "label": "Dudhani",
        "value": "Dudhani"
    },
    {
        "name": "Durgapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.00540000",
        "longitude": "79.30273000",
        "label": "Durgapur",
        "value": "Durgapur"
    },
    {
        "name": "Durgapur, Chandrapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.00000000",
        "longitude": "79.30000000",
        "label": "Durgapur, Chandrapur",
        "value": "Durgapur, Chandrapur"
    },
    {
        "name": "Erandol",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.92206000",
        "longitude": "75.32641000",
        "label": "Erandol",
        "value": "Erandol"
    },
    {
        "name": "Faizpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.16766000",
        "longitude": "75.86006000",
        "label": "Faizpur",
        "value": "Faizpur"
    },
    {
        "name": "Fort",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.93500000",
        "longitude": "72.83590000",
        "label": "Fort",
        "value": "Fort"
    },
    {
        "name": "Gadchiroli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.80000000",
        "longitude": "80.20000000",
        "label": "Gadchiroli",
        "value": "Gadchiroli"
    },
    {
        "name": "Gadhinglaj",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.22291000",
        "longitude": "74.35010000",
        "label": "Gadhinglaj",
        "value": "Gadhinglaj"
    },
    {
        "name": "Gangakher",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.96962000",
        "longitude": "76.74946000",
        "label": "Gangakher",
        "value": "Gangakher"
    },
    {
        "name": "Gangapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.69718000",
        "longitude": "75.01045000",
        "label": "Gangapur",
        "value": "Gangapur"
    },
    {
        "name": "Ganpatipule",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.14480000",
        "longitude": "73.26660000",
        "label": "Ganpatipule",
        "value": "Ganpatipule"
    },
    {
        "name": "Gevrai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.26372000",
        "longitude": "75.75007000",
        "label": "Gevrai",
        "value": "Gevrai"
    },
    {
        "name": "Ghargaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.74868400",
        "longitude": "74.63673400",
        "label": "Ghargaon",
        "value": "Ghargaon"
    },
    {
        "name": "Ghatanji",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.14183000",
        "longitude": "78.31333000",
        "label": "Ghatanji",
        "value": "Ghatanji"
    },
    {
        "name": "Ghatkopar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.08000000",
        "longitude": "72.91000000",
        "label": "Ghatkopar",
        "value": "Ghatkopar"
    },
    {
        "name": "Ghoti Budrukh",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.71641000",
        "longitude": "73.62821000",
        "label": "Ghoti Budrukh",
        "value": "Ghoti Budrukh"
    },
    {
        "name": "Ghugus",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.93810000",
        "longitude": "79.11192000",
        "label": "Ghugus",
        "value": "Ghugus"
    },
    {
        "name": "Girgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.95300000",
        "longitude": "72.81300000",
        "label": "Girgaon",
        "value": "Girgaon"
    },
    {
        "name": "Gondia",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.45000000",
        "longitude": "80.20000000",
        "label": "Gondia",
        "value": "Gondia"
    },
    {
        "name": "Gondiya",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.05000000",
        "longitude": "80.25000000",
        "label": "Gondiya",
        "value": "Gondiya"
    },
    {
        "name": "Gorai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.25005700",
        "longitude": "72.78202100",
        "label": "Gorai",
        "value": "Gorai"
    },
    {
        "name": "Goregaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.15483000",
        "longitude": "73.29147000",
        "label": "Goregaon",
        "value": "Goregaon"
    },
    {
        "name": "Guhagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.48415000",
        "longitude": "73.19289000",
        "label": "Guhagar",
        "value": "Guhagar"
    },
    {
        "name": "Hadapsar, Pune",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.49666667",
        "longitude": "73.94166667",
        "label": "Hadapsar, Pune",
        "value": "Hadapsar, Pune"
    },
    {
        "name": "Hadgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.49552000",
        "longitude": "77.65863000",
        "label": "Hadgaon",
        "value": "Hadgaon"
    },
    {
        "name": "Halkarni",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.16666667",
        "longitude": "74.46666667",
        "label": "Halkarni",
        "value": "Halkarni"
    },
    {
        "name": "Harangul",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.90000000",
        "longitude": "76.66666667",
        "label": "Harangul",
        "value": "Harangul"
    },
    {
        "name": "Harnai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.81340000",
        "longitude": "73.09668000",
        "label": "Harnai",
        "value": "Harnai"
    },
    {
        "name": "Helwak",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.38500000",
        "longitude": "73.73500000",
        "label": "Helwak",
        "value": "Helwak"
    },
    {
        "name": "Hinganghat",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.54875000",
        "longitude": "78.83978000",
        "label": "Hinganghat",
        "value": "Hinganghat"
    },
    {
        "name": "Hingoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.70000000",
        "longitude": "77.15000000",
        "label": "Hingoli",
        "value": "Hingoli"
    },
    {
        "name": "Hirapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.53000000",
        "longitude": "79.77000000",
        "label": "Hirapur",
        "value": "Hirapur"
    },
    {
        "name": "Hirapur Hamesha",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.55546000",
        "longitude": "79.78581000",
        "label": "Hirapur Hamesha",
        "value": "Hirapur Hamesha"
    },
    {
        "name": "Hotgi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.58333333",
        "longitude": "75.98333333",
        "label": "Hotgi",
        "value": "Hotgi"
    },
    {
        "name": "Ichalkaranji",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.69117000",
        "longitude": "74.46054000",
        "label": "Ichalkaranji",
        "value": "Ichalkaranji"
    },
    {
        "name": "Igatpuri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.69522000",
        "longitude": "73.56260000",
        "label": "Igatpuri",
        "value": "Igatpuri"
    },
    {
        "name": "Indapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.30000000",
        "longitude": "73.25000000",
        "label": "Indapur",
        "value": "Indapur"
    },
    {
        "name": "Jaisingpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.77639000",
        "longitude": "74.55361000",
        "label": "Jaisingpur",
        "value": "Jaisingpur"
    },
    {
        "name": "Jaitapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.59000000",
        "longitude": "73.35000000",
        "label": "Jaitapur",
        "value": "Jaitapur"
    },
    {
        "name": "Jakhangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.64639000",
        "longitude": "74.32111000",
        "label": "Jakhangaon",
        "value": "Jakhangaon"
    },
    {
        "name": "Jalgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.00292000",
        "longitude": "75.56602000",
        "label": "Jalgaon",
        "value": "Jalgaon"
    },
    {
        "name": "Jalgaon Jamod",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.05194000",
        "longitude": "76.53464000",
        "label": "Jalgaon Jamod",
        "value": "Jalgaon Jamod"
    },
    {
        "name": "Jalkot",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.62916667",
        "longitude": "77.18111111",
        "label": "Jalkot",
        "value": "Jalkot"
    },
    {
        "name": "Jalna",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.80000000",
        "longitude": "75.90000000",
        "label": "Jalna",
        "value": "Jalna"
    },
    {
        "name": "Jamkhed",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.72000000",
        "longitude": "75.32000000",
        "label": "Jamkhed",
        "value": "Jamkhed"
    },
    {
        "name": "Jamod",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.10000000",
        "longitude": "76.60000000",
        "label": "Jamod",
        "value": "Jamod"
    },
    {
        "name": "Janephal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.29361111",
        "longitude": "76.57583333",
        "label": "Janephal",
        "value": "Janephal"
    },
    {
        "name": "Jaoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.90000000",
        "longitude": "76.40000000",
        "label": "Jaoli",
        "value": "Jaoli"
    },
    {
        "name": "Jat, Sangli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.05000000",
        "longitude": "75.21666667",
        "label": "Jat, Sangli",
        "value": "Jat, Sangli"
    },
    {
        "name": "Jategaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.91666667",
        "longitude": "73.65000000",
        "label": "Jategaon",
        "value": "Jategaon"
    },
    {
        "name": "Jawhar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.91213000",
        "longitude": "73.22679000",
        "label": "Jawhar",
        "value": "Jawhar"
    },
    {
        "name": "Jaysingpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.78350556",
        "longitude": "74.56644167",
        "label": "Jaysingpur",
        "value": "Jaysingpur"
    },
    {
        "name": "Jejuri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.27658000",
        "longitude": "74.16008000",
        "label": "Jejuri",
        "value": "Jejuri"
    },
    {
        "name": "Jintur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.61186000",
        "longitude": "76.68740000",
        "label": "Jintur",
        "value": "Jintur"
    },
    {
        "name": "Jogeshwari",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.12000000",
        "longitude": "72.85000000",
        "label": "Jogeshwari",
        "value": "Jogeshwari"
    },
    {
        "name": "Juhu",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.10000000",
        "longitude": "72.83000000",
        "label": "Juhu",
        "value": "Juhu"
    },
    {
        "name": "Junnar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.20815000",
        "longitude": "73.87520000",
        "label": "Junnar",
        "value": "Junnar"
    },
    {
        "name": "Kachurwahi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.33330000",
        "longitude": "79.38330000",
        "label": "Kachurwahi",
        "value": "Kachurwahi"
    },
    {
        "name": "Kadegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.30000000",
        "longitude": "74.35000000",
        "label": "Kadegaon",
        "value": "Kadegaon"
    },
    {
        "name": "Kadus",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.88333333",
        "longitude": "73.81666667",
        "label": "Kadus",
        "value": "Kadus"
    },
    {
        "name": "Kagal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.57702000",
        "longitude": "74.31544000",
        "label": "Kagal",
        "value": "Kagal"
    },
    {
        "name": "Kaij",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.70000000",
        "longitude": "76.08333333",
        "label": "Kaij",
        "value": "Kaij"
    },
    {
        "name": "Kalamb",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.04437000",
        "longitude": "73.95554000",
        "label": "Kalamb",
        "value": "Kalamb"
    },
    {
        "name": "Kalamb, Osmanabad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.57416667",
        "longitude": "76.02166667",
        "label": "Kalamb, Osmanabad",
        "value": "Kalamb, Osmanabad"
    },
    {
        "name": "Kalamboli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.03333000",
        "longitude": "73.10000000",
        "label": "Kalamboli",
        "value": "Kalamboli"
    },
    {
        "name": "Kalamnuri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.67386000",
        "longitude": "77.31149000",
        "label": "Kalamnuri",
        "value": "Kalamnuri"
    },
    {
        "name": "Kalas",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.17241000",
        "longitude": "74.79045000",
        "label": "Kalas",
        "value": "Kalas"
    },
    {
        "name": "Kali(DK)",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.93877800",
        "longitude": "77.73285700",
        "label": "Kali(DK)",
        "value": "Kali(DK)"
    },
    {
        "name": "Kalmeshwar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.23219000",
        "longitude": "78.91988000",
        "label": "Kalmeshwar",
        "value": "Kalmeshwar"
    },
    {
        "name": "Kalundri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98020000",
        "longitude": "73.12708000",
        "label": "Kalundri",
        "value": "Kalundri"
    },
    {
        "name": "Kalyan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.24370000",
        "longitude": "73.13554000",
        "label": "Kalyan",
        "value": "Kalyan"
    },
    {
        "name": "Kalyani Nagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.54810000",
        "longitude": "73.90330000",
        "label": "Kalyani Nagar",
        "value": "Kalyani Nagar"
    },
    {
        "name": "Kamargaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.61666667",
        "longitude": "77.50000000",
        "label": "Kamargaon",
        "value": "Kamargaon"
    },
    {
        "name": "Kamatgi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.11900000",
        "longitude": "75.84820000",
        "label": "Kamatgi",
        "value": "Kamatgi"
    },
    {
        "name": "Kamptee",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.23330000",
        "longitude": "79.20000000",
        "label": "Kamptee",
        "value": "Kamptee"
    },
    {
        "name": "Kamthi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.21615000",
        "longitude": "79.19730000",
        "label": "Kamthi",
        "value": "Kamthi"
    },
    {
        "name": "Kandri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.42030000",
        "longitude": "79.27663000",
        "label": "Kandri",
        "value": "Kandri"
    },
    {
        "name": "Kankauli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.26609000",
        "longitude": "73.71217000",
        "label": "Kankauli",
        "value": "Kankauli"
    },
    {
        "name": "Kankavli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.28551111",
        "longitude": "73.68446111",
        "label": "Kankavli",
        "value": "Kankavli"
    },
    {
        "name": "Kannad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.25684000",
        "longitude": "75.13786000",
        "label": "Kannad",
        "value": "Kannad"
    },
    {
        "name": "Karad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.28937000",
        "longitude": "74.18183000",
        "label": "Karad",
        "value": "Karad"
    },
    {
        "name": "Karajagi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.11666667",
        "longitude": "75.58333333",
        "label": "Karajagi",
        "value": "Karajagi"
    },
    {
        "name": "Karanja",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.48273000",
        "longitude": "77.48857000",
        "label": "Karanja",
        "value": "Karanja"
    },
    {
        "name": "Karanja Lad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.48330000",
        "longitude": "77.48330000",
        "label": "Karanja Lad",
        "value": "Karanja Lad"
    },
    {
        "name": "Karjat",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.91070000",
        "longitude": "73.32354000",
        "label": "Karjat",
        "value": "Karjat"
    },
    {
        "name": "Karkamb",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.86530000",
        "longitude": "75.29640000",
        "label": "Karkamb",
        "value": "Karkamb"
    },
    {
        "name": "Karmala",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.40770000",
        "longitude": "75.19386000",
        "label": "Karmala",
        "value": "Karmala"
    },
    {
        "name": "Kasara",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.63330000",
        "longitude": "73.48330000",
        "label": "Kasara",
        "value": "Kasara"
    },
    {
        "name": "Kasoda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.81666667",
        "longitude": "75.30000000",
        "label": "Kasoda",
        "value": "Kasoda"
    },
    {
        "name": "Kati",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.96137000",
        "longitude": "75.88895000",
        "label": "Kati",
        "value": "Kati"
    },
    {
        "name": "Katol",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.27388000",
        "longitude": "78.58580000",
        "label": "Katol",
        "value": "Katol"
    },
    {
        "name": "Katral",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.16666667",
        "longitude": "75.63333333",
        "label": "Katral",
        "value": "Katral"
    },
    {
        "name": "Khadki",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.56350000",
        "longitude": "73.85205000",
        "label": "Khadki",
        "value": "Khadki"
    },
    {
        "name": "Khalapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.81666667",
        "longitude": "73.26666667",
        "label": "Khalapur",
        "value": "Khalapur"
    },
    {
        "name": "Khallar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.04028690",
        "longitude": "77.46562960",
        "label": "Khallar",
        "value": "Khallar"
    },
    {
        "name": "Khamgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.70738000",
        "longitude": "76.56827000",
        "label": "Khamgaon",
        "value": "Khamgaon"
    },
    {
        "name": "Khanapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.26111111",
        "longitude": "74.70166667",
        "label": "Khanapur",
        "value": "Khanapur"
    },
    {
        "name": "Khandala",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.05918056",
        "longitude": "74.01262778",
        "label": "Khandala",
        "value": "Khandala"
    },
    {
        "name": "Khangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.50000000",
        "longitude": "78.61666667",
        "label": "Khangaon",
        "value": "Khangaon"
    },
    {
        "name": "Khapa",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.42243000",
        "longitude": "78.98168000",
        "label": "Khapa",
        "value": "Khapa"
    },
    {
        "name": "Kharakvasla",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.43997000",
        "longitude": "73.77545000",
        "label": "Kharakvasla",
        "value": "Kharakvasla"
    },
    {
        "name": "Kharda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.63753000",
        "longitude": "75.47513000",
        "label": "Kharda",
        "value": "Kharda"
    },
    {
        "name": "Kharghar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.03614722",
        "longitude": "73.06172222",
        "label": "Kharghar",
        "value": "Kharghar"
    },
    {
        "name": "Kharsundi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.34158500",
        "longitude": "74.77599600",
        "label": "Kharsundi",
        "value": "Kharsundi"
    },
    {
        "name": "Khed",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.71888000",
        "longitude": "73.39693000",
        "label": "Khed",
        "value": "Khed"
    },
    {
        "name": "Khede",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.91000000",
        "longitude": "74.63000000",
        "label": "Khede",
        "value": "Khede"
    },
    {
        "name": "Khetia",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.67124000",
        "longitude": "74.58535000",
        "label": "Khetia",
        "value": "Khetia"
    },
    {
        "name": "Khoni",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.31130000",
        "longitude": "73.05440000",
        "label": "Khoni",
        "value": "Khoni"
    },
    {
        "name": "Khopoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.78562000",
        "longitude": "73.34589000",
        "label": "Khopoli",
        "value": "Khopoli"
    },
    {
        "name": "Khuldabad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.00671000",
        "longitude": "75.19245000",
        "label": "Khuldabad",
        "value": "Khuldabad"
    },
    {
        "name": "Kinwat",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.62557000",
        "longitude": "78.19870000",
        "label": "Kinwat",
        "value": "Kinwat"
    },
    {
        "name": "Kodoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.87639000",
        "longitude": "74.19090000",
        "label": "Kodoli",
        "value": "Kodoli"
    },
    {
        "name": "Kolhapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.69013000",
        "longitude": "74.22981000",
        "label": "Kolhapur",
        "value": "Kolhapur"
    },
    {
        "name": "Kondalwadi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.80727000",
        "longitude": "77.77031000",
        "label": "Kondalwadi",
        "value": "Kondalwadi"
    },
    {
        "name": "Kondhali",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.15000000",
        "longitude": "78.61666667",
        "label": "Kondhali",
        "value": "Kondhali"
    },
    {
        "name": "Kopar Khairane",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.10305556",
        "longitude": "73.01055556",
        "label": "Kopar Khairane",
        "value": "Kopar Khairane"
    },
    {
        "name": "Kopargaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.88239000",
        "longitude": "74.47605000",
        "label": "Kopargaon",
        "value": "Kopargaon"
    },
    {
        "name": "Kopela",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.83000000",
        "longitude": "80.23000000",
        "label": "Kopela",
        "value": "Kopela"
    },
    {
        "name": "Koradi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.24758000",
        "longitude": "79.10575000",
        "label": "Koradi",
        "value": "Koradi"
    },
    {
        "name": "Koregaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.64573000",
        "longitude": "74.05909000",
        "label": "Koregaon",
        "value": "Koregaon"
    },
    {
        "name": "Koynanagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.40000000",
        "longitude": "73.76667000",
        "label": "Koynanagar",
        "value": "Koynanagar"
    },
    {
        "name": "Kudal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.01148000",
        "longitude": "73.68867000",
        "label": "Kudal",
        "value": "Kudal"
    },
    {
        "name": "Kuhi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.01082222",
        "longitude": "79.35242222",
        "label": "Kuhi",
        "value": "Kuhi"
    },
    {
        "name": "Kurandvad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.68317000",
        "longitude": "74.58892000",
        "label": "Kurandvad",
        "value": "Kurandvad"
    },
    {
        "name": "Kurankhed",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.70194444",
        "longitude": "77.24972222",
        "label": "Kurankhed",
        "value": "Kurankhed"
    },
    {
        "name": "Kurduvadi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.09339000",
        "longitude": "75.41567000",
        "label": "Kurduvadi",
        "value": "Kurduvadi"
    },
    {
        "name": "Kusumba",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.91666667",
        "longitude": "74.65000000",
        "label": "Kusumba",
        "value": "Kusumba"
    },
    {
        "name": "Lakhandur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.75000000",
        "longitude": "79.88333333",
        "label": "Lakhandur",
        "value": "Lakhandur"
    },
    {
        "name": "Lanja",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.86086000",
        "longitude": "73.54993000",
        "label": "Lanja",
        "value": "Lanja"
    },
    {
        "name": "Lasalgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.14270000",
        "longitude": "74.23946000",
        "label": "Lasalgaon",
        "value": "Lasalgaon"
    },
    {
        "name": "Latur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.40000000",
        "longitude": "76.80000000",
        "label": "Latur",
        "value": "Latur"
    },
    {
        "name": "Lavasa",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.40528056",
        "longitude": "73.50626944",
        "label": "Lavasa",
        "value": "Lavasa"
    },
    {
        "name": "Lohogaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.59921000",
        "longitude": "73.92701000",
        "label": "Lohogaon",
        "value": "Lohogaon"
    },
    {
        "name": "Lonar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.98533000",
        "longitude": "76.52046000",
        "label": "Lonar",
        "value": "Lonar"
    },
    {
        "name": "Lonavla",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.75275000",
        "longitude": "73.40575000",
        "label": "Lonavla",
        "value": "Lonavla"
    },
    {
        "name": "Mahabaleshwar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.56000000",
        "longitude": "73.40000000",
        "label": "Mahabaleshwar",
        "value": "Mahabaleshwar"
    },
    {
        "name": "Mahad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.08333000",
        "longitude": "73.41667000",
        "label": "Mahad",
        "value": "Mahad"
    },
    {
        "name": "Mahape",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.11776000",
        "longitude": "73.02693800",
        "label": "Mahape",
        "value": "Mahape"
    },
    {
        "name": "Mahim",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.03500000",
        "longitude": "72.84000000",
        "label": "Mahim",
        "value": "Mahim"
    },
    {
        "name": "Maindargi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.45739000",
        "longitude": "76.29320000",
        "label": "Maindargi",
        "value": "Maindargi"
    },
    {
        "name": "Majalgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.15988000",
        "longitude": "76.20832000",
        "label": "Majalgaon",
        "value": "Majalgaon"
    },
    {
        "name": "Makhjan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.26980000",
        "longitude": "73.50031000",
        "label": "Makhjan",
        "value": "Makhjan"
    },
    {
        "name": "Malabar Hill",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.95000000",
        "longitude": "72.79500000",
        "label": "Malabar Hill",
        "value": "Malabar Hill"
    },
    {
        "name": "Malad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.18611111",
        "longitude": "72.84861111",
        "label": "Malad",
        "value": "Malad"
    },
    {
        "name": "Malegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.54966000",
        "longitude": "74.53462000",
        "label": "Malegaon",
        "value": "Malegaon"
    },
    {
        "name": "Malkapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.26214000",
        "longitude": "74.17574000",
        "label": "Malkapur",
        "value": "Malkapur"
    },
    {
        "name": "Malvan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.05981000",
        "longitude": "73.46290000",
        "label": "Malvan",
        "value": "Malvan"
    },
    {
        "name": "Manchar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.00436000",
        "longitude": "73.94346000",
        "label": "Manchar",
        "value": "Manchar"
    },
    {
        "name": "Mandangad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.98333333",
        "longitude": "73.25000000",
        "label": "Mandangad",
        "value": "Mandangad"
    },
    {
        "name": "Mandhal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.95000000",
        "longitude": "79.46666667",
        "label": "Mandhal",
        "value": "Mandhal"
    },
    {
        "name": "Mandwa",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.80300000",
        "longitude": "72.88200000",
        "label": "Mandwa",
        "value": "Mandwa"
    },
    {
        "name": "Mangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.23333333",
        "longitude": "73.28333333",
        "label": "Mangaon",
        "value": "Mangaon"
    },
    {
        "name": "Mangrul Pir",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.31379000",
        "longitude": "77.34178000",
        "label": "Mangrul Pir",
        "value": "Mangrul Pir"
    },
    {
        "name": "Manjlegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.15000000",
        "longitude": "76.23000000",
        "label": "Manjlegaon",
        "value": "Manjlegaon"
    },
    {
        "name": "Mankeshwar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.35416667",
        "longitude": "75.63916667",
        "label": "Mankeshwar",
        "value": "Mankeshwar"
    },
    {
        "name": "Mankhurd",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.05000000",
        "longitude": "72.93000000",
        "label": "Mankhurd",
        "value": "Mankhurd"
    },
    {
        "name": "Manmad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.25334000",
        "longitude": "74.43755000",
        "label": "Manmad",
        "value": "Manmad"
    },
    {
        "name": "Manor",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.72440000",
        "longitude": "72.90966000",
        "label": "Manor",
        "value": "Manor"
    },
    {
        "name": "Mansar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.39602000",
        "longitude": "79.26350000",
        "label": "Mansar",
        "value": "Mansar"
    },
    {
        "name": "Manwat",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.30133000",
        "longitude": "76.49735000",
        "label": "Manwat",
        "value": "Manwat"
    },
    {
        "name": "Manwath",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.30000000",
        "longitude": "76.50000000",
        "label": "Manwath",
        "value": "Manwath"
    },
    {
        "name": "Maregaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.10000000",
        "longitude": "78.81666667",
        "label": "Maregaon",
        "value": "Maregaon"
    },
    {
        "name": "Mastiholi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.08333333",
        "longitude": "74.55000000",
        "label": "Mastiholi",
        "value": "Mastiholi"
    },
    {
        "name": "Masur, India",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.40000000",
        "longitude": "74.15000000",
        "label": "Masur, India",
        "value": "Masur, India"
    },
    {
        "name": "Matheran",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98281000",
        "longitude": "73.26520000",
        "label": "Matheran",
        "value": "Matheran"
    },
    {
        "name": "Matunga",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.01798056",
        "longitude": "72.84476389",
        "label": "Matunga",
        "value": "Matunga"
    },
    {
        "name": "Mazagaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.97000000",
        "longitude": "72.85000000",
        "label": "Mazagaon",
        "value": "Mazagaon"
    },
    {
        "name": "Mehekar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.15050000",
        "longitude": "76.56841000",
        "label": "Mehekar",
        "value": "Mehekar"
    },
    {
        "name": "Mehergaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.96666667",
        "longitude": "74.63333333",
        "label": "Mehergaon",
        "value": "Mehergaon"
    },
    {
        "name": "Mehkar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.15000000",
        "longitude": "76.57500000",
        "label": "Mehkar",
        "value": "Mehkar"
    },
    {
        "name": "Mhasla",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.13340000",
        "longitude": "73.11162000",
        "label": "Mhasla",
        "value": "Mhasla"
    },
    {
        "name": "Mhasvad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.63359000",
        "longitude": "74.78773000",
        "label": "Mhasvad",
        "value": "Mhasvad"
    },
    {
        "name": "Miraj",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.83000000",
        "longitude": "74.63000000",
        "label": "Miraj",
        "value": "Miraj"
    },
    {
        "name": "Mohadi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.30833333",
        "longitude": "79.67500000",
        "label": "Mohadi",
        "value": "Mohadi"
    },
    {
        "name": "Mohol",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.81670000",
        "longitude": "75.66670000",
        "label": "Mohol",
        "value": "Mohol"
    },
    {
        "name": "Mohpa",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.31012000",
        "longitude": "78.82969000",
        "label": "Mohpa",
        "value": "Mohpa"
    },
    {
        "name": "Mokhada taluka",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.93333333",
        "longitude": "73.33333333",
        "label": "Mokhada taluka",
        "value": "Mokhada taluka"
    },
    {
        "name": "Mora, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.88900000",
        "longitude": "72.93400000",
        "label": "Mora, Maharashtra",
        "value": "Mora, Maharashtra"
    },
    {
        "name": "Moram",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.78812000",
        "longitude": "76.47077000",
        "label": "Moram",
        "value": "Moram"
    },
    {
        "name": "Morsi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.34030000",
        "longitude": "78.01258000",
        "label": "Morsi",
        "value": "Morsi"
    },
    {
        "name": "Mowad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.46475000",
        "longitude": "78.45103000",
        "label": "Mowad",
        "value": "Mowad"
    },
    {
        "name": "Mudkhed",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.15657000",
        "longitude": "77.50304000",
        "label": "Mudkhed",
        "value": "Mudkhed"
    },
    {
        "name": "Mukher",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.70636000",
        "longitude": "77.36795000",
        "label": "Mukher",
        "value": "Mukher"
    },
    {
        "name": "Mul",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.06987000",
        "longitude": "79.67826000",
        "label": "Mul",
        "value": "Mul"
    },
    {
        "name": "Mulher",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.78138889",
        "longitude": "74.06388889",
        "label": "Mulher",
        "value": "Mulher"
    },
    {
        "name": "Mulund",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.17168056",
        "longitude": "72.95600000",
        "label": "Mulund",
        "value": "Mulund"
    },
    {
        "name": "Mumbai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.07283000",
        "longitude": "72.88261000",
        "label": "Mumbai",
        "value": "Mumbai"
    },
    {
        "name": "Mumbai Suburban",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.12636000",
        "longitude": "72.84897000",
        "label": "Mumbai Suburban",
        "value": "Mumbai Suburban"
    },
    {
        "name": "Murbad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.25395000",
        "longitude": "73.38993000",
        "label": "Murbad",
        "value": "Murbad"
    },
    {
        "name": "Murgud",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.39604000",
        "longitude": "74.19142000",
        "label": "Murgud",
        "value": "Murgud"
    },
    {
        "name": "Murtajapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.73299000",
        "longitude": "77.36694000",
        "label": "Murtajapur",
        "value": "Murtajapur"
    },
    {
        "name": "Murud (Raigad)",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.32817000",
        "longitude": "72.96210000",
        "label": "Murud (Raigad)",
        "value": "Murud (Raigad)"
    },
    {
        "name": "Murud (Ratnagiri)",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.77494444",
        "longitude": "73.11815000",
        "label": "Murud (Ratnagiri)",
        "value": "Murud (Ratnagiri)"
    },
    {
        "name": "Murum",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.78777778",
        "longitude": "76.47000000",
        "label": "Murum",
        "value": "Murum"
    },
    {
        "name": "Nadgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.90101389",
        "longitude": "76.01396389",
        "label": "Nadgaon",
        "value": "Nadgaon"
    },
    {
        "name": "Nagapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.87000000",
        "longitude": "76.43000000",
        "label": "Nagapur",
        "value": "Nagapur"
    },
    {
        "name": "Nagothana",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.54225000",
        "longitude": "73.13493000",
        "label": "Nagothana",
        "value": "Nagothana"
    },
    {
        "name": "Nagpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.14631000",
        "longitude": "79.08491000",
        "label": "Nagpur",
        "value": "Nagpur"
    },
    {
        "name": "Nagpur Division",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.14911000",
        "longitude": "79.10748000",
        "label": "Nagpur Division",
        "value": "Nagpur Division"
    },
    {
        "name": "Nala Sopara",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.41540000",
        "longitude": "72.86130000",
        "label": "Nala Sopara",
        "value": "Nala Sopara"
    },
    {
        "name": "Naldurg",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.81667000",
        "longitude": "76.28182000",
        "label": "Naldurg",
        "value": "Naldurg"
    },
    {
        "name": "Nalegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.41666667",
        "longitude": "76.81666667",
        "label": "Nalegaon",
        "value": "Nalegaon"
    },
    {
        "name": "Nampur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.72861111",
        "longitude": "74.31083333",
        "label": "Nampur",
        "value": "Nampur"
    },
    {
        "name": "Nanded",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.91667000",
        "longitude": "77.50000000",
        "label": "Nanded",
        "value": "Nanded"
    },
    {
        "name": "Nandgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.30680000",
        "longitude": "74.65501000",
        "label": "Nandgaon",
        "value": "Nandgaon"
    },
    {
        "name": "Nandnee",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.45500000",
        "longitude": "75.84555556",
        "label": "Nandnee",
        "value": "Nandnee"
    },
    {
        "name": "Nandura",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.83333333",
        "longitude": "76.45972222",
        "label": "Nandura",
        "value": "Nandura"
    },
    {
        "name": "Nandura Buzurg",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.83417000",
        "longitude": "76.45924000",
        "label": "Nandura Buzurg",
        "value": "Nandura Buzurg"
    },
    {
        "name": "Nandurbar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.37000000",
        "longitude": "74.20000000",
        "label": "Nandurbar",
        "value": "Nandurbar"
    },
    {
        "name": "Narayangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.07000000",
        "longitude": "73.97000000",
        "label": "Narayangaon",
        "value": "Narayangaon"
    },
    {
        "name": "Nardana",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.20000000",
        "longitude": "74.81666667",
        "label": "Nardana",
        "value": "Nardana"
    },
    {
        "name": "Nariman Point",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.92000000",
        "longitude": "72.83000000",
        "label": "Nariman Point",
        "value": "Nariman Point"
    },
    {
        "name": "Narkhed",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.47000000",
        "longitude": "78.53000000",
        "label": "Narkhed",
        "value": "Narkhed"
    },
    {
        "name": "Nashik",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.99727000",
        "longitude": "73.79096000",
        "label": "Nashik",
        "value": "Nashik"
    },
    {
        "name": "Nashik Division",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.98295000",
        "longitude": "73.78942000",
        "label": "Nashik Division",
        "value": "Nashik Division"
    },
    {
        "name": "Navapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.17000000",
        "longitude": "73.78000000",
        "label": "Navapur",
        "value": "Navapur"
    },
    {
        "name": "Navi Mumbai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.03681000",
        "longitude": "73.01582000",
        "label": "Navi Mumbai",
        "value": "Navi Mumbai"
    },
    {
        "name": "Neral",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.02475000",
        "longitude": "73.31688000",
        "label": "Neral",
        "value": "Neral"
    },
    {
        "name": "Nerur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "15.98333333",
        "longitude": "73.65000000",
        "label": "Nerur",
        "value": "Nerur"
    },
    {
        "name": "Nevasa",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.53333333",
        "longitude": "74.93333333",
        "label": "Nevasa",
        "value": "Nevasa"
    },
    {
        "name": "Nighoj",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.95972222",
        "longitude": "74.27694444",
        "label": "Nighoj",
        "value": "Nighoj"
    },
    {
        "name": "Nilanga",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.11675000",
        "longitude": "76.75279000",
        "label": "Nilanga",
        "value": "Nilanga"
    },
    {
        "name": "Nipani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.39900000",
        "longitude": "74.38285000",
        "label": "Nipani",
        "value": "Nipani"
    },
    {
        "name": "Niphad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.08330000",
        "longitude": "73.80000000",
        "label": "Niphad",
        "value": "Niphad"
    },
    {
        "name": "Nira Narsingpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.98350000",
        "longitude": "75.12396000",
        "label": "Nira Narsingpur",
        "value": "Nira Narsingpur"
    },
    {
        "name": "Osmanabad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.25000000",
        "longitude": "76.16667000",
        "label": "Osmanabad",
        "value": "Osmanabad"
    },
    {
        "name": "Ozar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.09473000",
        "longitude": "73.92816000",
        "label": "Ozar",
        "value": "Ozar"
    },
    {
        "name": "Pabal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.83083333",
        "longitude": "74.05277778",
        "label": "Pabal",
        "value": "Pabal"
    },
    {
        "name": "Pachora",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.66727000",
        "longitude": "75.35305000",
        "label": "Pachora",
        "value": "Pachora"
    },
    {
        "name": "Pahur, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.70000000",
        "longitude": "75.68333333",
        "label": "Pahur, Maharashtra",
        "value": "Pahur, Maharashtra"
    },
    {
        "name": "Paithan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.47506000",
        "longitude": "75.38558000",
        "label": "Paithan",
        "value": "Paithan"
    },
    {
        "name": "Palghar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.69693000",
        "longitude": "72.76543000",
        "label": "Palghar",
        "value": "Palghar"
    },
    {
        "name": "Pali, Raigad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.54138400",
        "longitude": "73.21996500",
        "label": "Pali, Raigad",
        "value": "Pali, Raigad"
    },
    {
        "name": "Palso",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.76666667",
        "longitude": "77.23333333",
        "label": "Palso",
        "value": "Palso"
    },
    {
        "name": "Panchgani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.92449000",
        "longitude": "73.80080000",
        "label": "Panchgani",
        "value": "Panchgani"
    },
    {
        "name": "Pandharpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.67924000",
        "longitude": "75.33098000",
        "label": "Pandharpur",
        "value": "Pandharpur"
    },
    {
        "name": "Pandhurli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.82905200",
        "longitude": "73.85474500",
        "label": "Pandhurli",
        "value": "Pandhurli"
    },
    {
        "name": "Panhala",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.81210000",
        "longitude": "74.11007000",
        "label": "Panhala",
        "value": "Panhala"
    },
    {
        "name": "Panvel",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98878000",
        "longitude": "73.11013000",
        "label": "Panvel",
        "value": "Panvel"
    },
    {
        "name": "Parbhani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.50000000",
        "longitude": "76.75000000",
        "label": "Parbhani",
        "value": "Parbhani"
    },
    {
        "name": "Parel",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.99000000",
        "longitude": "72.84000000",
        "label": "Parel",
        "value": "Parel"
    },
    {
        "name": "Parli Vaijnath",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.85057000",
        "longitude": "76.53163000",
        "label": "Parli Vaijnath",
        "value": "Parli Vaijnath"
    },
    {
        "name": "Parner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.00300000",
        "longitude": "74.43800000",
        "label": "Parner",
        "value": "Parner"
    },
    {
        "name": "Parola",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.88098000",
        "longitude": "75.11937000",
        "label": "Parola",
        "value": "Parola"
    },
    {
        "name": "Parseoni",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.38333333",
        "longitude": "79.15000000",
        "label": "Parseoni",
        "value": "Parseoni"
    },
    {
        "name": "Partur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.59925000",
        "longitude": "76.21541000",
        "label": "Partur",
        "value": "Partur"
    },
    {
        "name": "Patan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.37513000",
        "longitude": "73.90143000",
        "label": "Patan",
        "value": "Patan"
    },
    {
        "name": "Pathardi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.17279000",
        "longitude": "75.17425000",
        "label": "Pathardi",
        "value": "Pathardi"
    },
    {
        "name": "Pathri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.25880000",
        "longitude": "76.43412000",
        "label": "Pathri",
        "value": "Pathri"
    },
    {
        "name": "Patur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.46093000",
        "longitude": "76.93725000",
        "label": "Patur",
        "value": "Patur"
    },
    {
        "name": "Paturda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.95000000",
        "longitude": "76.71666667",
        "label": "Paturda",
        "value": "Paturda"
    },
    {
        "name": "Paud",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.52416667",
        "longitude": "73.61583333",
        "label": "Paud",
        "value": "Paud"
    },
    {
        "name": "Pauni",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.78000000",
        "longitude": "79.63000000",
        "label": "Pauni",
        "value": "Pauni"
    },
    {
        "name": "Pawni",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.79229000",
        "longitude": "79.63644000",
        "label": "Pawni",
        "value": "Pawni"
    },
    {
        "name": "Pedgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.51166667",
        "longitude": "74.70750000",
        "label": "Pedgaon",
        "value": "Pedgaon"
    },
    {
        "name": "Peint",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.25844722",
        "longitude": "73.50305278",
        "label": "Peint",
        "value": "Peint"
    },
    {
        "name": "Pen",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.73734000",
        "longitude": "73.09603000",
        "label": "Pen",
        "value": "Pen"
    },
    {
        "name": "Phaltan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.99113000",
        "longitude": "74.43177000",
        "label": "Phaltan",
        "value": "Phaltan"
    },
    {
        "name": "Phulambri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.10000000",
        "longitude": "75.41666667",
        "label": "Phulambri",
        "value": "Phulambri"
    },
    {
        "name": "Piliv",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.67884200",
        "longitude": "74.96641200",
        "label": "Piliv",
        "value": "Piliv"
    },
    {
        "name": "Pimpalgaon Baswant",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.16666667",
        "longitude": "73.98333333",
        "label": "Pimpalgaon Baswant",
        "value": "Pimpalgaon Baswant"
    },
    {
        "name": "Pimpalgaon Raja",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.71666667",
        "longitude": "76.43333333",
        "label": "Pimpalgaon Raja",
        "value": "Pimpalgaon Raja"
    },
    {
        "name": "Pimpri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.62292000",
        "longitude": "73.80696000",
        "label": "Pimpri",
        "value": "Pimpri"
    },
    {
        "name": "Pimpri-Chinchwad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.62777778",
        "longitude": "73.81305556",
        "label": "Pimpri-Chinchwad",
        "value": "Pimpri-Chinchwad"
    },
    {
        "name": "Pipri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.79371000",
        "longitude": "75.53519000",
        "label": "Pipri",
        "value": "Pipri"
    },
    {
        "name": "Powai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.11640000",
        "longitude": "72.90471000",
        "label": "Powai",
        "value": "Powai"
    },
    {
        "name": "Prabhadevi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.01690500",
        "longitude": "72.82864900",
        "label": "Prabhadevi",
        "value": "Prabhadevi"
    },
    {
        "name": "Prakasha",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.51666667",
        "longitude": "74.31666667",
        "label": "Prakasha",
        "value": "Prakasha"
    },
    {
        "name": "Pulgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.72204000",
        "longitude": "78.32056000",
        "label": "Pulgaon",
        "value": "Pulgaon"
    },
    {
        "name": "Pune",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.51957000",
        "longitude": "73.85535000",
        "label": "Pune",
        "value": "Pune"
    },
    {
        "name": "Pune Division",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.74673000",
        "longitude": "73.75465000",
        "label": "Pune Division",
        "value": "Pune Division"
    },
    {
        "name": "Puntamba",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.75000000",
        "longitude": "74.63333333",
        "label": "Puntamba",
        "value": "Puntamba"
    },
    {
        "name": "Pural",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.45000000",
        "longitude": "73.36666667",
        "label": "Pural",
        "value": "Pural"
    },
    {
        "name": "Purna",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.18170000",
        "longitude": "77.02566000",
        "label": "Purna",
        "value": "Purna"
    },
    {
        "name": "Pusad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.91274000",
        "longitude": "77.57838000",
        "label": "Pusad",
        "value": "Pusad"
    },
    {
        "name": "Radhanagari",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.41388889",
        "longitude": "73.99777778",
        "label": "Radhanagari",
        "value": "Radhanagari"
    },
    {
        "name": "Rahata",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.71666667",
        "longitude": "74.48333333",
        "label": "Rahata",
        "value": "Rahata"
    },
    {
        "name": "Rahimatpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.59210000",
        "longitude": "74.19966000",
        "label": "Rahimatpur",
        "value": "Rahimatpur"
    },
    {
        "name": "Rahuri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.39069000",
        "longitude": "74.64979000",
        "label": "Rahuri",
        "value": "Rahuri"
    },
    {
        "name": "Raigarh",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.57000000",
        "longitude": "73.13000000",
        "label": "Raigarh",
        "value": "Raigarh"
    },
    {
        "name": "Raireshwar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.05000000",
        "longitude": "73.73333333",
        "label": "Raireshwar",
        "value": "Raireshwar"
    },
    {
        "name": "Rajapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.65679000",
        "longitude": "73.51701000",
        "label": "Rajapur",
        "value": "Rajapur"
    },
    {
        "name": "Rajgurunagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.86667000",
        "longitude": "73.90000000",
        "label": "Rajgurunagar",
        "value": "Rajgurunagar"
    },
    {
        "name": "Rajur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.11087000",
        "longitude": "78.89311000",
        "label": "Rajur",
        "value": "Rajur"
    },
    {
        "name": "Rajura",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.77947000",
        "longitude": "79.36459000",
        "label": "Rajura",
        "value": "Rajura"
    },
    {
        "name": "Ralegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.25000000",
        "longitude": "79.81000000",
        "label": "Ralegaon",
        "value": "Ralegaon"
    },
    {
        "name": "Ramewadi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.03333333",
        "longitude": "74.35000000",
        "label": "Ramewadi",
        "value": "Ramewadi"
    },
    {
        "name": "Ramtek",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.39562000",
        "longitude": "79.32725000",
        "label": "Ramtek",
        "value": "Ramtek"
    },
    {
        "name": "Ratnagiri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.00000000",
        "longitude": "73.50000000",
        "label": "Ratnagiri",
        "value": "Ratnagiri"
    },
    {
        "name": "Raver",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.24757000",
        "longitude": "76.03509000",
        "label": "Raver",
        "value": "Raver"
    },
    {
        "name": "Renapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.51666667",
        "longitude": "76.60000000",
        "label": "Renapur",
        "value": "Renapur"
    },
    {
        "name": "Renavi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.26972222",
        "longitude": "74.61055556",
        "label": "Renavi",
        "value": "Renavi"
    },
    {
        "name": "Revadanda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.55363000",
        "longitude": "72.92559000",
        "label": "Revadanda",
        "value": "Revadanda"
    },
    {
        "name": "Revdanda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.55000000",
        "longitude": "72.93333333",
        "label": "Revdanda",
        "value": "Revdanda"
    },
    {
        "name": "Risod",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.97671000",
        "longitude": "76.78799000",
        "label": "Risod",
        "value": "Risod"
    },
    {
        "name": "Roha",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.43687000",
        "longitude": "73.11964000",
        "label": "Roha",
        "value": "Roha"
    },
    {
        "name": "Sailu",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.47000000",
        "longitude": "76.47000000",
        "label": "Sailu",
        "value": "Sailu"
    },
    {
        "name": "Sakol",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.28250000",
        "longitude": "76.88444444",
        "label": "Sakol",
        "value": "Sakol"
    },
    {
        "name": "Sakoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.08000000",
        "longitude": "79.98000000",
        "label": "Sakoli",
        "value": "Sakoli"
    },
    {
        "name": "Sakri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.99027778",
        "longitude": "74.31444444",
        "label": "Sakri",
        "value": "Sakri"
    },
    {
        "name": "Samudrapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.65000000",
        "longitude": "78.96666667",
        "label": "Samudrapur",
        "value": "Samudrapur"
    },
    {
        "name": "Sangameshwar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.18694444",
        "longitude": "73.55305556",
        "label": "Sangameshwar",
        "value": "Sangameshwar"
    },
    {
        "name": "Sangamner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.56784000",
        "longitude": "74.21154000",
        "label": "Sangamner",
        "value": "Sangamner"
    },
    {
        "name": "Sangli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.11202000",
        "longitude": "74.76990000",
        "label": "Sangli",
        "value": "Sangli"
    },
    {
        "name": "Sangola",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.43948000",
        "longitude": "75.19379000",
        "label": "Sangola",
        "value": "Sangola"
    },
    {
        "name": "Sangole",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.43944444",
        "longitude": "75.19388889",
        "label": "Sangole",
        "value": "Sangole"
    },
    {
        "name": "Sangrampur, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.03000000",
        "longitude": "76.68000000",
        "label": "Sangrampur, Maharashtra",
        "value": "Sangrampur, Maharashtra"
    },
    {
        "name": "Saoli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.08133056",
        "longitude": "79.78286111",
        "label": "Saoli",
        "value": "Saoli"
    },
    {
        "name": "Saoner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.38510000",
        "longitude": "78.92155000",
        "label": "Saoner",
        "value": "Saoner"
    },
    {
        "name": "Sarangkheda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.43327300",
        "longitude": "74.52642100",
        "label": "Sarangkheda",
        "value": "Sarangkheda"
    },
    {
        "name": "Sasvad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.34351000",
        "longitude": "74.03102000",
        "label": "Sasvad",
        "value": "Sasvad"
    },
    {
        "name": "Saswad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.55000000",
        "longitude": "74.00000000",
        "label": "Saswad",
        "value": "Saswad"
    },
    {
        "name": "Satana",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.59483000",
        "longitude": "74.20301000",
        "label": "Satana",
        "value": "Satana"
    },
    {
        "name": "Satara",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.68589000",
        "longitude": "73.99333000",
        "label": "Satara",
        "value": "Satara"
    },
    {
        "name": "Satara Division",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.72601000",
        "longitude": "74.06433000",
        "label": "Satara Division",
        "value": "Satara Division"
    },
    {
        "name": "Satpati",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.71666667",
        "longitude": "72.70000000",
        "label": "Satpati",
        "value": "Satpati"
    },
    {
        "name": "Savantvadi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "15.90413000",
        "longitude": "73.82191000",
        "label": "Savantvadi",
        "value": "Savantvadi"
    },
    {
        "name": "Savda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.15054000",
        "longitude": "75.88938000",
        "label": "Savda",
        "value": "Savda"
    },
    {
        "name": "Savlaj",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.10000000",
        "longitude": "74.78000000",
        "label": "Savlaj",
        "value": "Savlaj"
    },
    {
        "name": "Sawantvadi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.00000000",
        "longitude": "73.75000000",
        "label": "Sawantvadi",
        "value": "Sawantvadi"
    },
    {
        "name": "Selu",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.45512000",
        "longitude": "76.44073000",
        "label": "Selu",
        "value": "Selu"
    },
    {
        "name": "Sevagram",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.73499167",
        "longitude": "78.66256944",
        "label": "Sevagram",
        "value": "Sevagram"
    },
    {
        "name": "Sewri",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.00000000",
        "longitude": "72.86000000",
        "label": "Sewri",
        "value": "Sewri"
    },
    {
        "name": "Shahada",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.54538000",
        "longitude": "74.47106000",
        "label": "Shahada",
        "value": "Shahada"
    },
    {
        "name": "Shahapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.45231000",
        "longitude": "73.32572000",
        "label": "Shahapur",
        "value": "Shahapur"
    },
    {
        "name": "Shedbal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.68916667",
        "longitude": "74.75425556",
        "label": "Shedbal",
        "value": "Shedbal"
    },
    {
        "name": "Shegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.79320000",
        "longitude": "76.69921000",
        "label": "Shegaon",
        "value": "Shegaon"
    },
    {
        "name": "Shevgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.35000000",
        "longitude": "75.23333333",
        "label": "Shevgaon",
        "value": "Shevgaon"
    },
    {
        "name": "Shikrapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.69361111",
        "longitude": "74.13805556",
        "label": "Shikrapur",
        "value": "Shikrapur"
    },
    {
        "name": "Shiraguppi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.61875000",
        "longitude": "74.70907000",
        "label": "Shiraguppi",
        "value": "Shiraguppi"
    },
    {
        "name": "Shirala",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.98401111",
        "longitude": "74.12415278",
        "label": "Shirala",
        "value": "Shirala"
    },
    {
        "name": "Shirdi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.76616000",
        "longitude": "74.47738000",
        "label": "Shirdi",
        "value": "Shirdi"
    },
    {
        "name": "Shirgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.69589000",
        "longitude": "72.71527000",
        "label": "Shirgaon",
        "value": "Shirgaon"
    },
    {
        "name": "Shirol",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.73333333",
        "longitude": "74.60000000",
        "label": "Shirol",
        "value": "Shirol"
    },
    {
        "name": "Shirpur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.34821000",
        "longitude": "74.88035000",
        "label": "Shirpur",
        "value": "Shirpur"
    },
    {
        "name": "Shirud",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.71666667",
        "longitude": "74.90000000",
        "label": "Shirud",
        "value": "Shirud"
    },
    {
        "name": "Shirwal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.15059000",
        "longitude": "73.97788000",
        "label": "Shirwal",
        "value": "Shirwal"
    },
    {
        "name": "Shivaji Nagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.53017000",
        "longitude": "73.85263000",
        "label": "Shivaji Nagar",
        "value": "Shivaji Nagar"
    },
    {
        "name": "Shrigonda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.61527000",
        "longitude": "74.69895000",
        "label": "Shrigonda",
        "value": "Shrigonda"
    },
    {
        "name": "Sillod",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.30303000",
        "longitude": "75.65284000",
        "label": "Sillod",
        "value": "Sillod"
    },
    {
        "name": "Sindewahi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.28333333",
        "longitude": "79.65000000",
        "label": "Sindewahi",
        "value": "Sindewahi"
    },
    {
        "name": "Sindhudurg",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.17000000",
        "longitude": "73.70000000",
        "label": "Sindhudurg",
        "value": "Sindhudurg"
    },
    {
        "name": "Sindi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.80509000",
        "longitude": "78.88752000",
        "label": "Sindi",
        "value": "Sindi"
    },
    {
        "name": "Sindkheda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.26666667",
        "longitude": "74.73333333",
        "label": "Sindkheda",
        "value": "Sindkheda"
    },
    {
        "name": "Sinnar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.84505000",
        "longitude": "73.99866000",
        "label": "Sinnar",
        "value": "Sinnar"
    },
    {
        "name": "Sion, Mumbai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.04000000",
        "longitude": "72.86000000",
        "label": "Sion, Mumbai",
        "value": "Sion, Mumbai"
    },
    {
        "name": "Sironcha",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.83000000",
        "longitude": "79.96000000",
        "label": "Sironcha",
        "value": "Sironcha"
    },
    {
        "name": "Sirur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.82760000",
        "longitude": "74.37475000",
        "label": "Sirur",
        "value": "Sirur"
    },
    {
        "name": "Sivala, East Godavari district",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.75722222",
        "longitude": "82.08861111",
        "label": "Sivala, East Godavari district",
        "value": "Sivala, East Godavari district"
    },
    {
        "name": "Solapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.75000000",
        "longitude": "75.50000000",
        "label": "Solapur",
        "value": "Solapur"
    },
    {
        "name": "Sonala",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.11666667",
        "longitude": "76.73333333",
        "label": "Sonala",
        "value": "Sonala"
    },
    {
        "name": "Sonegaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.62915000",
        "longitude": "78.69207000",
        "label": "Sonegaon",
        "value": "Sonegaon"
    },
    {
        "name": "Songir",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.08333333",
        "longitude": "74.78333333",
        "label": "Songir",
        "value": "Songir"
    },
    {
        "name": "Sonvad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.08333333",
        "longitude": "75.35000000",
        "label": "Sonvad",
        "value": "Sonvad"
    },
    {
        "name": "Soygaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.59606000",
        "longitude": "75.61765000",
        "label": "Soygaon",
        "value": "Soygaon"
    },
    {
        "name": "Srivardhan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.04592000",
        "longitude": "73.01552000",
        "label": "Srivardhan",
        "value": "Srivardhan"
    },
    {
        "name": "Surgana",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.55956000",
        "longitude": "73.63747000",
        "label": "Surgana",
        "value": "Surgana"
    },
    {
        "name": "Taklibhan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.61670000",
        "longitude": "74.80000000",
        "label": "Taklibhan",
        "value": "Taklibhan"
    },
    {
        "name": "Talbid",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.35000000",
        "longitude": "74.13000000",
        "label": "Talbid",
        "value": "Talbid"
    },
    {
        "name": "Talegaon Dabhade",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.73502000",
        "longitude": "73.67561000",
        "label": "Talegaon Dabhade",
        "value": "Talegaon Dabhade"
    },
    {
        "name": "Talegaon Dhamdhere",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.66780000",
        "longitude": "74.15360000",
        "label": "Talegaon Dhamdhere",
        "value": "Talegaon Dhamdhere"
    },
    {
        "name": "Taloda",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.56128000",
        "longitude": "74.21238000",
        "label": "Taloda",
        "value": "Taloda"
    },
    {
        "name": "Talode",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.56074167",
        "longitude": "74.83000000",
        "label": "Talode",
        "value": "Talode"
    },
    {
        "name": "Tarapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.86499000",
        "longitude": "72.68426000",
        "label": "Tarapur",
        "value": "Tarapur"
    },
    {
        "name": "Tardeo",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.97000000",
        "longitude": "72.81000000",
        "label": "Tardeo",
        "value": "Tardeo"
    },
    {
        "name": "Tasgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.03700000",
        "longitude": "74.60171000",
        "label": "Tasgaon",
        "value": "Tasgaon"
    },
    {
        "name": "Telhara",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.02694000",
        "longitude": "76.83889000",
        "label": "Telhara",
        "value": "Telhara"
    },
    {
        "name": "Thalner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.25000000",
        "longitude": "74.96670000",
        "label": "Thalner",
        "value": "Thalner"
    },
    {
        "name": "Thane",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.33333000",
        "longitude": "73.25000000",
        "label": "Thane",
        "value": "Thane"
    },
    {
        "name": "Trimbak",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.93268000",
        "longitude": "73.52907000",
        "label": "Trimbak",
        "value": "Trimbak"
    },
    {
        "name": "Trombay",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.00000000",
        "longitude": "72.90000000",
        "label": "Trombay",
        "value": "Trombay"
    },
    {
        "name": "Tuljapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.00804000",
        "longitude": "76.07011000",
        "label": "Tuljapur",
        "value": "Tuljapur"
    },
    {
        "name": "Tumsar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.38333000",
        "longitude": "79.73333000",
        "label": "Tumsar",
        "value": "Tumsar"
    },
    {
        "name": "Udgir",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.39258000",
        "longitude": "77.11756000",
        "label": "Udgir",
        "value": "Udgir"
    },
    {
        "name": "Ulhasnagar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.21667000",
        "longitude": "73.15000000",
        "label": "Ulhasnagar",
        "value": "Ulhasnagar"
    },
    {
        "name": "Umarga",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.83841000",
        "longitude": "76.62331000",
        "label": "Umarga",
        "value": "Umarga"
    },
    {
        "name": "Umarkhed",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.60144000",
        "longitude": "77.68878000",
        "label": "Umarkhed",
        "value": "Umarkhed"
    },
    {
        "name": "Umred",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.85396000",
        "longitude": "79.32466000",
        "label": "Umred",
        "value": "Umred"
    },
    {
        "name": "Uran",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.87813000",
        "longitude": "72.93924000",
        "label": "Uran",
        "value": "Uran"
    },
    {
        "name": "Uruli Kanchan",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.48333333",
        "longitude": "74.13333333",
        "label": "Uruli Kanchan",
        "value": "Uruli Kanchan"
    },
    {
        "name": "Vada",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.65347000",
        "longitude": "73.14811000",
        "label": "Vada",
        "value": "Vada"
    },
    {
        "name": "Vadgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.74860000",
        "longitude": "73.64100000",
        "label": "Vadgaon",
        "value": "Vadgaon"
    },
    {
        "name": "Vadner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.25000000",
        "longitude": "74.03330000",
        "label": "Vadner",
        "value": "Vadner"
    },
    {
        "name": "Vaijapur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.92672000",
        "longitude": "74.72750000",
        "label": "Vaijapur",
        "value": "Vaijapur"
    },
    {
        "name": "Vairag",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.05000000",
        "longitude": "75.80000000",
        "label": "Vairag",
        "value": "Vairag"
    },
    {
        "name": "Valsang",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.60000000",
        "longitude": "76.08330000",
        "label": "Valsang",
        "value": "Valsang"
    },
    {
        "name": "Vangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.86666667",
        "longitude": "72.75000000",
        "label": "Vangaon",
        "value": "Vangaon"
    },
    {
        "name": "Varangaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.01767000",
        "longitude": "75.91042000",
        "label": "Varangaon",
        "value": "Varangaon"
    },
    {
        "name": "Vashi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.08000000",
        "longitude": "73.01000000",
        "label": "Vashi",
        "value": "Vashi"
    },
    {
        "name": "Vasind",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.40844000",
        "longitude": "73.26285000",
        "label": "Vasind",
        "value": "Vasind"
    },
    {
        "name": "Vatul",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.75000000",
        "longitude": "73.60000000",
        "label": "Vatul",
        "value": "Vatul"
    },
    {
        "name": "Velas, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.95850000",
        "longitude": "73.04980000",
        "label": "Velas, Maharashtra",
        "value": "Velas, Maharashtra"
    },
    {
        "name": "Velneshwar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.38330000",
        "longitude": "73.20000000",
        "label": "Velneshwar",
        "value": "Velneshwar"
    },
    {
        "name": "Vengurla",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "15.86125000",
        "longitude": "73.63182000",
        "label": "Vengurla",
        "value": "Vengurla"
    },
    {
        "name": "Vijaydurg",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "16.56670000",
        "longitude": "73.33330000",
        "label": "Vijaydurg",
        "value": "Vijaydurg"
    },
    {
        "name": "Vikhroli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.11000000",
        "longitude": "72.94000000",
        "label": "Vikhroli",
        "value": "Vikhroli"
    },
    {
        "name": "Vile Parle",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.10000000",
        "longitude": "72.83000000",
        "label": "Vile Parle",
        "value": "Vile Parle"
    },
    {
        "name": "Vinchur",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.11666667",
        "longitude": "74.28333333",
        "label": "Vinchur",
        "value": "Vinchur"
    },
    {
        "name": "Virar",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.45591000",
        "longitude": "72.81136000",
        "label": "Virar",
        "value": "Virar"
    },
    {
        "name": "Vita, Maharashtra",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.27110000",
        "longitude": "74.53780000",
        "label": "Vita, Maharashtra",
        "value": "Vita, Maharashtra"
    },
    {
        "name": "Vite",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.27343000",
        "longitude": "74.53792000",
        "label": "Vite",
        "value": "Vite"
    },
    {
        "name": "Wadala",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.02163056",
        "longitude": "72.86458889",
        "label": "Wadala",
        "value": "Wadala"
    },
    {
        "name": "Wadgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.73920000",
        "longitude": "73.63945000",
        "label": "Wadgaon",
        "value": "Wadgaon"
    },
    {
        "name": "Wadner",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.84972222",
        "longitude": "76.33333333",
        "label": "Wadner",
        "value": "Wadner"
    },
    {
        "name": "Wadwani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.98333333",
        "longitude": "76.05000000",
        "label": "Wadwani",
        "value": "Wadwani"
    },
    {
        "name": "Wagholi",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.90000000",
        "longitude": "74.08300000",
        "label": "Wagholi",
        "value": "Wagholi"
    },
    {
        "name": "Wai",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "17.95276000",
        "longitude": "73.89058000",
        "label": "Wai",
        "value": "Wai"
    },
    {
        "name": "Wakad",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.59930000",
        "longitude": "73.76250000",
        "label": "Wakad",
        "value": "Wakad"
    },
    {
        "name": "Walgaon",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.99890000",
        "longitude": "77.70640000",
        "label": "Walgaon",
        "value": "Walgaon"
    },
    {
        "name": "Walki",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "18.95000000",
        "longitude": "74.75000000",
        "label": "Walki",
        "value": "Walki"
    },
    {
        "name": "Wani",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.05507000",
        "longitude": "78.95313000",
        "label": "Wani",
        "value": "Wani"
    },
    {
        "name": "Wardha",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.73933000",
        "longitude": "78.59784000",
        "label": "Wardha",
        "value": "Wardha"
    },
    {
        "name": "Warora",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.22885000",
        "longitude": "79.00277000",
        "label": "Warora",
        "value": "Warora"
    },
    {
        "name": "Warud",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.47101000",
        "longitude": "78.26965000",
        "label": "Warud",
        "value": "Warud"
    },
    {
        "name": "Washim",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.20000000",
        "longitude": "77.20000000",
        "label": "Washim",
        "value": "Washim"
    },
    {
        "name": "Worli",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "19.00000000",
        "longitude": "72.81500000",
        "label": "Worli",
        "value": "Worli"
    },
    {
        "name": "Yaval",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "21.16772000",
        "longitude": "75.69762000",
        "label": "Yaval",
        "value": "Yaval"
    },
    {
        "name": "Yavatmal",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.15000000",
        "longitude": "78.35000000",
        "label": "Yavatmal",
        "value": "Yavatmal"
    },
    {
        "name": "Yeola",
        "countryCode": "IN",
        "stateCode": "MH",
        "latitude": "20.04240000",
        "longitude": "74.48944000",
        "label": "Yeola",
        "value": "Yeola"
    }
,{
        "name": "Bishnupur",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.60769000",
        "longitude": "93.77998000",
        "label": "Bishnupur",
        "value": "Bishnupur"
    },
    {
        "name": "Churachandpur",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.33333000",
        "longitude": "93.68333000",
        "label": "Churachandpur",
        "value": "Churachandpur"
    },
    {
        "name": "Imphal",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.80805000",
        "longitude": "93.94420000",
        "label": "Imphal",
        "value": "Imphal"
    },
    {
        "name": "Kakching",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.49820000",
        "longitude": "93.98126000",
        "label": "Kakching",
        "value": "Kakching"
    },
    {
        "name": "Mayang Imphal",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.60998000",
        "longitude": "93.88873000",
        "label": "Mayang Imphal",
        "value": "Mayang Imphal"
    },
    {
        "name": "Moirang",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.49750000",
        "longitude": "93.77791000",
        "label": "Moirang",
        "value": "Moirang"
    },
    {
        "name": "Phek",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "25.66667000",
        "longitude": "94.50000000",
        "label": "Phek",
        "value": "Phek"
    },
    {
        "name": "Senapati",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "25.26705000",
        "longitude": "94.02237000",
        "label": "Senapati",
        "value": "Senapati"
    },
    {
        "name": "Tamenglong",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.97548000",
        "longitude": "93.51563000",
        "label": "Tamenglong",
        "value": "Tamenglong"
    },
    {
        "name": "Thoubal",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.62205000",
        "longitude": "94.01001000",
        "label": "Thoubal",
        "value": "Thoubal"
    },
    {
        "name": "Ukhrul",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "25.04828000",
        "longitude": "94.35883000",
        "label": "Ukhrul",
        "value": "Ukhrul"
    },
    {
        "name": "Wangjing",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.58921000",
        "longitude": "94.06386000",
        "label": "Wangjing",
        "value": "Wangjing"
    },
    {
        "name": "Yairipok",
        "countryCode": "IN",
        "stateCode": "MN",
        "latitude": "24.67792000",
        "longitude": "94.04767000",
        "label": "Yairipok",
        "value": "Yairipok"
    }
,{
        "name": "Cherrapunji",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.30089000",
        "longitude": "91.69619000",
        "label": "Cherrapunji",
        "value": "Cherrapunji"
    },
    {
        "name": "East Garo Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.61372000",
        "longitude": "90.62426000",
        "label": "East Garo Hills",
        "value": "East Garo Hills"
    },
    {
        "name": "East Jaintia Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.35976000",
        "longitude": "92.36680000",
        "label": "East Jaintia Hills",
        "value": "East Jaintia Hills"
    },
    {
        "name": "East Khasi Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.38050000",
        "longitude": "91.78905000",
        "label": "East Khasi Hills",
        "value": "East Khasi Hills"
    },
    {
        "name": "Mairang",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.56165000",
        "longitude": "91.63602000",
        "label": "Mairang",
        "value": "Mairang"
    },
    {
        "name": "Mankachar",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.53347000",
        "longitude": "89.86373000",
        "label": "Mankachar",
        "value": "Mankachar"
    },
    {
        "name": "Nongpoh",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.90230000",
        "longitude": "91.87694000",
        "label": "Nongpoh",
        "value": "Nongpoh"
    },
    {
        "name": "Nongstoin",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.51704000",
        "longitude": "91.26484000",
        "label": "Nongstoin",
        "value": "Nongstoin"
    },
    {
        "name": "North Garo Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.89682000",
        "longitude": "90.61602000",
        "label": "North Garo Hills",
        "value": "North Garo Hills"
    },
    {
        "name": "Ri-Bhoi",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.88997000",
        "longitude": "91.82707000",
        "label": "Ri-Bhoi",
        "value": "Ri-Bhoi"
    },
    {
        "name": "Shillong",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.56892000",
        "longitude": "91.88313000",
        "label": "Shillong",
        "value": "Shillong"
    },
    {
        "name": "South Garo Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.30162000",
        "longitude": "90.58530000",
        "label": "South Garo Hills",
        "value": "South Garo Hills"
    },
    {
        "name": "South West Garo Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.47245000",
        "longitude": "89.93399000",
        "label": "South West Garo Hills",
        "value": "South West Garo Hills"
    },
    {
        "name": "South West Khasi Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.32155000",
        "longitude": "91.29462000",
        "label": "South West Khasi Hills",
        "value": "South West Khasi Hills"
    },
    {
        "name": "Tura",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.51421000",
        "longitude": "90.20239000",
        "label": "Tura",
        "value": "Tura"
    },
    {
        "name": "West Garo Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.56794000",
        "longitude": "90.22447000",
        "label": "West Garo Hills",
        "value": "West Garo Hills"
    },
    {
        "name": "West Jaintia Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.50000000",
        "longitude": "92.25000000",
        "label": "West Jaintia Hills",
        "value": "West Jaintia Hills"
    },
    {
        "name": "West Khasi Hills",
        "countryCode": "IN",
        "stateCode": "ML",
        "latitude": "25.54776000",
        "longitude": "91.26957000",
        "label": "West Khasi Hills",
        "value": "West Khasi Hills"
    }
,{
        "name": "Aizawl",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.80000000",
        "longitude": "92.90000000",
        "label": "Aizawl",
        "value": "Aizawl"
    },
    {
        "name": "Champhai",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.47444000",
        "longitude": "93.32556000",
        "label": "Champhai",
        "value": "Champhai"
    },
    {
        "name": "Darlawn",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "24.01336000",
        "longitude": "92.92439000",
        "label": "Darlawn",
        "value": "Darlawn"
    },
    {
        "name": "Khawhai",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.37807000",
        "longitude": "93.12797000",
        "label": "Khawhai",
        "value": "Khawhai"
    },
    {
        "name": "Kolasib",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "24.22388000",
        "longitude": "92.67869000",
        "label": "Kolasib",
        "value": "Kolasib"
    },
    {
        "name": "Kolasib district",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "24.22215000",
        "longitude": "92.67697000",
        "label": "Kolasib district",
        "value": "Kolasib district"
    },
    {
        "name": "Lawngtlai",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "22.53000000",
        "longitude": "92.90000000",
        "label": "Lawngtlai",
        "value": "Lawngtlai"
    },
    {
        "name": "Lunglei",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "22.90000000",
        "longitude": "92.75000000",
        "label": "Lunglei",
        "value": "Lunglei"
    },
    {
        "name": "Mamit",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.78492000",
        "longitude": "92.46939000",
        "label": "Mamit",
        "value": "Mamit"
    },
    {
        "name": "North Vanlaiphai",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.13227000",
        "longitude": "93.06532000",
        "label": "North Vanlaiphai",
        "value": "North Vanlaiphai"
    },
    {
        "name": "Saiha",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "22.49183000",
        "longitude": "92.98143000",
        "label": "Saiha",
        "value": "Saiha"
    },
    {
        "name": "Sairang",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.81034000",
        "longitude": "92.65226000",
        "label": "Sairang",
        "value": "Sairang"
    },
    {
        "name": "Saitlaw",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.97187000",
        "longitude": "92.57454000",
        "label": "Saitlaw",
        "value": "Saitlaw"
    },
    {
        "name": "Serchhip",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.28172000",
        "longitude": "92.90039000",
        "label": "Serchhip",
        "value": "Serchhip"
    },
    {
        "name": "Thenzawl",
        "countryCode": "IN",
        "stateCode": "MZ",
        "latitude": "23.31667000",
        "longitude": "92.75000000",
        "label": "Thenzawl",
        "value": "Thenzawl"
    }
,{
        "name": "Dimapur",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "25.77852000",
        "longitude": "93.78508000",
        "label": "Dimapur",
        "value": "Dimapur"
    },
    {
        "name": "Kohima",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "25.67467000",
        "longitude": "94.11099000",
        "label": "Kohima",
        "value": "Kohima"
    },
    {
        "name": "Mokokchung",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "26.31393000",
        "longitude": "94.51675000",
        "label": "Mokokchung",
        "value": "Mokokchung"
    },
    {
        "name": "Mon",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "26.75000000",
        "longitude": "94.83333000",
        "label": "Mon",
        "value": "Mon"
    },
    {
        "name": "Peren",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "25.51276000",
        "longitude": "93.73716000",
        "label": "Peren",
        "value": "Peren"
    },
    {
        "name": "Phek",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "25.75000000",
        "longitude": "94.50000000",
        "label": "Phek",
        "value": "Phek"
    },
    {
        "name": "Tuensang",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "26.26704000",
        "longitude": "94.82415000",
        "label": "Tuensang",
        "value": "Tuensang"
    },
    {
        "name": "Tuensang District",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "26.25000000",
        "longitude": "94.75000000",
        "label": "Tuensang District",
        "value": "Tuensang District"
    },
    {
        "name": "Wokha",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "26.16667000",
        "longitude": "94.25000000",
        "label": "Wokha",
        "value": "Wokha"
    },
    {
        "name": "Zunheboto",
        "countryCode": "IN",
        "stateCode": "NL",
        "latitude": "26.00000000",
        "longitude": "94.50000000",
        "label": "Zunheboto",
        "value": "Zunheboto"
    }
,{
        "name": "Angul",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.84089000",
        "longitude": "85.10192000",
        "label": "Angul",
        "value": "Angul"
    },
    {
        "name": "Angul District",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.84903000",
        "longitude": "85.06079000",
        "label": "Angul District",
        "value": "Angul District"
    },
    {
        "name": "Asika",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.61114000",
        "longitude": "84.65998000",
        "label": "Asika",
        "value": "Asika"
    },
    {
        "name": "Athagarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.51999000",
        "longitude": "85.62965000",
        "label": "Athagarh",
        "value": "Athagarh"
    },
    {
        "name": "Bada Barabil",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.11186000",
        "longitude": "85.38684000",
        "label": "Bada Barabil",
        "value": "Bada Barabil"
    },
    {
        "name": "Balangir",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.75000000",
        "longitude": "83.25000000",
        "label": "Balangir",
        "value": "Balangir"
    },
    {
        "name": "Balasore",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.49266000",
        "longitude": "86.93348000",
        "label": "Balasore",
        "value": "Balasore"
    },
    {
        "name": "Baleshwar",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.50000000",
        "longitude": "86.75000000",
        "label": "Baleshwar",
        "value": "Baleshwar"
    },
    {
        "name": "Balimila",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "18.25167000",
        "longitude": "82.10659000",
        "label": "Balimila",
        "value": "Balimila"
    },
    {
        "name": "Balugaon",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.17838000",
        "longitude": "85.11327000",
        "label": "Balugaon",
        "value": "Balugaon"
    },
    {
        "name": "Banapur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.77889000",
        "longitude": "85.17033000",
        "label": "Banapur",
        "value": "Banapur"
    },
    {
        "name": "Banki",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.37912000",
        "longitude": "85.52953000",
        "label": "Banki",
        "value": "Banki"
    },
    {
        "name": "Banposh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.24834000",
        "longitude": "84.81044000",
        "label": "Banposh",
        "value": "Banposh"
    },
    {
        "name": "Baragarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.33333000",
        "longitude": "83.61667000",
        "label": "Baragarh",
        "value": "Baragarh"
    },
    {
        "name": "Barbil",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.10194000",
        "longitude": "85.37752000",
        "label": "Barbil",
        "value": "Barbil"
    },
    {
        "name": "Bargarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.33348000",
        "longitude": "83.61905000",
        "label": "Bargarh",
        "value": "Bargarh"
    },
    {
        "name": "Barpali",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.19005000",
        "longitude": "83.58721000",
        "label": "Barpali",
        "value": "Barpali"
    },
    {
        "name": "Basudebpur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.11974000",
        "longitude": "86.72896000",
        "label": "Basudebpur",
        "value": "Basudebpur"
    },
    {
        "name": "Baud",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.83773000",
        "longitude": "84.32618000",
        "label": "Baud",
        "value": "Baud"
    },
    {
        "name": "Baudh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.83300000",
        "longitude": "84.33300000",
        "label": "Baudh",
        "value": "Baudh"
    },
    {
        "name": "Belaguntha",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.88249000",
        "longitude": "84.63801000",
        "label": "Belaguntha",
        "value": "Belaguntha"
    },
    {
        "name": "Bhadrak",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.00000000",
        "longitude": "86.60000000",
        "label": "Bhadrak",
        "value": "Bhadrak"
    },
    {
        "name": "Bhadrakh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.05447000",
        "longitude": "86.51560000",
        "label": "Bhadrakh",
        "value": "Bhadrakh"
    },
    {
        "name": "Bhanjanagar",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.92719000",
        "longitude": "84.58201000",
        "label": "Bhanjanagar",
        "value": "Bhanjanagar"
    },
    {
        "name": "Bhawanipatna",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.90717000",
        "longitude": "83.16697000",
        "label": "Bhawanipatna",
        "value": "Bhawanipatna"
    },
    {
        "name": "Bhuban",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.88197000",
        "longitude": "85.83334000",
        "label": "Bhuban",
        "value": "Bhuban"
    },
    {
        "name": "Bhubaneshwar",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.27241000",
        "longitude": "85.83385000",
        "label": "Bhubaneshwar",
        "value": "Bhubaneshwar"
    },
    {
        "name": "Binka",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.02626000",
        "longitude": "83.81197000",
        "label": "Binka",
        "value": "Binka"
    },
    {
        "name": "Birmitrapur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.40000000",
        "longitude": "84.76667000",
        "label": "Birmitrapur",
        "value": "Birmitrapur"
    },
    {
        "name": "Bolanikhodan",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.11312000",
        "longitude": "85.33645000",
        "label": "Bolanikhodan",
        "value": "Bolanikhodan"
    },
    {
        "name": "Brahmapur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.31151000",
        "longitude": "84.79290000",
        "label": "Brahmapur",
        "value": "Brahmapur"
    },
    {
        "name": "Brajarajnagar",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.81667000",
        "longitude": "83.91667000",
        "label": "Brajarajnagar",
        "value": "Brajarajnagar"
    },
    {
        "name": "Buguda",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.80806000",
        "longitude": "84.79084000",
        "label": "Buguda",
        "value": "Buguda"
    },
    {
        "name": "Burla",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.50976000",
        "longitude": "83.87259000",
        "label": "Burla",
        "value": "Burla"
    },
    {
        "name": "Champua",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.06734000",
        "longitude": "85.66463000",
        "label": "Champua",
        "value": "Champua"
    },
    {
        "name": "Chandbali",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.77519000",
        "longitude": "86.74139000",
        "label": "Chandbali",
        "value": "Chandbali"
    },
    {
        "name": "Chatrapur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.35574000",
        "longitude": "84.98359000",
        "label": "Chatrapur",
        "value": "Chatrapur"
    },
    {
        "name": "Chikitigarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.20233000",
        "longitude": "84.61450000",
        "label": "Chikitigarh",
        "value": "Chikitigarh"
    },
    {
        "name": "Chittarkonda",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "18.12533000",
        "longitude": "82.10890000",
        "label": "Chittarkonda",
        "value": "Chittarkonda"
    },
    {
        "name": "Cuttack",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.50000000",
        "longitude": "86.25000000",
        "label": "Cuttack",
        "value": "Cuttack"
    },
    {
        "name": "Daitari",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.10000000",
        "longitude": "85.75000000",
        "label": "Daitari",
        "value": "Daitari"
    },
    {
        "name": "Deogarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.53827000",
        "longitude": "84.73337000",
        "label": "Deogarh",
        "value": "Deogarh"
    },
    {
        "name": "Dhenkanal",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.75000000",
        "longitude": "85.50000000",
        "label": "Dhenkanal",
        "value": "Dhenkanal"
    },
    {
        "name": "Digapahandi",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.37275000",
        "longitude": "84.57184000",
        "label": "Digapahandi",
        "value": "Digapahandi"
    },
    {
        "name": "Gajapati",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "18.91000000",
        "longitude": "84.20000000",
        "label": "Gajapati",
        "value": "Gajapati"
    },
    {
        "name": "Ganjam",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.38705000",
        "longitude": "85.05079000",
        "label": "Ganjam",
        "value": "Ganjam"
    },
    {
        "name": "Gopalpur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.25861000",
        "longitude": "84.90517000",
        "label": "Gopalpur",
        "value": "Gopalpur"
    },
    {
        "name": "Gudari",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.34762000",
        "longitude": "83.78128000",
        "label": "Gudari",
        "value": "Gudari"
    },
    {
        "name": "Gunupur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.08040000",
        "longitude": "83.80879000",
        "label": "Gunupur",
        "value": "Gunupur"
    },
    {
        "name": "Hinjilikatu",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.48166000",
        "longitude": "84.74489000",
        "label": "Hinjilikatu",
        "value": "Hinjilikatu"
    },
    {
        "name": "Hirakud",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.52502000",
        "longitude": "83.87275000",
        "label": "Hirakud",
        "value": "Hirakud"
    },
    {
        "name": "Jagatsinghapur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.25570000",
        "longitude": "86.17112000",
        "label": "Jagatsinghapur",
        "value": "Jagatsinghapur"
    },
    {
        "name": "Jagatsinghpur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.20000000",
        "longitude": "86.30000000",
        "label": "Jagatsinghpur",
        "value": "Jagatsinghpur"
    },
    {
        "name": "Jajpur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.84149000",
        "longitude": "86.31237000",
        "label": "Jajpur",
        "value": "Jajpur"
    },
    {
        "name": "Jaleshwar",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.80176000",
        "longitude": "87.22250000",
        "label": "Jaleshwar",
        "value": "Jaleshwar"
    },
    {
        "name": "Jatani",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.15975000",
        "longitude": "85.70742000",
        "label": "Jatani",
        "value": "Jatani"
    },
    {
        "name": "Jeypore",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "18.85630000",
        "longitude": "82.57160000",
        "label": "Jeypore",
        "value": "Jeypore"
    },
    {
        "name": "Jharsuguda",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.85531000",
        "longitude": "84.00698000",
        "label": "Jharsuguda",
        "value": "Jharsuguda"
    },
    {
        "name": "Jharsuguda District",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.85000000",
        "longitude": "84.00000000",
        "label": "Jharsuguda District",
        "value": "Jharsuguda District"
    },
    {
        "name": "Kaintragarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.72115000",
        "longitude": "84.53514000",
        "label": "Kaintragarh",
        "value": "Kaintragarh"
    },
    {
        "name": "Kalahandi",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.75000000",
        "longitude": "83.00000000",
        "label": "Kalahandi",
        "value": "Kalahandi"
    },
    {
        "name": "Kamakhyanagar",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.93385000",
        "longitude": "85.54489000",
        "label": "Kamakhyanagar",
        "value": "Kamakhyanagar"
    },
    {
        "name": "Kandhamal",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.30000000",
        "longitude": "84.00000000",
        "label": "Kandhamal",
        "value": "Kandhamal"
    },
    {
        "name": "Kantabanji",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.46709000",
        "longitude": "82.92042000",
        "label": "Kantabanji",
        "value": "Kantabanji"
    },
    {
        "name": "Kantilo",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.36152000",
        "longitude": "85.19212000",
        "label": "Kantilo",
        "value": "Kantilo"
    },
    {
        "name": "Kendrapara",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.50000000",
        "longitude": "86.50000000",
        "label": "Kendrapara",
        "value": "Kendrapara"
    },
    {
        "name": "Kendraparha",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.50166000",
        "longitude": "86.42227000",
        "label": "Kendraparha",
        "value": "Kendraparha"
    },
    {
        "name": "Kendujhar",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.50000000",
        "longitude": "85.50000000",
        "label": "Kendujhar",
        "value": "Kendujhar"
    },
    {
        "name": "Kesinga",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.18778000",
        "longitude": "83.21949000",
        "label": "Kesinga",
        "value": "Kesinga"
    },
    {
        "name": "Khallikot",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.60908000",
        "longitude": "85.08609000",
        "label": "Khallikot",
        "value": "Khallikot"
    },
    {
        "name": "Kharhial",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.28845000",
        "longitude": "82.76060000",
        "label": "Kharhial",
        "value": "Kharhial"
    },
    {
        "name": "Khordha",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.20000000",
        "longitude": "85.60000000",
        "label": "Khordha",
        "value": "Khordha"
    },
    {
        "name": "Khurda",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.18268000",
        "longitude": "85.61629000",
        "label": "Khurda",
        "value": "Khurda"
    },
    {
        "name": "Kiri Buru",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.08333000",
        "longitude": "85.35000000",
        "label": "Kiri Buru",
        "value": "Kiri Buru"
    },
    {
        "name": "Kodala",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.62425000",
        "longitude": "84.94075000",
        "label": "Kodala",
        "value": "Kodala"
    },
    {
        "name": "Konarka",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.89758000",
        "longitude": "86.11413000",
        "label": "Konarka",
        "value": "Konarka"
    },
    {
        "name": "Koraput",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.00000000",
        "longitude": "83.00000000",
        "label": "Koraput",
        "value": "Koraput"
    },
    {
        "name": "Kuchaiburi",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.26675000",
        "longitude": "86.17385000",
        "label": "Kuchaiburi",
        "value": "Kuchaiburi"
    },
    {
        "name": "Kuchinda",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.74356000",
        "longitude": "84.34848000",
        "label": "Kuchinda",
        "value": "Kuchinda"
    },
    {
        "name": "Malakanagiri",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "18.36428000",
        "longitude": "81.88800000",
        "label": "Malakanagiri",
        "value": "Malakanagiri"
    },
    {
        "name": "Malkangiri",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "18.25000000",
        "longitude": "81.95000000",
        "label": "Malkangiri",
        "value": "Malkangiri"
    },
    {
        "name": "Mayurbhanj",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.75000000",
        "longitude": "86.50000000",
        "label": "Mayurbhanj",
        "value": "Mayurbhanj"
    },
    {
        "name": "Nabarangpur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.23330000",
        "longitude": "82.55000000",
        "label": "Nabarangpur",
        "value": "Nabarangpur"
    },
    {
        "name": "Nayagarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.12882000",
        "longitude": "85.09626000",
        "label": "Nayagarh",
        "value": "Nayagarh"
    },
    {
        "name": "Nayagarh District",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.13000000",
        "longitude": "85.10000000",
        "label": "Nayagarh District",
        "value": "Nayagarh District"
    },
    {
        "name": "Nilgiri",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.46235000",
        "longitude": "86.76794000",
        "label": "Nilgiri",
        "value": "Nilgiri"
    },
    {
        "name": "Nimaparha",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.05756000",
        "longitude": "86.00436000",
        "label": "Nimaparha",
        "value": "Nimaparha"
    },
    {
        "name": "Nowrangapur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.23114000",
        "longitude": "82.54826000",
        "label": "Nowrangapur",
        "value": "Nowrangapur"
    },
    {
        "name": "Nuapada",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.60000000",
        "longitude": "82.50000000",
        "label": "Nuapada",
        "value": "Nuapada"
    },
    {
        "name": "Padampur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.99932000",
        "longitude": "83.06325000",
        "label": "Padampur",
        "value": "Padampur"
    },
    {
        "name": "Paradip Garh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.31641000",
        "longitude": "86.60850000",
        "label": "Paradip Garh",
        "value": "Paradip Garh"
    },
    {
        "name": "Patamundai",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.57806000",
        "longitude": "86.56063000",
        "label": "Patamundai",
        "value": "Patamundai"
    },
    {
        "name": "Patnagarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.70833000",
        "longitude": "83.13263000",
        "label": "Patnagarh",
        "value": "Patnagarh"
    },
    {
        "name": "Phulbani",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.48101000",
        "longitude": "84.23063000",
        "label": "Phulbani",
        "value": "Phulbani"
    },
    {
        "name": "Pipili",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.11357000",
        "longitude": "85.83147000",
        "label": "Pipili",
        "value": "Pipili"
    },
    {
        "name": "Polasara",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.69386000",
        "longitude": "84.81401000",
        "label": "Polasara",
        "value": "Polasara"
    },
    {
        "name": "Puri",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.90000000",
        "longitude": "85.60000000",
        "label": "Puri",
        "value": "Puri"
    },
    {
        "name": "Purushottampur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.52024000",
        "longitude": "84.88514000",
        "label": "Purushottampur",
        "value": "Purushottampur"
    },
    {
        "name": "Rambha",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.51667000",
        "longitude": "85.10000000",
        "label": "Rambha",
        "value": "Rambha"
    },
    {
        "name": "Raurkela",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.22496000",
        "longitude": "84.86414000",
        "label": "Raurkela",
        "value": "Raurkela"
    },
    {
        "name": "Rayagada",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.40000000",
        "longitude": "83.50000000",
        "label": "Rayagada",
        "value": "Rayagada"
    },
    {
        "name": "Remuna",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.52798000",
        "longitude": "86.87156000",
        "label": "Remuna",
        "value": "Remuna"
    },
    {
        "name": "Rengali",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.64602000",
        "longitude": "84.05311000",
        "label": "Rengali",
        "value": "Rengali"
    },
    {
        "name": "Sambalpur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.40000000",
        "longitude": "83.88333000",
        "label": "Sambalpur",
        "value": "Sambalpur"
    },
    {
        "name": "Sonepur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.83333000",
        "longitude": "83.91667000",
        "label": "Sonepur",
        "value": "Sonepur"
    },
    {
        "name": "Sorada",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "19.76082000",
        "longitude": "84.42997000",
        "label": "Sorada",
        "value": "Sorada"
    },
    {
        "name": "Soro",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "21.27851000",
        "longitude": "86.68833000",
        "label": "Soro",
        "value": "Soro"
    },
    {
        "name": "Subarnapur",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.93154000",
        "longitude": "83.82486000",
        "label": "Subarnapur",
        "value": "Subarnapur"
    },
    {
        "name": "Sundargarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "22.25000000",
        "longitude": "84.50000000",
        "label": "Sundargarh",
        "value": "Sundargarh"
    },
    {
        "name": "Talcher",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.94927000",
        "longitude": "85.23354000",
        "label": "Talcher",
        "value": "Talcher"
    },
    {
        "name": "Tarabha",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.73252000",
        "longitude": "83.67443000",
        "label": "Tarabha",
        "value": "Tarabha"
    },
    {
        "name": "Titlagarh",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.28961000",
        "longitude": "83.15233000",
        "label": "Titlagarh",
        "value": "Titlagarh"
    },
    {
        "name": "Udayagiri",
        "countryCode": "IN",
        "stateCode": "OR",
        "latitude": "20.12416000",
        "longitude": "84.36869000",
        "label": "Udayagiri",
        "value": "Udayagiri"
    }
,{
        "name": "Karaikal",
        "countryCode": "IN",
        "stateCode": "PY",
        "latitude": "10.92209000",
        "longitude": "79.83353000",
        "label": "Karaikal",
        "value": "Karaikal"
    },
    {
        "name": "Mahe",
        "countryCode": "IN",
        "stateCode": "PY",
        "latitude": "11.70000000",
        "longitude": "75.53333000",
        "label": "Mahe",
        "value": "Mahe"
    },
    {
        "name": "Puducherry",
        "countryCode": "IN",
        "stateCode": "PY",
        "latitude": "11.93381000",
        "longitude": "79.82979000",
        "label": "Puducherry",
        "value": "Puducherry"
    },
    {
        "name": "Yanam",
        "countryCode": "IN",
        "stateCode": "PY",
        "latitude": "16.73463000",
        "longitude": "82.21773000",
        "label": "Yanam",
        "value": "Yanam"
    }
,{
        "name": "Abohar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.14453000",
        "longitude": "74.19552000",
        "label": "Abohar",
        "value": "Abohar"
    },
    {
        "name": "Adampur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.43224000",
        "longitude": "75.71484000",
        "label": "Adampur",
        "value": "Adampur"
    },
    {
        "name": "Ajitgarh",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.65000000",
        "longitude": "76.70000000",
        "label": "Ajitgarh",
        "value": "Ajitgarh"
    },
    {
        "name": "Ajnala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.84473000",
        "longitude": "74.76295000",
        "label": "Ajnala",
        "value": "Ajnala"
    },
    {
        "name": "Akalgarh",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "29.82074000",
        "longitude": "75.89078000",
        "label": "Akalgarh",
        "value": "Akalgarh"
    },
    {
        "name": "Alawalpur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.43161000",
        "longitude": "75.65614000",
        "label": "Alawalpur",
        "value": "Alawalpur"
    },
    {
        "name": "Amloh",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.60837000",
        "longitude": "76.23199000",
        "label": "Amloh",
        "value": "Amloh"
    },
    {
        "name": "Amritsar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.67000000",
        "longitude": "74.84000000",
        "label": "Amritsar",
        "value": "Amritsar"
    },
    {
        "name": "Anandpur Sahib",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.23926000",
        "longitude": "76.50253000",
        "label": "Anandpur Sahib",
        "value": "Anandpur Sahib"
    },
    {
        "name": "Badhni Kalan",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.68130000",
        "longitude": "75.29087000",
        "label": "Badhni Kalan",
        "value": "Badhni Kalan"
    },
    {
        "name": "Bagha Purana",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.68809000",
        "longitude": "75.09838000",
        "label": "Bagha Purana",
        "value": "Bagha Purana"
    },
    {
        "name": "Bakloh",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "32.47939000",
        "longitude": "75.91874000",
        "label": "Bakloh",
        "value": "Bakloh"
    },
    {
        "name": "Balachor",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.06062000",
        "longitude": "76.30166000",
        "label": "Balachor",
        "value": "Balachor"
    },
    {
        "name": "Banga",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.18874000",
        "longitude": "75.99495000",
        "label": "Banga",
        "value": "Banga"
    },
    {
        "name": "Banur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.55407000",
        "longitude": "76.71948000",
        "label": "Banur",
        "value": "Banur"
    },
    {
        "name": "Barnala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.37451000",
        "longitude": "75.54870000",
        "label": "Barnala",
        "value": "Barnala"
    },
    {
        "name": "Batala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.80921000",
        "longitude": "75.20294000",
        "label": "Batala",
        "value": "Batala"
    },
    {
        "name": "Begowal",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.61152000",
        "longitude": "75.52135000",
        "label": "Begowal",
        "value": "Begowal"
    },
    {
        "name": "Bhadaur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.47651000",
        "longitude": "75.33049000",
        "label": "Bhadaur",
        "value": "Bhadaur"
    },
    {
        "name": "Bhatinda",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.20747000",
        "longitude": "74.93893000",
        "label": "Bhatinda",
        "value": "Bhatinda"
    },
    {
        "name": "Bhawanigarh",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.26685000",
        "longitude": "76.03854000",
        "label": "Bhawanigarh",
        "value": "Bhawanigarh"
    },
    {
        "name": "Bhikhi",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.05918000",
        "longitude": "75.53500000",
        "label": "Bhikhi",
        "value": "Bhikhi"
    },
    {
        "name": "Bhogpur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.55442000",
        "longitude": "75.64271000",
        "label": "Bhogpur",
        "value": "Bhogpur"
    },
    {
        "name": "Bholath",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.54277778",
        "longitude": "75.50750000",
        "label": "Bholath",
        "value": "Bholath"
    },
    {
        "name": "Budhlada",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "29.92799000",
        "longitude": "75.56205000",
        "label": "Budhlada",
        "value": "Budhlada"
    },
    {
        "name": "Chima",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.68540000",
        "longitude": "76.08643000",
        "label": "Chima",
        "value": "Chima"
    },
    {
        "name": "Dasuya",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.81679000",
        "longitude": "75.65310000",
        "label": "Dasuya",
        "value": "Dasuya"
    },
    {
        "name": "Dera Baba Nanak",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "32.03733000",
        "longitude": "75.02787000",
        "label": "Dera Baba Nanak",
        "value": "Dera Baba Nanak"
    },
    {
        "name": "Dera Bassi",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.58720000",
        "longitude": "76.84280000",
        "label": "Dera Bassi",
        "value": "Dera Bassi"
    },
    {
        "name": "Dhanaula",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.28216000",
        "longitude": "75.57341000",
        "label": "Dhanaula",
        "value": "Dhanaula"
    },
    {
        "name": "Dhariwal",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.95616000",
        "longitude": "75.32386000",
        "label": "Dhariwal",
        "value": "Dhariwal"
    },
    {
        "name": "Dhilwan",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.51432000",
        "longitude": "75.34574000",
        "label": "Dhilwan",
        "value": "Dhilwan"
    },
    {
        "name": "Dhudi",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.69636000",
        "longitude": "74.85246000",
        "label": "Dhudi",
        "value": "Dhudi"
    },
    {
        "name": "Dhuri",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.36846000",
        "longitude": "75.86791000",
        "label": "Dhuri",
        "value": "Dhuri"
    },
    {
        "name": "Dina Nagar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "32.13664000",
        "longitude": "75.47291000",
        "label": "Dina Nagar",
        "value": "Dina Nagar"
    },
    {
        "name": "Dirba",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.07222000",
        "longitude": "75.99607000",
        "label": "Dirba",
        "value": "Dirba"
    },
    {
        "name": "Doraha",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.79953000",
        "longitude": "76.02355000",
        "label": "Doraha",
        "value": "Doraha"
    },
    {
        "name": "Faridkot",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.67399000",
        "longitude": "74.75579000",
        "label": "Faridkot",
        "value": "Faridkot"
    },
    {
        "name": "Fatehgarh Churian",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.86431000",
        "longitude": "74.95665000",
        "label": "Fatehgarh Churian",
        "value": "Fatehgarh Churian"
    },
    {
        "name": "Fatehgarh Sahib",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.64379000",
        "longitude": "76.34787000",
        "label": "Fatehgarh Sahib",
        "value": "Fatehgarh Sahib"
    },
    {
        "name": "Fazilka",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.40207000",
        "longitude": "74.02836000",
        "label": "Fazilka",
        "value": "Fazilka"
    },
    {
        "name": "Firozpur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.92574000",
        "longitude": "74.61311000",
        "label": "Firozpur",
        "value": "Firozpur"
    },
    {
        "name": "Firozpur District",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.89000000",
        "longitude": "74.56000000",
        "label": "Firozpur District",
        "value": "Firozpur District"
    },
    {
        "name": "Gardhiwala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.74147000",
        "longitude": "75.75567000",
        "label": "Gardhiwala",
        "value": "Gardhiwala"
    },
    {
        "name": "Garhshankar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.21537000",
        "longitude": "76.14149000",
        "label": "Garhshankar",
        "value": "Garhshankar"
    },
    {
        "name": "Ghanaur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.33092000",
        "longitude": "76.61203000",
        "label": "Ghanaur",
        "value": "Ghanaur"
    },
    {
        "name": "Giddarbaha",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.19953000",
        "longitude": "74.66627000",
        "label": "Giddarbaha",
        "value": "Giddarbaha"
    },
    {
        "name": "Gurdaspur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.92000000",
        "longitude": "75.27000000",
        "label": "Gurdaspur",
        "value": "Gurdaspur"
    },
    {
        "name": "Guru Har Sahai",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.70862000",
        "longitude": "74.40407000",
        "label": "Guru Har Sahai",
        "value": "Guru Har Sahai"
    },
    {
        "name": "Hajipur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.97714000",
        "longitude": "75.75438000",
        "label": "Hajipur",
        "value": "Hajipur"
    },
    {
        "name": "Hariana",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.63512000",
        "longitude": "75.83887000",
        "label": "Hariana",
        "value": "Hariana"
    },
    {
        "name": "Hoshiarpur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.53723000",
        "longitude": "75.91269000",
        "label": "Hoshiarpur",
        "value": "Hoshiarpur"
    },
    {
        "name": "Ishanpur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.63929000",
        "longitude": "76.11761000",
        "label": "Ishanpur",
        "value": "Ishanpur"
    },
    {
        "name": "Jagraon",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.78783000",
        "longitude": "75.47391000",
        "label": "Jagraon",
        "value": "Jagraon"
    },
    {
        "name": "Jaito",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.45126000",
        "longitude": "74.89189000",
        "label": "Jaito",
        "value": "Jaito"
    },
    {
        "name": "Jalalabad",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.60622000",
        "longitude": "74.25727000",
        "label": "Jalalabad",
        "value": "Jalalabad"
    },
    {
        "name": "Jalandhar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.41667000",
        "longitude": "75.61667000",
        "label": "Jalandhar",
        "value": "Jalandhar"
    },
    {
        "name": "Jandiala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.15930000",
        "longitude": "75.61755000",
        "label": "Jandiala",
        "value": "Jandiala"
    },
    {
        "name": "Jandiala Guru",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.56198000",
        "longitude": "75.02770000",
        "label": "Jandiala Guru",
        "value": "Jandiala Guru"
    },
    {
        "name": "Kalanaur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "32.01227000",
        "longitude": "75.15063000",
        "label": "Kalanaur",
        "value": "Kalanaur"
    },
    {
        "name": "Kapurthala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.38011000",
        "longitude": "75.38105000",
        "label": "Kapurthala",
        "value": "Kapurthala"
    },
    {
        "name": "Kartarpur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.44268000",
        "longitude": "75.49847000",
        "label": "Kartarpur",
        "value": "Kartarpur"
    },
    {
        "name": "Khamanon",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.81725000",
        "longitude": "76.35478000",
        "label": "Khamanon",
        "value": "Khamanon"
    },
    {
        "name": "Khanna",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.70547000",
        "longitude": "76.22196000",
        "label": "Khanna",
        "value": "Khanna"
    },
    {
        "name": "Kharar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.74632000",
        "longitude": "76.64689000",
        "label": "Kharar",
        "value": "Kharar"
    },
    {
        "name": "Khemkaran",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.14443000",
        "longitude": "74.55938000",
        "label": "Khemkaran",
        "value": "Khemkaran"
    },
    {
        "name": "Kot Isa Khan",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.94659000",
        "longitude": "75.13780000",
        "label": "Kot Isa Khan",
        "value": "Kot Isa Khan"
    },
    {
        "name": "Kotkapura",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.58190000",
        "longitude": "74.83298000",
        "label": "Kotkapura",
        "value": "Kotkapura"
    },
    {
        "name": "Laungowal",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.19393000",
        "longitude": "75.68089000",
        "label": "Laungowal",
        "value": "Laungowal"
    },
    {
        "name": "Ludhiana",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.91204000",
        "longitude": "75.85379000",
        "label": "Ludhiana",
        "value": "Ludhiana"
    },
    {
        "name": "Machhiwara",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.91557000",
        "longitude": "76.20016000",
        "label": "Machhiwara",
        "value": "Machhiwara"
    },
    {
        "name": "Majitha",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.75711000",
        "longitude": "74.95891000",
        "label": "Majitha",
        "value": "Majitha"
    },
    {
        "name": "Makhu",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.10335000",
        "longitude": "74.99631000",
        "label": "Makhu",
        "value": "Makhu"
    },
    {
        "name": "Malaut",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.21121000",
        "longitude": "74.48180000",
        "label": "Malaut",
        "value": "Malaut"
    },
    {
        "name": "Malerkotla",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.53090000",
        "longitude": "75.87949000",
        "label": "Malerkotla",
        "value": "Malerkotla"
    },
    {
        "name": "Mansa",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "29.98844000",
        "longitude": "75.40167000",
        "label": "Mansa",
        "value": "Mansa"
    },
    {
        "name": "Maur Mandi",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.08333000",
        "longitude": "75.25000000",
        "label": "Maur Mandi",
        "value": "Maur Mandi"
    },
    {
        "name": "Moga",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.80376000",
        "longitude": "75.14938000",
        "label": "Moga",
        "value": "Moga"
    },
    {
        "name": "Mohali",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.67995000",
        "longitude": "76.72211000",
        "label": "Mohali",
        "value": "Mohali"
    },
    {
        "name": "Morinda",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.79014000",
        "longitude": "76.49883000",
        "label": "Morinda",
        "value": "Morinda"
    },
    {
        "name": "Mukerian",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.95394000",
        "longitude": "75.61716000",
        "label": "Mukerian",
        "value": "Mukerian"
    },
    {
        "name": "Nabha",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.37577000",
        "longitude": "76.15292000",
        "label": "Nabha",
        "value": "Nabha"
    },
    {
        "name": "Nakodar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.12586000",
        "longitude": "75.47508000",
        "label": "Nakodar",
        "value": "Nakodar"
    },
    {
        "name": "Nangal",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.38966000",
        "longitude": "76.37574000",
        "label": "Nangal",
        "value": "Nangal"
    },
    {
        "name": "Nawanshahr",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.12450000",
        "longitude": "76.11613000",
        "label": "Nawanshahr",
        "value": "Nawanshahr"
    },
    {
        "name": "Nurmahal",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.09662000",
        "longitude": "75.59386000",
        "label": "Nurmahal",
        "value": "Nurmahal"
    },
    {
        "name": "Nurpur Kalan",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.16667000",
        "longitude": "76.48333000",
        "label": "Nurpur Kalan",
        "value": "Nurpur Kalan"
    },
    {
        "name": "Pathankot",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "32.27484000",
        "longitude": "75.65287000",
        "label": "Pathankot",
        "value": "Pathankot"
    },
    {
        "name": "Patiala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.33625000",
        "longitude": "76.39220000",
        "label": "Patiala",
        "value": "Patiala"
    },
    {
        "name": "Patti",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.28092000",
        "longitude": "74.85849000",
        "label": "Patti",
        "value": "Patti"
    },
    {
        "name": "Phagwara",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.22452000",
        "longitude": "75.77387000",
        "label": "Phagwara",
        "value": "Phagwara"
    },
    {
        "name": "Phillaur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.01887000",
        "longitude": "75.79111000",
        "label": "Phillaur",
        "value": "Phillaur"
    },
    {
        "name": "Qadian",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.82198000",
        "longitude": "75.37663000",
        "label": "Qadian",
        "value": "Qadian"
    },
    {
        "name": "Rahon",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.05275000",
        "longitude": "76.11907000",
        "label": "Rahon",
        "value": "Rahon"
    },
    {
        "name": "Raikot",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.65000000",
        "longitude": "75.60000000",
        "label": "Raikot",
        "value": "Raikot"
    },
    {
        "name": "Rajasansi",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.72021000",
        "longitude": "74.80080000",
        "label": "Rajasansi",
        "value": "Rajasansi"
    },
    {
        "name": "Rajpura",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.47856000",
        "longitude": "76.59284000",
        "label": "Rajpura",
        "value": "Rajpura"
    },
    {
        "name": "Ram Das",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.96739000",
        "longitude": "74.90870000",
        "label": "Ram Das",
        "value": "Ram Das"
    },
    {
        "name": "Rampura",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.25600000",
        "longitude": "75.24116000",
        "label": "Rampura",
        "value": "Rampura"
    },
    {
        "name": "Rupnagar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.04000000",
        "longitude": "76.52000000",
        "label": "Rupnagar",
        "value": "Rupnagar"
    },
    {
        "name": "Samrala",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.83601000",
        "longitude": "76.19324000",
        "label": "Samrala",
        "value": "Samrala"
    },
    {
        "name": "Sanaur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.30182000",
        "longitude": "76.45786000",
        "label": "Sanaur",
        "value": "Sanaur"
    },
    {
        "name": "Sangrur",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.24506000",
        "longitude": "75.84488000",
        "label": "Sangrur",
        "value": "Sangrur"
    },
    {
        "name": "Sardulgarh",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "29.69224000",
        "longitude": "75.23608000",
        "label": "Sardulgarh",
        "value": "Sardulgarh"
    },
    {
        "name": "Shahid Bhagat Singh Nagar",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.13183000",
        "longitude": "76.13328000",
        "label": "Shahid Bhagat Singh Nagar",
        "value": "Shahid Bhagat Singh Nagar"
    },
    {
        "name": "Shahkot",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.08173000",
        "longitude": "75.33708000",
        "label": "Shahkot",
        "value": "Shahkot"
    },
    {
        "name": "Sham Churasi",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.50028000",
        "longitude": "75.74917000",
        "label": "Sham Churasi",
        "value": "Sham Churasi"
    },
    {
        "name": "Sirhind-Fategarh",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.64321000",
        "longitude": "76.38421000",
        "label": "Sirhind-Fategarh",
        "value": "Sirhind-Fategarh"
    },
    {
        "name": "Sri Muktsar Sahib",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.47426000",
        "longitude": "74.51660000",
        "label": "Sri Muktsar Sahib",
        "value": "Sri Muktsar Sahib"
    },
    {
        "name": "Sultanpur Lodhi",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.21468000",
        "longitude": "75.19602000",
        "label": "Sultanpur Lodhi",
        "value": "Sultanpur Lodhi"
    },
    {
        "name": "Sunam",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.12883000",
        "longitude": "75.79943000",
        "label": "Sunam",
        "value": "Sunam"
    },
    {
        "name": "Talwandi Bhai",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.85584000",
        "longitude": "74.92979000",
        "label": "Talwandi Bhai",
        "value": "Talwandi Bhai"
    },
    {
        "name": "Talwara",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.93760000",
        "longitude": "75.88657000",
        "label": "Talwara",
        "value": "Talwara"
    },
    {
        "name": "Tarn Taran Sahib",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "31.45191000",
        "longitude": "74.92777000",
        "label": "Tarn Taran Sahib",
        "value": "Tarn Taran Sahib"
    },
    {
        "name": "Zira",
        "countryCode": "IN",
        "stateCode": "PB",
        "latitude": "30.96853000",
        "longitude": "74.99106000",
        "label": "Zira",
        "value": "Zira"
    }
,{
        "name": "Abhaneri",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.00743000",
        "longitude": "76.60760000",
        "label": "Abhaneri",
        "value": "Abhaneri"
    },
    {
        "name": "Abu",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.59365000",
        "longitude": "72.71756000",
        "label": "Abu",
        "value": "Abu"
    },
    {
        "name": "Abu Road",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.48012000",
        "longitude": "72.78186000",
        "label": "Abu Road",
        "value": "Abu Road"
    },
    {
        "name": "Ajmer",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.25000000",
        "longitude": "74.66667000",
        "label": "Ajmer",
        "value": "Ajmer"
    },
    {
        "name": "Aklera",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.41288000",
        "longitude": "76.56719000",
        "label": "Aklera",
        "value": "Aklera"
    },
    {
        "name": "Alwar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.50000000",
        "longitude": "76.50000000",
        "label": "Alwar",
        "value": "Alwar"
    },
    {
        "name": "Amet",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.30609000",
        "longitude": "73.92580000",
        "label": "Amet",
        "value": "Amet"
    },
    {
        "name": "Anta",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.15000000",
        "longitude": "76.30000000",
        "label": "Anta",
        "value": "Anta"
    },
    {
        "name": "Anupgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.19111000",
        "longitude": "73.20861000",
        "label": "Anupgarh",
        "value": "Anupgarh"
    },
    {
        "name": "Asind",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.73420000",
        "longitude": "74.33278000",
        "label": "Asind",
        "value": "Asind"
    },
    {
        "name": "Bagar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.18784000",
        "longitude": "75.50012000",
        "label": "Bagar",
        "value": "Bagar"
    },
    {
        "name": "Bakani",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.28624000",
        "longitude": "76.23709000",
        "label": "Bakani",
        "value": "Bakani"
    },
    {
        "name": "Bali",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.19725000",
        "longitude": "73.29117000",
        "label": "Bali",
        "value": "Bali"
    },
    {
        "name": "Balotra",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.83242000",
        "longitude": "72.24000000",
        "label": "Balotra",
        "value": "Balotra"
    },
    {
        "name": "Bandikui",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.05087000",
        "longitude": "76.57325000",
        "label": "Bandikui",
        "value": "Bandikui"
    },
    {
        "name": "Banswara",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "23.54109000",
        "longitude": "74.44250000",
        "label": "Banswara",
        "value": "Banswara"
    },
    {
        "name": "Baran",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.09000000",
        "longitude": "76.66000000",
        "label": "Baran",
        "value": "Baran"
    },
    {
        "name": "Bari",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.64661000",
        "longitude": "77.61634000",
        "label": "Bari",
        "value": "Bari"
    },
    {
        "name": "Bari Sadri",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.41339000",
        "longitude": "74.47331000",
        "label": "Bari Sadri",
        "value": "Bari Sadri"
    },
    {
        "name": "Barmer",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.75000000",
        "longitude": "71.50000000",
        "label": "Barmer",
        "value": "Barmer"
    },
    {
        "name": "Basi",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.83150000",
        "longitude": "76.04856000",
        "label": "Basi",
        "value": "Basi"
    },
    {
        "name": "Basni",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.17232000",
        "longitude": "73.64519000",
        "label": "Basni",
        "value": "Basni"
    },
    {
        "name": "Baswa",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.14955000",
        "longitude": "76.58345000",
        "label": "Baswa",
        "value": "Baswa"
    },
    {
        "name": "Bayana",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.90791000",
        "longitude": "77.28985000",
        "label": "Bayana",
        "value": "Bayana"
    },
    {
        "name": "Beawar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.10119000",
        "longitude": "74.32028000",
        "label": "Beawar",
        "value": "Beawar"
    },
    {
        "name": "Begun",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.98333000",
        "longitude": "75.00000000",
        "label": "Begun",
        "value": "Begun"
    },
    {
        "name": "Behror",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.88832000",
        "longitude": "76.28108000",
        "label": "Behror",
        "value": "Behror"
    },
    {
        "name": "Bhadasar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.31457000",
        "longitude": "74.28952000",
        "label": "Bhadasar",
        "value": "Bhadasar"
    },
    {
        "name": "Bhadra",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.10298000",
        "longitude": "75.17138000",
        "label": "Bhadra",
        "value": "Bhadra"
    },
    {
        "name": "Bharatpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.21000000",
        "longitude": "77.29000000",
        "label": "Bharatpur",
        "value": "Bharatpur"
    },
    {
        "name": "Bhasawar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.03895000",
        "longitude": "77.04849000",
        "label": "Bhasawar",
        "value": "Bhasawar"
    },
    {
        "name": "Bhilwara",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.50000000",
        "longitude": "74.75000000",
        "label": "Bhilwara",
        "value": "Bhilwara"
    },
    {
        "name": "Bhindar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.50235000",
        "longitude": "74.18551000",
        "label": "Bhindar",
        "value": "Bhindar"
    },
    {
        "name": "Bhinmal",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.99944000",
        "longitude": "72.27141000",
        "label": "Bhinmal",
        "value": "Bhinmal"
    },
    {
        "name": "Bhiwadi",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.21024000",
        "longitude": "76.86056000",
        "label": "Bhiwadi",
        "value": "Bhiwadi"
    },
    {
        "name": "Bhuma",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.78333000",
        "longitude": "74.93333000",
        "label": "Bhuma",
        "value": "Bhuma"
    },
    {
        "name": "Bikaner",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.01762000",
        "longitude": "73.31495000",
        "label": "Bikaner",
        "value": "Bikaner"
    },
    {
        "name": "Bilara",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.18067000",
        "longitude": "73.70550000",
        "label": "Bilara",
        "value": "Bilara"
    },
    {
        "name": "Bissau",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.24737000",
        "longitude": "75.07666000",
        "label": "Bissau",
        "value": "Bissau"
    },
    {
        "name": "Borkhera",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.52115000",
        "longitude": "75.64028000",
        "label": "Borkhera",
        "value": "Borkhera"
    },
    {
        "name": "Bundi",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.43855000",
        "longitude": "75.63735000",
        "label": "Bundi",
        "value": "Bundi"
    },
    {
        "name": "Chaksu",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.60510000",
        "longitude": "75.94814000",
        "label": "Chaksu",
        "value": "Chaksu"
    },
    {
        "name": "Chechat",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.76667000",
        "longitude": "75.88333000",
        "label": "Chechat",
        "value": "Chechat"
    },
    {
        "name": "Chhabra",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.66472000",
        "longitude": "76.84379000",
        "label": "Chhabra",
        "value": "Chhabra"
    },
    {
        "name": "Chhapar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.81900000",
        "longitude": "74.43936000",
        "label": "Chhapar",
        "value": "Chhapar"
    },
    {
        "name": "Chhoti Sadri",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.38145000",
        "longitude": "74.70120000",
        "label": "Chhoti Sadri",
        "value": "Chhoti Sadri"
    },
    {
        "name": "Chidawa",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.23937000",
        "longitude": "75.64035000",
        "label": "Chidawa",
        "value": "Chidawa"
    },
    {
        "name": "Chittaurgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.50000000",
        "longitude": "74.50000000",
        "label": "Chittaurgarh",
        "value": "Chittaurgarh"
    },
    {
        "name": "Churu",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.30415000",
        "longitude": "74.96718000",
        "label": "Churu",
        "value": "Churu"
    },
    {
        "name": "Dariba",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.94865000",
        "longitude": "74.13420000",
        "label": "Dariba",
        "value": "Dariba"
    },
    {
        "name": "Dausa",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.88269000",
        "longitude": "76.57053000",
        "label": "Dausa",
        "value": "Dausa"
    },
    {
        "name": "Deoli",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.75728000",
        "longitude": "75.37991000",
        "label": "Deoli",
        "value": "Deoli"
    },
    {
        "name": "Deshnoke",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.79836000",
        "longitude": "73.34297000",
        "label": "Deshnoke",
        "value": "Deshnoke"
    },
    {
        "name": "Devgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.52533000",
        "longitude": "73.90812000",
        "label": "Devgarh",
        "value": "Devgarh"
    },
    {
        "name": "Dhaulpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.71183000",
        "longitude": "77.73956000",
        "label": "Dhaulpur",
        "value": "Dhaulpur"
    },
    {
        "name": "Didwana",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.40096000",
        "longitude": "74.57537000",
        "label": "Didwana",
        "value": "Didwana"
    },
    {
        "name": "Dig",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.47188000",
        "longitude": "77.32564000",
        "label": "Dig",
        "value": "Dig"
    },
    {
        "name": "Dungarpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "23.84306000",
        "longitude": "73.71466000",
        "label": "Dungarpur",
        "value": "Dungarpur"
    },
    {
        "name": "Fatehpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.99486000",
        "longitude": "74.95628000",
        "label": "Fatehpur",
        "value": "Fatehpur"
    },
    {
        "name": "Galiakot",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "23.51995000",
        "longitude": "74.02028000",
        "label": "Galiakot",
        "value": "Galiakot"
    },
    {
        "name": "Ganganagar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.92009000",
        "longitude": "73.87496000",
        "label": "Ganganagar",
        "value": "Ganganagar"
    },
    {
        "name": "Gangapur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.47249000",
        "longitude": "76.71744000",
        "label": "Gangapur",
        "value": "Gangapur"
    },
    {
        "name": "Govindgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.50423000",
        "longitude": "76.99938000",
        "label": "Govindgarh",
        "value": "Govindgarh"
    },
    {
        "name": "Gulabpura",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.90448000",
        "longitude": "74.66025000",
        "label": "Gulabpura",
        "value": "Gulabpura"
    },
    {
        "name": "Hanumangarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.11000000",
        "longitude": "74.60000000",
        "label": "Hanumangarh",
        "value": "Hanumangarh"
    },
    {
        "name": "Hindaun",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.73411000",
        "longitude": "77.03519000",
        "label": "Hindaun",
        "value": "Hindaun"
    },
    {
        "name": "Jahazpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.61994000",
        "longitude": "75.27609000",
        "label": "Jahazpur",
        "value": "Jahazpur"
    },
    {
        "name": "Jaipur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.00000000",
        "longitude": "76.00000000",
        "label": "Jaipur",
        "value": "Jaipur"
    },
    {
        "name": "Jaisalmer",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.99382000",
        "longitude": "71.00889000",
        "label": "Jaisalmer",
        "value": "Jaisalmer"
    },
    {
        "name": "Jaitaran",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.20446000",
        "longitude": "73.93676000",
        "label": "Jaitaran",
        "value": "Jaitaran"
    },
    {
        "name": "Jalor",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.34558000",
        "longitude": "72.61559000",
        "label": "Jalor",
        "value": "Jalor"
    },
    {
        "name": "Jalore",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.08000000",
        "longitude": "72.29000000",
        "label": "Jalore",
        "value": "Jalore"
    },
    {
        "name": "Jhalawar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.59633000",
        "longitude": "76.16499000",
        "label": "Jhalawar",
        "value": "Jhalawar"
    },
    {
        "name": "Jhalrapatan",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.54205000",
        "longitude": "76.17242000",
        "label": "Jhalrapatan",
        "value": "Jhalrapatan"
    },
    {
        "name": "Jhunjhunun",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.12559000",
        "longitude": "75.39797000",
        "label": "Jhunjhunun",
        "value": "Jhunjhunun"
    },
    {
        "name": "Jobner",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.97257000",
        "longitude": "75.38752000",
        "label": "Jobner",
        "value": "Jobner"
    },
    {
        "name": "Jodhpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.75000000",
        "longitude": "72.75000000",
        "label": "Jodhpur",
        "value": "Jodhpur"
    },
    {
        "name": "Kaman",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.65791000",
        "longitude": "77.26908000",
        "label": "Kaman",
        "value": "Kaman"
    },
    {
        "name": "Kanor",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.43437000",
        "longitude": "74.26546000",
        "label": "Kanor",
        "value": "Kanor"
    },
    {
        "name": "Kapren",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.40529000",
        "longitude": "76.07431000",
        "label": "Kapren",
        "value": "Kapren"
    },
    {
        "name": "Karanpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.84042000",
        "longitude": "73.45519000",
        "label": "Karanpur",
        "value": "Karanpur"
    },
    {
        "name": "Karauli",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.58000000",
        "longitude": "77.10000000",
        "label": "Karauli",
        "value": "Karauli"
    },
    {
        "name": "Kekri",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.97132000",
        "longitude": "75.14992000",
        "label": "Kekri",
        "value": "Kekri"
    },
    {
        "name": "Keshorai Patan",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.29275000",
        "longitude": "75.93948000",
        "label": "Keshorai Patan",
        "value": "Keshorai Patan"
    },
    {
        "name": "Khandela",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.60499000",
        "longitude": "75.50200000",
        "label": "Khandela",
        "value": "Khandela"
    },
    {
        "name": "Khanpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.73241000",
        "longitude": "76.39601000",
        "label": "Khanpur",
        "value": "Khanpur"
    },
    {
        "name": "Khetri",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.00069000",
        "longitude": "75.78644000",
        "label": "Khetri",
        "value": "Khetri"
    },
    {
        "name": "Kishangarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.59006000",
        "longitude": "74.85397000",
        "label": "Kishangarh",
        "value": "Kishangarh"
    },
    {
        "name": "Kota",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.17512000",
        "longitude": "75.84412000",
        "label": "Kota",
        "value": "Kota"
    },
    {
        "name": "Kotputli",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.70207000",
        "longitude": "76.19911000",
        "label": "Kotputli",
        "value": "Kotputli"
    },
    {
        "name": "Kuchaman",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.14745000",
        "longitude": "74.85655000",
        "label": "Kuchaman",
        "value": "Kuchaman"
    },
    {
        "name": "Kuchera",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.98747000",
        "longitude": "73.97108000",
        "label": "Kuchera",
        "value": "Kuchera"
    },
    {
        "name": "Kumher",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.31657000",
        "longitude": "77.37079000",
        "label": "Kumher",
        "value": "Kumher"
    },
    {
        "name": "Kushalgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "23.19899000",
        "longitude": "74.45074000",
        "label": "Kushalgarh",
        "value": "Kushalgarh"
    },
    {
        "name": "Lachhmangarh Sikar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.82294000",
        "longitude": "75.02754000",
        "label": "Lachhmangarh Sikar",
        "value": "Lachhmangarh Sikar"
    },
    {
        "name": "Ladnun",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.65312000",
        "longitude": "74.39993000",
        "label": "Ladnun",
        "value": "Ladnun"
    },
    {
        "name": "Lakheri",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.67237000",
        "longitude": "76.17692000",
        "label": "Lakheri",
        "value": "Lakheri"
    },
    {
        "name": "Lalsot",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.55951000",
        "longitude": "76.32915000",
        "label": "Lalsot",
        "value": "Lalsot"
    },
    {
        "name": "Losal",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.40000000",
        "longitude": "74.91667000",
        "label": "Losal",
        "value": "Losal"
    },
    {
        "name": "Mahwah",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.04594000",
        "longitude": "76.93152000",
        "label": "Mahwah",
        "value": "Mahwah"
    },
    {
        "name": "Makrana",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.04361000",
        "longitude": "74.72445000",
        "label": "Makrana",
        "value": "Makrana"
    },
    {
        "name": "Malpura",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.28380000",
        "longitude": "75.36458000",
        "label": "Malpura",
        "value": "Malpura"
    },
    {
        "name": "Mandal",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.44126000",
        "longitude": "74.56979000",
        "label": "Mandal",
        "value": "Mandal"
    },
    {
        "name": "Mandalgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.19407000",
        "longitude": "75.07215000",
        "label": "Mandalgarh",
        "value": "Mandalgarh"
    },
    {
        "name": "Mandawar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.86374000",
        "longitude": "76.54999000",
        "label": "Mandawar",
        "value": "Mandawar"
    },
    {
        "name": "Mangrol",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.33061000",
        "longitude": "76.50973000",
        "label": "Mangrol",
        "value": "Mangrol"
    },
    {
        "name": "Manohar Thana",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.24000000",
        "longitude": "76.80182000",
        "label": "Manohar Thana",
        "value": "Manohar Thana"
    },
    {
        "name": "Manoharpur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.29769000",
        "longitude": "75.94950000",
        "label": "Manoharpur",
        "value": "Manoharpur"
    },
    {
        "name": "Meethari Marwar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.57615000",
        "longitude": "74.68661000",
        "label": "Meethari Marwar",
        "value": "Meethari Marwar"
    },
    {
        "name": "Merta",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.64859000",
        "longitude": "74.03414000",
        "label": "Merta",
        "value": "Merta"
    },
    {
        "name": "Mundwa",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.06310000",
        "longitude": "73.82304000",
        "label": "Mundwa",
        "value": "Mundwa"
    },
    {
        "name": "Nadbai",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.22288000",
        "longitude": "77.19569000",
        "label": "Nadbai",
        "value": "Nadbai"
    },
    {
        "name": "Nagar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.42397000",
        "longitude": "77.09922000",
        "label": "Nagar",
        "value": "Nagar"
    },
    {
        "name": "Nagaur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.20201000",
        "longitude": "73.73394000",
        "label": "Nagaur",
        "value": "Nagaur"
    },
    {
        "name": "Nainwa",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.77145000",
        "longitude": "75.84978000",
        "label": "Nainwa",
        "value": "Nainwa"
    },
    {
        "name": "Napasar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.96059000",
        "longitude": "73.55913000",
        "label": "Napasar",
        "value": "Napasar"
    },
    {
        "name": "Naraina",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.79069000",
        "longitude": "75.20608000",
        "label": "Naraina",
        "value": "Naraina"
    },
    {
        "name": "Nasirabad",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.30473000",
        "longitude": "74.73364000",
        "label": "Nasirabad",
        "value": "Nasirabad"
    },
    {
        "name": "Nathdwara",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.93805000",
        "longitude": "73.82392000",
        "label": "Nathdwara",
        "value": "Nathdwara"
    },
    {
        "name": "Nawa",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.01950000",
        "longitude": "75.00226000",
        "label": "Nawa",
        "value": "Nawa"
    },
    {
        "name": "Nawalgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.85161000",
        "longitude": "75.27384000",
        "label": "Nawalgarh",
        "value": "Nawalgarh"
    },
    {
        "name": "Neem ka Thana",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.73976000",
        "longitude": "75.78652000",
        "label": "Neem ka Thana",
        "value": "Neem ka Thana"
    },
    {
        "name": "Nimaj",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.14995000",
        "longitude": "74.00094000",
        "label": "Nimaj",
        "value": "Nimaj"
    },
    {
        "name": "Nimbahera",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.62166000",
        "longitude": "74.67999000",
        "label": "Nimbahera",
        "value": "Nimbahera"
    },
    {
        "name": "Niwai",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.36073000",
        "longitude": "75.91836000",
        "label": "Niwai",
        "value": "Niwai"
    },
    {
        "name": "Nohar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.18292000",
        "longitude": "74.77064000",
        "label": "Nohar",
        "value": "Nohar"
    },
    {
        "name": "Nokha",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.56155000",
        "longitude": "73.47141000",
        "label": "Nokha",
        "value": "Nokha"
    },
    {
        "name": "Padampur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.70885000",
        "longitude": "73.62539000",
        "label": "Padampur",
        "value": "Padampur"
    },
    {
        "name": "Pali",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.77276000",
        "longitude": "73.32335000",
        "label": "Pali",
        "value": "Pali"
    },
    {
        "name": "Partapur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "23.59276000",
        "longitude": "74.17396000",
        "label": "Partapur",
        "value": "Partapur"
    },
    {
        "name": "Parvatsar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.88604000",
        "longitude": "74.76602000",
        "label": "Parvatsar",
        "value": "Parvatsar"
    },
    {
        "name": "Phalodi",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.13102000",
        "longitude": "72.36826000",
        "label": "Phalodi",
        "value": "Phalodi"
    },
    {
        "name": "Phulera",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.87401000",
        "longitude": "75.24171000",
        "label": "Phulera",
        "value": "Phulera"
    },
    {
        "name": "Pilani",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.36725000",
        "longitude": "75.60352000",
        "label": "Pilani",
        "value": "Pilani"
    },
    {
        "name": "Pilibangan",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.44964000",
        "longitude": "74.10093000",
        "label": "Pilibangan",
        "value": "Pilibangan"
    },
    {
        "name": "Pindwara",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.79749000",
        "longitude": "73.05505000",
        "label": "Pindwara",
        "value": "Pindwara"
    },
    {
        "name": "Pipar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.38441000",
        "longitude": "73.54394000",
        "label": "Pipar",
        "value": "Pipar"
    },
    {
        "name": "Pirawa",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.15506000",
        "longitude": "76.02728000",
        "label": "Pirawa",
        "value": "Pirawa"
    },
    {
        "name": "Pokaran",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.92007000",
        "longitude": "71.91631000",
        "label": "Pokaran",
        "value": "Pokaran"
    },
    {
        "name": "Pratapgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.03000000",
        "longitude": "74.78000000",
        "label": "Pratapgarh",
        "value": "Pratapgarh"
    },
    {
        "name": "Pushkar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.49022000",
        "longitude": "74.55211000",
        "label": "Pushkar",
        "value": "Pushkar"
    },
    {
        "name": "Raipur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.04259000",
        "longitude": "74.02373000",
        "label": "Raipur",
        "value": "Raipur"
    },
    {
        "name": "Raisinghnagar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.53583000",
        "longitude": "73.44917000",
        "label": "Raisinghnagar",
        "value": "Raisinghnagar"
    },
    {
        "name": "Rajakhera",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.89802000",
        "longitude": "78.17100000",
        "label": "Rajakhera",
        "value": "Rajakhera"
    },
    {
        "name": "Rajaldesar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.02849000",
        "longitude": "74.47442000",
        "label": "Rajaldesar",
        "value": "Rajaldesar"
    },
    {
        "name": "Rajgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.23731000",
        "longitude": "76.62243000",
        "label": "Rajgarh",
        "value": "Rajgarh"
    },
    {
        "name": "Rajsamand",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.23822000",
        "longitude": "73.93503000",
        "label": "Rajsamand",
        "value": "Rajsamand"
    },
    {
        "name": "Ramganj Mandi",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.64648000",
        "longitude": "75.94325000",
        "label": "Ramganj Mandi",
        "value": "Ramganj Mandi"
    },
    {
        "name": "Ramgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.25097000",
        "longitude": "75.17893000",
        "label": "Ramgarh",
        "value": "Ramgarh"
    },
    {
        "name": "Rani",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.35031000",
        "longitude": "73.30885000",
        "label": "Rani",
        "value": "Rani"
    },
    {
        "name": "Ratangarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.08137000",
        "longitude": "74.61854000",
        "label": "Ratangarh",
        "value": "Ratangarh"
    },
    {
        "name": "Rawatbhata",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.92981000",
        "longitude": "75.59209000",
        "label": "Rawatbhata",
        "value": "Rawatbhata"
    },
    {
        "name": "Rawatsar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.26724000",
        "longitude": "74.40288000",
        "label": "Rawatsar",
        "value": "Rawatsar"
    },
    {
        "name": "Ringas",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.36360000",
        "longitude": "75.56838000",
        "label": "Ringas",
        "value": "Ringas"
    },
    {
        "name": "Sadri",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.18555000",
        "longitude": "73.45288000",
        "label": "Sadri",
        "value": "Sadri"
    },
    {
        "name": "Salumbar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.13524000",
        "longitude": "74.04442000",
        "label": "Salumbar",
        "value": "Salumbar"
    },
    {
        "name": "Sambhar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.90806000",
        "longitude": "75.19137000",
        "label": "Sambhar",
        "value": "Sambhar"
    },
    {
        "name": "Samdari",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.81299000",
        "longitude": "72.57879000",
        "label": "Samdari",
        "value": "Samdari"
    },
    {
        "name": "Sanchor",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.75361000",
        "longitude": "71.77280000",
        "label": "Sanchor",
        "value": "Sanchor"
    },
    {
        "name": "Sangaria",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.79886000",
        "longitude": "74.46683000",
        "label": "Sangaria",
        "value": "Sangaria"
    },
    {
        "name": "Sangod",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.92707000",
        "longitude": "76.28649000",
        "label": "Sangod",
        "value": "Sangod"
    },
    {
        "name": "Sardarshahr",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.44062000",
        "longitude": "74.49100000",
        "label": "Sardarshahr",
        "value": "Sardarshahr"
    },
    {
        "name": "Sarwar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.06272000",
        "longitude": "75.01104000",
        "label": "Sarwar",
        "value": "Sarwar"
    },
    {
        "name": "Sawai Madhopur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.02301000",
        "longitude": "76.34408000",
        "label": "Sawai Madhopur",
        "value": "Sawai Madhopur"
    },
    {
        "name": "Shahpura",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.62094000",
        "longitude": "74.92487000",
        "label": "Shahpura",
        "value": "Shahpura"
    },
    {
        "name": "Sheoganj",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.13915000",
        "longitude": "73.06784000",
        "label": "Sheoganj",
        "value": "Sheoganj"
    },
    {
        "name": "Sikar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.61206000",
        "longitude": "75.13996000",
        "label": "Sikar",
        "value": "Sikar"
    },
    {
        "name": "Sirohi",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.83333000",
        "longitude": "72.75000000",
        "label": "Sirohi",
        "value": "Sirohi"
    },
    {
        "name": "Siwana",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.65154000",
        "longitude": "72.42243000",
        "label": "Siwana",
        "value": "Siwana"
    },
    {
        "name": "Sojat",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.92493000",
        "longitude": "73.66633000",
        "label": "Sojat",
        "value": "Sojat"
    },
    {
        "name": "Sri Dungargarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.09617000",
        "longitude": "74.00868000",
        "label": "Sri Dungargarh",
        "value": "Sri Dungargarh"
    },
    {
        "name": "Sri Madhopur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.46599000",
        "longitude": "75.59736000",
        "label": "Sri Madhopur",
        "value": "Sri Madhopur"
    },
    {
        "name": "Sujangarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.70000000",
        "longitude": "74.46667000",
        "label": "Sujangarh",
        "value": "Sujangarh"
    },
    {
        "name": "Suket",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.64609000",
        "longitude": "76.04170000",
        "label": "Suket",
        "value": "Suket"
    },
    {
        "name": "Sunel",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.37065000",
        "longitude": "75.95708000",
        "label": "Sunel",
        "value": "Sunel"
    },
    {
        "name": "Surajgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.31005000",
        "longitude": "75.73271000",
        "label": "Surajgarh",
        "value": "Surajgarh"
    },
    {
        "name": "Suratgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "29.32150000",
        "longitude": "73.89979000",
        "label": "Suratgarh",
        "value": "Suratgarh"
    },
    {
        "name": "Takhatgarh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "25.32235000",
        "longitude": "73.00487000",
        "label": "Takhatgarh",
        "value": "Takhatgarh"
    },
    {
        "name": "Taranagar",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "28.66860000",
        "longitude": "75.03207000",
        "label": "Taranagar",
        "value": "Taranagar"
    },
    {
        "name": "Tijara",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.93411000",
        "longitude": "76.85541000",
        "label": "Tijara",
        "value": "Tijara"
    },
    {
        "name": "Todabhim",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.91667000",
        "longitude": "76.81667000",
        "label": "Todabhim",
        "value": "Todabhim"
    },
    {
        "name": "Todaraisingh",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.02401000",
        "longitude": "75.48182000",
        "label": "Todaraisingh",
        "value": "Todaraisingh"
    },
    {
        "name": "Tonk",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.16667000",
        "longitude": "75.58333000",
        "label": "Tonk",
        "value": "Tonk"
    },
    {
        "name": "Udaipur",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.33000000",
        "longitude": "73.77000000",
        "label": "Udaipur",
        "value": "Udaipur"
    },
    {
        "name": "Udpura",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "24.73355000",
        "longitude": "75.97514000",
        "label": "Udpura",
        "value": "Udpura"
    },
    {
        "name": "Uniara",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "26.15336000",
        "longitude": "75.21523000",
        "label": "Uniara",
        "value": "Uniara"
    },
    {
        "name": "Wer",
        "countryCode": "IN",
        "stateCode": "RJ",
        "latitude": "27.01860000",
        "longitude": "77.17636000",
        "label": "Wer",
        "value": "Wer"
    }
,{
        "name": "East District",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.33333000",
        "longitude": "88.66667000",
        "label": "East District",
        "value": "East District"
    },
    {
        "name": "Gangtok",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.32574000",
        "longitude": "88.61216000",
        "label": "Gangtok",
        "value": "Gangtok"
    },
    {
        "name": "Gyalshing",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.28952000",
        "longitude": "88.25764000",
        "label": "Gyalshing",
        "value": "Gyalshing"
    },
    {
        "name": "Jorethang",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.10696000",
        "longitude": "88.32332000",
        "label": "Jorethang",
        "value": "Jorethang"
    },
    {
        "name": "Mangan",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.50965000",
        "longitude": "88.52206000",
        "label": "Mangan",
        "value": "Mangan"
    },
    {
        "name": "Namchi",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.16494000",
        "longitude": "88.36380000",
        "label": "Namchi",
        "value": "Namchi"
    },
    {
        "name": "Naya Bazar",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.13082000",
        "longitude": "88.23972000",
        "label": "Naya Bazar",
        "value": "Naya Bazar"
    },
    {
        "name": "North District",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.66667000",
        "longitude": "88.50000000",
        "label": "North District",
        "value": "North District"
    },
    {
        "name": "Rangpo",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.17733000",
        "longitude": "88.53358000",
        "label": "Rangpo",
        "value": "Rangpo"
    },
    {
        "name": "Singtam",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.23467000",
        "longitude": "88.50168000",
        "label": "Singtam",
        "value": "Singtam"
    },
    {
        "name": "South District",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.33333000",
        "longitude": "88.41667000",
        "label": "South District",
        "value": "South District"
    },
    {
        "name": "West District",
        "countryCode": "IN",
        "stateCode": "SK",
        "latitude": "27.33333000",
        "longitude": "88.25000000",
        "label": "West District",
        "value": "West District"
    }
,{
        "name": "Abiramam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.44230000",
        "longitude": "78.43990000",
        "label": "Abiramam",
        "value": "Abiramam"
    },
    {
        "name": "Adirampattinam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.34059000",
        "longitude": "79.37905000",
        "label": "Adirampattinam",
        "value": "Adirampattinam"
    },
    {
        "name": "Aduthurai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.01542000",
        "longitude": "79.48093000",
        "label": "Aduthurai",
        "value": "Aduthurai"
    },
    {
        "name": "Alagapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.88705000",
        "longitude": "78.91758000",
        "label": "Alagapuram",
        "value": "Alagapuram"
    },
    {
        "name": "Alandur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.00250000",
        "longitude": "80.20611000",
        "label": "Alandur",
        "value": "Alandur"
    },
    {
        "name": "Alanganallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.04697000",
        "longitude": "78.09033000",
        "label": "Alanganallur",
        "value": "Alanganallur"
    },
    {
        "name": "Alangayam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.62235000",
        "longitude": "78.75207000",
        "label": "Alangayam",
        "value": "Alangayam"
    },
    {
        "name": "Alangudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.36060000",
        "longitude": "78.98492000",
        "label": "Alangudi",
        "value": "Alangudi"
    },
    {
        "name": "Alangulam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.86404000",
        "longitude": "77.49937000",
        "label": "Alangulam",
        "value": "Alangulam"
    },
    {
        "name": "Alappakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.59895000",
        "longitude": "79.71893000",
        "label": "Alappakkam",
        "value": "Alappakkam"
    },
    {
        "name": "Alwa Tirunagari",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.60635000",
        "longitude": "77.93983000",
        "label": "Alwa Tirunagari",
        "value": "Alwa Tirunagari"
    },
    {
        "name": "Ambasamudram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.71068000",
        "longitude": "77.45190000",
        "label": "Ambasamudram",
        "value": "Ambasamudram"
    },
    {
        "name": "Ambattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.09818000",
        "longitude": "80.16152000",
        "label": "Ambattur",
        "value": "Ambattur"
    },
    {
        "name": "Ambur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.79163000",
        "longitude": "78.71644000",
        "label": "Ambur",
        "value": "Ambur"
    },
    {
        "name": "Ammapettai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.79476000",
        "longitude": "79.31986000",
        "label": "Ammapettai",
        "value": "Ammapettai"
    },
    {
        "name": "Anamalais",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.58303000",
        "longitude": "76.93441000",
        "label": "Anamalais",
        "value": "Anamalais"
    },
    {
        "name": "Andippatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.99797000",
        "longitude": "77.62097000",
        "label": "Andippatti",
        "value": "Andippatti"
    },
    {
        "name": "Annamalainagar",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.40000000",
        "longitude": "79.73333000",
        "label": "Annamalainagar",
        "value": "Annamalainagar"
    },
    {
        "name": "Annavasal",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.46060000",
        "longitude": "78.70029000",
        "label": "Annavasal",
        "value": "Annavasal"
    },
    {
        "name": "Annur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.23616000",
        "longitude": "77.10514000",
        "label": "Annur",
        "value": "Annur"
    },
    {
        "name": "Anthiyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.57506000",
        "longitude": "77.59043000",
        "label": "Anthiyur",
        "value": "Anthiyur"
    },
    {
        "name": "Arakkonam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.08449000",
        "longitude": "79.67053000",
        "label": "Arakkonam",
        "value": "Arakkonam"
    },
    {
        "name": "Arantangi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.17235000",
        "longitude": "78.99118000",
        "label": "Arantangi",
        "value": "Arantangi"
    },
    {
        "name": "Arcot",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.90569000",
        "longitude": "79.31897000",
        "label": "Arcot",
        "value": "Arcot"
    },
    {
        "name": "Arimalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.25498000",
        "longitude": "78.88403000",
        "label": "Arimalam",
        "value": "Arimalam"
    },
    {
        "name": "Ariyalur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.15000000",
        "longitude": "79.25000000",
        "label": "Ariyalur",
        "value": "Ariyalur"
    },
    {
        "name": "Arni",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.66771000",
        "longitude": "79.28529000",
        "label": "Arni",
        "value": "Arni"
    },
    {
        "name": "Arumbavur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.38096000",
        "longitude": "78.72965000",
        "label": "Arumbavur",
        "value": "Arumbavur"
    },
    {
        "name": "Arumuganeri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.56880000",
        "longitude": "78.09091000",
        "label": "Arumuganeri",
        "value": "Arumuganeri"
    },
    {
        "name": "Aruppukkottai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.50960000",
        "longitude": "78.09588000",
        "label": "Aruppukkottai",
        "value": "Aruppukkottai"
    },
    {
        "name": "Aruvankad",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.36315000",
        "longitude": "76.75790000",
        "label": "Aruvankad",
        "value": "Aruvankad"
    },
    {
        "name": "Attayyampatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.53272000",
        "longitude": "78.05363000",
        "label": "Attayyampatti",
        "value": "Attayyampatti"
    },
    {
        "name": "Attur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.59414000",
        "longitude": "78.60143000",
        "label": "Attur",
        "value": "Attur"
    },
    {
        "name": "Auroville",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.00549000",
        "longitude": "79.80885000",
        "label": "Auroville",
        "value": "Auroville"
    },
    {
        "name": "Avadi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.11470000",
        "longitude": "80.10981000",
        "label": "Avadi",
        "value": "Avadi"
    },
    {
        "name": "Avinashi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.19297000",
        "longitude": "77.26865000",
        "label": "Avinashi",
        "value": "Avinashi"
    },
    {
        "name": "Ayakudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.44992000",
        "longitude": "77.55198000",
        "label": "Ayakudi",
        "value": "Ayakudi"
    },
    {
        "name": "Ayyampettai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.90141000",
        "longitude": "79.17984000",
        "label": "Ayyampettai",
        "value": "Ayyampettai"
    },
    {
        "name": "Belur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.70752000",
        "longitude": "78.41437000",
        "label": "Belur",
        "value": "Belur"
    },
    {
        "name": "Bhavani",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.44553000",
        "longitude": "77.68215000",
        "label": "Bhavani",
        "value": "Bhavani"
    },
    {
        "name": "Bodinayakkanur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.01171000",
        "longitude": "77.34976000",
        "label": "Bodinayakkanur",
        "value": "Bodinayakkanur"
    },
    {
        "name": "Chengam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.30889000",
        "longitude": "78.79137000",
        "label": "Chengam",
        "value": "Chengam"
    },
    {
        "name": "Chennai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.08784000",
        "longitude": "80.27847000",
        "label": "Chennai",
        "value": "Chennai"
    },
    {
        "name": "Chennimalai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.16378000",
        "longitude": "77.60388000",
        "label": "Chennimalai",
        "value": "Chennimalai"
    },
    {
        "name": "Chetput",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.07000000",
        "longitude": "80.24083000",
        "label": "Chetput",
        "value": "Chetput"
    },
    {
        "name": "Chettipalaiyam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.91248000",
        "longitude": "77.03699000",
        "label": "Chettipalaiyam",
        "value": "Chettipalaiyam"
    },
    {
        "name": "Cheyyar",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.66052000",
        "longitude": "79.54308000",
        "label": "Cheyyar",
        "value": "Cheyyar"
    },
    {
        "name": "Cheyyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.34948000",
        "longitude": "80.00304000",
        "label": "Cheyyur",
        "value": "Cheyyur"
    },
    {
        "name": "Chidambaram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.39933000",
        "longitude": "79.69144000",
        "label": "Chidambaram",
        "value": "Chidambaram"
    },
    {
        "name": "Chingleput",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.69184000",
        "longitude": "79.97661000",
        "label": "Chingleput",
        "value": "Chingleput"
    },
    {
        "name": "Chinna Salem",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.63422000",
        "longitude": "78.87412000",
        "label": "Chinna Salem",
        "value": "Chinna Salem"
    },
    {
        "name": "Chinnamanur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.83999000",
        "longitude": "77.38109000",
        "label": "Chinnamanur",
        "value": "Chinnamanur"
    },
    {
        "name": "Chinnasekkadu",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.16089000",
        "longitude": "80.25727000",
        "label": "Chinnasekkadu",
        "value": "Chinnasekkadu"
    },
    {
        "name": "Cholapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.35193000",
        "longitude": "77.56839000",
        "label": "Cholapuram",
        "value": "Cholapuram"
    },
    {
        "name": "Coimbatore",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.80000000",
        "longitude": "77.09000000",
        "label": "Coimbatore",
        "value": "Coimbatore"
    },
    {
        "name": "Colachel",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.17938000",
        "longitude": "77.25818000",
        "label": "Colachel",
        "value": "Colachel"
    },
    {
        "name": "Cuddalore",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.52000000",
        "longitude": "79.51000000",
        "label": "Cuddalore",
        "value": "Cuddalore"
    },
    {
        "name": "Cumbum",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.73647000",
        "longitude": "77.28470000",
        "label": "Cumbum",
        "value": "Cumbum"
    },
    {
        "name": "Denkanikota",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.53010000",
        "longitude": "77.78887000",
        "label": "Denkanikota",
        "value": "Denkanikota"
    },
    {
        "name": "Desur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.43727000",
        "longitude": "79.48145000",
        "label": "Desur",
        "value": "Desur"
    },
    {
        "name": "Devadanappatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.14673000",
        "longitude": "77.64390000",
        "label": "Devadanappatti",
        "value": "Devadanappatti"
    },
    {
        "name": "Devakottai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.94704000",
        "longitude": "78.82330000",
        "label": "Devakottai",
        "value": "Devakottai"
    },
    {
        "name": "Dhali",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.51049000",
        "longitude": "77.18806000",
        "label": "Dhali",
        "value": "Dhali"
    },
    {
        "name": "Dharapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.73828000",
        "longitude": "77.53223000",
        "label": "Dharapuram",
        "value": "Dharapuram"
    },
    {
        "name": "Dharmapuri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.12770000",
        "longitude": "78.15794000",
        "label": "Dharmapuri",
        "value": "Dharmapuri"
    },
    {
        "name": "Dindigul",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.40000000",
        "longitude": "77.80000000",
        "label": "Dindigul",
        "value": "Dindigul"
    },
    {
        "name": "Dusi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.77574000",
        "longitude": "79.67892000",
        "label": "Dusi",
        "value": "Dusi"
    },
    {
        "name": "Elayirampannai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.27033000",
        "longitude": "77.82494000",
        "label": "Elayirampannai",
        "value": "Elayirampannai"
    },
    {
        "name": "Elumalai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.86501000",
        "longitude": "77.69923000",
        "label": "Elumalai",
        "value": "Elumalai"
    },
    {
        "name": "Eral",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.62584000",
        "longitude": "78.02282000",
        "label": "Eral",
        "value": "Eral"
    },
    {
        "name": "Eraniel",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.20589000",
        "longitude": "77.31726000",
        "label": "Eraniel",
        "value": "Eraniel"
    },
    {
        "name": "Erode",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.34000000",
        "longitude": "77.55000000",
        "label": "Erode",
        "value": "Erode"
    },
    {
        "name": "Erumaippatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.14671000",
        "longitude": "78.28996000",
        "label": "Erumaippatti",
        "value": "Erumaippatti"
    },
    {
        "name": "Ettaiyapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.14405000",
        "longitude": "77.99066000",
        "label": "Ettaiyapuram",
        "value": "Ettaiyapuram"
    },
    {
        "name": "Gandhi Nagar",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.00639000",
        "longitude": "80.25417000",
        "label": "Gandhi Nagar",
        "value": "Gandhi Nagar"
    },
    {
        "name": "Gangaikondan",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.85785000",
        "longitude": "77.78019000",
        "label": "Gangaikondan",
        "value": "Gangaikondan"
    },
    {
        "name": "Gangavalli",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.49828000",
        "longitude": "78.64966000",
        "label": "Gangavalli",
        "value": "Gangavalli"
    },
    {
        "name": "Gingee",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.25282000",
        "longitude": "79.41727000",
        "label": "Gingee",
        "value": "Gingee"
    },
    {
        "name": "Gobichettipalayam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.45496000",
        "longitude": "77.44220000",
        "label": "Gobichettipalayam",
        "value": "Gobichettipalayam"
    },
    {
        "name": "Gudalur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.67826000",
        "longitude": "77.24951000",
        "label": "Gudalur",
        "value": "Gudalur"
    },
    {
        "name": "Gudiyatham",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.94601000",
        "longitude": "78.87377000",
        "label": "Gudiyatham",
        "value": "Gudiyatham"
    },
    {
        "name": "Guduvancheri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.84519000",
        "longitude": "80.06055000",
        "label": "Guduvancheri",
        "value": "Guduvancheri"
    },
    {
        "name": "Gummidipundi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.40765000",
        "longitude": "80.10879000",
        "label": "Gummidipundi",
        "value": "Gummidipundi"
    },
    {
        "name": "Harur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.05267000",
        "longitude": "78.48023000",
        "label": "Harur",
        "value": "Harur"
    },
    {
        "name": "Hosur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.73647000",
        "longitude": "77.83264000",
        "label": "Hosur",
        "value": "Hosur"
    },
    {
        "name": "Idappadi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.58624000",
        "longitude": "77.83891000",
        "label": "Idappadi",
        "value": "Idappadi"
    },
    {
        "name": "Ilampillai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.60659000",
        "longitude": "78.00676000",
        "label": "Ilampillai",
        "value": "Ilampillai"
    },
    {
        "name": "Iluppur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.51347000",
        "longitude": "78.62357000",
        "label": "Iluppur",
        "value": "Iluppur"
    },
    {
        "name": "Injambakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.91620000",
        "longitude": "80.24880000",
        "label": "Injambakkam",
        "value": "Injambakkam"
    },
    {
        "name": "Irugur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.01782000",
        "longitude": "77.06285000",
        "label": "Irugur",
        "value": "Irugur"
    },
    {
        "name": "Jalakandapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.69779000",
        "longitude": "77.87298000",
        "label": "Jalakandapuram",
        "value": "Jalakandapuram"
    },
    {
        "name": "Jalarpet",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.57025000",
        "longitude": "78.57318000",
        "label": "Jalarpet",
        "value": "Jalarpet"
    },
    {
        "name": "Jayamkondacholapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.21266000",
        "longitude": "79.36369000",
        "label": "Jayamkondacholapuram",
        "value": "Jayamkondacholapuram"
    },
    {
        "name": "Kadambur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.99739000",
        "longitude": "77.86191000",
        "label": "Kadambur",
        "value": "Kadambur"
    },
    {
        "name": "Kadayanallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.07277000",
        "longitude": "77.34152000",
        "label": "Kadayanallur",
        "value": "Kadayanallur"
    },
    {
        "name": "Kalakkadu",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.51380000",
        "longitude": "77.54944000",
        "label": "Kalakkadu",
        "value": "Kalakkadu"
    },
    {
        "name": "Kalavai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.77029000",
        "longitude": "79.41999000",
        "label": "Kalavai",
        "value": "Kalavai"
    },
    {
        "name": "Kallakkurichchi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.74040000",
        "longitude": "78.95900000",
        "label": "Kallakkurichchi",
        "value": "Kallakkurichchi"
    },
    {
        "name": "Kallidaikurichi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.68591000",
        "longitude": "77.46592000",
        "label": "Kallidaikurichi",
        "value": "Kallidaikurichi"
    },
    {
        "name": "Kallupatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.71667000",
        "longitude": "77.86667000",
        "label": "Kallupatti",
        "value": "Kallupatti"
    },
    {
        "name": "Kalugumalai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.14941000",
        "longitude": "77.70569000",
        "label": "Kalugumalai",
        "value": "Kalugumalai"
    },
    {
        "name": "Kamuthi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.40732000",
        "longitude": "78.37337000",
        "label": "Kamuthi",
        "value": "Kamuthi"
    },
    {
        "name": "Kanadukattan",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.17209000",
        "longitude": "78.77935000",
        "label": "Kanadukattan",
        "value": "Kanadukattan"
    },
    {
        "name": "Kancheepuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.67000000",
        "longitude": "79.99000000",
        "label": "Kancheepuram",
        "value": "Kancheepuram"
    },
    {
        "name": "Kanchipuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.83515000",
        "longitude": "79.70006000",
        "label": "Kanchipuram",
        "value": "Kanchipuram"
    },
    {
        "name": "Kangayam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.00599000",
        "longitude": "77.56090000",
        "label": "Kangayam",
        "value": "Kangayam"
    },
    {
        "name": "Kanniyakumari",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.32000000",
        "longitude": "77.34000000",
        "label": "Kanniyakumari",
        "value": "Kanniyakumari"
    },
    {
        "name": "Karaikkudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.06615000",
        "longitude": "78.76784000",
        "label": "Karaikkudi",
        "value": "Karaikkudi"
    },
    {
        "name": "Karamadai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.24058000",
        "longitude": "76.96009000",
        "label": "Karamadai",
        "value": "Karamadai"
    },
    {
        "name": "Karambakkudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.45866000",
        "longitude": "79.14101000",
        "label": "Karambakkudi",
        "value": "Karambakkudi"
    },
    {
        "name": "Kariapatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.67505000",
        "longitude": "78.09992000",
        "label": "Kariapatti",
        "value": "Kariapatti"
    },
    {
        "name": "Karumbakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.70203000",
        "longitude": "80.09110000",
        "label": "Karumbakkam",
        "value": "Karumbakkam"
    },
    {
        "name": "Karur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.95771000",
        "longitude": "78.08095000",
        "label": "Karur",
        "value": "Karur"
    },
    {
        "name": "Katpadi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.96951000",
        "longitude": "79.14552000",
        "label": "Katpadi",
        "value": "Katpadi"
    },
    {
        "name": "Kattivakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.21667000",
        "longitude": "80.31667000",
        "label": "Kattivakkam",
        "value": "Kattivakkam"
    },
    {
        "name": "Kattupputtur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.99385000",
        "longitude": "78.21929000",
        "label": "Kattupputtur",
        "value": "Kattupputtur"
    },
    {
        "name": "Kaveripatnam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.42186000",
        "longitude": "78.21880000",
        "label": "Kaveripatnam",
        "value": "Kaveripatnam"
    },
    {
        "name": "Kayalpattinam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.57143000",
        "longitude": "78.11992000",
        "label": "Kayalpattinam",
        "value": "Kayalpattinam"
    },
    {
        "name": "Kayattar",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.94834000",
        "longitude": "77.77424000",
        "label": "Kayattar",
        "value": "Kayattar"
    },
    {
        "name": "Keelakarai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.23183000",
        "longitude": "78.78545000",
        "label": "Keelakarai",
        "value": "Keelakarai"
    },
    {
        "name": "Kelamangalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.60307000",
        "longitude": "77.85193000",
        "label": "Kelamangalam",
        "value": "Kelamangalam"
    },
    {
        "name": "Kil Bhuvanagiri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.44216000",
        "longitude": "79.64763000",
        "label": "Kil Bhuvanagiri",
        "value": "Kil Bhuvanagiri"
    },
    {
        "name": "Kilvelur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.76721000",
        "longitude": "79.74186000",
        "label": "Kilvelur",
        "value": "Kilvelur"
    },
    {
        "name": "Kiranur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.56988000",
        "longitude": "78.78682000",
        "label": "Kiranur",
        "value": "Kiranur"
    },
    {
        "name": "Kodaikanal",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.23925000",
        "longitude": "77.48932000",
        "label": "Kodaikanal",
        "value": "Kodaikanal"
    },
    {
        "name": "Kodumudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.07751000",
        "longitude": "77.88363000",
        "label": "Kodumudi",
        "value": "Kodumudi"
    },
    {
        "name": "Kombai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.84745000",
        "longitude": "77.29603000",
        "label": "Kombai",
        "value": "Kombai"
    },
    {
        "name": "Konganapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.57105000",
        "longitude": "77.90040000",
        "label": "Konganapuram",
        "value": "Konganapuram"
    },
    {
        "name": "Koothanallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.71990000",
        "longitude": "79.51570000",
        "label": "Koothanallur",
        "value": "Koothanallur"
    },
    {
        "name": "Koradachcheri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.77019000",
        "longitude": "79.49158000",
        "label": "Koradachcheri",
        "value": "Koradachcheri"
    },
    {
        "name": "Korampallam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.77506000",
        "longitude": "78.09158000",
        "label": "Korampallam",
        "value": "Korampallam"
    },
    {
        "name": "Kotagiri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.42072000",
        "longitude": "76.86035000",
        "label": "Kotagiri",
        "value": "Kotagiri"
    },
    {
        "name": "Kottaiyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.10956000",
        "longitude": "78.79560000",
        "label": "Kottaiyur",
        "value": "Kottaiyur"
    },
    {
        "name": "Kovilpatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.17167000",
        "longitude": "77.86989000",
        "label": "Kovilpatti",
        "value": "Kovilpatti"
    },
    {
        "name": "Krishnagiri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.58000000",
        "longitude": "77.96000000",
        "label": "Krishnagiri",
        "value": "Krishnagiri"
    },
    {
        "name": "Kulattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.00320000",
        "longitude": "78.19280000",
        "label": "Kulattur",
        "value": "Kulattur"
    },
    {
        "name": "Kulittalai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.93487000",
        "longitude": "78.41251000",
        "label": "Kulittalai",
        "value": "Kulittalai"
    },
    {
        "name": "Kumaralingam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.48936000",
        "longitude": "77.34990000",
        "label": "Kumaralingam",
        "value": "Kumaralingam"
    },
    {
        "name": "Kumbakonam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.96209000",
        "longitude": "79.39124000",
        "label": "Kumbakonam",
        "value": "Kumbakonam"
    },
    {
        "name": "Kunnattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.34782000",
        "longitude": "78.51046000",
        "label": "Kunnattur",
        "value": "Kunnattur"
    },
    {
        "name": "Kurinjippadi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.55028000",
        "longitude": "79.59066000",
        "label": "Kurinjippadi",
        "value": "Kurinjippadi"
    },
    {
        "name": "Kuttalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.93030000",
        "longitude": "77.26951000",
        "label": "Kuttalam",
        "value": "Kuttalam"
    },
    {
        "name": "Kuzhithurai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.31792000",
        "longitude": "77.19192000",
        "label": "Kuzhithurai",
        "value": "Kuzhithurai"
    },
    {
        "name": "Lalgudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.87419000",
        "longitude": "78.81935000",
        "label": "Lalgudi",
        "value": "Lalgudi"
    },
    {
        "name": "Madambakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.85250000",
        "longitude": "80.04667000",
        "label": "Madambakkam",
        "value": "Madambakkam"
    },
    {
        "name": "Madipakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.96226000",
        "longitude": "80.19864000",
        "label": "Madipakkam",
        "value": "Madipakkam"
    },
    {
        "name": "Madukkarai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.90568000",
        "longitude": "76.96344000",
        "label": "Madukkarai",
        "value": "Madukkarai"
    },
    {
        "name": "Madukkur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.48098000",
        "longitude": "79.39939000",
        "label": "Madukkur",
        "value": "Madukkur"
    },
    {
        "name": "Madurai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.89000000",
        "longitude": "78.03000000",
        "label": "Madurai",
        "value": "Madurai"
    },
    {
        "name": "Madurantakam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.51167000",
        "longitude": "79.88485000",
        "label": "Madurantakam",
        "value": "Madurantakam"
    },
    {
        "name": "Mallapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.98231000",
        "longitude": "78.24796000",
        "label": "Mallapuram",
        "value": "Mallapuram"
    },
    {
        "name": "Mallasamudram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.49333000",
        "longitude": "78.03119000",
        "label": "Mallasamudram",
        "value": "Mallasamudram"
    },
    {
        "name": "Mallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.54424000",
        "longitude": "78.14078000",
        "label": "Mallur",
        "value": "Mallur"
    },
    {
        "name": "Manali",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.16667000",
        "longitude": "80.26667000",
        "label": "Manali",
        "value": "Manali"
    },
    {
        "name": "Manalurpettai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.00788000",
        "longitude": "79.09184000",
        "label": "Manalurpettai",
        "value": "Manalurpettai"
    },
    {
        "name": "Manamadurai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.67318000",
        "longitude": "78.47096000",
        "label": "Manamadurai",
        "value": "Manamadurai"
    },
    {
        "name": "Manappakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.01083000",
        "longitude": "80.16861000",
        "label": "Manappakkam",
        "value": "Manappakkam"
    },
    {
        "name": "Manapparai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.60772000",
        "longitude": "78.42582000",
        "label": "Manapparai",
        "value": "Manapparai"
    },
    {
        "name": "Manavalakurichi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.14776000",
        "longitude": "77.30552000",
        "label": "Manavalakurichi",
        "value": "Manavalakurichi"
    },
    {
        "name": "Mandapam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.27571000",
        "longitude": "79.12362000",
        "label": "Mandapam",
        "value": "Mandapam"
    },
    {
        "name": "Mangalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.76473000",
        "longitude": "78.64037000",
        "label": "Mangalam",
        "value": "Mangalam"
    },
    {
        "name": "Mannargudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.66626000",
        "longitude": "79.45064000",
        "label": "Mannargudi",
        "value": "Mannargudi"
    },
    {
        "name": "Marakkanam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.19214000",
        "longitude": "79.94193000",
        "label": "Marakkanam",
        "value": "Marakkanam"
    },
    {
        "name": "Marandahalli",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.38826000",
        "longitude": "78.00316000",
        "label": "Marandahalli",
        "value": "Marandahalli"
    },
    {
        "name": "Masinigudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.56831000",
        "longitude": "76.64087000",
        "label": "Masinigudi",
        "value": "Masinigudi"
    },
    {
        "name": "Mattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.32147000",
        "longitude": "79.20245000",
        "label": "Mattur",
        "value": "Mattur"
    },
    {
        "name": "Mayiladuthurai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.10354000",
        "longitude": "79.65500000",
        "label": "Mayiladuthurai",
        "value": "Mayiladuthurai"
    },
    {
        "name": "Melur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.03241000",
        "longitude": "78.33930000",
        "label": "Melur",
        "value": "Melur"
    },
    {
        "name": "Mettuppalaiyam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.16806000",
        "longitude": "78.44944000",
        "label": "Mettuppalaiyam",
        "value": "Mettuppalaiyam"
    },
    {
        "name": "Mettur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.78796000",
        "longitude": "77.80080000",
        "label": "Mettur",
        "value": "Mettur"
    },
    {
        "name": "Minjur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.27951000",
        "longitude": "80.25815000",
        "label": "Minjur",
        "value": "Minjur"
    },
    {
        "name": "Mohanur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.05936000",
        "longitude": "78.13965000",
        "label": "Mohanur",
        "value": "Mohanur"
    },
    {
        "name": "Mudukulattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.34169000",
        "longitude": "78.51388000",
        "label": "Mudukulattur",
        "value": "Mudukulattur"
    },
    {
        "name": "Mulanur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.79426000",
        "longitude": "77.71150000",
        "label": "Mulanur",
        "value": "Mulanur"
    },
    {
        "name": "Musiri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.95299000",
        "longitude": "78.44427000",
        "label": "Musiri",
        "value": "Musiri"
    },
    {
        "name": "Muttupet",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.39505000",
        "longitude": "79.49353000",
        "label": "Muttupet",
        "value": "Muttupet"
    },
    {
        "name": "Naduvattam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.48075000",
        "longitude": "76.54365000",
        "label": "Naduvattam",
        "value": "Naduvattam"
    },
    {
        "name": "Nagapattinam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.85000000",
        "longitude": "79.74000000",
        "label": "Nagapattinam",
        "value": "Nagapattinam"
    },
    {
        "name": "Nagercoil",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.17899000",
        "longitude": "77.43227000",
        "label": "Nagercoil",
        "value": "Nagercoil"
    },
    {
        "name": "Namagiripettai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.45513000",
        "longitude": "78.26818000",
        "label": "Namagiripettai",
        "value": "Namagiripettai"
    },
    {
        "name": "Namakkal",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.30000000",
        "longitude": "78.13000000",
        "label": "Namakkal",
        "value": "Namakkal"
    },
    {
        "name": "Nambiyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.35811000",
        "longitude": "77.32115000",
        "label": "Nambiyur",
        "value": "Nambiyur"
    },
    {
        "name": "Nambutalai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.72766000",
        "longitude": "79.00707000",
        "label": "Nambutalai",
        "value": "Nambutalai"
    },
    {
        "name": "Nandambakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.97795000",
        "longitude": "80.06781000",
        "label": "Nandambakkam",
        "value": "Nandambakkam"
    },
    {
        "name": "Nangavalli",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.76189000",
        "longitude": "77.89093000",
        "label": "Nangavalli",
        "value": "Nangavalli"
    },
    {
        "name": "Nangilickondan",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.25539000",
        "longitude": "79.47508000",
        "label": "Nangilickondan",
        "value": "Nangilickondan"
    },
    {
        "name": "Nanguneri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.49326000",
        "longitude": "77.65806000",
        "label": "Nanguneri",
        "value": "Nanguneri"
    },
    {
        "name": "Nannilam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.87933000",
        "longitude": "79.61062000",
        "label": "Nannilam",
        "value": "Nannilam"
    },
    {
        "name": "Naravarikuppam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.19133000",
        "longitude": "80.18473000",
        "label": "Naravarikuppam",
        "value": "Naravarikuppam"
    },
    {
        "name": "Nattam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.22776000",
        "longitude": "78.22969000",
        "label": "Nattam",
        "value": "Nattam"
    },
    {
        "name": "Nattarasankottai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.86905000",
        "longitude": "78.55305000",
        "label": "Nattarasankottai",
        "value": "Nattarasankottai"
    },
    {
        "name": "Needamangalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.77378000",
        "longitude": "79.41875000",
        "label": "Needamangalam",
        "value": "Needamangalam"
    },
    {
        "name": "Neelankarai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.94950000",
        "longitude": "80.25920000",
        "label": "Neelankarai",
        "value": "Neelankarai"
    },
    {
        "name": "Negapatam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.76377000",
        "longitude": "79.84313000",
        "label": "Negapatam",
        "value": "Negapatam"
    },
    {
        "name": "Nellikkuppam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.77554000",
        "longitude": "79.67016000",
        "label": "Nellikkuppam",
        "value": "Nellikkuppam"
    },
    {
        "name": "Nilakottai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.16500000",
        "longitude": "77.85024000",
        "label": "Nilakottai",
        "value": "Nilakottai"
    },
    {
        "name": "Nilgiris",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.46000000",
        "longitude": "76.64000000",
        "label": "Nilgiris",
        "value": "Nilgiris"
    },
    {
        "name": "Odugattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.76793000",
        "longitude": "78.88304000",
        "label": "Odugattur",
        "value": "Odugattur"
    },
    {
        "name": "Omalur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.74099000",
        "longitude": "78.04559000",
        "label": "Omalur",
        "value": "Omalur"
    },
    {
        "name": "Ooty",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.41340000",
        "longitude": "76.69521000",
        "label": "Ooty",
        "value": "Ooty"
    },
    {
        "name": "Padmanabhapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.24462000",
        "longitude": "77.32581000",
        "label": "Padmanabhapuram",
        "value": "Padmanabhapuram"
    },
    {
        "name": "Palakkodu",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.30696000",
        "longitude": "78.07022000",
        "label": "Palakkodu",
        "value": "Palakkodu"
    },
    {
        "name": "Palamedu",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.10501000",
        "longitude": "78.11336000",
        "label": "Palamedu",
        "value": "Palamedu"
    },
    {
        "name": "Palani",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.45034000",
        "longitude": "77.52090000",
        "label": "Palani",
        "value": "Palani"
    },
    {
        "name": "Palavakkam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.95350000",
        "longitude": "80.25720000",
        "label": "Palavakkam",
        "value": "Palavakkam"
    },
    {
        "name": "Palladam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.99175000",
        "longitude": "77.28633000",
        "label": "Palladam",
        "value": "Palladam"
    },
    {
        "name": "Pallappatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.72057000",
        "longitude": "77.87951000",
        "label": "Pallappatti",
        "value": "Pallappatti"
    },
    {
        "name": "Pallattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.14609000",
        "longitude": "78.80309000",
        "label": "Pallattur",
        "value": "Pallattur"
    },
    {
        "name": "Pallavaram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.96796000",
        "longitude": "80.15025000",
        "label": "Pallavaram",
        "value": "Pallavaram"
    },
    {
        "name": "Pallikondai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.90518000",
        "longitude": "78.94270000",
        "label": "Pallikondai",
        "value": "Pallikondai"
    },
    {
        "name": "Pallipattu",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.33860000",
        "longitude": "79.44489000",
        "label": "Pallipattu",
        "value": "Pallipattu"
    },
    {
        "name": "Pallippatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.93990000",
        "longitude": "78.40161000",
        "label": "Pallippatti",
        "value": "Pallippatti"
    },
    {
        "name": "Panruti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.77662000",
        "longitude": "79.55269000",
        "label": "Panruti",
        "value": "Panruti"
    },
    {
        "name": "Papanasam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.92687000",
        "longitude": "79.27056000",
        "label": "Papanasam",
        "value": "Papanasam"
    },
    {
        "name": "Papireddippatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.91774000",
        "longitude": "78.36865000",
        "label": "Papireddippatti",
        "value": "Papireddippatti"
    },
    {
        "name": "Papparappatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.22086000",
        "longitude": "78.05920000",
        "label": "Papparappatti",
        "value": "Papparappatti"
    },
    {
        "name": "Paramagudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.54633000",
        "longitude": "78.59070000",
        "label": "Paramagudi",
        "value": "Paramagudi"
    },
    {
        "name": "Pattukkottai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.42358000",
        "longitude": "79.31949000",
        "label": "Pattukkottai",
        "value": "Pattukkottai"
    },
    {
        "name": "Pennadam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.40389000",
        "longitude": "79.24156000",
        "label": "Pennadam",
        "value": "Pennadam"
    },
    {
        "name": "Pennagaram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.13433000",
        "longitude": "77.89525000",
        "label": "Pennagaram",
        "value": "Pennagaram"
    },
    {
        "name": "Pennathur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.24681000",
        "longitude": "79.22592000",
        "label": "Pennathur",
        "value": "Pennathur"
    },
    {
        "name": "Peraiyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.73579000",
        "longitude": "77.78955000",
        "label": "Peraiyur",
        "value": "Peraiyur"
    },
    {
        "name": "Perambalur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.27200000",
        "longitude": "78.87380000",
        "label": "Perambalur",
        "value": "Perambalur"
    },
    {
        "name": "Peranamallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.57052000",
        "longitude": "79.43332000",
        "label": "Peranamallur",
        "value": "Peranamallur"
    },
    {
        "name": "Peranampattu",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.93430000",
        "longitude": "78.71890000",
        "label": "Peranampattu",
        "value": "Peranampattu"
    },
    {
        "name": "Peravurani",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.29035000",
        "longitude": "79.20156000",
        "label": "Peravurani",
        "value": "Peravurani"
    },
    {
        "name": "Periyakulam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.12268000",
        "longitude": "77.54372000",
        "label": "Periyakulam",
        "value": "Periyakulam"
    },
    {
        "name": "Periyanayakkanpalaiyam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.15255000",
        "longitude": "76.95159000",
        "label": "Periyanayakkanpalaiyam",
        "value": "Periyanayakkanpalaiyam"
    },
    {
        "name": "Periyanegamam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.74317000",
        "longitude": "77.10296000",
        "label": "Periyanegamam",
        "value": "Periyanegamam"
    },
    {
        "name": "Periyapatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.75812000",
        "longitude": "77.27087000",
        "label": "Periyapatti",
        "value": "Periyapatti"
    },
    {
        "name": "Periyapattinam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.27263000",
        "longitude": "78.90232000",
        "label": "Periyapattinam",
        "value": "Periyapattinam"
    },
    {
        "name": "Perundurai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.27564000",
        "longitude": "77.58794000",
        "label": "Perundurai",
        "value": "Perundurai"
    },
    {
        "name": "Perungudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.96095000",
        "longitude": "80.24094000",
        "label": "Perungudi",
        "value": "Perungudi"
    },
    {
        "name": "Perur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.97519000",
        "longitude": "76.91292000",
        "label": "Perur",
        "value": "Perur"
    },
    {
        "name": "Pollachi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.65825000",
        "longitude": "77.00850000",
        "label": "Pollachi",
        "value": "Pollachi"
    },
    {
        "name": "Polur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.51217000",
        "longitude": "79.12405000",
        "label": "Polur",
        "value": "Polur"
    },
    {
        "name": "Ponnamaravati",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.28032000",
        "longitude": "78.53601000",
        "label": "Ponnamaravati",
        "value": "Ponnamaravati"
    },
    {
        "name": "Ponneri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.33868000",
        "longitude": "80.19487000",
        "label": "Ponneri",
        "value": "Ponneri"
    },
    {
        "name": "Poonamalle",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.04888000",
        "longitude": "80.11488000",
        "label": "Poonamalle",
        "value": "Poonamalle"
    },
    {
        "name": "Porur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.03565000",
        "longitude": "80.15821000",
        "label": "Porur",
        "value": "Porur"
    },
    {
        "name": "Pudukkottai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.35000000",
        "longitude": "78.90000000",
        "label": "Pudukkottai",
        "value": "Pudukkottai"
    },
    {
        "name": "Puduppatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.15217000",
        "longitude": "78.21205000",
        "label": "Puduppatti",
        "value": "Puduppatti"
    },
    {
        "name": "Pudur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.99801000",
        "longitude": "79.14352000",
        "label": "Pudur",
        "value": "Pudur"
    },
    {
        "name": "Puduvayal",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.33015000",
        "longitude": "80.14577000",
        "label": "Puduvayal",
        "value": "Puduvayal"
    },
    {
        "name": "Puliyangudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.17489000",
        "longitude": "77.39799000",
        "label": "Puliyangudi",
        "value": "Puliyangudi"
    },
    {
        "name": "Puliyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.63375000",
        "longitude": "78.84139000",
        "label": "Puliyur",
        "value": "Puliyur"
    },
    {
        "name": "Pullambadi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.94110000",
        "longitude": "78.91041000",
        "label": "Pullambadi",
        "value": "Pullambadi"
    },
    {
        "name": "Punjai Puliyampatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.35163000",
        "longitude": "77.16671000",
        "label": "Punjai Puliyampatti",
        "value": "Punjai Puliyampatti"
    },
    {
        "name": "Rajapalaiyam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.45296000",
        "longitude": "77.55335000",
        "label": "Rajapalaiyam",
        "value": "Rajapalaiyam"
    },
    {
        "name": "Ramanathapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.37158000",
        "longitude": "78.83077000",
        "label": "Ramanathapuram",
        "value": "Ramanathapuram"
    },
    {
        "name": "Rameswaram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.28850000",
        "longitude": "79.31271000",
        "label": "Rameswaram",
        "value": "Rameswaram"
    },
    {
        "name": "Ranipet",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.95347490",
        "longitude": "79.28159160",
        "label": "Ranipet",
        "value": "Ranipet"
    },
    {
        "name": "Rasipuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.46009000",
        "longitude": "78.18635000",
        "label": "Rasipuram",
        "value": "Rasipuram"
    },
    {
        "name": "Saint Thomas Mount",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.00334000",
        "longitude": "80.19614000",
        "label": "Saint Thomas Mount",
        "value": "Saint Thomas Mount"
    },
    {
        "name": "Salem",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.69000000",
        "longitude": "78.29000000",
        "label": "Salem",
        "value": "Salem"
    },
    {
        "name": "Sathankulam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.44164000",
        "longitude": "77.91349000",
        "label": "Sathankulam",
        "value": "Sathankulam"
    },
    {
        "name": "Sathyamangalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.50526000",
        "longitude": "77.23826000",
        "label": "Sathyamangalam",
        "value": "Sathyamangalam"
    },
    {
        "name": "Sattur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.35592000",
        "longitude": "77.92457000",
        "label": "Sattur",
        "value": "Sattur"
    },
    {
        "name": "Sayalkudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.16925000",
        "longitude": "78.44702000",
        "label": "Sayalkudi",
        "value": "Sayalkudi"
    },
    {
        "name": "Seven Pagodas",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.62091000",
        "longitude": "80.19331000",
        "label": "Seven Pagodas",
        "value": "Seven Pagodas"
    },
    {
        "name": "Sholinghur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.11810000",
        "longitude": "79.42025000",
        "label": "Sholinghur",
        "value": "Sholinghur"
    },
    {
        "name": "Singanallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.99898000",
        "longitude": "77.03238000",
        "label": "Singanallur",
        "value": "Singanallur"
    },
    {
        "name": "Singapperumalkovil",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.75947000",
        "longitude": "80.00750000",
        "label": "Singapperumalkovil",
        "value": "Singapperumalkovil"
    },
    {
        "name": "Sirkazhi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.23725000",
        "longitude": "79.73585000",
        "label": "Sirkazhi",
        "value": "Sirkazhi"
    },
    {
        "name": "Sirumugai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.32137000",
        "longitude": "77.00521000",
        "label": "Sirumugai",
        "value": "Sirumugai"
    },
    {
        "name": "Sivaganga",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.92762000",
        "longitude": "78.53763000",
        "label": "Sivaganga",
        "value": "Sivaganga"
    },
    {
        "name": "Sivagiri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.34461000",
        "longitude": "77.42911000",
        "label": "Sivagiri",
        "value": "Sivagiri"
    },
    {
        "name": "Sivakasi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.44999000",
        "longitude": "77.79797000",
        "label": "Sivakasi",
        "value": "Sivakasi"
    },
    {
        "name": "Srimushnam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.40118000",
        "longitude": "79.40384000",
        "label": "Srimushnam",
        "value": "Srimushnam"
    },
    {
        "name": "Sriperumbudur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.96763000",
        "longitude": "79.94197000",
        "label": "Sriperumbudur",
        "value": "Sriperumbudur"
    },
    {
        "name": "Srivaikuntam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.62931000",
        "longitude": "77.91281000",
        "label": "Srivaikuntam",
        "value": "Srivaikuntam"
    },
    {
        "name": "Srivilliputhur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.51272000",
        "longitude": "77.63369000",
        "label": "Srivilliputhur",
        "value": "Srivilliputhur"
    },
    {
        "name": "Suchindram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.15442000",
        "longitude": "77.46704000",
        "label": "Suchindram",
        "value": "Suchindram"
    },
    {
        "name": "Sulur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.02427000",
        "longitude": "77.12565000",
        "label": "Sulur",
        "value": "Sulur"
    },
    {
        "name": "Surandai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.97574000",
        "longitude": "77.41923000",
        "label": "Surandai",
        "value": "Surandai"
    },
    {
        "name": "Swamimalai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.95747000",
        "longitude": "79.32931000",
        "label": "Swamimalai",
        "value": "Swamimalai"
    },
    {
        "name": "Tambaram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.92460000",
        "longitude": "80.12707000",
        "label": "Tambaram",
        "value": "Tambaram"
    },
    {
        "name": "Tanjore",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.78523000",
        "longitude": "79.13909000",
        "label": "Tanjore",
        "value": "Tanjore"
    },
    {
        "name": "Taramangalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.69403000",
        "longitude": "77.97035000",
        "label": "Taramangalam",
        "value": "Taramangalam"
    },
    {
        "name": "Tattayyangarpettai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.12417000",
        "longitude": "78.44916000",
        "label": "Tattayyangarpettai",
        "value": "Tattayyangarpettai"
    },
    {
        "name": "Thanjavur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.64000000",
        "longitude": "79.22000000",
        "label": "Thanjavur",
        "value": "Thanjavur"
    },
    {
        "name": "Tharangambadi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.02764000",
        "longitude": "79.85425000",
        "label": "Tharangambadi",
        "value": "Tharangambadi"
    },
    {
        "name": "Theni",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.85000000",
        "longitude": "77.42000000",
        "label": "Theni",
        "value": "Theni"
    },
    {
        "name": "Thenkasi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.96003000",
        "longitude": "77.31525000",
        "label": "Thenkasi",
        "value": "Thenkasi"
    },
    {
        "name": "Thirukattupalli",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.84431000",
        "longitude": "78.95647000",
        "label": "Thirukattupalli",
        "value": "Thirukattupalli"
    },
    {
        "name": "Thiruthani",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.17594000",
        "longitude": "79.61637000",
        "label": "Thiruthani",
        "value": "Thiruthani"
    },
    {
        "name": "Thiruvaiyaru",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.88405000",
        "longitude": "79.10362000",
        "label": "Thiruvaiyaru",
        "value": "Thiruvaiyaru"
    },
    {
        "name": "Thiruvallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.25000000",
        "longitude": "80.00000000",
        "label": "Thiruvallur",
        "value": "Thiruvallur"
    },
    {
        "name": "Thiruvarur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.77269000",
        "longitude": "79.63680000",
        "label": "Thiruvarur",
        "value": "Thiruvarur"
    },
    {
        "name": "Thiruvidaimaruthur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.99857000",
        "longitude": "79.45227000",
        "label": "Thiruvidaimaruthur",
        "value": "Thiruvidaimaruthur"
    },
    {
        "name": "Thoothukudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.76735000",
        "longitude": "78.13425000",
        "label": "Thoothukudi",
        "value": "Thoothukudi"
    },
    {
        "name": "Tindivanam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.23400000",
        "longitude": "79.65551000",
        "label": "Tindivanam",
        "value": "Tindivanam"
    },
    {
        "name": "Tinnanur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.11448000",
        "longitude": "80.02713000",
        "label": "Tinnanur",
        "value": "Tinnanur"
    },
    {
        "name": "Tiruchchendur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.49725000",
        "longitude": "78.11906000",
        "label": "Tiruchchendur",
        "value": "Tiruchchendur"
    },
    {
        "name": "Tiruchengode",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.38016000",
        "longitude": "77.89444000",
        "label": "Tiruchengode",
        "value": "Tiruchengode"
    },
    {
        "name": "Tiruchirappalli",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.97000000",
        "longitude": "78.65000000",
        "label": "Tiruchirappalli",
        "value": "Tiruchirappalli"
    },
    {
        "name": "Tirukkoyilur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.96620000",
        "longitude": "79.20259000",
        "label": "Tirukkoyilur",
        "value": "Tirukkoyilur"
    },
    {
        "name": "Tirumullaivasal",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.23996000",
        "longitude": "79.83705000",
        "label": "Tirumullaivasal",
        "value": "Tirumullaivasal"
    },
    {
        "name": "Tirunelveli",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.72742000",
        "longitude": "77.68380000",
        "label": "Tirunelveli",
        "value": "Tirunelveli"
    },
    {
        "name": "Tirunelveli Kattabo",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.79270000",
        "longitude": "77.57409000",
        "label": "Tirunelveli Kattabo",
        "value": "Tirunelveli Kattabo"
    },
    {
        "name": "Tiruppalaikudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.54606000",
        "longitude": "78.91721000",
        "label": "Tiruppalaikudi",
        "value": "Tiruppalaikudi"
    },
    {
        "name": "Tirupparangunram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.88151000",
        "longitude": "78.07306000",
        "label": "Tirupparangunram",
        "value": "Tirupparangunram"
    },
    {
        "name": "Tiruppur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.11541000",
        "longitude": "77.35456000",
        "label": "Tiruppur",
        "value": "Tiruppur"
    },
    {
        "name": "Tiruppuvanam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.82564000",
        "longitude": "78.25795000",
        "label": "Tiruppuvanam",
        "value": "Tiruppuvanam"
    },
    {
        "name": "Tiruttangal",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.48333000",
        "longitude": "77.83333000",
        "label": "Tiruttangal",
        "value": "Tiruttangal"
    },
    {
        "name": "Tiruvannamalai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.51000000",
        "longitude": "79.09000000",
        "label": "Tiruvannamalai",
        "value": "Tiruvannamalai"
    },
    {
        "name": "Tiruvottiyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.15823000",
        "longitude": "80.30181000",
        "label": "Tiruvottiyur",
        "value": "Tiruvottiyur"
    },
    {
        "name": "Tisaiyanvilai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.33702000",
        "longitude": "77.86776000",
        "label": "Tisaiyanvilai",
        "value": "Tisaiyanvilai"
    },
    {
        "name": "Tondi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.74173000",
        "longitude": "79.01774000",
        "label": "Tondi",
        "value": "Tondi"
    },
    {
        "name": "Turaiyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.14968000",
        "longitude": "78.59870000",
        "label": "Turaiyur",
        "value": "Turaiyur"
    },
    {
        "name": "Udangudi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.42918000",
        "longitude": "78.02968000",
        "label": "Udangudi",
        "value": "Udangudi"
    },
    {
        "name": "Udumalaippettai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.58806000",
        "longitude": "77.24779000",
        "label": "Udumalaippettai",
        "value": "Udumalaippettai"
    },
    {
        "name": "Uppiliyapuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.26356000",
        "longitude": "78.51390000",
        "label": "Uppiliyapuram",
        "value": "Uppiliyapuram"
    },
    {
        "name": "Usilampatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.96936000",
        "longitude": "77.78621000",
        "label": "Usilampatti",
        "value": "Usilampatti"
    },
    {
        "name": "Uttamapalaiyam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.80701000",
        "longitude": "77.32718000",
        "label": "Uttamapalaiyam",
        "value": "Uttamapalaiyam"
    },
    {
        "name": "Uttiramerur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.61433000",
        "longitude": "79.75748000",
        "label": "Uttiramerur",
        "value": "Uttiramerur"
    },
    {
        "name": "Uttukkuli",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.16892000",
        "longitude": "77.45431000",
        "label": "Uttukkuli",
        "value": "Uttukkuli"
    },
    {
        "name": "V.S.K.Valasai (Dindigul-Dist.)",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.31549000",
        "longitude": "78.15141000",
        "label": "V.S.K.Valasai (Dindigul-Dist.)",
        "value": "V.S.K.Valasai (Dindigul-Dist.)"
    },
    {
        "name": "Vadakku Valliyur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.38286000",
        "longitude": "77.61221000",
        "label": "Vadakku Valliyur",
        "value": "Vadakku Valliyur"
    },
    {
        "name": "Vadakku Viravanallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "8.69786000",
        "longitude": "77.51916000",
        "label": "Vadakku Viravanallur",
        "value": "Vadakku Viravanallur"
    },
    {
        "name": "Vadamadurai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.44081000",
        "longitude": "78.09784000",
        "label": "Vadamadurai",
        "value": "Vadamadurai"
    },
    {
        "name": "Vadippatti",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.08481000",
        "longitude": "77.96113000",
        "label": "Vadippatti",
        "value": "Vadippatti"
    },
    {
        "name": "Valangaiman",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.89012000",
        "longitude": "79.39322000",
        "label": "Valangaiman",
        "value": "Valangaiman"
    },
    {
        "name": "Valavanur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.92094000",
        "longitude": "79.58239000",
        "label": "Valavanur",
        "value": "Valavanur"
    },
    {
        "name": "Vallam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.71988000",
        "longitude": "79.05981000",
        "label": "Vallam",
        "value": "Vallam"
    },
    {
        "name": "Valparai",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.32691000",
        "longitude": "76.95116000",
        "label": "Valparai",
        "value": "Valparai"
    },
    {
        "name": "Vandalur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.89240000",
        "longitude": "80.08079000",
        "label": "Vandalur",
        "value": "Vandalur"
    },
    {
        "name": "Vandavasi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.50429000",
        "longitude": "79.60556000",
        "label": "Vandavasi",
        "value": "Vandavasi"
    },
    {
        "name": "Vaniyambadi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.68162000",
        "longitude": "78.62014000",
        "label": "Vaniyambadi",
        "value": "Vaniyambadi"
    },
    {
        "name": "Vasudevanallur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.24171000",
        "longitude": "77.41177000",
        "label": "Vasudevanallur",
        "value": "Vasudevanallur"
    },
    {
        "name": "Vattalkundu",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.16069000",
        "longitude": "77.75883000",
        "label": "Vattalkundu",
        "value": "Vattalkundu"
    },
    {
        "name": "Vedaraniyam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.37208000",
        "longitude": "79.85095000",
        "label": "Vedaraniyam",
        "value": "Vedaraniyam"
    },
    {
        "name": "Vedasandur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.53102000",
        "longitude": "77.95019000",
        "label": "Vedasandur",
        "value": "Vedasandur"
    },
    {
        "name": "Velankanni",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.68333000",
        "longitude": "79.83333000",
        "label": "Velankanni",
        "value": "Velankanni"
    },
    {
        "name": "Vellanur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "13.15804000",
        "longitude": "80.10634000",
        "label": "Vellanur",
        "value": "Vellanur"
    },
    {
        "name": "Vellore",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.86000000",
        "longitude": "79.03500000",
        "label": "Vellore",
        "value": "Vellore"
    },
    {
        "name": "Velur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.10825000",
        "longitude": "78.00113000",
        "label": "Velur",
        "value": "Velur"
    },
    {
        "name": "Vengavasal",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.89911000",
        "longitude": "80.16900000",
        "label": "Vengavasal",
        "value": "Vengavasal"
    },
    {
        "name": "Vettaikkaranpudur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "10.56207000",
        "longitude": "76.91305000",
        "label": "Vettaikkaranpudur",
        "value": "Vettaikkaranpudur"
    },
    {
        "name": "Vettavalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.10769000",
        "longitude": "79.24516000",
        "label": "Vettavalam",
        "value": "Vettavalam"
    },
    {
        "name": "Vijayapuri",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.24530000",
        "longitude": "77.50066000",
        "label": "Vijayapuri",
        "value": "Vijayapuri"
    },
    {
        "name": "Vikravandi",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.03690000",
        "longitude": "79.54595000",
        "label": "Vikravandi",
        "value": "Vikravandi"
    },
    {
        "name": "Vilattikulam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.13227000",
        "longitude": "78.16635000",
        "label": "Vilattikulam",
        "value": "Vilattikulam"
    },
    {
        "name": "Villupuram",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.99000000",
        "longitude": "79.37000000",
        "label": "Villupuram",
        "value": "Villupuram"
    },
    {
        "name": "Viraganur",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.47613000",
        "longitude": "78.73553000",
        "label": "Viraganur",
        "value": "Viraganur"
    },
    {
        "name": "Virudhunagar",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "9.45000000",
        "longitude": "77.92000000",
        "label": "Virudhunagar",
        "value": "Virudhunagar"
    },
    {
        "name": "Vriddhachalam",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.51830000",
        "longitude": "79.32411000",
        "label": "Vriddhachalam",
        "value": "Vriddhachalam"
    },
    {
        "name": "Walajapet",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.92510000",
        "longitude": "79.36626000",
        "label": "Walajapet",
        "value": "Walajapet"
    },
    {
        "name": "Wallajahbad",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "12.79041000",
        "longitude": "79.82358000",
        "label": "Wallajahbad",
        "value": "Wallajahbad"
    },
    {
        "name": "Wellington",
        "countryCode": "IN",
        "stateCode": "TN",
        "latitude": "11.36552000",
        "longitude": "76.78442000",
        "label": "Wellington",
        "value": "Wellington"
    }
,{
        "name": "Adilabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.50000000",
        "longitude": "78.50000000",
        "label": "Adilabad",
        "value": "Adilabad"
    },
    {
        "name": "Alampur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "15.87987000",
        "longitude": "78.13352000",
        "label": "Alampur",
        "value": "Alampur"
    },
    {
        "name": "Andol",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.81458000",
        "longitude": "78.07713000",
        "label": "Andol",
        "value": "Andol"
    },
    {
        "name": "Asifabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.35851000",
        "longitude": "79.28415000",
        "label": "Asifabad",
        "value": "Asifabad"
    },
    {
        "name": "Balapur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.31018000",
        "longitude": "78.49969000",
        "label": "Balapur",
        "value": "Balapur"
    },
    {
        "name": "Banswada",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.37725000",
        "longitude": "77.88007000",
        "label": "Banswada",
        "value": "Banswada"
    },
    {
        "name": "Bellampalli",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.05577000",
        "longitude": "79.49300000",
        "label": "Bellampalli",
        "value": "Bellampalli"
    },
    {
        "name": "Bhadrachalam",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.66846000",
        "longitude": "80.88887000",
        "label": "Bhadrachalam",
        "value": "Bhadrachalam"
    },
    {
        "name": "Bhadradri Kothagudem",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.55460000",
        "longitude": "80.61976000",
        "label": "Bhadradri Kothagudem",
        "value": "Bhadradri Kothagudem"
    },
    {
        "name": "Bhaisa",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.11285000",
        "longitude": "77.96336000",
        "label": "Bhaisa",
        "value": "Bhaisa"
    },
    {
        "name": "Bhongir",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.51544000",
        "longitude": "78.88563000",
        "label": "Bhongir",
        "value": "Bhongir"
    },
    {
        "name": "Bodhan",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.66208000",
        "longitude": "77.88581000",
        "label": "Bodhan",
        "value": "Bodhan"
    },
    {
        "name": "Chandur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.87455000",
        "longitude": "78.10017000",
        "label": "Chandur",
        "value": "Chandur"
    },
    {
        "name": "Chatakonda",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.55303000",
        "longitude": "80.64770000",
        "label": "Chatakonda",
        "value": "Chatakonda"
    },
    {
        "name": "Dasnapur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.65399000",
        "longitude": "78.51213000",
        "label": "Dasnapur",
        "value": "Dasnapur"
    },
    {
        "name": "Devarkonda",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.69186000",
        "longitude": "78.92073000",
        "label": "Devarkonda",
        "value": "Devarkonda"
    },
    {
        "name": "Dornakal",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.44475000",
        "longitude": "80.14905000",
        "label": "Dornakal",
        "value": "Dornakal"
    },
    {
        "name": "Farrukhnagar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.07787000",
        "longitude": "78.20339000",
        "label": "Farrukhnagar",
        "value": "Farrukhnagar"
    },
    {
        "name": "Gaddi Annaram",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.36687000",
        "longitude": "78.52420000",
        "label": "Gaddi Annaram",
        "value": "Gaddi Annaram"
    },
    {
        "name": "Gadwal",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.23504000",
        "longitude": "77.79556000",
        "label": "Gadwal",
        "value": "Gadwal"
    },
    {
        "name": "Ghatkesar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.45081000",
        "longitude": "78.68366000",
        "label": "Ghatkesar",
        "value": "Ghatkesar"
    },
    {
        "name": "Gopalur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.61220000",
        "longitude": "77.80728000",
        "label": "Gopalur",
        "value": "Gopalur"
    },
    {
        "name": "Gudur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.49174000",
        "longitude": "78.82302000",
        "label": "Gudur",
        "value": "Gudur"
    },
    {
        "name": "Hyderabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.38405000",
        "longitude": "78.45636000",
        "label": "Hyderabad",
        "value": "Hyderabad"
    },
    {
        "name": "Jagtial",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.79473000",
        "longitude": "78.91661000",
        "label": "Jagtial",
        "value": "Jagtial"
    },
    {
        "name": "Jangaon",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.72602000",
        "longitude": "79.15236000",
        "label": "Jangaon",
        "value": "Jangaon"
    },
    {
        "name": "Jangoan",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.72943000",
        "longitude": "79.16096000",
        "label": "Jangoan",
        "value": "Jangoan"
    },
    {
        "name": "Jayashankar Bhupalapally",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.19678000",
        "longitude": "79.93976000",
        "label": "Jayashankar Bhupalapally",
        "value": "Jayashankar Bhupalapally"
    },
    {
        "name": "Jogulamba Gadwal",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.23401000",
        "longitude": "77.80564000",
        "label": "Jogulamba Gadwal",
        "value": "Jogulamba Gadwal"
    },
    {
        "name": "Kagaznagar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.33159000",
        "longitude": "79.46605000",
        "label": "Kagaznagar",
        "value": "Kagaznagar"
    },
    {
        "name": "Kamareddi",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.32001000",
        "longitude": "78.34177000",
        "label": "Kamareddi",
        "value": "Kamareddi"
    },
    {
        "name": "Kamareddy",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.32567000",
        "longitude": "78.33416000",
        "label": "Kamareddy",
        "value": "Kamareddy"
    },
    {
        "name": "Karimnagar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.33844000",
        "longitude": "79.22938000",
        "label": "Karimnagar",
        "value": "Karimnagar"
    },
    {
        "name": "Khammam",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.50000000",
        "longitude": "80.33333000",
        "label": "Khammam",
        "value": "Khammam"
    },
    {
        "name": "Kodar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.99850000",
        "longitude": "79.96560000",
        "label": "Kodar",
        "value": "Kodar"
    },
    {
        "name": "Koratla",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.82154000",
        "longitude": "78.71186000",
        "label": "Koratla",
        "value": "Koratla"
    },
    {
        "name": "Kothapet",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.35176000",
        "longitude": "79.48323000",
        "label": "Kothapet",
        "value": "Kothapet"
    },
    {
        "name": "Kottagudem",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.55106000",
        "longitude": "80.61779000",
        "label": "Kottagudem",
        "value": "Kottagudem"
    },
    {
        "name": "Kottapalli",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.49543000",
        "longitude": "79.09430000",
        "label": "Kottapalli",
        "value": "Kottapalli"
    },
    {
        "name": "Kukatpalli",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.48486000",
        "longitude": "78.41376000",
        "label": "Kukatpalli",
        "value": "Kukatpalli"
    },
    {
        "name": "Kyathampalle",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.66781000",
        "longitude": "78.52890000",
        "label": "Kyathampalle",
        "value": "Kyathampalle"
    },
    {
        "name": "Lakshettipet",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.86667000",
        "longitude": "79.21667000",
        "label": "Lakshettipet",
        "value": "Lakshettipet"
    },
    {
        "name": "Lal Bahadur Nagar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.34769000",
        "longitude": "78.55757000",
        "label": "Lal Bahadur Nagar",
        "value": "Lal Bahadur Nagar"
    },
    {
        "name": "Mahabubabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.60040000",
        "longitude": "80.00543000",
        "label": "Mahabubabad",
        "value": "Mahabubabad"
    },
    {
        "name": "Mahbubnagar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.63171000",
        "longitude": "77.75556000",
        "label": "Mahbubnagar",
        "value": "Mahbubnagar"
    },
    {
        "name": "Malkajgiri",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.44781000",
        "longitude": "78.52633000",
        "label": "Malkajgiri",
        "value": "Malkajgiri"
    },
    {
        "name": "Mancheral",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.87074000",
        "longitude": "79.42863000",
        "label": "Mancheral",
        "value": "Mancheral"
    },
    {
        "name": "Mandamarri",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.96506000",
        "longitude": "79.47475000",
        "label": "Mandamarri",
        "value": "Mandamarri"
    },
    {
        "name": "Manthani",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.65087000",
        "longitude": "79.66501000",
        "label": "Manthani",
        "value": "Manthani"
    },
    {
        "name": "Manuguru",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.98102000",
        "longitude": "80.75470000",
        "label": "Manuguru",
        "value": "Manuguru"
    },
    {
        "name": "Medak",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.75000000",
        "longitude": "78.25000000",
        "label": "Medak",
        "value": "Medak"
    },
    {
        "name": "Medchal",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.62972000",
        "longitude": "78.48139000",
        "label": "Medchal",
        "value": "Medchal"
    },
    {
        "name": "Medchal Malkajgiri",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.60644000",
        "longitude": "78.54007000",
        "label": "Medchal Malkajgiri",
        "value": "Medchal Malkajgiri"
    },
    {
        "name": "Mirialguda",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.87220000",
        "longitude": "79.56247000",
        "label": "Mirialguda",
        "value": "Mirialguda"
    },
    {
        "name": "Nagar Karnul",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.48210000",
        "longitude": "78.32471000",
        "label": "Nagar Karnul",
        "value": "Nagar Karnul"
    },
    {
        "name": "Nalgonda",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.16667000",
        "longitude": "79.50000000",
        "label": "Nalgonda",
        "value": "Nalgonda"
    },
    {
        "name": "Narayanpet",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.74799000",
        "longitude": "77.49540000",
        "label": "Narayanpet",
        "value": "Narayanpet"
    },
    {
        "name": "Narsingi",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.04468000",
        "longitude": "78.42516000",
        "label": "Narsingi",
        "value": "Narsingi"
    },
    {
        "name": "Naspur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.84577000",
        "longitude": "79.46165000",
        "label": "Naspur",
        "value": "Naspur"
    },
    {
        "name": "Nirmal",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.09685000",
        "longitude": "78.34407000",
        "label": "Nirmal",
        "value": "Nirmal"
    },
    {
        "name": "Nizamabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.75000000",
        "longitude": "78.25000000",
        "label": "Nizamabad",
        "value": "Nizamabad"
    },
    {
        "name": "Paloncha",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.60184000",
        "longitude": "80.70509000",
        "label": "Paloncha",
        "value": "Paloncha"
    },
    {
        "name": "Palwancha",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.58152000",
        "longitude": "80.67651000",
        "label": "Palwancha",
        "value": "Palwancha"
    },
    {
        "name": "Patancheru",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.53334000",
        "longitude": "78.26450000",
        "label": "Patancheru",
        "value": "Patancheru"
    },
    {
        "name": "Peddapalli",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.61357000",
        "longitude": "79.37442000",
        "label": "Peddapalli",
        "value": "Peddapalli"
    },
    {
        "name": "Quthbullapur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.50107000",
        "longitude": "78.45818000",
        "label": "Quthbullapur",
        "value": "Quthbullapur"
    },
    {
        "name": "Rajanna Sircilla",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.38629000",
        "longitude": "78.81560000",
        "label": "Rajanna Sircilla",
        "value": "Rajanna Sircilla"
    },
    {
        "name": "Ramagundam",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.75500000",
        "longitude": "79.47400000",
        "label": "Ramagundam",
        "value": "Ramagundam"
    },
    {
        "name": "Ramgundam",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.80084000",
        "longitude": "79.45206000",
        "label": "Ramgundam",
        "value": "Ramgundam"
    },
    {
        "name": "Rangareddi",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.27883000",
        "longitude": "78.16844000",
        "label": "Rangareddi",
        "value": "Rangareddi"
    },
    {
        "name": "Sadaseopet",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.61925000",
        "longitude": "77.95263000",
        "label": "Sadaseopet",
        "value": "Sadaseopet"
    },
    {
        "name": "Sangareddi",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.62477000",
        "longitude": "78.08669000",
        "label": "Sangareddi",
        "value": "Sangareddi"
    },
    {
        "name": "Sathupalli",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.24968000",
        "longitude": "80.86899000",
        "label": "Sathupalli",
        "value": "Sathupalli"
    },
    {
        "name": "Secunderabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.50427000",
        "longitude": "78.54263000",
        "label": "Secunderabad",
        "value": "Secunderabad"
    },
    {
        "name": "Serilingampalle",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.49313000",
        "longitude": "78.30196000",
        "label": "Serilingampalle",
        "value": "Serilingampalle"
    },
    {
        "name": "Siddipet",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.10483000",
        "longitude": "78.84858000",
        "label": "Siddipet",
        "value": "Siddipet"
    },
    {
        "name": "Singapur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.46982000",
        "longitude": "78.12574000",
        "label": "Singapur",
        "value": "Singapur"
    },
    {
        "name": "Sirpur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "19.47953000",
        "longitude": "79.57558000",
        "label": "Sirpur",
        "value": "Sirpur"
    },
    {
        "name": "Sirsilla",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.38865000",
        "longitude": "78.81048000",
        "label": "Sirsilla",
        "value": "Sirsilla"
    },
    {
        "name": "Sriramnagar",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.26652000",
        "longitude": "78.25544000",
        "label": "Sriramnagar",
        "value": "Sriramnagar"
    },
    {
        "name": "Suriapet",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.14054000",
        "longitude": "79.62045000",
        "label": "Suriapet",
        "value": "Suriapet"
    },
    {
        "name": "Tandur",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.24849000",
        "longitude": "77.57698000",
        "label": "Tandur",
        "value": "Tandur"
    },
    {
        "name": "Uppal Kalan",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.40577000",
        "longitude": "78.55911000",
        "label": "Uppal Kalan",
        "value": "Uppal Kalan"
    },
    {
        "name": "Vemalwada",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.46523000",
        "longitude": "78.86894000",
        "label": "Vemalwada",
        "value": "Vemalwada"
    },
    {
        "name": "Vikarabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.33810000",
        "longitude": "77.90441000",
        "label": "Vikarabad",
        "value": "Vikarabad"
    },
    {
        "name": "Wanparti",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "16.36738000",
        "longitude": "78.06889000",
        "label": "Wanparti",
        "value": "Wanparti"
    },
    {
        "name": "Warangal",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "18.00000000",
        "longitude": "79.83333000",
        "label": "Warangal",
        "value": "Warangal"
    },
    {
        "name": "Yellandu",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.59064000",
        "longitude": "80.32146000",
        "label": "Yellandu",
        "value": "Yellandu"
    },
    {
        "name": "Zahirabad",
        "countryCode": "IN",
        "stateCode": "TG",
        "latitude": "17.68138000",
        "longitude": "77.60743000",
        "label": "Zahirabad",
        "value": "Zahirabad"
    }
,{
        "name": "Agartala",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.83605000",
        "longitude": "91.27939000",
        "label": "Agartala",
        "value": "Agartala"
    },
    {
        "name": "Amarpur",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.52570000",
        "longitude": "91.65879000",
        "label": "Amarpur",
        "value": "Amarpur"
    },
    {
        "name": "Ambasa",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.93600000",
        "longitude": "91.85436000",
        "label": "Ambasa",
        "value": "Ambasa"
    },
    {
        "name": "Barjala",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.61820000",
        "longitude": "91.35596000",
        "label": "Barjala",
        "value": "Barjala"
    },
    {
        "name": "Belonia",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.25178000",
        "longitude": "91.45407000",
        "label": "Belonia",
        "value": "Belonia"
    },
    {
        "name": "Dhalai",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.84307000",
        "longitude": "91.92591000",
        "label": "Dhalai",
        "value": "Dhalai"
    },
    {
        "name": "Dharmanagar",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "24.36667000",
        "longitude": "92.16667000",
        "label": "Dharmanagar",
        "value": "Dharmanagar"
    },
    {
        "name": "Gomati",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.53399000",
        "longitude": "91.48122000",
        "label": "Gomati",
        "value": "Gomati"
    },
    {
        "name": "Kailashahar",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "24.33199000",
        "longitude": "92.00391000",
        "label": "Kailashahar",
        "value": "Kailashahar"
    },
    {
        "name": "Kamalpur",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "24.19593000",
        "longitude": "91.83438000",
        "label": "Kamalpur",
        "value": "Kamalpur"
    },
    {
        "name": "Khowai",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "24.07964000",
        "longitude": "91.59972000",
        "label": "Khowai",
        "value": "Khowai"
    },
    {
        "name": "North Tripura",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "24.13050000",
        "longitude": "92.15552000",
        "label": "North Tripura",
        "value": "North Tripura"
    },
    {
        "name": "Ranir Bazar",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.83463000",
        "longitude": "91.36614000",
        "label": "Ranir Bazar",
        "value": "Ranir Bazar"
    },
    {
        "name": "Sabrum",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.00153000",
        "longitude": "91.72427000",
        "label": "Sabrum",
        "value": "Sabrum"
    },
    {
        "name": "Sonamura",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.47547000",
        "longitude": "91.26590000",
        "label": "Sonamura",
        "value": "Sonamura"
    },
    {
        "name": "South Tripura",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.16710000",
        "longitude": "91.60953000",
        "label": "South Tripura",
        "value": "South Tripura"
    },
    {
        "name": "Udaipur",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.53333000",
        "longitude": "91.48333000",
        "label": "Udaipur",
        "value": "Udaipur"
    },
    {
        "name": "Unakoti",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "24.32781000",
        "longitude": "92.00377000",
        "label": "Unakoti",
        "value": "Unakoti"
    },
    {
        "name": "West Tripura",
        "countryCode": "IN",
        "stateCode": "TR",
        "latitude": "23.91667000",
        "longitude": "91.50000000",
        "label": "West Tripura",
        "value": "West Tripura"
    }
,{
        "name": "Achhnera",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.17826000",
        "longitude": "77.75674000",
        "label": "Achhnera",
        "value": "Achhnera"
    },
    {
        "name": "Afzalgarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.39370000",
        "longitude": "78.67393000",
        "label": "Afzalgarh",
        "value": "Afzalgarh"
    },
    {
        "name": "Agra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.18333000",
        "longitude": "78.01667000",
        "label": "Agra",
        "value": "Agra"
    },
    {
        "name": "Ahraura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.01579000",
        "longitude": "83.03294000",
        "label": "Ahraura",
        "value": "Ahraura"
    },
    {
        "name": "Aidalpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.13333333",
        "longitude": "79.45000000",
        "label": "Aidalpur",
        "value": "Aidalpur"
    },
    {
        "name": "Airwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.90000000",
        "longitude": "79.43333333",
        "label": "Airwa",
        "value": "Airwa"
    },
    {
        "name": "Akbarpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.42953000",
        "longitude": "82.53431000",
        "label": "Akbarpur",
        "value": "Akbarpur"
    },
    {
        "name": "Akola",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.06547000",
        "longitude": "77.88084000",
        "label": "Akola",
        "value": "Akola"
    },
    {
        "name": "Aliganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.49358000",
        "longitude": "79.17127000",
        "label": "Aliganj",
        "value": "Aliganj"
    },
    {
        "name": "Aligarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.83333000",
        "longitude": "78.16667000",
        "label": "Aligarh",
        "value": "Aligarh"
    },
    {
        "name": "Allahabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.45000000",
        "longitude": "81.85000000",
        "label": "Allahabad",
        "value": "Allahabad"
    },
    {
        "name": "Allahganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.54540000",
        "longitude": "79.68715000",
        "label": "Allahganj",
        "value": "Allahganj"
    },
    {
        "name": "Amanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.71222000",
        "longitude": "78.73788000",
        "label": "Amanpur",
        "value": "Amanpur"
    },
    {
        "name": "Amauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.01666667",
        "longitude": "80.30000000",
        "label": "Amauli",
        "value": "Amauli"
    },
    {
        "name": "Ambahta",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.85706000",
        "longitude": "77.33583000",
        "label": "Ambahta",
        "value": "Ambahta"
    },
    {
        "name": "Ambedkar Nagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.40544000",
        "longitude": "82.69762000",
        "label": "Ambedkar Nagar",
        "value": "Ambedkar Nagar"
    },
    {
        "name": "Amethi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.01667000",
        "longitude": "81.05000000",
        "label": "Amethi",
        "value": "Amethi"
    },
    {
        "name": "Amroha",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.90314000",
        "longitude": "78.46984000",
        "label": "Amroha",
        "value": "Amroha"
    },
    {
        "name": "Anandnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.10062000",
        "longitude": "83.27156000",
        "label": "Anandnagar",
        "value": "Anandnagar"
    },
    {
        "name": "Antu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.05654000",
        "longitude": "81.90267000",
        "label": "Antu",
        "value": "Antu"
    },
    {
        "name": "Anupshahr",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.35748000",
        "longitude": "78.26914000",
        "label": "Anupshahr",
        "value": "Anupshahr"
    },
    {
        "name": "Aonla",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.27402000",
        "longitude": "79.16521000",
        "label": "Aonla",
        "value": "Aonla"
    },
    {
        "name": "Araul",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.91700000",
        "longitude": "80.03300000",
        "label": "Araul",
        "value": "Araul"
    },
    {
        "name": "Asalatganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.68333333",
        "longitude": "79.85000000",
        "label": "Asalatganj",
        "value": "Asalatganj"
    },
    {
        "name": "Atarra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.28618000",
        "longitude": "80.57155000",
        "label": "Atarra",
        "value": "Atarra"
    },
    {
        "name": "Atrauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.02964000",
        "longitude": "78.28571000",
        "label": "Atrauli",
        "value": "Atrauli"
    },
    {
        "name": "Atraulia",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.33330000",
        "longitude": "82.94727000",
        "label": "Atraulia",
        "value": "Atraulia"
    },
    {
        "name": "Auraiya",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.64692000",
        "longitude": "79.42858000",
        "label": "Auraiya",
        "value": "Auraiya"
    },
    {
        "name": "Auras",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.91414000",
        "longitude": "80.50792000",
        "label": "Auras",
        "value": "Auras"
    },
    {
        "name": "Ayodhya",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.79909000",
        "longitude": "82.20470000",
        "label": "Ayodhya",
        "value": "Ayodhya"
    },
    {
        "name": "Azamgarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.06832000",
        "longitude": "83.18358000",
        "label": "Azamgarh",
        "value": "Azamgarh"
    },
    {
        "name": "Azizpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.97638889",
        "longitude": "79.22138889",
        "label": "Azizpur",
        "value": "Azizpur"
    },
    {
        "name": "Baberu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.54711000",
        "longitude": "80.70443000",
        "label": "Baberu",
        "value": "Baberu"
    },
    {
        "name": "Babina",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.23947000",
        "longitude": "78.47028000",
        "label": "Babina",
        "value": "Babina"
    },
    {
        "name": "Babrala",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.26419000",
        "longitude": "78.40560000",
        "label": "Babrala",
        "value": "Babrala"
    },
    {
        "name": "Babugarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.72353000",
        "longitude": "77.84677000",
        "label": "Babugarh",
        "value": "Babugarh"
    },
    {
        "name": "Bachhraon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.92694000",
        "longitude": "78.23456000",
        "label": "Bachhraon",
        "value": "Bachhraon"
    },
    {
        "name": "Bachhrawan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.47090000",
        "longitude": "81.11580000",
        "label": "Bachhrawan",
        "value": "Bachhrawan"
    },
    {
        "name": "Baghpat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.95000000",
        "longitude": "77.21670000",
        "label": "Baghpat",
        "value": "Baghpat"
    },
    {
        "name": "Baghra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.46666667",
        "longitude": "77.58333333",
        "label": "Baghra",
        "value": "Baghra"
    },
    {
        "name": "Bah",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.86912000",
        "longitude": "78.59385000",
        "label": "Bah",
        "value": "Bah"
    },
    {
        "name": "Baheri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.77416000",
        "longitude": "79.49740000",
        "label": "Baheri",
        "value": "Baheri"
    },
    {
        "name": "Bahjoi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.39502000",
        "longitude": "78.62659000",
        "label": "Bahjoi",
        "value": "Bahjoi"
    },
    {
        "name": "Bahraich",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.80021000",
        "longitude": "81.51855000",
        "label": "Bahraich",
        "value": "Bahraich"
    },
    {
        "name": "Bahraigh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.57429000",
        "longitude": "81.59474000",
        "label": "Bahraigh",
        "value": "Bahraigh"
    },
    {
        "name": "Bahsuma",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.20063000",
        "longitude": "77.97221000",
        "label": "Bahsuma",
        "value": "Bahsuma"
    },
    {
        "name": "Bahua",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.83942000",
        "longitude": "80.62255000",
        "label": "Bahua",
        "value": "Bahua"
    },
    {
        "name": "Bajna",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.89793000",
        "longitude": "77.67836000",
        "label": "Bajna",
        "value": "Bajna"
    },
    {
        "name": "Bakewar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.66226000",
        "longitude": "79.17625000",
        "label": "Bakewar",
        "value": "Bakewar"
    },
    {
        "name": "Baksar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.76666667",
        "longitude": "78.01666667",
        "label": "Baksar",
        "value": "Baksar"
    },
    {
        "name": "Balamau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.16666667",
        "longitude": "80.35000000",
        "label": "Balamau",
        "value": "Balamau"
    },
    {
        "name": "Baldeo",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.41666667",
        "longitude": "77.81666667",
        "label": "Baldeo",
        "value": "Baldeo"
    },
    {
        "name": "Baldev",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.40684000",
        "longitude": "77.82214000",
        "label": "Baldev",
        "value": "Baldev"
    },
    {
        "name": "Ballia",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.83333000",
        "longitude": "84.16667000",
        "label": "Ballia",
        "value": "Ballia"
    },
    {
        "name": "Balrampur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.43449000",
        "longitude": "82.40281000",
        "label": "Balrampur",
        "value": "Balrampur"
    },
    {
        "name": "Banat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.46355000",
        "longitude": "77.35478000",
        "label": "Banat",
        "value": "Banat"
    },
    {
        "name": "Banbasa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.99132000",
        "longitude": "80.07608000",
        "label": "Banbasa",
        "value": "Banbasa"
    },
    {
        "name": "Banda",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.50000000",
        "longitude": "80.50000000",
        "label": "Banda",
        "value": "Banda"
    },
    {
        "name": "Bangarmau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.89120000",
        "longitude": "80.21149000",
        "label": "Bangarmau",
        "value": "Bangarmau"
    },
    {
        "name": "Bansdih",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.88377000",
        "longitude": "84.21827000",
        "label": "Bansdih",
        "value": "Bansdih"
    },
    {
        "name": "Bansgaon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.55032000",
        "longitude": "83.34503000",
        "label": "Bansgaon",
        "value": "Bansgaon"
    },
    {
        "name": "Bansi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.17749000",
        "longitude": "82.93442000",
        "label": "Bansi",
        "value": "Bansi"
    },
    {
        "name": "Banthra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.91720000",
        "longitude": "79.81740000",
        "label": "Banthra",
        "value": "Banthra"
    },
    {
        "name": "Bara Banki",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.93864000",
        "longitude": "81.32740000",
        "label": "Bara Banki",
        "value": "Bara Banki"
    },
    {
        "name": "Baragaon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.47554000",
        "longitude": "78.71224000",
        "label": "Baragaon",
        "value": "Baragaon"
    },
    {
        "name": "Baraut",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.10199000",
        "longitude": "77.26334000",
        "label": "Baraut",
        "value": "Baraut"
    },
    {
        "name": "Bareilly",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.41667000",
        "longitude": "79.38333000",
        "label": "Bareilly",
        "value": "Bareilly"
    },
    {
        "name": "Barhalganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.28333333",
        "longitude": "83.50000000",
        "label": "Barhalganj",
        "value": "Barhalganj"
    },
    {
        "name": "Barkhera",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.45000000",
        "longitude": "79.80000000",
        "label": "Barkhera",
        "value": "Barkhera"
    },
    {
        "name": "Barkhera Kalan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.45209000",
        "longitude": "79.80655000",
        "label": "Barkhera Kalan",
        "value": "Barkhera Kalan"
    },
    {
        "name": "Barokhar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.91666667",
        "longitude": "81.96666667",
        "label": "Barokhar",
        "value": "Barokhar"
    },
    {
        "name": "Barsana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.64802000",
        "longitude": "77.37640000",
        "label": "Barsana",
        "value": "Barsana"
    },
    {
        "name": "Barwar (Lakhimpur Kheri)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.50583333",
        "longitude": "79.13972222",
        "label": "Barwar (Lakhimpur Kheri)",
        "value": "Barwar (Lakhimpur Kheri)"
    },
    {
        "name": "Basti",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.82816000",
        "longitude": "82.77924000",
        "label": "Basti",
        "value": "Basti"
    },
    {
        "name": "Behat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "30.17180000",
        "longitude": "77.61390000",
        "label": "Behat",
        "value": "Behat"
    },
    {
        "name": "Bela",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.92058000",
        "longitude": "81.99629000",
        "label": "Bela",
        "value": "Bela"
    },
    {
        "name": "Belthara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.12694444",
        "longitude": "83.89138889",
        "label": "Belthara",
        "value": "Belthara"
    },
    {
        "name": "Beniganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.29293000",
        "longitude": "80.44364000",
        "label": "Beniganj",
        "value": "Beniganj"
    },
    {
        "name": "Beswan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.63792000",
        "longitude": "77.88019000",
        "label": "Beswan",
        "value": "Beswan"
    },
    {
        "name": "Bewar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.21869000",
        "longitude": "79.29761000",
        "label": "Bewar",
        "value": "Bewar"
    },
    {
        "name": "Bhadarsa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.66027778",
        "longitude": "82.12416667",
        "label": "Bhadarsa",
        "value": "Bhadarsa"
    },
    {
        "name": "Bhadohi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.39526000",
        "longitude": "82.57030000",
        "label": "Bhadohi",
        "value": "Bhadohi"
    },
    {
        "name": "Bhagwantnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.22383000",
        "longitude": "80.75750000",
        "label": "Bhagwantnagar",
        "value": "Bhagwantnagar"
    },
    {
        "name": "Bharatpura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.23333333",
        "longitude": "78.96666667",
        "label": "Bharatpura",
        "value": "Bharatpura"
    },
    {
        "name": "Bhargain",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.61500000",
        "longitude": "79.14416667",
        "label": "Bhargain",
        "value": "Bhargain"
    },
    {
        "name": "Bharthana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75231000",
        "longitude": "79.22180000",
        "label": "Bharthana",
        "value": "Bharthana"
    },
    {
        "name": "Bharwari",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.56078000",
        "longitude": "81.49164000",
        "label": "Bharwari",
        "value": "Bharwari"
    },
    {
        "name": "Bhaupur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.91666667",
        "longitude": "79.21666667",
        "label": "Bhaupur",
        "value": "Bhaupur"
    },
    {
        "name": "Bhimtal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.34444444",
        "longitude": "79.56305556",
        "label": "Bhimtal",
        "value": "Bhimtal"
    },
    {
        "name": "Bhinga",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.70283000",
        "longitude": "81.93430000",
        "label": "Bhinga",
        "value": "Bhinga"
    },
    {
        "name": "Bhognipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.24000000",
        "longitude": "79.80000000",
        "label": "Bhognipur",
        "value": "Bhognipur"
    },
    {
        "name": "Bhongaon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.25515000",
        "longitude": "79.18118000",
        "label": "Bhongaon",
        "value": "Bhongaon"
    },
    {
        "name": "Bidhnu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.33333333",
        "longitude": "80.28333333",
        "label": "Bidhnu",
        "value": "Bidhnu"
    },
    {
        "name": "Bidhuna",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.80172000",
        "longitude": "79.50829000",
        "label": "Bidhuna",
        "value": "Bidhuna"
    },
    {
        "name": "Bighapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.35000000",
        "longitude": "80.68000000",
        "label": "Bighapur",
        "value": "Bighapur"
    },
    {
        "name": "Bighapur Khurd",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.34734000",
        "longitude": "80.65698000",
        "label": "Bighapur Khurd",
        "value": "Bighapur Khurd"
    },
    {
        "name": "Bijnor",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.41667000",
        "longitude": "78.51667000",
        "label": "Bijnor",
        "value": "Bijnor"
    },
    {
        "name": "Bikapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.59534000",
        "longitude": "82.13272000",
        "label": "Bikapur",
        "value": "Bikapur"
    },
    {
        "name": "Bilari",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.62146000",
        "longitude": "78.80361000",
        "label": "Bilari",
        "value": "Bilari"
    },
    {
        "name": "Bilariaganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.19593000",
        "longitude": "83.22690000",
        "label": "Bilariaganj",
        "value": "Bilariaganj"
    },
    {
        "name": "Bilaspur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.88655000",
        "longitude": "79.27030000",
        "label": "Bilaspur",
        "value": "Bilaspur"
    },
    {
        "name": "Bilgram",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.17509000",
        "longitude": "80.03201000",
        "label": "Bilgram",
        "value": "Bilgram"
    },
    {
        "name": "Bilhaur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.84345000",
        "longitude": "80.06388000",
        "label": "Bilhaur",
        "value": "Bilhaur"
    },
    {
        "name": "Bilsanda",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.24341000",
        "longitude": "79.95135000",
        "label": "Bilsanda",
        "value": "Bilsanda"
    },
    {
        "name": "Bilsi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.12941000",
        "longitude": "78.91090000",
        "label": "Bilsi",
        "value": "Bilsi"
    },
    {
        "name": "Bilthra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.12705000",
        "longitude": "83.89148000",
        "label": "Bilthra",
        "value": "Bilthra"
    },
    {
        "name": "Binauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.09416667",
        "longitude": "77.40083333",
        "label": "Binauli",
        "value": "Binauli"
    },
    {
        "name": "Binaur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.38333333",
        "longitude": "80.16666667",
        "label": "Binaur",
        "value": "Binaur"
    },
    {
        "name": "Bindki",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.03613000",
        "longitude": "80.57617000",
        "label": "Bindki",
        "value": "Bindki"
    },
    {
        "name": "Birdpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.38330000",
        "longitude": "83.11670000",
        "label": "Birdpur",
        "value": "Birdpur"
    },
    {
        "name": "Birpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.53000000",
        "longitude": "86.25000000",
        "label": "Birpur",
        "value": "Birpur"
    },
    {
        "name": "Bisalpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.29253000",
        "longitude": "79.80472000",
        "label": "Bisalpur",
        "value": "Bisalpur"
    },
    {
        "name": "Bisanda Buzurg",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.41666667",
        "longitude": "80.61666667",
        "label": "Bisanda Buzurg",
        "value": "Bisanda Buzurg"
    },
    {
        "name": "Bisauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.30772000",
        "longitude": "78.93678000",
        "label": "Bisauli",
        "value": "Bisauli"
    },
    {
        "name": "Bisenda Buzurg",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.40350000",
        "longitude": "80.61889000",
        "label": "Bisenda Buzurg",
        "value": "Bisenda Buzurg"
    },
    {
        "name": "Bishunpur Urf Maharajganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.25914000",
        "longitude": "83.11643000",
        "label": "Bishunpur Urf Maharajganj",
        "value": "Bishunpur Urf Maharajganj"
    },
    {
        "name": "Biswan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.49581000",
        "longitude": "80.99618000",
        "label": "Biswan",
        "value": "Biswan"
    },
    {
        "name": "Bithur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.60664000",
        "longitude": "80.27098000",
        "label": "Bithur",
        "value": "Bithur"
    },
    {
        "name": "Budaun",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.11667000",
        "longitude": "78.98333000",
        "label": "Budaun",
        "value": "Budaun"
    },
    {
        "name": "Budhana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.28805000",
        "longitude": "77.47534000",
        "label": "Budhana",
        "value": "Budhana"
    },
    {
        "name": "Bulandshahr",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.41667000",
        "longitude": "77.83333000",
        "label": "Bulandshahr",
        "value": "Bulandshahr"
    },
    {
        "name": "Captainganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.92640000",
        "longitude": "83.71334000",
        "label": "Captainganj",
        "value": "Captainganj"
    },
    {
        "name": "Chail",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.42654000",
        "longitude": "81.63198000",
        "label": "Chail",
        "value": "Chail"
    },
    {
        "name": "Chakia",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.04891000",
        "longitude": "83.22155000",
        "label": "Chakia",
        "value": "Chakia"
    },
    {
        "name": "Chandauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.25803000",
        "longitude": "83.26825000",
        "label": "Chandauli",
        "value": "Chandauli"
    },
    {
        "name": "Chandauli District",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.26134000",
        "longitude": "83.26408000",
        "label": "Chandauli District",
        "value": "Chandauli District"
    },
    {
        "name": "Chandausi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.45000000",
        "longitude": "78.77000000",
        "label": "Chandausi",
        "value": "Chandausi"
    },
    {
        "name": "Chandpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.13489000",
        "longitude": "78.27187000",
        "label": "Chandpur",
        "value": "Chandpur"
    },
    {
        "name": "Chanduasi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.45178000",
        "longitude": "78.78277000",
        "label": "Chanduasi",
        "value": "Chanduasi"
    },
    {
        "name": "Charkhari",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.40304000",
        "longitude": "79.74877000",
        "label": "Charkhari",
        "value": "Charkhari"
    },
    {
        "name": "Charthawal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.54687000",
        "longitude": "77.59438000",
        "label": "Charthawal",
        "value": "Charthawal"
    },
    {
        "name": "Chhaprauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.20989000",
        "longitude": "77.17454000",
        "label": "Chhaprauli",
        "value": "Chhaprauli"
    },
    {
        "name": "Chharra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.92470000",
        "longitude": "78.40102000",
        "label": "Chharra",
        "value": "Chharra"
    },
    {
        "name": "Chhata",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.72374000",
        "longitude": "77.50810000",
        "label": "Chhata",
        "value": "Chhata"
    },
    {
        "name": "Chhibramau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.14872000",
        "longitude": "79.50078000",
        "label": "Chhibramau",
        "value": "Chhibramau"
    },
    {
        "name": "Chhitauni",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.11666667",
        "longitude": "83.98333333",
        "label": "Chhitauni",
        "value": "Chhitauni"
    },
    {
        "name": "Chhutmalpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "30.03209000",
        "longitude": "77.75329000",
        "label": "Chhutmalpur",
        "value": "Chhutmalpur"
    },
    {
        "name": "Chillupar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.28221000",
        "longitude": "83.50640000",
        "label": "Chillupar",
        "value": "Chillupar"
    },
    {
        "name": "Chirgaon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.57198000",
        "longitude": "78.81432000",
        "label": "Chirgaon",
        "value": "Chirgaon"
    },
    {
        "name": "Chitrakoot",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.20511000",
        "longitude": "81.08962000",
        "label": "Chitrakoot",
        "value": "Chitrakoot"
    },
    {
        "name": "Chitrakoot Dham",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.20000000",
        "longitude": "80.90000000",
        "label": "Chitrakoot Dham",
        "value": "Chitrakoot Dham"
    },
    {
        "name": "Chopan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.51954000",
        "longitude": "83.02287000",
        "label": "Chopan",
        "value": "Chopan"
    },
    {
        "name": "Chunar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.12776000",
        "longitude": "82.88210000",
        "label": "Chunar",
        "value": "Chunar"
    },
    {
        "name": "Churk",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.65000000",
        "longitude": "83.10000000",
        "label": "Churk",
        "value": "Churk"
    },
    {
        "name": "Colonelganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.13432000",
        "longitude": "81.69868000",
        "label": "Colonelganj",
        "value": "Colonelganj"
    },
    {
        "name": "Dadri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.55257000",
        "longitude": "77.55403000",
        "label": "Dadri",
        "value": "Dadri"
    },
    {
        "name": "Dalmau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.06477000",
        "longitude": "81.02980000",
        "label": "Dalmau",
        "value": "Dalmau"
    },
    {
        "name": "Dankaur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.35121000",
        "longitude": "77.55508000",
        "label": "Dankaur",
        "value": "Dankaur"
    },
    {
        "name": "Daraganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.43333333",
        "longitude": "81.88333333",
        "label": "Daraganj",
        "value": "Daraganj"
    },
    {
        "name": "Daranagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.68162080",
        "longitude": "81.34975790",
        "label": "Daranagar",
        "value": "Daranagar"
    },
    {
        "name": "Dasna",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.67736000",
        "longitude": "77.52252000",
        "label": "Dasna",
        "value": "Dasna"
    },
    {
        "name": "Dataganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.02530000",
        "longitude": "79.40819000",
        "label": "Dataganj",
        "value": "Dataganj"
    },
    {
        "name": "Daurala",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.11344000",
        "longitude": "77.70467000",
        "label": "Daurala",
        "value": "Daurala"
    },
    {
        "name": "Dayal Bagh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.22122000",
        "longitude": "78.01095000",
        "label": "Dayal Bagh",
        "value": "Dayal Bagh"
    },
    {
        "name": "Deoband",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.69505000",
        "longitude": "77.67964000",
        "label": "Deoband",
        "value": "Deoband"
    },
    {
        "name": "Deogarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.52600000",
        "longitude": "78.23800000",
        "label": "Deogarh",
        "value": "Deogarh"
    },
    {
        "name": "Deoranian",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.62989000",
        "longitude": "79.47648000",
        "label": "Deoranian",
        "value": "Deoranian"
    },
    {
        "name": "Deoria",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.66667000",
        "longitude": "83.75000000",
        "label": "Deoria",
        "value": "Deoria"
    },
    {
        "name": "Derapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.41666667",
        "longitude": "79.80000000",
        "label": "Derapur",
        "value": "Derapur"
    },
    {
        "name": "Dewa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.03621000",
        "longitude": "81.16692000",
        "label": "Dewa",
        "value": "Dewa"
    },
    {
        "name": "Dhampur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.30883000",
        "longitude": "78.51083000",
        "label": "Dhampur",
        "value": "Dhampur"
    },
    {
        "name": "Dhanaura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.95912000",
        "longitude": "78.25629000",
        "label": "Dhanaura",
        "value": "Dhanaura"
    },
    {
        "name": "Dhanghata",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.55419444",
        "longitude": "83.00806111",
        "label": "Dhanghata",
        "value": "Dhanghata"
    },
    {
        "name": "Dharau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.38333333",
        "longitude": "79.96666667",
        "label": "Dharau",
        "value": "Dharau"
    },
    {
        "name": "Dhaurahra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.99814000",
        "longitude": "81.08975000",
        "label": "Dhaurahra",
        "value": "Dhaurahra"
    },
    {
        "name": "Dibai",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.20849000",
        "longitude": "78.26173000",
        "label": "Dibai",
        "value": "Dibai"
    },
    {
        "name": "Divrasai",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.81900000",
        "longitude": "79.14900000",
        "label": "Divrasai",
        "value": "Divrasai"
    },
    {
        "name": "Dohrighat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.27217000",
        "longitude": "83.50916000",
        "label": "Dohrighat",
        "value": "Dohrighat"
    },
    {
        "name": "Domariaganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.22000000",
        "longitude": "82.67000000",
        "label": "Domariaganj",
        "value": "Domariaganj"
    },
    {
        "name": "Dostpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.27486000",
        "longitude": "82.47091000",
        "label": "Dostpur",
        "value": "Dostpur"
    },
    {
        "name": "Dudhi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.21357000",
        "longitude": "83.24067000",
        "label": "Dudhi",
        "value": "Dudhi"
    },
    {
        "name": "Etah",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.50000000",
        "longitude": "78.75000000",
        "label": "Etah",
        "value": "Etah"
    },
    {
        "name": "Etawah",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75000000",
        "longitude": "79.25000000",
        "label": "Etawah",
        "value": "Etawah"
    },
    {
        "name": "Etmadpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.23971944",
        "longitude": "78.20000000",
        "label": "Etmadpur",
        "value": "Etmadpur"
    },
    {
        "name": "Faizabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75000000",
        "longitude": "82.00000000",
        "label": "Faizabad",
        "value": "Faizabad"
    },
    {
        "name": "Farah",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.32081000",
        "longitude": "77.76185000",
        "label": "Farah",
        "value": "Farah"
    },
    {
        "name": "Faridnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.76923000",
        "longitude": "77.62934000",
        "label": "Faridnagar",
        "value": "Faridnagar"
    },
    {
        "name": "Faridpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.20997000",
        "longitude": "79.54149000",
        "label": "Faridpur",
        "value": "Faridpur"
    },
    {
        "name": "Farrukhabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.50000000",
        "longitude": "79.50000000",
        "label": "Farrukhabad",
        "value": "Farrukhabad"
    },
    {
        "name": "Fatehabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.02645000",
        "longitude": "78.30270000",
        "label": "Fatehabad",
        "value": "Fatehabad"
    },
    {
        "name": "Fatehganj West",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.46620000",
        "longitude": "79.30657000",
        "label": "Fatehganj West",
        "value": "Fatehganj West"
    },
    {
        "name": "Fatehgarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.36409000",
        "longitude": "79.63111000",
        "label": "Fatehgarh",
        "value": "Fatehgarh"
    },
    {
        "name": "Fatehpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.75000000",
        "longitude": "80.75000000",
        "label": "Fatehpur",
        "value": "Fatehpur"
    },
    {
        "name": "Fatehpur (Barabanki)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.17000000",
        "longitude": "81.22000000",
        "label": "Fatehpur (Barabanki)",
        "value": "Fatehpur (Barabanki)"
    },
    {
        "name": "Fatehpur Chaurasi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.78925000",
        "longitude": "80.26547000",
        "label": "Fatehpur Chaurasi",
        "value": "Fatehpur Chaurasi"
    },
    {
        "name": "Fatehpur Sikri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.09370000",
        "longitude": "77.66003000",
        "label": "Fatehpur Sikri",
        "value": "Fatehpur Sikri"
    },
    {
        "name": "Firozabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.20072000",
        "longitude": "78.42867000",
        "label": "Firozabad",
        "value": "Firozabad"
    },
    {
        "name": "Fyzabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.77549000",
        "longitude": "82.15018000",
        "label": "Fyzabad",
        "value": "Fyzabad"
    },
    {
        "name": "Gahlon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.53333333",
        "longitude": "79.96666667",
        "label": "Gahlon",
        "value": "Gahlon"
    },
    {
        "name": "Gahmar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.49700000",
        "longitude": "83.82200000",
        "label": "Gahmar",
        "value": "Gahmar"
    },
    {
        "name": "Gaini",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.21000000",
        "longitude": "79.16000000",
        "label": "Gaini",
        "value": "Gaini"
    },
    {
        "name": "Gajraula",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.84570000",
        "longitude": "78.23960000",
        "label": "Gajraula",
        "value": "Gajraula"
    },
    {
        "name": "Gangoh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.78004000",
        "longitude": "77.26346000",
        "label": "Gangoh",
        "value": "Gangoh"
    },
    {
        "name": "Ganj Dundawara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.73277778",
        "longitude": "78.94166667",
        "label": "Ganj Dundawara",
        "value": "Ganj Dundawara"
    },
    {
        "name": "Ganj Dundwara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.73308000",
        "longitude": "78.94119000",
        "label": "Ganj Dundwara",
        "value": "Ganj Dundwara"
    },
    {
        "name": "Ganj Muradabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.95733000",
        "longitude": "80.18400000",
        "label": "Ganj Muradabad",
        "value": "Ganj Muradabad"
    },
    {
        "name": "Garautha",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.57190000",
        "longitude": "79.29764000",
        "label": "Garautha",
        "value": "Garautha"
    },
    {
        "name": "Garhi Pukhta",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.54980000",
        "longitude": "77.30881000",
        "label": "Garhi Pukhta",
        "value": "Garhi Pukhta"
    },
    {
        "name": "Garhmuktesar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.78732000",
        "longitude": "78.10214000",
        "label": "Garhmuktesar",
        "value": "Garhmuktesar"
    },
    {
        "name": "Garhwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.18000000",
        "longitude": "83.82000000",
        "label": "Garhwa",
        "value": "Garhwa"
    },
    {
        "name": "Gauriganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.20600000",
        "longitude": "81.69000000",
        "label": "Gauriganj",
        "value": "Gauriganj"
    },
    {
        "name": "Gautam Buddha Nagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.35898000",
        "longitude": "77.55076000",
        "label": "Gautam Buddha Nagar",
        "value": "Gautam Buddha Nagar"
    },
    {
        "name": "Gawan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.41969000",
        "longitude": "78.35186000",
        "label": "Gawan",
        "value": "Gawan"
    },
    {
        "name": "Ghatampur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.15272000",
        "longitude": "80.16803000",
        "label": "Ghatampur",
        "value": "Ghatampur"
    },
    {
        "name": "Ghaziabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.66535000",
        "longitude": "77.43915000",
        "label": "Ghaziabad",
        "value": "Ghaziabad"
    },
    {
        "name": "Ghazipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.58333000",
        "longitude": "83.58526000",
        "label": "Ghazipur",
        "value": "Ghazipur"
    },
    {
        "name": "Ghiror",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.18912000",
        "longitude": "78.79312000",
        "label": "Ghiror",
        "value": "Ghiror"
    },
    {
        "name": "Ghorawal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.75459000",
        "longitude": "82.77965000",
        "label": "Ghorawal",
        "value": "Ghorawal"
    },
    {
        "name": "Ghosi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.10587000",
        "longitude": "83.53930000",
        "label": "Ghosi",
        "value": "Ghosi"
    },
    {
        "name": "Gohand",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.69871000",
        "longitude": "79.54567000",
        "label": "Gohand",
        "value": "Gohand"
    },
    {
        "name": "Gokul",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.43926000",
        "longitude": "77.72019000",
        "label": "Gokul",
        "value": "Gokul"
    },
    {
        "name": "Gola Bazar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.34460000",
        "longitude": "83.35303000",
        "label": "Gola Bazar",
        "value": "Gola Bazar"
    },
    {
        "name": "Gola Gokarannath",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.07837000",
        "longitude": "80.47054000",
        "label": "Gola Gokarannath",
        "value": "Gola Gokarannath"
    },
    {
        "name": "Gonda",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.18581000",
        "longitude": "81.96693000",
        "label": "Gonda",
        "value": "Gonda"
    },
    {
        "name": "Gonda (Aligarh)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.83330000",
        "longitude": "77.88330000",
        "label": "Gonda (Aligarh)",
        "value": "Gonda (Aligarh)"
    },
    {
        "name": "Gonda City",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.13181000",
        "longitude": "81.95332000",
        "label": "Gonda City",
        "value": "Gonda City"
    },
    {
        "name": "Gopamau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.53468000",
        "longitude": "80.28507000",
        "label": "Gopamau",
        "value": "Gopamau"
    },
    {
        "name": "Gorakhpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.91667000",
        "longitude": "83.25000000",
        "label": "Gorakhpur",
        "value": "Gorakhpur"
    },
    {
        "name": "Gosainganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.77000000",
        "longitude": "81.12000000",
        "label": "Gosainganj",
        "value": "Gosainganj"
    },
    {
        "name": "Goshainganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.57115000",
        "longitude": "82.38091000",
        "label": "Goshainganj",
        "value": "Goshainganj"
    },
    {
        "name": "Govardhan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.49658000",
        "longitude": "77.46263000",
        "label": "Govardhan",
        "value": "Govardhan"
    },
    {
        "name": "Greater Noida",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.49615000",
        "longitude": "77.53601000",
        "label": "Greater Noida",
        "value": "Greater Noida"
    },
    {
        "name": "Gulaothi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.58938000",
        "longitude": "77.79318000",
        "label": "Gulaothi",
        "value": "Gulaothi"
    },
    {
        "name": "Gunnaur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.23995000",
        "longitude": "78.43994000",
        "label": "Gunnaur",
        "value": "Gunnaur"
    },
    {
        "name": "Gursahaiganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.11518000",
        "longitude": "79.73174000",
        "label": "Gursahaiganj",
        "value": "Gursahaiganj"
    },
    {
        "name": "Gursarai",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.61677000",
        "longitude": "79.18053000",
        "label": "Gursarai",
        "value": "Gursarai"
    },
    {
        "name": "Gyanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.33268000",
        "longitude": "82.46637000",
        "label": "Gyanpur",
        "value": "Gyanpur"
    },
    {
        "name": "Haldaur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.28988000",
        "longitude": "78.28437000",
        "label": "Haldaur",
        "value": "Haldaur"
    },
    {
        "name": "Hamirpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.75000000",
        "longitude": "80.00000000",
        "label": "Hamirpur",
        "value": "Hamirpur"
    },
    {
        "name": "Handia",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.36379000",
        "longitude": "82.18655000",
        "label": "Handia",
        "value": "Handia"
    },
    {
        "name": "Hapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.72985000",
        "longitude": "77.78068000",
        "label": "Hapur",
        "value": "Hapur"
    },
    {
        "name": "Haraipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.90000000",
        "longitude": "79.70000000",
        "label": "Haraipur",
        "value": "Haraipur"
    },
    {
        "name": "Haraiya",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.79477000",
        "longitude": "82.46436000",
        "label": "Haraiya",
        "value": "Haraiya"
    },
    {
        "name": "Harchandpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.36000000",
        "longitude": "81.17000000",
        "label": "Harchandpur",
        "value": "Harchandpur"
    },
    {
        "name": "Hardoi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.41667000",
        "longitude": "80.25000000",
        "label": "Hardoi",
        "value": "Hardoi"
    },
    {
        "name": "Harduaganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.94361000",
        "longitude": "78.15789000",
        "label": "Harduaganj",
        "value": "Harduaganj"
    },
    {
        "name": "Hasanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.72249000",
        "longitude": "78.28436000",
        "label": "Hasanpur",
        "value": "Hasanpur"
    },
    {
        "name": "Hastinapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.16042000",
        "longitude": "78.00762000",
        "label": "Hastinapur",
        "value": "Hastinapur"
    },
    {
        "name": "Hata",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.74120000",
        "longitude": "83.74526000",
        "label": "Hata",
        "value": "Hata"
    },
    {
        "name": "Hata (India)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75000000",
        "longitude": "83.74000000",
        "label": "Hata (India)",
        "value": "Hata (India)"
    },
    {
        "name": "Hathras",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.59551000",
        "longitude": "78.05201000",
        "label": "Hathras",
        "value": "Hathras"
    },
    {
        "name": "Hulas",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.69027778",
        "longitude": "77.36027778",
        "label": "Hulas",
        "value": "Hulas"
    },
    {
        "name": "Ibrahimpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.01027778",
        "longitude": "83.16861111",
        "label": "Ibrahimpur",
        "value": "Ibrahimpur"
    },
    {
        "name": "Iglas",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.71100000",
        "longitude": "77.93967000",
        "label": "Iglas",
        "value": "Iglas"
    },
    {
        "name": "Ikauna",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.53097000",
        "longitude": "81.96917000",
        "label": "Ikauna",
        "value": "Ikauna"
    },
    {
        "name": "Indergarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.93521000",
        "longitude": "79.67120000",
        "label": "Indergarh",
        "value": "Indergarh"
    },
    {
        "name": "Indragarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.91299444",
        "longitude": "74.32284167",
        "label": "Indragarh",
        "value": "Indragarh"
    },
    {
        "name": "Islamnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.32896000",
        "longitude": "78.72524000",
        "label": "Islamnagar",
        "value": "Islamnagar"
    },
    {
        "name": "Islamnagar (Badaun)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.33000000",
        "longitude": "78.72000000",
        "label": "Islamnagar (Badaun)",
        "value": "Islamnagar (Badaun)"
    },
    {
        "name": "Itaunja",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.08347000",
        "longitude": "80.89672000",
        "label": "Itaunja",
        "value": "Itaunja"
    },
    {
        "name": "Itimadpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.23541000",
        "longitude": "78.19829000",
        "label": "Itimadpur",
        "value": "Itimadpur"
    },
    {
        "name": "Jagdishpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.74967000",
        "longitude": "80.54510000",
        "label": "Jagdishpur",
        "value": "Jagdishpur"
    },
    {
        "name": "Jagnair",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.86360000",
        "longitude": "77.60231000",
        "label": "Jagnair",
        "value": "Jagnair"
    },
    {
        "name": "Jahanabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.63025000",
        "longitude": "79.71818000",
        "label": "Jahanabad",
        "value": "Jahanabad"
    },
    {
        "name": "Jahanabad (Pilibhit)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.63330000",
        "longitude": "79.80000000",
        "label": "Jahanabad (Pilibhit)",
        "value": "Jahanabad (Pilibhit)"
    },
    {
        "name": "Jahangirabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.40549000",
        "longitude": "78.10588000",
        "label": "Jahangirabad",
        "value": "Jahangirabad"
    },
    {
        "name": "Jahangirpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.17919000",
        "longitude": "77.70501000",
        "label": "Jahangirpur",
        "value": "Jahangirpur"
    },
    {
        "name": "Jainpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.15389000",
        "longitude": "83.33505000",
        "label": "Jainpur",
        "value": "Jainpur"
    },
    {
        "name": "Jais",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.26490000",
        "longitude": "81.54855000",
        "label": "Jais",
        "value": "Jais"
    },
    {
        "name": "Jalalabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.61853000",
        "longitude": "77.43908000",
        "label": "Jalalabad",
        "value": "Jalalabad"
    },
    {
        "name": "Jalali",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.86680000",
        "longitude": "78.25267000",
        "label": "Jalali",
        "value": "Jalali"
    },
    {
        "name": "Jalalpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.31162000",
        "longitude": "82.73859000",
        "label": "Jalalpur",
        "value": "Jalalpur"
    },
    {
        "name": "Jalaun",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.00000000",
        "longitude": "79.50000000",
        "label": "Jalaun",
        "value": "Jalaun"
    },
    {
        "name": "Jalesar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.47315000",
        "longitude": "78.30310000",
        "label": "Jalesar",
        "value": "Jalesar"
    },
    {
        "name": "Janghai",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.55000000",
        "longitude": "82.31666667",
        "label": "Janghai",
        "value": "Janghai"
    },
    {
        "name": "Jansath",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.32502000",
        "longitude": "77.85044000",
        "label": "Jansath",
        "value": "Jansath"
    },
    {
        "name": "Jarwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.65000000",
        "longitude": "82.51666667",
        "label": "Jarwa",
        "value": "Jarwa"
    },
    {
        "name": "Jarwal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.16290000",
        "longitude": "81.54179000",
        "label": "Jarwal",
        "value": "Jarwal"
    },
    {
        "name": "Jasrana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.23587000",
        "longitude": "78.65244000",
        "label": "Jasrana",
        "value": "Jasrana"
    },
    {
        "name": "Jaswantnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.88271000",
        "longitude": "78.90256000",
        "label": "Jaswantnagar",
        "value": "Jaswantnagar"
    },
    {
        "name": "Jaunpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.75000000",
        "longitude": "82.75000000",
        "label": "Jaunpur",
        "value": "Jaunpur"
    },
    {
        "name": "Jewar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.12200000",
        "longitude": "77.55734000",
        "label": "Jewar",
        "value": "Jewar"
    },
    {
        "name": "Jhajhar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.86000000",
        "longitude": "75.28000000",
        "label": "Jhajhar",
        "value": "Jhajhar"
    },
    {
        "name": "Jhalu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.33609000",
        "longitude": "78.22608000",
        "label": "Jhalu",
        "value": "Jhalu"
    },
    {
        "name": "Jhansi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.50000000",
        "longitude": "78.50000000",
        "label": "Jhansi",
        "value": "Jhansi"
    },
    {
        "name": "Jhinjhak",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.56093000",
        "longitude": "79.73423000",
        "label": "Jhinjhak",
        "value": "Jhinjhak"
    },
    {
        "name": "Jhinjhana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.52118000",
        "longitude": "77.22470000",
        "label": "Jhinjhana",
        "value": "Jhinjhana"
    },
    {
        "name": "Jhusi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.43745000",
        "longitude": "81.90550000",
        "label": "Jhusi",
        "value": "Jhusi"
    },
    {
        "name": "Jiyanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.15000000",
        "longitude": "83.33000000",
        "label": "Jiyanpur",
        "value": "Jiyanpur"
    },
    {
        "name": "Jyotiba Phule Nagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.77160000",
        "longitude": "78.33871000",
        "label": "Jyotiba Phule Nagar",
        "value": "Jyotiba Phule Nagar"
    },
    {
        "name": "Kabrai",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.40281000",
        "longitude": "79.99970000",
        "label": "Kabrai",
        "value": "Kabrai"
    },
    {
        "name": "Kachhwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.20615000",
        "longitude": "82.71442000",
        "label": "Kachhwa",
        "value": "Kachhwa"
    },
    {
        "name": "Kadaura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.98537000",
        "longitude": "79.83842000",
        "label": "Kadaura",
        "value": "Kadaura"
    },
    {
        "name": "Kadipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.16779000",
        "longitude": "82.37028000",
        "label": "Kadipur",
        "value": "Kadipur"
    },
    {
        "name": "Kagarol",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.01666667",
        "longitude": "77.85000000",
        "label": "Kagarol",
        "value": "Kagarol"
    },
    {
        "name": "Kaimganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.55441000",
        "longitude": "79.33525000",
        "label": "Kaimganj",
        "value": "Kaimganj"
    },
    {
        "name": "Kairana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.39541000",
        "longitude": "77.20540000",
        "label": "Kairana",
        "value": "Kairana"
    },
    {
        "name": "Kakori",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.86800000",
        "longitude": "80.78570000",
        "label": "Kakori",
        "value": "Kakori"
    },
    {
        "name": "Kakrala",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.89269000",
        "longitude": "79.19450000",
        "label": "Kakrala",
        "value": "Kakrala"
    },
    {
        "name": "Kalinagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.62019000",
        "longitude": "80.08152000",
        "label": "Kalinagar",
        "value": "Kalinagar"
    },
    {
        "name": "Kalpi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.11667000",
        "longitude": "79.73333000",
        "label": "Kalpi",
        "value": "Kalpi"
    },
    {
        "name": "Kalyanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.51912222",
        "longitude": "80.24980556",
        "label": "Kalyanpur",
        "value": "Kalyanpur"
    },
    {
        "name": "Kamalganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.26181000",
        "longitude": "79.63134000",
        "label": "Kamalganj",
        "value": "Kamalganj"
    },
    {
        "name": "Kampil",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.61268000",
        "longitude": "79.27687000",
        "label": "Kampil",
        "value": "Kampil"
    },
    {
        "name": "Kandhla",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.32104000",
        "longitude": "77.27101000",
        "label": "Kandhla",
        "value": "Kandhla"
    },
    {
        "name": "Kannauj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.01770000",
        "longitude": "79.67846000",
        "label": "Kannauj",
        "value": "Kannauj"
    },
    {
        "name": "Kanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.46523000",
        "longitude": "80.34975000",
        "label": "Kanpur",
        "value": "Kanpur"
    },
    {
        "name": "Kanpur Dehat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.41506000",
        "longitude": "79.98957000",
        "label": "Kanpur Dehat",
        "value": "Kanpur Dehat"
    },
    {
        "name": "Kant",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.81049000",
        "longitude": "79.79185000",
        "label": "Kant",
        "value": "Kant"
    },
    {
        "name": "Kanth",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.05939000",
        "longitude": "78.62951000",
        "label": "Kanth",
        "value": "Kanth"
    },
    {
        "name": "Kaptanganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.93000000",
        "longitude": "83.72000000",
        "label": "Kaptanganj",
        "value": "Kaptanganj"
    },
    {
        "name": "Kara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.70000000",
        "longitude": "81.35000000",
        "label": "Kara",
        "value": "Kara"
    },
    {
        "name": "Karari",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.45241000",
        "longitude": "81.42675000",
        "label": "Karari",
        "value": "Karari"
    },
    {
        "name": "Karbigwan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.20000000",
        "longitude": "80.50000000",
        "label": "Karbigwan",
        "value": "Karbigwan"
    },
    {
        "name": "Karchana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.28000000",
        "longitude": "81.93000000",
        "label": "Karchana",
        "value": "Karchana"
    },
    {
        "name": "Karhal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.00089000",
        "longitude": "78.93935000",
        "label": "Karhal",
        "value": "Karhal"
    },
    {
        "name": "Kasganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.80882000",
        "longitude": "78.64579000",
        "label": "Kasganj",
        "value": "Kasganj"
    },
    {
        "name": "Katra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.50871000",
        "longitude": "82.02636000",
        "label": "Katra",
        "value": "Katra"
    },
    {
        "name": "Kausani",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.84305556",
        "longitude": "79.60333333",
        "label": "Kausani",
        "value": "Kausani"
    },
    {
        "name": "Kaushambi District",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.53074000",
        "longitude": "81.37729000",
        "label": "Kaushambi District",
        "value": "Kaushambi District"
    },
    {
        "name": "Kemri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.80673000",
        "longitude": "79.20480000",
        "label": "Kemri",
        "value": "Kemri"
    },
    {
        "name": "Khada",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.18333000",
        "longitude": "83.88333000",
        "label": "Khada",
        "value": "Khada"
    },
    {
        "name": "Khaga",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.77215000",
        "longitude": "81.10393000",
        "label": "Khaga",
        "value": "Khaga"
    },
    {
        "name": "Khailar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.35000000",
        "longitude": "78.53000000",
        "label": "Khailar",
        "value": "Khailar"
    },
    {
        "name": "Khair",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.94195000",
        "longitude": "77.84243000",
        "label": "Khair",
        "value": "Khair"
    },
    {
        "name": "Khairabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.52698000",
        "longitude": "80.75461000",
        "label": "Khairabad",
        "value": "Khairabad"
    },
    {
        "name": "Khalilabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.77268000",
        "longitude": "83.07179000",
        "label": "Khalilabad",
        "value": "Khalilabad"
    },
    {
        "name": "Khanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.53446000",
        "longitude": "78.06546000",
        "label": "Khanpur",
        "value": "Khanpur"
    },
    {
        "name": "Kharela",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.54277000",
        "longitude": "79.81235000",
        "label": "Kharela",
        "value": "Kharela"
    },
    {
        "name": "Khargupur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.37611000",
        "longitude": "81.98820000",
        "label": "Khargupur",
        "value": "Khargupur"
    },
    {
        "name": "Kharkhauda",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.83644000",
        "longitude": "77.74159000",
        "label": "Kharkhauda",
        "value": "Kharkhauda"
    },
    {
        "name": "Khatauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.27844000",
        "longitude": "77.73302000",
        "label": "Khatauli",
        "value": "Khatauli"
    },
    {
        "name": "Khekra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.86586000",
        "longitude": "77.28410000",
        "label": "Khekra",
        "value": "Khekra"
    },
    {
        "name": "Kheri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.11667000",
        "longitude": "80.71667000",
        "label": "Kheri",
        "value": "Kheri"
    },
    {
        "name": "Khudaganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.14607000",
        "longitude": "79.71472000",
        "label": "Khudaganj",
        "value": "Khudaganj"
    },
    {
        "name": "Khurja",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.25382000",
        "longitude": "77.85535000",
        "label": "Khurja",
        "value": "Khurja"
    },
    {
        "name": "Khutar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.20307000",
        "longitude": "80.27046000",
        "label": "Khutar",
        "value": "Khutar"
    },
    {
        "name": "Kirakat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.63745000",
        "longitude": "82.91596000",
        "label": "Kirakat",
        "value": "Kirakat"
    },
    {
        "name": "Kiraoli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.13768000",
        "longitude": "77.78516000",
        "label": "Kiraoli",
        "value": "Kiraoli"
    },
    {
        "name": "Kiratpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.50671000",
        "longitude": "78.20613000",
        "label": "Kiratpur",
        "value": "Kiratpur"
    },
    {
        "name": "Kishanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.64232000",
        "longitude": "81.02270000",
        "label": "Kishanpur",
        "value": "Kishanpur"
    },
    {
        "name": "Kishanpur baral",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.20000000",
        "longitude": "77.28333333",
        "label": "Kishanpur baral",
        "value": "Kishanpur baral"
    },
    {
        "name": "Kishni",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.02487000",
        "longitude": "79.26200000",
        "label": "Kishni",
        "value": "Kishni"
    },
    {
        "name": "Kithor",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.86684000",
        "longitude": "77.93861000",
        "label": "Kithor",
        "value": "Kithor"
    },
    {
        "name": "Konch",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.99451000",
        "longitude": "79.15127000",
        "label": "Konch",
        "value": "Konch"
    },
    {
        "name": "Kopaganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.01923000",
        "longitude": "83.56630000",
        "label": "Kopaganj",
        "value": "Kopaganj"
    },
    {
        "name": "Kosi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.79449000",
        "longitude": "77.43680000",
        "label": "Kosi",
        "value": "Kosi"
    },
    {
        "name": "Kota",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.44643000",
        "longitude": "83.13063000",
        "label": "Kota",
        "value": "Kota"
    },
    {
        "name": "Kotra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.80770000",
        "longitude": "79.30909000",
        "label": "Kotra",
        "value": "Kotra"
    },
    {
        "name": "Kuchesar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.68333333",
        "longitude": "77.95000000",
        "label": "Kuchesar",
        "value": "Kuchesar"
    },
    {
        "name": "Kudarkot",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.81666667",
        "longitude": "79.40000000",
        "label": "Kudarkot",
        "value": "Kudarkot"
    },
    {
        "name": "Kulpahar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.32007000",
        "longitude": "79.63931000",
        "label": "Kulpahar",
        "value": "Kulpahar"
    },
    {
        "name": "Kunda",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.71702000",
        "longitude": "81.51396000",
        "label": "Kunda",
        "value": "Kunda"
    },
    {
        "name": "Kundarkhi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.68304000",
        "longitude": "78.78559000",
        "label": "Kundarkhi",
        "value": "Kundarkhi"
    },
    {
        "name": "Kundarki",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.68300000",
        "longitude": "78.78500000",
        "label": "Kundarki",
        "value": "Kundarki"
    },
    {
        "name": "Kurara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.98046000",
        "longitude": "79.98984000",
        "label": "Kurara",
        "value": "Kurara"
    },
    {
        "name": "Kurebhar,saidkhanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.43333333",
        "longitude": "82.11666667",
        "label": "Kurebhar,saidkhanpur",
        "value": "Kurebhar,saidkhanpur"
    },
    {
        "name": "Kushinagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.74028000",
        "longitude": "83.88889000",
        "label": "Kushinagar",
        "value": "Kushinagar"
    },
    {
        "name": "Kusmara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.11666667",
        "longitude": "79.28333333",
        "label": "Kusmara",
        "value": "Kusmara"
    },
    {
        "name": "Kuthaund",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.36666667",
        "longitude": "79.41666667",
        "label": "Kuthaund",
        "value": "Kuthaund"
    },
    {
        "name": "Laharpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.70827000",
        "longitude": "80.90256000",
        "label": "Laharpur",
        "value": "Laharpur"
    },
    {
        "name": "Lakhimpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.94822000",
        "longitude": "80.77935000",
        "label": "Lakhimpur",
        "value": "Lakhimpur"
    },
    {
        "name": "Lakhna",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.64822000",
        "longitude": "79.14770000",
        "label": "Lakhna",
        "value": "Lakhna"
    },
    {
        "name": "Lalganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.93182000",
        "longitude": "81.70478000",
        "label": "Lalganj",
        "value": "Lalganj"
    },
    {
        "name": "Lalitpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.50000000",
        "longitude": "78.50000000",
        "label": "Lalitpur",
        "value": "Lalitpur"
    },
    {
        "name": "Lambhua",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.15000000",
        "longitude": "82.21000000",
        "label": "Lambhua",
        "value": "Lambhua"
    },
    {
        "name": "Lar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.20394000",
        "longitude": "83.96906000",
        "label": "Lar",
        "value": "Lar"
    },
    {
        "name": "Lawar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.11666667",
        "longitude": "77.76666667",
        "label": "Lawar",
        "value": "Lawar"
    },
    {
        "name": "Lawar Khas",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.11091000",
        "longitude": "77.77767000",
        "label": "Lawar Khas",
        "value": "Lawar Khas"
    },
    {
        "name": "Loni",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.75143000",
        "longitude": "77.29023000",
        "label": "Loni",
        "value": "Loni"
    },
    {
        "name": "Lucknow",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.83928000",
        "longitude": "80.92313000",
        "label": "Lucknow",
        "value": "Lucknow"
    },
    {
        "name": "Lucknow District",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75000000",
        "longitude": "81.00000000",
        "label": "Lucknow District",
        "value": "Lucknow District"
    },
    {
        "name": "Machhali Shahar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.68000000",
        "longitude": "82.42000000",
        "label": "Machhali Shahar",
        "value": "Machhali Shahar"
    },
    {
        "name": "Machhlishahr",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.68564000",
        "longitude": "82.41106000",
        "label": "Machhlishahr",
        "value": "Machhlishahr"
    },
    {
        "name": "Madhoganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.11807000",
        "longitude": "80.14058000",
        "label": "Madhoganj",
        "value": "Madhoganj"
    },
    {
        "name": "Madhogarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.27522000",
        "longitude": "79.18590000",
        "label": "Madhogarh",
        "value": "Madhogarh"
    },
    {
        "name": "Maghar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75586000",
        "longitude": "83.12773000",
        "label": "Maghar",
        "value": "Maghar"
    },
    {
        "name": "Mahaban",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.43262000",
        "longitude": "77.74338000",
        "label": "Mahaban",
        "value": "Mahaban"
    },
    {
        "name": "Maharajganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.16945000",
        "longitude": "83.50667000",
        "label": "Maharajganj",
        "value": "Maharajganj"
    },
    {
        "name": "Mahmudabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.29191000",
        "longitude": "81.11775000",
        "label": "Mahmudabad",
        "value": "Mahmudabad"
    },
    {
        "name": "Mahoba",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.29210000",
        "longitude": "79.87242000",
        "label": "Mahoba",
        "value": "Mahoba"
    },
    {
        "name": "Maholi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.66368000",
        "longitude": "80.47371000",
        "label": "Maholi",
        "value": "Maholi"
    },
    {
        "name": "Mahrajganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.26666667",
        "longitude": "83.11666667",
        "label": "Mahrajganj",
        "value": "Mahrajganj"
    },
    {
        "name": "Mahrajganj (Raebareli)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.38333333",
        "longitude": "81.28333333",
        "label": "Mahrajganj (Raebareli)",
        "value": "Mahrajganj (Raebareli)"
    },
    {
        "name": "Mahroni",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.58624000",
        "longitude": "78.72771000",
        "label": "Mahroni",
        "value": "Mahroni"
    },
    {
        "name": "Mahul",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.13333333",
        "longitude": "82.81666667",
        "label": "Mahul",
        "value": "Mahul"
    },
    {
        "name": "Mailani",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.29088000",
        "longitude": "80.34380000",
        "label": "Mailani",
        "value": "Mailani"
    },
    {
        "name": "Mainpuri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.16667000",
        "longitude": "79.00000000",
        "label": "Mainpuri",
        "value": "Mainpuri"
    },
    {
        "name": "Majhupur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.03333333",
        "longitude": "79.41666667",
        "label": "Majhupur",
        "value": "Majhupur"
    },
    {
        "name": "Makanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.90222222",
        "longitude": "79.97805556",
        "label": "Makanpur",
        "value": "Makanpur"
    },
    {
        "name": "Malasa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.26755000",
        "longitude": "79.94489000",
        "label": "Malasa",
        "value": "Malasa"
    },
    {
        "name": "Malihabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.92223000",
        "longitude": "80.71078000",
        "label": "Malihabad",
        "value": "Malihabad"
    },
    {
        "name": "Mandawar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.48655000",
        "longitude": "78.12732000",
        "label": "Mandawar",
        "value": "Mandawar"
    },
    {
        "name": "Maniar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.98546000",
        "longitude": "84.17233000",
        "label": "Maniar",
        "value": "Maniar"
    },
    {
        "name": "Manikpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.06083000",
        "longitude": "81.09961000",
        "label": "Manikpur",
        "value": "Manikpur"
    },
    {
        "name": "Manjhanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.53046000",
        "longitude": "81.37566000",
        "label": "Manjhanpur",
        "value": "Manjhanpur"
    },
    {
        "name": "Mankapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.05189000",
        "longitude": "82.22961000",
        "label": "Mankapur",
        "value": "Mankapur"
    },
    {
        "name": "Marahra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.73680000",
        "longitude": "78.56891000",
        "label": "Marahra",
        "value": "Marahra"
    },
    {
        "name": "Mariahu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.60404000",
        "longitude": "82.60379000",
        "label": "Mariahu",
        "value": "Mariahu"
    },
    {
        "name": "Mataundh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.43594000",
        "longitude": "80.15653000",
        "label": "Mataundh",
        "value": "Mataundh"
    },
    {
        "name": "Mathura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.63333000",
        "longitude": "77.58333000",
        "label": "Mathura",
        "value": "Mathura"
    },
    {
        "name": "Mau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.02940000",
        "longitude": "83.50756000",
        "label": "Mau",
        "value": "Mau"
    },
    {
        "name": "Mau Aima",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.70000000",
        "longitude": "81.91666667",
        "label": "Mau Aima",
        "value": "Mau Aima"
    },
    {
        "name": "Mau Aimma",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.69515000",
        "longitude": "81.92336000",
        "label": "Mau Aimma",
        "value": "Mau Aimma"
    },
    {
        "name": "Maudaha",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.68312000",
        "longitude": "80.11419000",
        "label": "Maudaha",
        "value": "Maudaha"
    },
    {
        "name": "Mauranwan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.42876000",
        "longitude": "80.88008000",
        "label": "Mauranwan",
        "value": "Mauranwan"
    },
    {
        "name": "Maurawan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.43000000",
        "longitude": "80.88000000",
        "label": "Maurawan",
        "value": "Maurawan"
    },
    {
        "name": "Mawana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.10288000",
        "longitude": "77.92199000",
        "label": "Mawana",
        "value": "Mawana"
    },
    {
        "name": "Mawar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.30416667",
        "longitude": "79.92111111",
        "label": "Mawar",
        "value": "Mawar"
    },
    {
        "name": "Meerut",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.91667000",
        "longitude": "77.68333000",
        "label": "Meerut",
        "value": "Meerut"
    },
    {
        "name": "Mehdawal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.98333333",
        "longitude": "83.11666667",
        "label": "Mehdawal",
        "value": "Mehdawal"
    },
    {
        "name": "Mehnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.87889000",
        "longitude": "83.11611000",
        "label": "Mehnagar",
        "value": "Mehnagar"
    },
    {
        "name": "Mehndawal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.97579000",
        "longitude": "83.10995000",
        "label": "Mehndawal",
        "value": "Mehndawal"
    },
    {
        "name": "Milak",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.61031000",
        "longitude": "79.16997000",
        "label": "Milak",
        "value": "Milak"
    },
    {
        "name": "Milkipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.60000000",
        "longitude": "81.91000000",
        "label": "Milkipur",
        "value": "Milkipur"
    },
    {
        "name": "Miranpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.29026000",
        "longitude": "77.94939000",
        "label": "Miranpur",
        "value": "Miranpur"
    },
    {
        "name": "Miranpur Katra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.02963000",
        "longitude": "79.66778000",
        "label": "Miranpur Katra",
        "value": "Miranpur Katra"
    },
    {
        "name": "Mirganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.54012000",
        "longitude": "79.20817000",
        "label": "Mirganj",
        "value": "Mirganj"
    },
    {
        "name": "Mirzapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.14490000",
        "longitude": "82.56534000",
        "label": "Mirzapur",
        "value": "Mirzapur"
    },
    {
        "name": "Misrikh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.43137000",
        "longitude": "80.53157000",
        "label": "Misrikh",
        "value": "Misrikh"
    },
    {
        "name": "Mohan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.78008000",
        "longitude": "80.67497000",
        "label": "Mohan",
        "value": "Mohan"
    },
    {
        "name": "Mohanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.25261000",
        "longitude": "80.24498000",
        "label": "Mohanpur",
        "value": "Mohanpur"
    },
    {
        "name": "Moradabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.85250000",
        "longitude": "78.79703000",
        "label": "Moradabad",
        "value": "Moradabad"
    },
    {
        "name": "Moth",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.72595000",
        "longitude": "78.95029000",
        "label": "Moth",
        "value": "Moth"
    },
    {
        "name": "Mubarakpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.08866000",
        "longitude": "83.29088000",
        "label": "Mubarakpur",
        "value": "Mubarakpur"
    },
    {
        "name": "Mughal Sarai",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.28307000",
        "longitude": "83.11968000",
        "label": "Mughal Sarai",
        "value": "Mughal Sarai"
    },
    {
        "name": "Muhammadabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.61907000",
        "longitude": "83.75576000",
        "label": "Muhammadabad",
        "value": "Muhammadabad"
    },
    {
        "name": "Mukteshwar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.47220000",
        "longitude": "79.64790000",
        "label": "Mukteshwar",
        "value": "Mukteshwar"
    },
    {
        "name": "Mungra Badshahpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.65000000",
        "longitude": "82.18000000",
        "label": "Mungra Badshahpur",
        "value": "Mungra Badshahpur"
    },
    {
        "name": "Munsyari",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "30.06741389",
        "longitude": "80.23856111",
        "label": "Munsyari",
        "value": "Munsyari"
    },
    {
        "name": "Muradabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.83888889",
        "longitude": "78.77694444",
        "label": "Muradabad",
        "value": "Muradabad"
    },
    {
        "name": "Muradnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.78069000",
        "longitude": "77.49865000",
        "label": "Muradnagar",
        "value": "Muradnagar"
    },
    {
        "name": "Mursan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.57788000",
        "longitude": "77.94091000",
        "label": "Mursan",
        "value": "Mursan"
    },
    {
        "name": "Musafir-Khana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.37837000",
        "longitude": "81.79607000",
        "label": "Musafir-Khana",
        "value": "Musafir-Khana"
    },
    {
        "name": "Musafirkhana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.45000000",
        "longitude": "81.80000000",
        "label": "Musafirkhana",
        "value": "Musafirkhana"
    },
    {
        "name": "Muzaffarnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.45000000",
        "longitude": "77.58333000",
        "label": "Muzaffarnagar",
        "value": "Muzaffarnagar"
    },
    {
        "name": "Nadigaon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.10784000",
        "longitude": "79.02283000",
        "label": "Nadigaon",
        "value": "Nadigaon"
    },
    {
        "name": "Nagina",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.44433000",
        "longitude": "78.43646000",
        "label": "Nagina",
        "value": "Nagina"
    },
    {
        "name": "Nagla",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.01000000",
        "longitude": "79.51000000",
        "label": "Nagla",
        "value": "Nagla"
    },
    {
        "name": "Nagram",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.61872000",
        "longitude": "81.14043000",
        "label": "Nagram",
        "value": "Nagram"
    },
    {
        "name": "Najibabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.61194000",
        "longitude": "78.34274000",
        "label": "Najibabad",
        "value": "Najibabad"
    },
    {
        "name": "Nakur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.91964000",
        "longitude": "77.30438000",
        "label": "Nakur",
        "value": "Nakur"
    },
    {
        "name": "Nanauta",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.71215000",
        "longitude": "77.41728000",
        "label": "Nanauta",
        "value": "Nanauta"
    },
    {
        "name": "Nandgaon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.71102000",
        "longitude": "77.38653000",
        "label": "Nandgaon",
        "value": "Nandgaon"
    },
    {
        "name": "Nanpara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.86459000",
        "longitude": "81.50036000",
        "label": "Nanpara",
        "value": "Nanpara"
    },
    {
        "name": "Narauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.48547000",
        "longitude": "78.71484000",
        "label": "Narauli",
        "value": "Narauli"
    },
    {
        "name": "Naraura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.20147000",
        "longitude": "78.38723000",
        "label": "Naraura",
        "value": "Naraura"
    },
    {
        "name": "Narora",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.19666667",
        "longitude": "78.38138889",
        "label": "Narora",
        "value": "Narora"
    },
    {
        "name": "Naugama",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.70000000",
        "longitude": "79.65000000",
        "label": "Naugama",
        "value": "Naugama"
    },
    {
        "name": "Naurangpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.16666667",
        "longitude": "79.78333333",
        "label": "Naurangpur",
        "value": "Naurangpur"
    },
    {
        "name": "Nautanwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.42752000",
        "longitude": "83.41789000",
        "label": "Nautanwa",
        "value": "Nautanwa"
    },
    {
        "name": "Nawabganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.93129000",
        "longitude": "81.19841000",
        "label": "Nawabganj",
        "value": "Nawabganj"
    },
    {
        "name": "Nawabganj (Barabanki)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.94000000",
        "longitude": "81.19000000",
        "label": "Nawabganj (Barabanki)",
        "value": "Nawabganj (Barabanki)"
    },
    {
        "name": "Nawabganj (Bareilly)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.54000000",
        "longitude": "79.63300000",
        "label": "Nawabganj (Bareilly)",
        "value": "Nawabganj (Bareilly)"
    },
    {
        "name": "Newara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.76666667",
        "longitude": "79.28333333",
        "label": "Newara",
        "value": "Newara"
    },
    {
        "name": "Nichlaul",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.31247000",
        "longitude": "83.72530000",
        "label": "Nichlaul",
        "value": "Nichlaul"
    },
    {
        "name": "Nigoh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75000000",
        "longitude": "79.15000000",
        "label": "Nigoh",
        "value": "Nigoh"
    },
    {
        "name": "Nihtaur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.32416000",
        "longitude": "78.38724000",
        "label": "Nihtaur",
        "value": "Nihtaur"
    },
    {
        "name": "Niwari",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.87611000",
        "longitude": "77.53820000",
        "label": "Niwari",
        "value": "Niwari"
    },
    {
        "name": "Nizamabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.05295000",
        "longitude": "83.05787000",
        "label": "Nizamabad",
        "value": "Nizamabad"
    },
    {
        "name": "Noida",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.58000000",
        "longitude": "77.33000000",
        "label": "Noida",
        "value": "Noida"
    },
    {
        "name": "Nurpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.14956000",
        "longitude": "78.40840000",
        "label": "Nurpur",
        "value": "Nurpur"
    },
    {
        "name": "Obra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.41863000",
        "longitude": "82.98797000",
        "label": "Obra",
        "value": "Obra"
    },
    {
        "name": "Orai",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.99023000",
        "longitude": "79.45334000",
        "label": "Orai",
        "value": "Orai"
    },
    {
        "name": "Oran",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.36882000",
        "longitude": "80.74230000",
        "label": "Oran",
        "value": "Oran"
    },
    {
        "name": "Pachperwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.51234000",
        "longitude": "82.64297000",
        "label": "Pachperwa",
        "value": "Pachperwa"
    },
    {
        "name": "Padrauna",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.90403000",
        "longitude": "83.98087000",
        "label": "Padrauna",
        "value": "Padrauna"
    },
    {
        "name": "Pahasu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.17220000",
        "longitude": "78.06376000",
        "label": "Pahasu",
        "value": "Pahasu"
    },
    {
        "name": "Paigaon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.78333333",
        "longitude": "77.53333333",
        "label": "Paigaon",
        "value": "Paigaon"
    },
    {
        "name": "Pali",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.49188000",
        "longitude": "78.41617000",
        "label": "Pali",
        "value": "Pali"
    },
    {
        "name": "Palia Kalan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.43205000",
        "longitude": "80.58137000",
        "label": "Palia Kalan",
        "value": "Palia Kalan"
    },
    {
        "name": "Paras Rampur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "31.32388889",
        "longitude": "75.67472222",
        "label": "Paras Rampur",
        "value": "Paras Rampur"
    },
    {
        "name": "Parichha",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.50789000",
        "longitude": "78.75954000",
        "label": "Parichha",
        "value": "Parichha"
    },
    {
        "name": "Parichhatgarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.97841000",
        "longitude": "77.93422000",
        "label": "Parichhatgarh",
        "value": "Parichhatgarh"
    },
    {
        "name": "Parshadepur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.07354000",
        "longitude": "81.49207000",
        "label": "Parshadepur",
        "value": "Parshadepur"
    },
    {
        "name": "Pathakpura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.84441000",
        "longitude": "78.74037000",
        "label": "Pathakpura",
        "value": "Pathakpura"
    },
    {
        "name": "Patiali",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.69086000",
        "longitude": "78.99823000",
        "label": "Patiali",
        "value": "Patiali"
    },
    {
        "name": "Patti",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.92150000",
        "longitude": "82.20048000",
        "label": "Patti",
        "value": "Patti"
    },
    {
        "name": "Pawayan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.06626000",
        "longitude": "80.10305000",
        "label": "Pawayan",
        "value": "Pawayan"
    },
    {
        "name": "Payagpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.40000000",
        "longitude": "81.80000000",
        "label": "Payagpur",
        "value": "Payagpur"
    },
    {
        "name": "Phalauda",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.18824000",
        "longitude": "77.82996000",
        "label": "Phalauda",
        "value": "Phalauda"
    },
    {
        "name": "Phaphamau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.53333333",
        "longitude": "81.86666667",
        "label": "Phaphamau",
        "value": "Phaphamau"
    },
    {
        "name": "Phaphund",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.59888000",
        "longitude": "79.46437000",
        "label": "Phaphund",
        "value": "Phaphund"
    },
    {
        "name": "Phariha",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.32166000",
        "longitude": "78.47267000",
        "label": "Phariha",
        "value": "Phariha"
    },
    {
        "name": "Pheona",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.08333333",
        "longitude": "78.35000000",
        "label": "Pheona",
        "value": "Pheona"
    },
    {
        "name": "Phulpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.54895000",
        "longitude": "82.08950000",
        "label": "Phulpur",
        "value": "Phulpur"
    },
    {
        "name": "Pichhaura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.33333333",
        "longitude": "79.31666667",
        "label": "Pichhaura",
        "value": "Pichhaura"
    },
    {
        "name": "Pihani",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.61987000",
        "longitude": "80.20343000",
        "label": "Pihani",
        "value": "Pihani"
    },
    {
        "name": "Pilibhit",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.63124000",
        "longitude": "79.80436000",
        "label": "Pilibhit",
        "value": "Pilibhit"
    },
    {
        "name": "Pilkhua",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.71271000",
        "longitude": "77.65600000",
        "label": "Pilkhua",
        "value": "Pilkhua"
    },
    {
        "name": "Pilkhuwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.71196944",
        "longitude": "77.65445556",
        "label": "Pilkhuwa",
        "value": "Pilkhuwa"
    },
    {
        "name": "Pinahat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.88487000",
        "longitude": "78.37647000",
        "label": "Pinahat",
        "value": "Pinahat"
    },
    {
        "name": "Pipraich",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.82745000",
        "longitude": "83.52632000",
        "label": "Pipraich",
        "value": "Pipraich"
    },
    {
        "name": "Pipri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.18000000",
        "longitude": "83.00000000",
        "label": "Pipri",
        "value": "Pipri"
    },
    {
        "name": "Pratapgarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.75000000",
        "longitude": "81.75000000",
        "label": "Pratapgarh",
        "value": "Pratapgarh"
    },
    {
        "name": "Prayagraj (Allahabad)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.42012000",
        "longitude": "81.88385000",
        "label": "Prayagraj (Allahabad)",
        "value": "Prayagraj (Allahabad)"
    },
    {
        "name": "Pukhrayan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.22375000",
        "longitude": "79.83739000",
        "label": "Pukhrayan",
        "value": "Pukhrayan"
    },
    {
        "name": "Puranpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.51283000",
        "longitude": "80.14829000",
        "label": "Puranpur",
        "value": "Puranpur"
    },
    {
        "name": "Purmafi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.55000000",
        "longitude": "77.26666667",
        "label": "Purmafi",
        "value": "Purmafi"
    },
    {
        "name": "Purwa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.45756000",
        "longitude": "80.77403000",
        "label": "Purwa",
        "value": "Purwa"
    },
    {
        "name": "Qadirganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.78333333",
        "longitude": "79.06666667",
        "label": "Qadirganj",
        "value": "Qadirganj"
    },
    {
        "name": "Rabupura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.25153000",
        "longitude": "77.60253000",
        "label": "Rabupura",
        "value": "Rabupura"
    },
    {
        "name": "Radha Kund",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.52444444",
        "longitude": "77.49027778",
        "label": "Radha Kund",
        "value": "Radha Kund"
    },
    {
        "name": "Radhakund",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.52432000",
        "longitude": "77.49101000",
        "label": "Radhakund",
        "value": "Radhakund"
    },
    {
        "name": "Raebareli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.23090000",
        "longitude": "81.23315000",
        "label": "Raebareli",
        "value": "Raebareli"
    },
    {
        "name": "Rajapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.38725000",
        "longitude": "81.15125000",
        "label": "Rajapur",
        "value": "Rajapur"
    },
    {
        "name": "Ramkola",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.90172000",
        "longitude": "83.83758000",
        "label": "Ramkola",
        "value": "Ramkola"
    },
    {
        "name": "Ramnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.26907000",
        "longitude": "83.02971000",
        "label": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "name": "Rampur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.81014000",
        "longitude": "79.02699000",
        "label": "Rampur",
        "value": "Rampur"
    },
    {
        "name": "Rampura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.34967000",
        "longitude": "79.18234000",
        "label": "Rampura",
        "value": "Rampura"
    },
    {
        "name": "Ranipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.25034000",
        "longitude": "79.06204000",
        "label": "Ranipur",
        "value": "Ranipur"
    },
    {
        "name": "Ranipur Barsi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.90000000",
        "longitude": "77.21666667",
        "label": "Ranipur Barsi",
        "value": "Ranipur Barsi"
    },
    {
        "name": "Rasra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.85760000",
        "longitude": "83.85487000",
        "label": "Rasra",
        "value": "Rasra"
    },
    {
        "name": "Rasulabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.74491000",
        "longitude": "80.49012000",
        "label": "Rasulabad",
        "value": "Rasulabad"
    },
    {
        "name": "Rath",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.59474000",
        "longitude": "79.56660000",
        "label": "Rath",
        "value": "Rath"
    },
    {
        "name": "Raya",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.55607000",
        "longitude": "77.78972000",
        "label": "Raya",
        "value": "Raya"
    },
    {
        "name": "Rehar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.36666667",
        "longitude": "78.76666667",
        "label": "Rehar",
        "value": "Rehar"
    },
    {
        "name": "Renukoot",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.20000000",
        "longitude": "83.03000000",
        "label": "Renukoot",
        "value": "Renukoot"
    },
    {
        "name": "Renukut",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.21641000",
        "longitude": "83.03580000",
        "label": "Renukut",
        "value": "Renukut"
    },
    {
        "name": "Reoti",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.85091000",
        "longitude": "84.37780000",
        "label": "Reoti",
        "value": "Reoti"
    },
    {
        "name": "Reotipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.55000000",
        "longitude": "83.71666667",
        "label": "Reotipur",
        "value": "Reotipur"
    },
    {
        "name": "Richha",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.69467000",
        "longitude": "79.52284000",
        "label": "Richha",
        "value": "Richha"
    },
    {
        "name": "Robertsganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.68860000",
        "longitude": "83.06784000",
        "label": "Robertsganj",
        "value": "Robertsganj"
    },
    {
        "name": "Rudarpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.44467000",
        "longitude": "83.61302000",
        "label": "Rudarpur",
        "value": "Rudarpur"
    },
    {
        "name": "Rudauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.75000000",
        "longitude": "81.75000000",
        "label": "Rudauli",
        "value": "Rudauli"
    },
    {
        "name": "Rura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.49001000",
        "longitude": "79.90108000",
        "label": "Rura",
        "value": "Rura"
    },
    {
        "name": "Sabalpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.17604900",
        "longitude": "79.42012000",
        "label": "Sabalpur",
        "value": "Sabalpur"
    },
    {
        "name": "Sachendi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.37116500",
        "longitude": "80.11230000",
        "label": "Sachendi",
        "value": "Sachendi"
    },
    {
        "name": "Sadabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.43818000",
        "longitude": "78.03758000",
        "label": "Sadabad",
        "value": "Sadabad"
    },
    {
        "name": "Sadat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.67117000",
        "longitude": "83.30269000",
        "label": "Sadat",
        "value": "Sadat"
    },
    {
        "name": "Safipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.73783000",
        "longitude": "80.34350000",
        "label": "Safipur",
        "value": "Safipur"
    },
    {
        "name": "Saharanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.90000000",
        "longitude": "77.68333000",
        "label": "Saharanpur",
        "value": "Saharanpur"
    },
    {
        "name": "Sahaspur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.12125000",
        "longitude": "78.62273000",
        "label": "Sahaspur",
        "value": "Sahaspur"
    },
    {
        "name": "Sahaswan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.07227000",
        "longitude": "78.75082000",
        "label": "Sahaswan",
        "value": "Sahaswan"
    },
    {
        "name": "Sahawar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.79603000",
        "longitude": "78.83373000",
        "label": "Sahawar",
        "value": "Sahawar"
    },
    {
        "name": "Sahibabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.68333333",
        "longitude": "77.40000000",
        "label": "Sahibabad",
        "value": "Sahibabad"
    },
    {
        "name": "Sahpau",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.43527778",
        "longitude": "78.14138889",
        "label": "Sahpau",
        "value": "Sahpau"
    },
    {
        "name": "Saidpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.53749000",
        "longitude": "83.22378000",
        "label": "Saidpur",
        "value": "Saidpur"
    },
    {
        "name": "Sakhanu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.95472222",
        "longitude": "79.22750000",
        "label": "Sakhanu",
        "value": "Sakhanu"
    },
    {
        "name": "Sakit",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.43463000",
        "longitude": "78.77903000",
        "label": "Sakit",
        "value": "Sakit"
    },
    {
        "name": "Salempur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.30000000",
        "longitude": "83.91666667",
        "label": "Salempur",
        "value": "Salempur"
    },
    {
        "name": "Salon",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.02857000",
        "longitude": "81.45403000",
        "label": "Salon",
        "value": "Salon"
    },
    {
        "name": "Sambhal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.58498000",
        "longitude": "78.56959000",
        "label": "Sambhal",
        "value": "Sambhal"
    },
    {
        "name": "Samthar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.84348000",
        "longitude": "78.90683000",
        "label": "Samthar",
        "value": "Samthar"
    },
    {
        "name": "Sandi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.28867000",
        "longitude": "79.95190000",
        "label": "Sandi",
        "value": "Sandi"
    },
    {
        "name": "Sandila",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.06989000",
        "longitude": "80.51497000",
        "label": "Sandila",
        "value": "Sandila"
    },
    {
        "name": "Sant Kabir Nagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.79016000",
        "longitude": "83.03481000",
        "label": "Sant Kabir Nagar",
        "value": "Sant Kabir Nagar"
    },
    {
        "name": "Sant Ravi Das Nagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.35792000",
        "longitude": "82.43080000",
        "label": "Sant Ravi Das Nagar",
        "value": "Sant Ravi Das Nagar"
    },
    {
        "name": "Sarai Akil",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.37890000",
        "longitude": "81.51035000",
        "label": "Sarai Akil",
        "value": "Sarai Akil"
    },
    {
        "name": "Sarai Ekdil",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.74442000",
        "longitude": "79.09353000",
        "label": "Sarai Ekdil",
        "value": "Sarai Ekdil"
    },
    {
        "name": "Sarai Mir",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.02705000",
        "longitude": "82.91843000",
        "label": "Sarai Mir",
        "value": "Sarai Mir"
    },
    {
        "name": "Sarauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.49404000",
        "longitude": "79.09177000",
        "label": "Sarauli",
        "value": "Sarauli"
    },
    {
        "name": "Sardhana",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.14551000",
        "longitude": "77.61433000",
        "label": "Sardhana",
        "value": "Sardhana"
    },
    {
        "name": "Sarila",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.77579000",
        "longitude": "79.67535000",
        "label": "Sarila",
        "value": "Sarila"
    },
    {
        "name": "Sarurpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.03333333",
        "longitude": "77.23333333",
        "label": "Sarurpur",
        "value": "Sarurpur"
    },
    {
        "name": "Sasni",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.70287000",
        "longitude": "78.08278000",
        "label": "Sasni",
        "value": "Sasni"
    },
    {
        "name": "Satrikh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.86045000",
        "longitude": "81.19567000",
        "label": "Satrikh",
        "value": "Satrikh"
    },
    {
        "name": "Saurikh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.03051000",
        "longitude": "79.48813000",
        "label": "Saurikh",
        "value": "Saurikh"
    },
    {
        "name": "Sector",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.57080000",
        "longitude": "77.32610000",
        "label": "Sector",
        "value": "Sector"
    },
    {
        "name": "Seohara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.20904000",
        "longitude": "78.58837000",
        "label": "Seohara",
        "value": "Seohara"
    },
    {
        "name": "Shahabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.64310000",
        "longitude": "79.94020000",
        "label": "Shahabad",
        "value": "Shahabad"
    },
    {
        "name": "Shahganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.04965000",
        "longitude": "82.68423000",
        "label": "Shahganj",
        "value": "Shahganj"
    },
    {
        "name": "Shahi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.55023000",
        "longitude": "79.31761000",
        "label": "Shahi",
        "value": "Shahi"
    },
    {
        "name": "Shahjahanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.00000000",
        "longitude": "79.83333000",
        "label": "Shahjahanpur",
        "value": "Shahjahanpur"
    },
    {
        "name": "Shahpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.35010000",
        "longitude": "77.55160000",
        "label": "Shahpur",
        "value": "Shahpur"
    },
    {
        "name": "Shamli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.44970000",
        "longitude": "77.30959000",
        "label": "Shamli",
        "value": "Shamli"
    },
    {
        "name": "Shamsabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.01718000",
        "longitude": "78.12358000",
        "label": "Shamsabad",
        "value": "Shamsabad"
    },
    {
        "name": "Shankargarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.18200000",
        "longitude": "81.61769000",
        "label": "Shankargarh",
        "value": "Shankargarh"
    },
    {
        "name": "Shergarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.65128000",
        "longitude": "79.36815000",
        "label": "Shergarh",
        "value": "Shergarh"
    },
    {
        "name": "Sherkot",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.32704000",
        "longitude": "78.57429000",
        "label": "Sherkot",
        "value": "Sherkot"
    },
    {
        "name": "Shibnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.50000000",
        "longitude": "79.98333333",
        "label": "Shibnagar",
        "value": "Shibnagar"
    },
    {
        "name": "Shikarpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.28072000",
        "longitude": "78.01411000",
        "label": "Shikarpur",
        "value": "Shikarpur"
    },
    {
        "name": "Shikarpur (Bulandshahr)",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.28000000",
        "longitude": "78.02000000",
        "label": "Shikarpur (Bulandshahr)",
        "value": "Shikarpur (Bulandshahr)"
    },
    {
        "name": "Shikohabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.10800000",
        "longitude": "78.58661000",
        "label": "Shikohabad",
        "value": "Shikohabad"
    },
    {
        "name": "Shishgarh",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.72928000",
        "longitude": "79.31469000",
        "label": "Shishgarh",
        "value": "Shishgarh"
    },
    {
        "name": "Shivrajpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.85722222",
        "longitude": "79.11500000",
        "label": "Shivrajpur",
        "value": "Shivrajpur"
    },
    {
        "name": "Shrawasti",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.50746000",
        "longitude": "82.00470000",
        "label": "Shrawasti",
        "value": "Shrawasti"
    },
    {
        "name": "Siddharthnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.25797000",
        "longitude": "83.01465000",
        "label": "Siddharthnagar",
        "value": "Siddharthnagar"
    },
    {
        "name": "Siddhaur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.76944444",
        "longitude": "81.41805556",
        "label": "Siddhaur",
        "value": "Siddhaur"
    },
    {
        "name": "Sidhauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.28202000",
        "longitude": "80.83450000",
        "label": "Sidhauli",
        "value": "Sidhauli"
    },
    {
        "name": "Sidhpura",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.63312000",
        "longitude": "78.86918000",
        "label": "Sidhpura",
        "value": "Sidhpura"
    },
    {
        "name": "Sikandarabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.45226000",
        "longitude": "77.70004000",
        "label": "Sikandarabad",
        "value": "Sikandarabad"
    },
    {
        "name": "Sikandarpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.04327000",
        "longitude": "84.05298000",
        "label": "Sikandarpur",
        "value": "Sikandarpur"
    },
    {
        "name": "Sikandra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.36722000",
        "longitude": "79.62980000",
        "label": "Sikandra",
        "value": "Sikandra"
    },
    {
        "name": "Sikandra Rao",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.68859000",
        "longitude": "78.37985000",
        "label": "Sikandra Rao",
        "value": "Sikandra Rao"
    },
    {
        "name": "Sikandrabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.44952500",
        "longitude": "77.69415278",
        "label": "Sikandrabad",
        "value": "Sikandrabad"
    },
    {
        "name": "Sirathu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.64292000",
        "longitude": "81.31855000",
        "label": "Sirathu",
        "value": "Sirathu"
    },
    {
        "name": "Sirsa",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.26340000",
        "longitude": "82.09190000",
        "label": "Sirsa",
        "value": "Sirsa"
    },
    {
        "name": "Sirsaganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.05715000",
        "longitude": "78.68661000",
        "label": "Sirsaganj",
        "value": "Sirsaganj"
    },
    {
        "name": "Sirsi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.63916000",
        "longitude": "78.64303000",
        "label": "Sirsi",
        "value": "Sirsi"
    },
    {
        "name": "Sisauli",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.41386000",
        "longitude": "77.46890000",
        "label": "Sisauli",
        "value": "Sisauli"
    },
    {
        "name": "Siswa Bazar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.14652000",
        "longitude": "83.75803000",
        "label": "Siswa Bazar",
        "value": "Siswa Bazar"
    },
    {
        "name": "Sitapur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.50000000",
        "longitude": "80.91667000",
        "label": "Sitapur",
        "value": "Sitapur"
    },
    {
        "name": "Sonbhadra",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "24.40212000",
        "longitude": "83.05352000",
        "label": "Sonbhadra",
        "value": "Sonbhadra"
    },
    {
        "name": "Soron",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.89055000",
        "longitude": "78.74621000",
        "label": "Soron",
        "value": "Soron"
    },
    {
        "name": "Suar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.02841000",
        "longitude": "79.05654000",
        "label": "Suar",
        "value": "Suar"
    },
    {
        "name": "Sultanpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.25000000",
        "longitude": "82.00000000",
        "label": "Sultanpur",
        "value": "Sultanpur"
    },
    {
        "name": "Surianwan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.46387000",
        "longitude": "82.41922000",
        "label": "Surianwan",
        "value": "Surianwan"
    },
    {
        "name": "Tajpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.16242000",
        "longitude": "78.48458000",
        "label": "Tajpur",
        "value": "Tajpur"
    },
    {
        "name": "Talbahat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.04357000",
        "longitude": "78.43441000",
        "label": "Talbahat",
        "value": "Talbahat"
    },
    {
        "name": "Talgram",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.04753000",
        "longitude": "79.64811000",
        "label": "Talgram",
        "value": "Talgram"
    },
    {
        "name": "Tanda",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.97621000",
        "longitude": "78.94187000",
        "label": "Tanda",
        "value": "Tanda"
    },
    {
        "name": "Terha",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.83330000",
        "longitude": "80.25000000",
        "label": "Terha",
        "value": "Terha"
    },
    {
        "name": "Thakurdwara",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.19203000",
        "longitude": "78.86145000",
        "label": "Thakurdwara",
        "value": "Thakurdwara"
    },
    {
        "name": "Thana Bhawan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.58605000",
        "longitude": "77.41811000",
        "label": "Thana Bhawan",
        "value": "Thana Bhawan"
    },
    {
        "name": "Tigri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.51083333",
        "longitude": "77.23888889",
        "label": "Tigri",
        "value": "Tigri"
    },
    {
        "name": "Tikaitnagar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.94612000",
        "longitude": "81.56583000",
        "label": "Tikaitnagar",
        "value": "Tikaitnagar"
    },
    {
        "name": "Tikri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.22910000",
        "longitude": "77.35479000",
        "label": "Tikri",
        "value": "Tikri"
    },
    {
        "name": "Tilhar",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.96282000",
        "longitude": "79.73827000",
        "label": "Tilhar",
        "value": "Tilhar"
    },
    {
        "name": "Tilsahri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.33330000",
        "longitude": "80.41670000",
        "label": "Tilsahri",
        "value": "Tilsahri"
    },
    {
        "name": "Tindwari",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.61739000",
        "longitude": "80.52718000",
        "label": "Tindwari",
        "value": "Tindwari"
    },
    {
        "name": "Titron",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.66824000",
        "longitude": "77.32391000",
        "label": "Titron",
        "value": "Titron"
    },
    {
        "name": "Tori Fatehpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.45000000",
        "longitude": "79.13330000",
        "label": "Tori Fatehpur",
        "value": "Tori Fatehpur"
    },
    {
        "name": "Tori-Fatehpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.45505000",
        "longitude": "79.11428000",
        "label": "Tori-Fatehpur",
        "value": "Tori-Fatehpur"
    },
    {
        "name": "Tulsipur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.53370000",
        "longitude": "82.41653000",
        "label": "Tulsipur",
        "value": "Tulsipur"
    },
    {
        "name": "Tundla",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.21460000",
        "longitude": "78.23683000",
        "label": "Tundla",
        "value": "Tundla"
    },
    {
        "name": "Ugu",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.79681000",
        "longitude": "80.32093000",
        "label": "Ugu",
        "value": "Ugu"
    },
    {
        "name": "Ujhani",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.00311000",
        "longitude": "79.00821000",
        "label": "Ujhani",
        "value": "Ujhani"
    },
    {
        "name": "Umri",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.33333333",
        "longitude": "79.25000000",
        "label": "Umri",
        "value": "Umri"
    },
    {
        "name": "Un",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "29.58479000",
        "longitude": "77.25540000",
        "label": "Un",
        "value": "Un"
    },
    {
        "name": "Unnao",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.50000000",
        "longitude": "80.50000000",
        "label": "Unnao",
        "value": "Unnao"
    },
    {
        "name": "Usawan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.81583333",
        "longitude": "79.34861111",
        "label": "Usawan",
        "value": "Usawan"
    },
    {
        "name": "Usehat",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.79796000",
        "longitude": "79.23763000",
        "label": "Usehat",
        "value": "Usehat"
    },
    {
        "name": "Uska",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.20000000",
        "longitude": "83.11666667",
        "label": "Uska",
        "value": "Uska"
    },
    {
        "name": "Utraula",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.31933000",
        "longitude": "82.41872000",
        "label": "Utraula",
        "value": "Utraula"
    },
    {
        "name": "Varanasi",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.31668000",
        "longitude": "83.01041000",
        "label": "Varanasi",
        "value": "Varanasi"
    },
    {
        "name": "Vindhyachal",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.16670000",
        "longitude": "82.50000000",
        "label": "Vindhyachal",
        "value": "Vindhyachal"
    },
    {
        "name": "Vrindavan",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "27.58105000",
        "longitude": "77.69662000",
        "label": "Vrindavan",
        "value": "Vrindavan"
    },
    {
        "name": "Walterganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.86670000",
        "longitude": "82.71670000",
        "label": "Walterganj",
        "value": "Walterganj"
    },
    {
        "name": "Wazirganj",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "28.21145000",
        "longitude": "79.05665000",
        "label": "Wazirganj",
        "value": "Wazirganj"
    },
    {
        "name": "Yusufpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.66670000",
        "longitude": "83.46670000",
        "label": "Yusufpur",
        "value": "Yusufpur"
    },
    {
        "name": "Zafarabad",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.69867000",
        "longitude": "82.73354000",
        "label": "Zafarabad",
        "value": "Zafarabad"
    },
    {
        "name": "Zaidpur",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "26.83093000",
        "longitude": "81.32929000",
        "label": "Zaidpur",
        "value": "Zaidpur"
    },
    {
        "name": "Zamania",
        "countryCode": "IN",
        "stateCode": "UP",
        "latitude": "25.41961000",
        "longitude": "83.55786000",
        "label": "Zamania",
        "value": "Zamania"
    }
,{
        "name": "Almora",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.69223000",
        "longitude": "79.49789000",
        "label": "Almora",
        "value": "Almora"
    },
    {
        "name": "Bageshwar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.97315000",
        "longitude": "79.83224000",
        "label": "Bageshwar",
        "value": "Bageshwar"
    },
    {
        "name": "Barkot",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.80861000",
        "longitude": "78.20596000",
        "label": "Barkot",
        "value": "Barkot"
    },
    {
        "name": "Bazpur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.15299000",
        "longitude": "79.10814000",
        "label": "Bazpur",
        "value": "Bazpur"
    },
    {
        "name": "Bhim Tal",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.34447000",
        "longitude": "79.56336000",
        "label": "Bhim Tal",
        "value": "Bhim Tal"
    },
    {
        "name": "Bhowali",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.38985000",
        "longitude": "79.50481000",
        "label": "Bhowali",
        "value": "Bhowali"
    },
    {
        "name": "Birbhaddar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.07120000",
        "longitude": "78.28189000",
        "label": "Birbhaddar",
        "value": "Birbhaddar"
    },
    {
        "name": "Chakrata",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.70369000",
        "longitude": "77.86386000",
        "label": "Chakrata",
        "value": "Chakrata"
    },
    {
        "name": "Chamoli",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.50000000",
        "longitude": "79.50000000",
        "label": "Chamoli",
        "value": "Chamoli"
    },
    {
        "name": "Champawat",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.28756000",
        "longitude": "80.03737000",
        "label": "Champawat",
        "value": "Champawat"
    },
    {
        "name": "Clement Town",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.26361000",
        "longitude": "78.00862000",
        "label": "Clement Town",
        "value": "Clement Town"
    },
    {
        "name": "Dehradun",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.33000000",
        "longitude": "78.06000000",
        "label": "Dehradun",
        "value": "Dehradun"
    },
    {
        "name": "Devaprayag",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.14603000",
        "longitude": "78.60272000",
        "label": "Devaprayag",
        "value": "Devaprayag"
    },
    {
        "name": "Dharchula",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.84707000",
        "longitude": "80.51951000",
        "label": "Dharchula",
        "value": "Dharchula"
    },
    {
        "name": "Doiwala",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.17667000",
        "longitude": "78.11659000",
        "label": "Doiwala",
        "value": "Doiwala"
    },
    {
        "name": "Dugadda",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.80673000",
        "longitude": "78.61109000",
        "label": "Dugadda",
        "value": "Dugadda"
    },
    {
        "name": "Dwarahat",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.77785000",
        "longitude": "79.42731000",
        "label": "Dwarahat",
        "value": "Dwarahat"
    },
    {
        "name": "Garhwal",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.96366000",
        "longitude": "78.92853000",
        "label": "Garhwal",
        "value": "Garhwal"
    },
    {
        "name": "Haldwani",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.22254000",
        "longitude": "79.52860000",
        "label": "Haldwani",
        "value": "Haldwani"
    },
    {
        "name": "Harbatpur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.43863000",
        "longitude": "77.74058000",
        "label": "Harbatpur",
        "value": "Harbatpur"
    },
    {
        "name": "Haridwar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.94791000",
        "longitude": "78.16025000",
        "label": "Haridwar",
        "value": "Haridwar"
    },
    {
        "name": "Jaspur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.27919000",
        "longitude": "78.82798000",
        "label": "Jaspur",
        "value": "Jaspur"
    },
    {
        "name": "Joshimath",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.55543000",
        "longitude": "79.56436000",
        "label": "Joshimath",
        "value": "Joshimath"
    },
    {
        "name": "Kaladhungi",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.28351000",
        "longitude": "79.35100000",
        "label": "Kaladhungi",
        "value": "Kaladhungi"
    },
    {
        "name": "Kalagarh Project Colony",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.47780000",
        "longitude": "78.78449000",
        "label": "Kalagarh Project Colony",
        "value": "Kalagarh Project Colony"
    },
    {
        "name": "Kashipur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.21399000",
        "longitude": "78.95693000",
        "label": "Kashipur",
        "value": "Kashipur"
    },
    {
        "name": "Khatima",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "28.92134000",
        "longitude": "79.97075000",
        "label": "Khatima",
        "value": "Khatima"
    },
    {
        "name": "Kichha",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "28.91154000",
        "longitude": "79.52009000",
        "label": "Kichha",
        "value": "Kichha"
    },
    {
        "name": "Kotdwara",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.74612000",
        "longitude": "78.52219000",
        "label": "Kotdwara",
        "value": "Kotdwara"
    },
    {
        "name": "Laksar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.75870000",
        "longitude": "78.04148000",
        "label": "Laksar",
        "value": "Laksar"
    },
    {
        "name": "Lansdowne",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.84183000",
        "longitude": "78.68014000",
        "label": "Lansdowne",
        "value": "Lansdowne"
    },
    {
        "name": "Lohaghat",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.40356000",
        "longitude": "80.08965000",
        "label": "Lohaghat",
        "value": "Lohaghat"
    },
    {
        "name": "Manglaur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.79094000",
        "longitude": "77.87836000",
        "label": "Manglaur",
        "value": "Manglaur"
    },
    {
        "name": "Mussoorie",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.45498000",
        "longitude": "78.07068000",
        "label": "Mussoorie",
        "value": "Mussoorie"
    },
    {
        "name": "Naini Tal",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.39743000",
        "longitude": "79.44686000",
        "label": "Naini Tal",
        "value": "Naini Tal"
    },
    {
        "name": "Narendranagar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.16173000",
        "longitude": "78.28712000",
        "label": "Narendranagar",
        "value": "Narendranagar"
    },
    {
        "name": "Pauri",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.15286000",
        "longitude": "78.77710000",
        "label": "Pauri",
        "value": "Pauri"
    },
    {
        "name": "Pipalkoti",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.42553000",
        "longitude": "79.43066000",
        "label": "Pipalkoti",
        "value": "Pipalkoti"
    },
    {
        "name": "Pithoragarh",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.00000000",
        "longitude": "80.25000000",
        "label": "Pithoragarh",
        "value": "Pithoragarh"
    },
    {
        "name": "Raipur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.31097000",
        "longitude": "78.08979000",
        "label": "Raipur",
        "value": "Raipur"
    },
    {
        "name": "Raiwala Bara",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.01864000",
        "longitude": "78.22930000",
        "label": "Raiwala Bara",
        "value": "Raiwala Bara"
    },
    {
        "name": "Ramnagar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.39250000",
        "longitude": "79.12830000",
        "label": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "name": "Ranikhet",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.64082000",
        "longitude": "79.43229000",
        "label": "Ranikhet",
        "value": "Ranikhet"
    },
    {
        "name": "Rishikesh",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.10778000",
        "longitude": "78.29255000",
        "label": "Rishikesh",
        "value": "Rishikesh"
    },
    {
        "name": "Roorkee",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.86632000",
        "longitude": "77.89118000",
        "label": "Roorkee",
        "value": "Roorkee"
    },
    {
        "name": "Rudraprayag",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.60872000",
        "longitude": "79.06517000",
        "label": "Rudraprayag",
        "value": "Rudraprayag"
    },
    {
        "name": "Sitarganj",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "28.92930000",
        "longitude": "79.70436000",
        "label": "Sitarganj",
        "value": "Sitarganj"
    },
    {
        "name": "Srinagar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.22243000",
        "longitude": "78.78341000",
        "label": "Srinagar",
        "value": "Srinagar"
    },
    {
        "name": "Sultanpur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.75534000",
        "longitude": "78.11034000",
        "label": "Sultanpur",
        "value": "Sultanpur"
    },
    {
        "name": "Tanakpur",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.07400000",
        "longitude": "80.11139000",
        "label": "Tanakpur",
        "value": "Tanakpur"
    },
    {
        "name": "Tehri",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.39086000",
        "longitude": "78.48030000",
        "label": "Tehri",
        "value": "Tehri"
    },
    {
        "name": "Tehri-Garhwal",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.50000000",
        "longitude": "78.66667000",
        "label": "Tehri-Garhwal",
        "value": "Tehri-Garhwal"
    },
    {
        "name": "Udham Singh Nagar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "29.02746000",
        "longitude": "79.52347000",
        "label": "Udham Singh Nagar",
        "value": "Udham Singh Nagar"
    },
    {
        "name": "Uttarkashi",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.72986000",
        "longitude": "78.44342000",
        "label": "Uttarkashi",
        "value": "Uttarkashi"
    },
    {
        "name": "Vikasnagar",
        "countryCode": "IN",
        "stateCode": "UT",
        "latitude": "30.46944000",
        "longitude": "77.77275000",
        "label": "Vikasnagar",
        "value": "Vikasnagar"
    }
,{
        "name": "Ahmedpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.83009000",
        "longitude": "87.68661000",
        "label": "Ahmedpur",
        "value": "Ahmedpur"
    },
    {
        "name": "Aistala",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.18000000",
        "longitude": "88.58000000",
        "label": "Aistala",
        "value": "Aistala"
    },
    {
        "name": "Aknapur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "18.38576389",
        "longitude": "77.27225278",
        "label": "Aknapur",
        "value": "Aknapur"
    },
    {
        "name": "Alipurduar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.49136000",
        "longitude": "89.52796000",
        "label": "Alipurduar",
        "value": "Alipurduar"
    },
    {
        "name": "Amlagora",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.84616000",
        "longitude": "87.33559000",
        "label": "Amlagora",
        "value": "Amlagora"
    },
    {
        "name": "Amta",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.57333333",
        "longitude": "88.01611111",
        "label": "Amta",
        "value": "Amta"
    },
    {
        "name": "Amtala",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.22000000",
        "longitude": "88.17000000",
        "label": "Amtala",
        "value": "Amtala"
    },
    {
        "name": "Andal",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.60000000",
        "longitude": "87.20000000",
        "label": "Andal",
        "value": "Andal"
    },
    {
        "name": "Arambagh community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.88000000",
        "longitude": "87.78000000",
        "label": "Arambagh community development block",
        "value": "Arambagh community development block"
    },
    {
        "name": "Asansol",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.68333333",
        "longitude": "86.96666667",
        "label": "Asansol",
        "value": "Asansol"
    },
    {
        "name": "Ashoknagar Kalyangarh",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.83300000",
        "longitude": "88.63300000",
        "label": "Ashoknagar Kalyangarh",
        "value": "Ashoknagar Kalyangarh"
    },
    {
        "name": "Badkulla",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.28000000",
        "longitude": "88.53000000",
        "label": "Badkulla",
        "value": "Badkulla"
    },
    {
        "name": "Baduria",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.74000000",
        "longitude": "88.79000000",
        "label": "Baduria",
        "value": "Baduria"
    },
    {
        "name": "Bagdogra",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.69980400",
        "longitude": "88.31939200",
        "label": "Bagdogra",
        "value": "Bagdogra"
    },
    {
        "name": "Bagnan",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.47000000",
        "longitude": "87.97000000",
        "label": "Bagnan",
        "value": "Bagnan"
    },
    {
        "name": "Bagula",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.33500000",
        "longitude": "88.64400000",
        "label": "Bagula",
        "value": "Bagula"
    },
    {
        "name": "Bahula",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.65741900",
        "longitude": "87.19597400",
        "label": "Bahula",
        "value": "Bahula"
    },
    {
        "name": "Baidyabati",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.79000000",
        "longitude": "88.32000000",
        "label": "Baidyabati",
        "value": "Baidyabati"
    },
    {
        "name": "Bakreswar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.88320000",
        "longitude": "87.37265000",
        "label": "Bakreswar",
        "value": "Bakreswar"
    },
    {
        "name": "Balarampur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.09714000",
        "longitude": "86.22292000",
        "label": "Balarampur",
        "value": "Balarampur"
    },
    {
        "name": "Bali Chak",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.36482000",
        "longitude": "87.55304000",
        "label": "Bali Chak",
        "value": "Bali Chak"
    },
    {
        "name": "Bally",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.65000000",
        "longitude": "88.34000000",
        "label": "Bally",
        "value": "Bally"
    },
    {
        "name": "Balurghat",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.21666667",
        "longitude": "88.76666667",
        "label": "Balurghat",
        "value": "Balurghat"
    },
    {
        "name": "Bamangola community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.17000000",
        "longitude": "88.33500000",
        "label": "Bamangola community development block",
        "value": "Bamangola community development block"
    },
    {
        "name": "Baneswar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.65000000",
        "longitude": "89.81666667",
        "label": "Baneswar",
        "value": "Baneswar"
    },
    {
        "name": "Bangaon",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.07000000",
        "longitude": "88.82000000",
        "label": "Bangaon",
        "value": "Bangaon"
    },
    {
        "name": "Bankra",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.63000000",
        "longitude": "88.30000000",
        "label": "Bankra",
        "value": "Bankra"
    },
    {
        "name": "Bankura",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.25000000",
        "longitude": "87.06666667",
        "label": "Bankura",
        "value": "Bankura"
    },
    {
        "name": "Bansberia",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.97000000",
        "longitude": "88.40000000",
        "label": "Bansberia",
        "value": "Bansberia"
    },
    {
        "name": "Bansihari community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.40000000",
        "longitude": "88.41670000",
        "label": "Bansihari community development block",
        "value": "Bansihari community development block"
    },
    {
        "name": "Barabazar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.36000000",
        "longitude": "86.65000000",
        "label": "Barabazar",
        "value": "Barabazar"
    },
    {
        "name": "Baranagar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.64000000",
        "longitude": "88.37000000",
        "label": "Baranagar",
        "value": "Baranagar"
    },
    {
        "name": "Barasat",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.23333333",
        "longitude": "88.45000000",
        "label": "Barasat",
        "value": "Barasat"
    },
    {
        "name": "Bardhaman",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.25000000",
        "longitude": "87.85000000",
        "label": "Bardhaman",
        "value": "Bardhaman"
    },
    {
        "name": "Barjora",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.43333333",
        "longitude": "87.28333333",
        "label": "Barjora",
        "value": "Barjora"
    },
    {
        "name": "Barrackpore",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.75000000",
        "longitude": "88.36666667",
        "label": "Barrackpore",
        "value": "Barrackpore"
    },
    {
        "name": "Baruipur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.35000000",
        "longitude": "88.44000000",
        "label": "Baruipur",
        "value": "Baruipur"
    },
    {
        "name": "Basanti",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.18915340",
        "longitude": "88.67056850",
        "label": "Basanti",
        "value": "Basanti"
    },
    {
        "name": "Basirhat",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.65722222",
        "longitude": "88.89416667",
        "label": "Basirhat",
        "value": "Basirhat"
    },
    {
        "name": "Bawali",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.42563000",
        "longitude": "88.19336000",
        "label": "Bawali",
        "value": "Bawali"
    },
    {
        "name": "Begampur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.74000000",
        "longitude": "88.24000000",
        "label": "Begampur",
        "value": "Begampur"
    },
    {
        "name": "Belda",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.08000000",
        "longitude": "87.35000000",
        "label": "Belda",
        "value": "Belda"
    },
    {
        "name": "Beldanga",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.93000000",
        "longitude": "88.25000000",
        "label": "Beldanga",
        "value": "Beldanga"
    },
    {
        "name": "Beliatore",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.33333333",
        "longitude": "87.21666667",
        "label": "Beliatore",
        "value": "Beliatore"
    },
    {
        "name": "Berhampore",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.10000000",
        "longitude": "88.25000000",
        "label": "Berhampore",
        "value": "Berhampore"
    },
    {
        "name": "Bhadreswar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.82000000",
        "longitude": "88.35000000",
        "label": "Bhadreswar",
        "value": "Bhadreswar"
    },
    {
        "name": "Bhandardaha",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.62000000",
        "longitude": "88.21000000",
        "label": "Bhandardaha",
        "value": "Bhandardaha"
    },
    {
        "name": "Bhatpara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.86666667",
        "longitude": "88.41666667",
        "label": "Bhatpara",
        "value": "Bhatpara"
    },
    {
        "name": "Birbhum district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.00000000",
        "longitude": "87.58333000",
        "label": "Birbhum district",
        "value": "Birbhum district"
    },
    {
        "name": "Birpara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.50000000",
        "longitude": "89.50000000",
        "label": "Birpara",
        "value": "Birpara"
    },
    {
        "name": "Bishnupur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.38000000",
        "longitude": "88.27000000",
        "label": "Bishnupur",
        "value": "Bishnupur"
    },
    {
        "name": "Bolpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.67000000",
        "longitude": "87.72000000",
        "label": "Bolpur",
        "value": "Bolpur"
    },
    {
        "name": "Budge Budge",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.47000000",
        "longitude": "88.17000000",
        "label": "Budge Budge",
        "value": "Budge Budge"
    },
    {
        "name": "Canning",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.32000000",
        "longitude": "88.67000000",
        "label": "Canning",
        "value": "Canning"
    },
    {
        "name": "Chakapara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.63000000",
        "longitude": "88.35000000",
        "label": "Chakapara",
        "value": "Chakapara"
    },
    {
        "name": "Chakdaha",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.08000000",
        "longitude": "88.52000000",
        "label": "Chakdaha",
        "value": "Chakdaha"
    },
    {
        "name": "Champadanga",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.83000000",
        "longitude": "87.96000000",
        "label": "Champadanga",
        "value": "Champadanga"
    },
    {
        "name": "Champahati",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.40026000",
        "longitude": "88.49209000",
        "label": "Champahati",
        "value": "Champahati"
    },
    {
        "name": "Champdani",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.80000000",
        "longitude": "88.37000000",
        "label": "Champdani",
        "value": "Champdani"
    },
    {
        "name": "Chandannagar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.86666667",
        "longitude": "88.38333333",
        "label": "Chandannagar",
        "value": "Chandannagar"
    },
    {
        "name": "Chandrakona",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.73000000",
        "longitude": "87.52000000",
        "label": "Chandrakona",
        "value": "Chandrakona"
    },
    {
        "name": "Chittaranjan",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.87000000",
        "longitude": "86.87000000",
        "label": "Chittaranjan",
        "value": "Chittaranjan"
    },
    {
        "name": "Churulia",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.78000000",
        "longitude": "87.08000000",
        "label": "Churulia",
        "value": "Churulia"
    },
    {
        "name": "Contai",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "21.78000000",
        "longitude": "87.75000000",
        "label": "Contai",
        "value": "Contai"
    },
    {
        "name": "Cooch Behar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.31666667",
        "longitude": "89.43333333",
        "label": "Cooch Behar",
        "value": "Cooch Behar"
    },
    {
        "name": "Cossimbazar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.12000000",
        "longitude": "88.28000000",
        "label": "Cossimbazar",
        "value": "Cossimbazar"
    },
    {
        "name": "Dakshin Dinajpur district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.22000000",
        "longitude": "88.76000000",
        "label": "Dakshin Dinajpur district",
        "value": "Dakshin Dinajpur district"
    },
    {
        "name": "Dalkola",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.87577000",
        "longitude": "87.84009000",
        "label": "Dalkola",
        "value": "Dalkola"
    },
    {
        "name": "Dam Dam",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.63333333",
        "longitude": "88.42277778",
        "label": "Dam Dam",
        "value": "Dam Dam"
    },
    {
        "name": "Darjeeling",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "27.04166667",
        "longitude": "88.26305556",
        "label": "Darjeeling",
        "value": "Darjeeling"
    },
    {
        "name": "Daulatpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.32605000",
        "longitude": "88.32989000",
        "label": "Daulatpur",
        "value": "Daulatpur"
    },
    {
        "name": "Debagram",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.68333333",
        "longitude": "88.28333333",
        "label": "Debagram",
        "value": "Debagram"
    },
    {
        "name": "Debipur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.25029000",
        "longitude": "88.61824000",
        "label": "Debipur",
        "value": "Debipur"
    },
    {
        "name": "Dhaniakhali community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.97000000",
        "longitude": "88.10000000",
        "label": "Dhaniakhali community development block",
        "value": "Dhaniakhali community development block"
    },
    {
        "name": "Dhulagari",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.58222222",
        "longitude": "88.17111111",
        "label": "Dhulagari",
        "value": "Dhulagari"
    },
    {
        "name": "Dhulian",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.68000000",
        "longitude": "87.97000000",
        "label": "Dhulian",
        "value": "Dhulian"
    },
    {
        "name": "Dhupguri",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.60000000",
        "longitude": "89.02000000",
        "label": "Dhupguri",
        "value": "Dhupguri"
    },
    {
        "name": "Diamond Harbour",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.20000000",
        "longitude": "88.20000000",
        "label": "Diamond Harbour",
        "value": "Diamond Harbour"
    },
    {
        "name": "Digha",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "21.68000000",
        "longitude": "87.55000000",
        "label": "Digha",
        "value": "Digha"
    },
    {
        "name": "Dinhata",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.13000000",
        "longitude": "89.47000000",
        "label": "Dinhata",
        "value": "Dinhata"
    },
    {
        "name": "Domjur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.64000000",
        "longitude": "88.22000000",
        "label": "Domjur",
        "value": "Domjur"
    },
    {
        "name": "Dubrajpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.80000000",
        "longitude": "87.38000000",
        "label": "Dubrajpur",
        "value": "Dubrajpur"
    },
    {
        "name": "Durgapur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.55000000",
        "longitude": "87.32000000",
        "label": "Durgapur",
        "value": "Durgapur"
    },
    {
        "name": "Egra",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "21.90000000",
        "longitude": "87.53000000",
        "label": "Egra",
        "value": "Egra"
    },
    {
        "name": "Falakata",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.53000000",
        "longitude": "89.20000000",
        "label": "Falakata",
        "value": "Falakata"
    },
    {
        "name": "Farakka",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.81667000",
        "longitude": "87.90000000",
        "label": "Farakka",
        "value": "Farakka"
    },
    {
        "name": "Fort Gloster",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.50444444",
        "longitude": "88.18333333",
        "label": "Fort Gloster",
        "value": "Fort Gloster"
    },
    {
        "name": "Gaighata community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.93000000",
        "longitude": "88.73000000",
        "label": "Gaighata community development block",
        "value": "Gaighata community development block"
    },
    {
        "name": "Gairkata",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.68000000",
        "longitude": "89.03000000",
        "label": "Gairkata",
        "value": "Gairkata"
    },
    {
        "name": "Gangadharpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.76040000",
        "longitude": "88.22180000",
        "label": "Gangadharpur",
        "value": "Gangadharpur"
    },
    {
        "name": "Gangarampur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.40000000",
        "longitude": "88.52000000",
        "label": "Gangarampur",
        "value": "Gangarampur"
    },
    {
        "name": "Garui",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.63333333",
        "longitude": "88.40000000",
        "label": "Garui",
        "value": "Garui"
    },
    {
        "name": "Garulia",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.82000000",
        "longitude": "88.37000000",
        "label": "Garulia",
        "value": "Garulia"
    },
    {
        "name": "Ghatal",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.67000000",
        "longitude": "87.72000000",
        "label": "Ghatal",
        "value": "Ghatal"
    },
    {
        "name": "Giria",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.51666667",
        "longitude": "88.06666667",
        "label": "Giria",
        "value": "Giria"
    },
    {
        "name": "Gobardanga",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.87000000",
        "longitude": "88.76000000",
        "label": "Gobardanga",
        "value": "Gobardanga"
    },
    {
        "name": "Gobindapur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.59306000",
        "longitude": "88.09139000",
        "label": "Gobindapur",
        "value": "Gobindapur"
    },
    {
        "name": "Gopalpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.61845000",
        "longitude": "88.75119000",
        "label": "Gopalpur",
        "value": "Gopalpur"
    },
    {
        "name": "Gopinathpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.22000000",
        "longitude": "88.15000000",
        "label": "Gopinathpur",
        "value": "Gopinathpur"
    },
    {
        "name": "Gorubathan",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.97000000",
        "longitude": "88.70000000",
        "label": "Gorubathan",
        "value": "Gorubathan"
    },
    {
        "name": "Gosaba",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.16000000",
        "longitude": "88.80000000",
        "label": "Gosaba",
        "value": "Gosaba"
    },
    {
        "name": "Gosanimari",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.15000000",
        "longitude": "89.36666667",
        "label": "Gosanimari",
        "value": "Gosanimari"
    },
    {
        "name": "Gurdaha",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.73472222",
        "longitude": "88.75944444",
        "label": "Gurdaha",
        "value": "Gurdaha"
    },
    {
        "name": "Guskhara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.49277778",
        "longitude": "87.73472222",
        "label": "Guskhara",
        "value": "Guskhara"
    },
    {
        "name": "Habra",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.83000000",
        "longitude": "88.63000000",
        "label": "Habra",
        "value": "Habra"
    },
    {
        "name": "Haldia",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.06046000",
        "longitude": "88.10975000",
        "label": "Haldia",
        "value": "Haldia"
    },
    {
        "name": "Haldibari",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.33000000",
        "longitude": "88.77000000",
        "label": "Haldibari",
        "value": "Haldibari"
    },
    {
        "name": "Halisahar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.95000000",
        "longitude": "88.42000000",
        "label": "Halisahar",
        "value": "Halisahar"
    },
    {
        "name": "Harindanga",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.03333333",
        "longitude": "88.31666667",
        "label": "Harindanga",
        "value": "Harindanga"
    },
    {
        "name": "Haringhata",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.95000000",
        "longitude": "88.57000000",
        "label": "Haringhata",
        "value": "Haringhata"
    },
    {
        "name": "Haripur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.93611000",
        "longitude": "88.23194000",
        "label": "Haripur",
        "value": "Haripur"
    },
    {
        "name": "Hasimara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.75000000",
        "longitude": "89.35000000",
        "label": "Hasimara",
        "value": "Hasimara"
    },
    {
        "name": "Hindusthan Cables Town",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.82000000",
        "longitude": "86.90000000",
        "label": "Hindusthan Cables Town",
        "value": "Hindusthan Cables Town"
    },
    {
        "name": "Hooghly district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.91000000",
        "longitude": "88.39000000",
        "label": "Hooghly district",
        "value": "Hooghly district"
    },
    {
        "name": "Howrah",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.57688000",
        "longitude": "88.31857000",
        "label": "Howrah",
        "value": "Howrah"
    },
    {
        "name": "Ichapur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.80499000",
        "longitude": "88.37088000",
        "label": "Ichapur",
        "value": "Ichapur"
    },
    {
        "name": "Indpur community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.16670000",
        "longitude": "86.93330000",
        "label": "Indpur community development block",
        "value": "Indpur community development block"
    },
    {
        "name": "Ingraj Bazar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.00447000",
        "longitude": "88.14573000",
        "label": "Ingraj Bazar",
        "value": "Ingraj Bazar"
    },
    {
        "name": "Islampur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.27000000",
        "longitude": "88.20000000",
        "label": "Islampur",
        "value": "Islampur"
    },
    {
        "name": "Jafarpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.32000000",
        "longitude": "88.23000000",
        "label": "Jafarpur",
        "value": "Jafarpur"
    },
    {
        "name": "Jaigaon",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.86666667",
        "longitude": "89.38333333",
        "label": "Jaigaon",
        "value": "Jaigaon"
    },
    {
        "name": "Jalpaiguri",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.51666667",
        "longitude": "88.73333333",
        "label": "Jalpaiguri",
        "value": "Jalpaiguri"
    },
    {
        "name": "Jamuria",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.70000000",
        "longitude": "87.08000000",
        "label": "Jamuria",
        "value": "Jamuria"
    },
    {
        "name": "Jangipur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.47001000",
        "longitude": "88.07659000",
        "label": "Jangipur",
        "value": "Jangipur"
    },
    {
        "name": "Jaynagar Majilpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.17722222",
        "longitude": "88.42583333",
        "label": "Jaynagar Majilpur",
        "value": "Jaynagar Majilpur"
    },
    {
        "name": "Jejur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.88000000",
        "longitude": "88.12000000",
        "label": "Jejur",
        "value": "Jejur"
    },
    {
        "name": "Jhalida",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.36541000",
        "longitude": "85.97581000",
        "label": "Jhalida",
        "value": "Jhalida"
    },
    {
        "name": "Jhargram",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.45000000",
        "longitude": "86.98000000",
        "label": "Jhargram",
        "value": "Jhargram"
    },
    {
        "name": "Jhilimili",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.81670000",
        "longitude": "86.61670000",
        "label": "Jhilimili",
        "value": "Jhilimili"
    },
    {
        "name": "Kakdwip",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "21.88330000",
        "longitude": "88.18330000",
        "label": "Kakdwip",
        "value": "Kakdwip"
    },
    {
        "name": "Kalaikunda",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.33916667",
        "longitude": "87.22638889",
        "label": "Kalaikunda",
        "value": "Kalaikunda"
    },
    {
        "name": "Kaliaganj",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.63000000",
        "longitude": "88.32000000",
        "label": "Kaliaganj",
        "value": "Kaliaganj"
    },
    {
        "name": "Kalimpong",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "27.07059000",
        "longitude": "88.47529000",
        "label": "Kalimpong",
        "value": "Kalimpong"
    },
    {
        "name": "Kalna",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.22000000",
        "longitude": "88.37000000",
        "label": "Kalna",
        "value": "Kalna"
    },
    {
        "name": "Kalyani",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.97500000",
        "longitude": "88.43444444",
        "label": "Kalyani",
        "value": "Kalyani"
    },
    {
        "name": "Kamarhati",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.67000000",
        "longitude": "88.37000000",
        "label": "Kamarhati",
        "value": "Kamarhati"
    },
    {
        "name": "Kamarpukur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.91000000",
        "longitude": "87.65000000",
        "label": "Kamarpukur",
        "value": "Kamarpukur"
    },
    {
        "name": "Kanchrapara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.97000000",
        "longitude": "88.43000000",
        "label": "Kanchrapara",
        "value": "Kanchrapara"
    },
    {
        "name": "Kandi",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.95000000",
        "longitude": "88.03000000",
        "label": "Kandi",
        "value": "Kandi"
    },
    {
        "name": "Karimpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.96666667",
        "longitude": "88.61666667",
        "label": "Karimpur",
        "value": "Karimpur"
    },
    {
        "name": "Katwa",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.65000000",
        "longitude": "88.13000000",
        "label": "Katwa",
        "value": "Katwa"
    },
    {
        "name": "Kenda",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.20000000",
        "longitude": "86.53000000",
        "label": "Kenda",
        "value": "Kenda"
    },
    {
        "name": "Keshabpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.97000000",
        "longitude": "88.26000000",
        "label": "Keshabpur",
        "value": "Keshabpur"
    },
    {
        "name": "Kharagpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.33023900",
        "longitude": "87.32365300",
        "label": "Kharagpur",
        "value": "Kharagpur"
    },
    {
        "name": "Kharar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.70000000",
        "longitude": "87.68000000",
        "label": "Kharar",
        "value": "Kharar"
    },
    {
        "name": "Kharba",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.42000000",
        "longitude": "88.07000000",
        "label": "Kharba",
        "value": "Kharba"
    },
    {
        "name": "Khardaha",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.72000000",
        "longitude": "88.38000000",
        "label": "Khardaha",
        "value": "Khardaha"
    },
    {
        "name": "Khatra",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.98000000",
        "longitude": "86.85000000",
        "label": "Khatra",
        "value": "Khatra"
    },
    {
        "name": "Kirnahar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.75000000",
        "longitude": "87.87000000",
        "label": "Kirnahar",
        "value": "Kirnahar"
    },
    {
        "name": "Kolkata",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.54111111",
        "longitude": "88.33777778",
        "label": "Kolkata",
        "value": "Kolkata"
    },
    {
        "name": "Konnagar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.70000000",
        "longitude": "88.35000000",
        "label": "Konnagar",
        "value": "Konnagar"
    },
    {
        "name": "Krishnanagar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.40000000",
        "longitude": "88.50000000",
        "label": "Krishnanagar",
        "value": "Krishnanagar"
    },
    {
        "name": "Krishnapur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.67000000",
        "longitude": "88.26000000",
        "label": "Krishnapur",
        "value": "Krishnapur"
    },
    {
        "name": "Kshirpai",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.71096640",
        "longitude": "87.61740380",
        "label": "Kshirpai",
        "value": "Kshirpai"
    },
    {
        "name": "Kulpi",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.08000000",
        "longitude": "88.24000000",
        "label": "Kulpi",
        "value": "Kulpi"
    },
    {
        "name": "Kultali",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "21.90000000",
        "longitude": "88.40000000",
        "label": "Kultali",
        "value": "Kultali"
    },
    {
        "name": "Kulti",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.73000000",
        "longitude": "86.85000000",
        "label": "Kulti",
        "value": "Kulti"
    },
    {
        "name": "Kurseong",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.88000000",
        "longitude": "88.28000000",
        "label": "Kurseong",
        "value": "Kurseong"
    },
    {
        "name": "Lalgarh",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.58000000",
        "longitude": "87.05000000",
        "label": "Lalgarh",
        "value": "Lalgarh"
    },
    {
        "name": "Lalgola",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.42000000",
        "longitude": "88.25000000",
        "label": "Lalgola",
        "value": "Lalgola"
    },
    {
        "name": "Loyabad",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.67000000",
        "longitude": "86.67000000",
        "label": "Loyabad",
        "value": "Loyabad"
    },
    {
        "name": "Madanpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.02000000",
        "longitude": "88.48000000",
        "label": "Madanpur",
        "value": "Madanpur"
    },
    {
        "name": "Madhyamgram",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.70000000",
        "longitude": "88.45000000",
        "label": "Madhyamgram",
        "value": "Madhyamgram"
    },
    {
        "name": "Mahiari",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.59000000",
        "longitude": "88.24000000",
        "label": "Mahiari",
        "value": "Mahiari"
    },
    {
        "name": "Mahishadal community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.18333300",
        "longitude": "87.98333300",
        "label": "Mahishadal community development block",
        "value": "Mahishadal community development block"
    },
    {
        "name": "Mainaguri",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.57000000",
        "longitude": "88.82000000",
        "label": "Mainaguri",
        "value": "Mainaguri"
    },
    {
        "name": "Manikpara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.36666667",
        "longitude": "87.11666667",
        "label": "Manikpara",
        "value": "Manikpara"
    },
    {
        "name": "Masila",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.58083000",
        "longitude": "88.23278000",
        "label": "Masila",
        "value": "Masila"
    },
    {
        "name": "Mathabhanga",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.34197000",
        "longitude": "89.21555000",
        "label": "Mathabhanga",
        "value": "Mathabhanga"
    },
    {
        "name": "Matiali community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.92715900",
        "longitude": "88.81330490",
        "label": "Matiali community development block",
        "value": "Matiali community development block"
    },
    {
        "name": "Matigara community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.71666700",
        "longitude": "88.38333300",
        "label": "Matigara community development block",
        "value": "Matigara community development block"
    },
    {
        "name": "Medinipur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.43333333",
        "longitude": "87.33333333",
        "label": "Medinipur",
        "value": "Medinipur"
    },
    {
        "name": "Mejia community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.57000000",
        "longitude": "87.10000000",
        "label": "Mejia community development block",
        "value": "Mejia community development block"
    },
    {
        "name": "Memari",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.20000000",
        "longitude": "88.12000000",
        "label": "Memari",
        "value": "Memari"
    },
    {
        "name": "Mirik",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.88700000",
        "longitude": "88.18700000",
        "label": "Mirik",
        "value": "Mirik"
    },
    {
        "name": "Mohanpur community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "21.83856000",
        "longitude": "87.43145000",
        "label": "Mohanpur community development block",
        "value": "Mohanpur community development block"
    },
    {
        "name": "Monoharpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.68000000",
        "longitude": "88.30000000",
        "label": "Monoharpur",
        "value": "Monoharpur"
    },
    {
        "name": "Muragacha",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.53631000",
        "longitude": "88.39425000",
        "label": "Muragacha",
        "value": "Muragacha"
    },
    {
        "name": "Muri",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.37000000",
        "longitude": "85.86000000",
        "label": "Muri",
        "value": "Muri"
    },
    {
        "name": "Murshidabad",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.18000000",
        "longitude": "88.27000000",
        "label": "Murshidabad",
        "value": "Murshidabad"
    },
    {
        "name": "Nabadwip",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.40722222",
        "longitude": "88.37777778",
        "label": "Nabadwip",
        "value": "Nabadwip"
    },
    {
        "name": "Nabagram",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.29000000",
        "longitude": "88.51000000",
        "label": "Nabagram",
        "value": "Nabagram"
    },
    {
        "name": "Nadia district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.40000000",
        "longitude": "88.50000000",
        "label": "Nadia district",
        "value": "Nadia district"
    },
    {
        "name": "Nagarukhra",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.94099000",
        "longitude": "88.63701000",
        "label": "Nagarukhra",
        "value": "Nagarukhra"
    },
    {
        "name": "Nagrakata",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.90000000",
        "longitude": "88.96670000",
        "label": "Nagrakata",
        "value": "Nagrakata"
    },
    {
        "name": "Naihati",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.90000000",
        "longitude": "88.42000000",
        "label": "Naihati",
        "value": "Naihati"
    },
    {
        "name": "Naksalbari",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.68333333",
        "longitude": "88.21666667",
        "label": "Naksalbari",
        "value": "Naksalbari"
    },
    {
        "name": "Nalhati",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.30000000",
        "longitude": "87.82000000",
        "label": "Nalhati",
        "value": "Nalhati"
    },
    {
        "name": "Nalpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.53000000",
        "longitude": "88.19000000",
        "label": "Nalpur",
        "value": "Nalpur"
    },
    {
        "name": "Namkhana community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "21.70000000",
        "longitude": "88.14000000",
        "label": "Namkhana community development block",
        "value": "Namkhana community development block"
    },
    {
        "name": "Nandigram",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.01000000",
        "longitude": "87.99000000",
        "label": "Nandigram",
        "value": "Nandigram"
    },
    {
        "name": "Nangi",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.50833333",
        "longitude": "88.21527778",
        "label": "Nangi",
        "value": "Nangi"
    },
    {
        "name": "Nayagram community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.03190000",
        "longitude": "87.17810000",
        "label": "Nayagram community development block",
        "value": "Nayagram community development block"
    },
    {
        "name": "North 24 Parganas district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.13000000",
        "longitude": "88.50000000",
        "label": "North 24 Parganas district",
        "value": "North 24 Parganas district"
    },
    {
        "name": "Odlabari",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.83638889",
        "longitude": "88.62944444",
        "label": "Odlabari",
        "value": "Odlabari"
    },
    {
        "name": "Paikpara",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.77121000",
        "longitude": "87.92251000",
        "label": "Paikpara",
        "value": "Paikpara"
    },
    {
        "name": "Panagarh",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.45000000",
        "longitude": "87.43000000",
        "label": "Panagarh",
        "value": "Panagarh"
    },
    {
        "name": "Panchla",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.54000000",
        "longitude": "88.14000000",
        "label": "Panchla",
        "value": "Panchla"
    },
    {
        "name": "Panchmura",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.96670000",
        "longitude": "87.16670000",
        "label": "Panchmura",
        "value": "Panchmura"
    },
    {
        "name": "Pandua",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.08000000",
        "longitude": "88.28000000",
        "label": "Pandua",
        "value": "Pandua"
    },
    {
        "name": "Panihati",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.69000000",
        "longitude": "88.37000000",
        "label": "Panihati",
        "value": "Panihati"
    },
    {
        "name": "Panskura",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.42000000",
        "longitude": "87.70000000",
        "label": "Panskura",
        "value": "Panskura"
    },
    {
        "name": "Parbatipur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.66250000",
        "longitude": "88.22222000",
        "label": "Parbatipur",
        "value": "Parbatipur"
    },
    {
        "name": "Paschim Medinipur district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.43333333",
        "longitude": "87.33333333",
        "label": "Paschim Medinipur district",
        "value": "Paschim Medinipur district"
    },
    {
        "name": "Patiram",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.31666667",
        "longitude": "88.75000000",
        "label": "Patiram",
        "value": "Patiram"
    },
    {
        "name": "Patrasaer",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.19697000",
        "longitude": "87.53319000",
        "label": "Patrasaer",
        "value": "Patrasaer"
    },
    {
        "name": "Patuli",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.55000000",
        "longitude": "88.25000000",
        "label": "Patuli",
        "value": "Patuli"
    },
    {
        "name": "Pujali",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.47000000",
        "longitude": "88.15000000",
        "label": "Pujali",
        "value": "Pujali"
    },
    {
        "name": "Puncha community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.12300000",
        "longitude": "86.65300000",
        "label": "Puncha community development block",
        "value": "Puncha community development block"
    },
    {
        "name": "Purba Medinipur district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.30000000",
        "longitude": "87.91666667",
        "label": "Purba Medinipur district",
        "value": "Purba Medinipur district"
    },
    {
        "name": "Purulia",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.33333333",
        "longitude": "86.36666667",
        "label": "Purulia",
        "value": "Purulia"
    },
    {
        "name": "Raghudebbati",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.53000000",
        "longitude": "88.20000000",
        "label": "Raghudebbati",
        "value": "Raghudebbati"
    },
    {
        "name": "Raghunathpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.55000000",
        "longitude": "86.67000000",
        "label": "Raghunathpur",
        "value": "Raghunathpur"
    },
    {
        "name": "Raiganj",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.61666667",
        "longitude": "88.11666667",
        "label": "Raiganj",
        "value": "Raiganj"
    },
    {
        "name": "Rajmahal",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.05000000",
        "longitude": "87.84000000",
        "label": "Rajmahal",
        "value": "Rajmahal"
    },
    {
        "name": "Rajnagar community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.94720000",
        "longitude": "87.36250000",
        "label": "Rajnagar community development block",
        "value": "Rajnagar community development block"
    },
    {
        "name": "Ramchandrapur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.90000000",
        "longitude": "88.48000000",
        "label": "Ramchandrapur",
        "value": "Ramchandrapur"
    },
    {
        "name": "Ramjibanpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.83000000",
        "longitude": "87.62000000",
        "label": "Ramjibanpur",
        "value": "Ramjibanpur"
    },
    {
        "name": "Ramnagar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.77778000",
        "longitude": "88.24639000",
        "label": "Ramnagar",
        "value": "Ramnagar"
    },
    {
        "name": "Rampur Hat",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.17737000",
        "longitude": "87.78275000",
        "label": "Rampur Hat",
        "value": "Rampur Hat"
    },
    {
        "name": "Ranaghat",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.18000000",
        "longitude": "88.58000000",
        "label": "Ranaghat",
        "value": "Ranaghat"
    },
    {
        "name": "Raniganj",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.62000000",
        "longitude": "87.13000000",
        "label": "Raniganj",
        "value": "Raniganj"
    },
    {
        "name": "Raypur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.41083000",
        "longitude": "88.50944000",
        "label": "Raypur",
        "value": "Raypur"
    },
    {
        "name": "Rishra",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.71000000",
        "longitude": "88.35000000",
        "label": "Rishra",
        "value": "Rishra"
    },
    {
        "name": "Sahapur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.52000000",
        "longitude": "88.17000000",
        "label": "Sahapur",
        "value": "Sahapur"
    },
    {
        "name": "Sainthia",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.95000000",
        "longitude": "87.67000000",
        "label": "Sainthia",
        "value": "Sainthia"
    },
    {
        "name": "Salanpur community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.76702000",
        "longitude": "86.87492000",
        "label": "Salanpur community development block",
        "value": "Salanpur community development block"
    },
    {
        "name": "Sankarpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.60000000",
        "longitude": "87.20000000",
        "label": "Sankarpur",
        "value": "Sankarpur"
    },
    {
        "name": "Sankrail",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.57000000",
        "longitude": "88.24000000",
        "label": "Sankrail",
        "value": "Sankrail"
    },
    {
        "name": "Santipur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.25000000",
        "longitude": "88.43000000",
        "label": "Santipur",
        "value": "Santipur"
    },
    {
        "name": "Santoshpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.50000000",
        "longitude": "88.17000000",
        "label": "Santoshpur",
        "value": "Santoshpur"
    },
    {
        "name": "Santuri community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.52720000",
        "longitude": "86.85709000",
        "label": "Santuri community development block",
        "value": "Santuri community development block"
    },
    {
        "name": "Sarenga",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.54000000",
        "longitude": "88.21000000",
        "label": "Sarenga",
        "value": "Sarenga"
    },
    {
        "name": "Serampore",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.75000000",
        "longitude": "88.34000000",
        "label": "Serampore",
        "value": "Serampore"
    },
    {
        "name": "Serpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "24.13000000",
        "longitude": "88.00000000",
        "label": "Serpur",
        "value": "Serpur"
    },
    {
        "name": "Shyamnagar, West Bengal",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.83000000",
        "longitude": "88.37000000",
        "label": "Shyamnagar, West Bengal",
        "value": "Shyamnagar, West Bengal"
    },
    {
        "name": "Siliguri",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.71004000",
        "longitude": "88.42851000",
        "label": "Siliguri",
        "value": "Siliguri"
    },
    {
        "name": "Singur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.81000000",
        "longitude": "88.23000000",
        "label": "Singur",
        "value": "Singur"
    },
    {
        "name": "Sodpur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.70416667",
        "longitude": "88.39166667",
        "label": "Sodpur",
        "value": "Sodpur"
    },
    {
        "name": "Solap",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.12777778",
        "longitude": "88.15861111",
        "label": "Solap",
        "value": "Solap"
    },
    {
        "name": "Sonada",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "27.00000000",
        "longitude": "88.14000000",
        "label": "Sonada",
        "value": "Sonada"
    },
    {
        "name": "Sonamukhi",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.30000000",
        "longitude": "87.42000000",
        "label": "Sonamukhi",
        "value": "Sonamukhi"
    },
    {
        "name": "Sonarpur community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.44259000",
        "longitude": "88.43044000",
        "label": "Sonarpur community development block",
        "value": "Sonarpur community development block"
    },
    {
        "name": "South 24 Parganas district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.16197000",
        "longitude": "88.43170000",
        "label": "South 24 Parganas district",
        "value": "South 24 Parganas district"
    },
    {
        "name": "Srikhanda",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.60000000",
        "longitude": "88.08330000",
        "label": "Srikhanda",
        "value": "Srikhanda"
    },
    {
        "name": "Srirampur",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.35000000",
        "longitude": "88.12000000",
        "label": "Srirampur",
        "value": "Srirampur"
    },
    {
        "name": "Suri",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.91666667",
        "longitude": "87.53333333",
        "label": "Suri",
        "value": "Suri"
    },
    {
        "name": "Swarupnagar community development block",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.83330000",
        "longitude": "88.86670000",
        "label": "Swarupnagar community development block",
        "value": "Swarupnagar community development block"
    },
    {
        "name": "Takdah",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "27.03330000",
        "longitude": "88.36670000",
        "label": "Takdah",
        "value": "Takdah"
    },
    {
        "name": "Taki",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.59000000",
        "longitude": "88.92000000",
        "label": "Taki",
        "value": "Taki"
    },
    {
        "name": "Tamluk",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.30000000",
        "longitude": "87.91666667",
        "label": "Tamluk",
        "value": "Tamluk"
    },
    {
        "name": "Tarakeswar",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.89000000",
        "longitude": "88.02000000",
        "label": "Tarakeswar",
        "value": "Tarakeswar"
    },
    {
        "name": "Titagarh",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.74000000",
        "longitude": "88.37000000",
        "label": "Titagarh",
        "value": "Titagarh"
    },
    {
        "name": "Tufanganj",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "26.32000000",
        "longitude": "89.67000000",
        "label": "Tufanganj",
        "value": "Tufanganj"
    },
    {
        "name": "Tulin",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.37000000",
        "longitude": "85.90000000",
        "label": "Tulin",
        "value": "Tulin"
    },
    {
        "name": "Uchalan",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "23.03330000",
        "longitude": "87.78330000",
        "label": "Uchalan",
        "value": "Uchalan"
    },
    {
        "name": "Ula",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.72250000",
        "longitude": "88.55556000",
        "label": "Ula",
        "value": "Ula"
    },
    {
        "name": "Uluberia",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.47000000",
        "longitude": "88.11000000",
        "label": "Uluberia",
        "value": "Uluberia"
    },
    {
        "name": "Uttar Dinajpur district",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "25.62000000",
        "longitude": "88.12000000",
        "label": "Uttar Dinajpur district",
        "value": "Uttar Dinajpur district"
    },
    {
        "name": "Uttarpara Kotrung",
        "countryCode": "IN",
        "stateCode": "WB",
        "latitude": "22.67000000",
        "longitude": "88.35000000",
        "label": "Uttarpara Kotrung",
        "value": "Uttarpara Kotrung"
    }
]