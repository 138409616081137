import React from "react";
import { withRouter } from "../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../hoc/Common.hoc";
import OrderService from '../../services/Order.service';
import CustomerHeader from "../../components/layout/CustomerHeader";
import CustomerFooter from "../../components/layout/CustomerFooter";
import OrderListAction from "../../redux/actions/OrderList.action";
import moment from 'moment';
import OrderAction from "../../redux/actions/Order.action";
import $ from 'jquery';
import CustomerService from "../../services/Customer.service";
class UploadPrescription extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        this.fileInputRef = React.createRef();
        this.state = {
            customer: null,
            orders: [],
            orderList: [],
            allOrders: [],
            selectedOrders: null,
            selectedFile: null,
            fileUploadError: '',
            imagename: '',
            color: 'red',
            prescription: null,

        }
        

    }
    

    static getDerivedStateFromProps(props, state) {
        return {
            customer: props.customer || null,
            orders: props.orders || [],
            allOrders: props.allOrders || [],
            selectedOrders: props.selectedOrders || null,
        }
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        // console.log(file.name,"file");
        if (file) {
            this.setState({ selectedFile: file, fileUploadError: '', imagename: file.name }, () => {
                // this.handleUpload()
            });
        }
    };
    handleUpload = () => {
        console.log(this.state.isLoading, "loading status");

        const MaxFileSize = 10 * 1024 * 1024; // 10MB in bytes
        // Handle the file upload logic here
        const { selectedFile } = this.state;

        if (selectedFile) {
            this.setState({ isLoading: true });
            const fileType = selectedFile.type;
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'application/pdf') {

                this.setState({ isLoading: false });
                this.setState({ fileUploadError: 'Only JPG, PNG, and PDF files are allowed.', color: "red" });

                return;
            }
            else if (fileSizeMB > 25) {
                this.setState({ isLoading: false });
                this.setState({ fileUploadError: 'File size must be less than 25MB.', color: "red" });

                return;
            }
            else {
                let payload = new FormData()
                payload.append("customer_id", this.state.customer._id || null)
                payload.append("doctor_id", this.state.selectedOrders.doctor_id || null)
                payload.append("prescription", selectedFile)
                payload.append("order_id",this.state.selectedOrders._id)

                //console.log('File to upload:', selectedFile, this.state.customer, payload);
                CustomerService.uploadPrescriptionUpdateOrder(payload).then(data => {
                    console.log(data.data.data.filepath, "data !!!!!!!!!!!!file pathh");
                    this.getPrescription()
                    this.setState({ isLoading: false });
                    this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: data.data.data.filepath });

                    this.props.hoc.customAlert('Prescription uploaded successfully.', true, "", true, true, () => {

                        this.props.navigate('/customer/order-history');
                    }, () => {
                    
                    })


                }).catch(err => {
                    this.setState({ isLoading: false });
                    console.log(err, "err")
                })
            }
        } else {
            this.setState({ fileUploadError: 'Please select a file first.' });
        }
    };

    getPrescription = () => {
        const customer = this.state.customer;
        let customer_id = customer._id || null
        let doctor_id = this.state.selectedOrders.doctor_id || null
        //console.log(customer_id, doctor_id, customer, 4444444);
        if (customer_id && doctor_id) {
            CustomerService.getPrescription({ customer_id, doctor_id }).then(data => {
                //console.log(data, "getPrescription");
                if (data.success) {
                    let prescription = this.state.prescription
                    if (prescription) {
                        this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: prescription.filepath || "", selectedFile: true });
                    }
                }
            })
        }
    }

    handleButtonClick = () => {

        // Programmatically trigger the file input click event
        this.fileInputRef.current.click();
    };

    componentDidMount() {

        // console.log(this.state.customer, "customer");
        // let customer = this.state.customer
        // if (customer) {
        //     OrderService.getOrders(customer._id).then(data => {
        //         console.log(data.data, "orderHistory")

        //         if (data.data && data.data.success) {

        //             this.setState({ orderList: data.data.data.order });
        //             this.setState({ orders: data.data.data.order });

        //             this.props.saveAllOrders(data.data.data.order);
        //             // this.dataTable = $(this.tableRef.current).DataTable();

        //         }
        //     })
        // }


       
    }
 
    render = () => {
        const { fileUploadError, color, imagename} = this.state;
        // let _data = this.setOrderHistoryData(this.state.orderList)
        // console.log(_data,"alldata orders");
        return (

            <>
                {/* <section className="landing-page-pt pt-0">
                    <div className="container">
                        <CustomerHeader />
                    </div>
               
                    <div className="mr-landing-page">
                        <div className="landing-head-pt">
                            <h2>Order History</h2>
                        </div>

                        
                            <div className="cl-inpt-part-st">
                                <input type="text" className="form-control mbl-inpt-part" placeholder="Search Doctor"/>
                            </div>
                        
                    </div>
                </section> */}

                {/*landing-page-details------*/}
                <section className="landing-profl-detls pt-4">
                    <div className="container">
                        <CustomerHeader page="tracking"/>
                        <div className="mr-landing-page">
                            <div className="landing-head-pt">
                                <h2 className="ordertitle">Upload Prescription</h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="title">
                                    <h5 className="mb-0">Upload New Prescription</h5>
                                </div>
                                
                            </div>
                            <form className="mt-3 registration-form">
                           <div className="row">

                           <div className="col-md-12 mt-4 mb-4">
                                <div className="card border-0 rounded-4">
                                    <div className="card-body p-4">
                                    <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileChange}
                                />
                                <button type="button" className="upld-prscrbtn-pt common-btn" id onClick={this.handleButtonClick}>
                                    {this.state.imagename ? "Change Your Prescription" : "Upload Your Prescription"}
                                </button>
                                <p className="my-3" style={{ fontSize: 14, paddingTop: 10, textAlign: 'left', color: "#0a4f73", marginRight: 5 }}> Prescription needs to be uploaded in either jpeg, png and pdf format within a maximum limit of 25 MB </p>
                                {fileUploadError && <div style={{ color, textAlign: 'left', marginRight: 20 }}>{fileUploadError}</div>}
                                {this.state.imagename != "" && <p>Selected file: {this.state.imagename}</p>}
                                <button type="button" className="submit-btn btn rounded-5 mt-4 bg-success py-2 px-5 text-white" id onClick={this.handleUpload}>
                                Submit
                                </button>
                                    </div>
                                </div>
                            </div>

                           </div>
                           </form>

                        </div>
                      
                        
                        <CustomerFooter/>
                    </div>
                </section>
                {/*landing-page-details-end------*/}
            </>





        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setOrders: (orders) => dispatch(OrderListAction.getOrderList(orders)),
        saveAllOrders: (allOrders) => dispatch(OrderAction.saveAllOrders(allOrders)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { allOrders } = OrderReducer
    const { selectedOrders } = OrderReducer
    console.log(customer, 123);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        allOrders,
        selectedOrders
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(UploadPrescription)))