import {
    SET_FEEDBACK,
    LOGOUT,
    CLEAR_FEEDBACK_STATE
} from '../types/Feedback.type'

const initState = {
    details: {},
    token: null
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_FEEDBACK:
            return Object.assign({}, state, { details: { ...payload } })
        case LOGOUT:
            return initState;
        case CLEAR_FEEDBACK_STATE:
            return Object.assign({}, initState)
        default:
            return state
    }
}