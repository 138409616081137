import Base from './Base.service'
import { store } from '../redux/index.store'
import {
  STOCKIST_DETAIL,
  STOCKIST_INFO,
  APPROVED_PRESCRIPTION
} from './Slugs'
class StockistService extends Base {
  constructor(props) {
    super(props)
  }

 
  getStockistDetails(data) {
    return this.post(STOCKIST_DETAIL,data);
  }
  getStockistInfo(data) {
    return this.post(STOCKIST_INFO,data);
  }

  approvedPrescription(data){
    return this.post(APPROVED_PRESCRIPTION,data);
  }

}

export default new StockistService()