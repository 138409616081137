import React from 'react'
import { Provider } from 'react-redux'
import { store, persistor } from "./redux/index.store";
import { PersistGate } from 'redux-persist/integration/react';
import Router from './router/index.router';
import CustomerService from './services/Customer.service';
import CustomLoaderComponent from './components/CustomLoader/CustomLoader.component';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render = () => {
    return (
      <div>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <CustomLoaderComponent />
            <Router />
          </PersistGate>
        </Provider>
      </div>
    )
  }
}

export default App
