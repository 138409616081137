import {
  LOADER_ACTION,
  TOKEN,
  LOGOUT,
  SET_USER_TYPE,
  SET_SUMMARY_DETAILS
} from '../types/Common.type'

const initState = {
  isloading: false,
  token: null,
  userType: "",
  summaryDetails: null
}

export default (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOADER_ACTION:
      return Object.assign({}, state, { isloading: payload })
    case TOKEN:
      console.log(action, "actionactionaction")
      return Object.assign({}, state, { token: payload })
    case SET_USER_TYPE:
      return Object.assign({}, state, { userType: payload })
    case SET_SUMMARY_DETAILS:
      return Object.assign({}, state, { summaryDetails: payload })
    case LOGOUT:
      return initState;
    default:
      return state
  }
}