import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import MrHeader from "../../../components/layout/MR/MrHeader";
import MrAction from "../../../redux/actions/Mr.action";
import MrService from "../../../services/Mr.service";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import StockistHeader from "../../../components/layout/Stockist/StockistHeader";
import StockistService from "../../../services/Stockist.service";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import CommonAction from "../../../redux/actions/Common.action";
import DropdownDoctor from "../../../components/SearchableDropdown/DropdownDoctor.component";
import OrderCancelModal from "../../../components/Order/OrderCancelModal";
import Summary from "../../../components/Summary/Summary.component";
import { ExportToExcel } from '../../../components/Excel/ExportToExcel'
import config from "../../../config/emrok.config";
import StockistAction from "../../../redux/actions/Stockist.action";
class StockistList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stockistdetails: null,
            stockistState: null,
            stockistid: "",
            stockistList: [],
            stockistinfo: "",
            stockistdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryCols: ["No. of Total Dr. Prescribed", "Total Rx Generated", "Total Rx Fulfilled", "Total Rx Under Process", "Order Pending", "Pickup Initiated", "Order Delivered"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Stockist_Data",
        }
        this.copyState = this.state
    }

    static getDerivedStateFromProps(props, state) {
        return {
            stockistdetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
            stockistState: props.stockistState || null
        }
    }
    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async (stockist_id = null) => {
        if (stockist_id) {
            // let payload = {
            //     type: "STOCKIST",
            //     id: stockist_id
            // }
            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state.stockistState;
            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
            let payload = {
                type: "STOCKIST",
                id: stockist_id,
                fromDate: fromdate ? selectedOnlyDateFrom : "",
                toDate: todate ? selectedOnlyDateTo : "",
                stateName: selectedState,
                town: selectedTown,
                doctor_id: selectedDoctor,
                orderStatus: setorderStatus
            };
            OtherService.getSummary(payload).then(data => {
                console.log(data, "getSummary");
            }).catch(err => {
                console.log(err, "getSummary err");
            })
        }
    }

    clickFn = async (index) => {
        const stockistdetails = this.props.stockistdetails;
        if (stockistdetails) {
            // let payload = await {
            //     stockist_id: stockistdetails._id
            // }

            let payload = {};
            let orderstatus = "";

            const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state.stockistState;
            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
            if (index > 1) {
                payload = {
                    stockist_id: stockistdetails._id,
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor,
                    orderStatus: setorderStatus
                };
            }

            if (index === 0 || index === 1) {
                payload = {

                    stockist_id: stockistdetails._id,
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    doctor_id: selectedDoctor,
                    orderStatus: setorderStatus
                };

                orderstatus = ""
                payload["orderStatus"] = ""
            }
            // else if (index === 2) {
            //     payload["orderStatus"] = await "cancelled"
            //     this.setState({ setorderStatus: "cancelled" })
            // }
            else if (index === 2) {
                payload["orderStatus"] = await "complete"
                orderstatus = "complete"
                this.setState({ setorderStatus: "complete" })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "underprocess"
                orderstatus = "underprocess"
                this.setState({ setorderStatus: "underprocess" })
            }
            else if (index === 4) {
                payload["orderStatus"] = await "pending"
                orderstatus = "pending"
                this.setState({ setorderStatus: "pending" })
            }
            else if (index === 5) {
                payload["orderStatus"] = await "Pickup Initiated"
                orderstatus = "Pickup Initiated"
                this.setState({ setorderStatus: "Pickup Initiated" })
            }
            else if (index === 6) {
                payload["deliveryStatus"] = await "delivered"
                orderstatus = "delivered"
                this.setState({ setorderStatus: "delivered" })
            }
            const stockistState = this.state.stockistState;
            stockistState.setorderStatus = orderstatus;
            stockistState.stockistdetailsList = [];
            this.props.setStateData(stockistState);
            this.getStockistDetails(payload)
        }

    }

    formatData(auditdata) {

        console.log(auditdata, auditdata.length, "auditdata")

        let formatedArr = [];
        for (let i = 0; i < auditdata.length; i++) {

            const materials = auditdata[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');

            formatedArr.push({

                "Order No": auditdata[i].order_id,
                "Doctor Name": auditdata[i].doctor.name,
                "MR Name": auditdata[i].mr.name,
                "Patient Name": auditdata[i].customer.name,
                "Patient State": auditdata[i].customer.address.state,
                "Patient City": auditdata[i].customer.address.town,
                "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                "Shipment Date": auditdata[i].GRN_DATE ? moment(auditdata[i].GRN_DATE).format('DD-MM-YYYY') : "",
                "Delivery Date": auditdata[i].deliveryDate ? moment(auditdata[i].deliveryDate).format('DD-MM-YYYY') : "",
                "Order Value": auditdata[i].total,
                "Product Name": materialString,
                "Status": auditdata[i].orderStatus,
            })
        }
        return formatedArr;


    }
    getDefaultTown = (alldata) => {
        // console.log(alldata, "alldata");

        let data = [];

        const allCustomerAddress = alldata.map(item => item.customer.address.town);
        // Create a Set to automatically remove duplicates
        const uniqueTownsSet = new Set(allCustomerAddress);

        // Convert the Set back to an array (if needed)
        const uniqueTowns = Array.from(uniqueTownsSet);
        console.log(allCustomerAddress, "alldata allCustomerAddress");
        console.log(uniqueTowns, "alldata uniqueTowns");

        for (let i = 0; i < uniqueTowns.length; i++) {

            //  console.log(allCustomerAddress[i], "allcustomer123")

            data.push({ countryCode: "", isoCode: "", label: uniqueTowns[i], latitude: "", longitude: "", name: uniqueTowns[i], value: uniqueTowns[i] })

            // data[i]["label"] = allCustomerAddress[i]
            // data[i]["value"] = allCustomerAddress[i]



        }

        setTimeout(() => {
            this.setState({ towns: data })
            const stockistState = this.state.stockistState;
            stockistState.towns = data;
            this.props.setStateData(stockistState);
            // this.populateStates(countryObj.isoCode)	
        }, 500);



        // console.log(data, "allCustomerAddress123");



    }

    getStockistDetails = (details) => {
        this.setState({ stockistdetailsList: [] }, () => {
            StockistService.getStockistDetails(details).then(data => {
                console.log(data.data, "Stockist details 2");

                if (data.data.success && data.data.data.stockistdetails !== '') {
                    console.log(data.data, "okkkkk");


                    const formatedData = this.formatData(data.data.data.stockistdetails);

                    console.log(formatedData, "formatedData")

                    this.setState({ exportData: formatedData });

                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "Stockist_Data" + currentDateTime;
                    this.setState({ filename: filename });



                    // if(!this.state.isSearch){
                    const doctors = data.data.data.stockistdetails.map(item => item.doctor);
                    this.getDoctors(doctors);
                    // this.setState({ doctorList: data.data.data.doctorinfo });
                    this.setState({ stockistdetailsList: data.data.data.stockistdetails });

                    this.getDefaultTown(data.data.data.stockistdetails)

                    const stockistState = this.state.stockistState;
                    stockistState.exportData = formatedData;
                    stockistState.filename = filename;
                    // stockistState.stockistdetailsList = data.data.data.stockistdetails;

                    if (stockistState.stockistdetailsList.length) {
                        stockistState.stockistdetailsList = stockistState.stockistdetailsList;
                    } else {
                        stockistState.stockistdetailsList = data.data.data.stockistdetails;
                    }

                    this.props.setStateData(stockistState);
                    // }
                }
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        })
    }

    componentDidMount() {

        const stockistdetails = this.props.stockistdetails;
        if (stockistdetails) {
            const stockist_id = stockistdetails._id;
            let details = {
                stockist_id: stockist_id
            };
            console.log(details, "stockist details 1",);
            this.getSummary(stockist_id)
            StockistService.getStockistInfo(details).then(data => {
                console.log(data.data, "stockist info");

                if (data.data.success && data.data.data.stockistinfo !== '') {
                    console.log(data.data.data.stockistinfo[0], "okkkkk");

                    this.setState({ stockistinfo: data.data.data.stockistinfo[0] });
                    const stockistState = this.state.stockistState;
                    stockistState.stockistinfo = data.data.data.stockistinfo[0];
                    this.props.setStateData(stockistState);
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })


            this.getStockistDetails(details)

            let detailsBody = {
                id: stockist_id,
                type: "stockist",
            };

            MrService.getDoctorsByMr(detailsBody).then(data => {
                console.log(data.data, "doctordetailsbymr123");

                if (data.data.success && data.data.data.doctorinfo !== '') {
                    console.log(data.data, "doctorokkk1233");

                    // const doctors = data.data.data.mrdetails.map(item => item.doctor);
                    // this.setState({ doctorList: data.data.data.doctorinfo });
                    // console.log(doctors, "alldoctors list");
                    // this.getDoctors(data.data.data.doctorinfo);
                    // this.setState({ mrdetailsList: data.data.data.mrdetails });
                    // this.setState({ doctorList: data.data.data.doctorinfo });
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);
        } else {


        }



    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    const stockistState = this.state.stockistState;
                    stockistState.states = data.states;
                    console.log(stockistState.states, 6666666);
                    this.props.setStateData(stockistState);
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.stockistState.selectedState || null
                        console.log(_state, this.state.stockistState.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.stockistState.states.length; i++) {
                                if (this.state.stockistState.states[i].name === _state) {
                                    stateObj = this.state.stockistState.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })

                    setTimeout(() => {
                        let stateDetails = this.state.stockistState.states;

                        const findIfExistAll = stateDetails.find(item => item.name === 'All');
                        console.log(findIfExistAll, 33333);

                        if (!findIfExistAll) {
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                      

                        console.log(this.state.stockistState.states, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        // let optionsWithAll1 = [option2, ...optionsWithAll];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ states: optionsWithAll })

                        const stockistState = this.state.stockistState;
                        stockistState.states = optionsWithAll;
                        this.props.setStateData(stockistState);
                    }

                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.towns;
                        const allOption1 = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll1 = [allOption1, ...stateDetails];
                        console.log(optionsWithAll1, "details town all")
                        this.setState({ towns: optionsWithAll1 })

                        const stockistState = this.state.stockistState;
                        stockistState.towns = optionsWithAll1;
                        this.props.setStateData(stockistState);
                    }, 500);
                }
                // }, 2000);
            })
        }
    }

    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            const materials = data[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');
            resp.push({
                _id: data[i]._id,
                stockist_name: data[i].stockist.name,
                mr_name: data[i].mr.name,
                doctor_name: data[i].doctor.name,
                customer_name: data[i].customer.name,
                customer_state: data[i].customer.address.state,
                customer_town: data[i].customer.address.town,
                customer_mobile: data[i].customer.mobileno,
                order_date: data[i].createdAt ? data[i].createdAt : "",
                grn_date: data[i].GRN_DATE ? data[i].GRN_DATE : "",
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                order_id: data[i].order_id ? data[i].order_id : "",
                total: data[i].total ? data[i].total : "",
                product_code: materialString,
                prescription_path: data[i].prescription ? data[i].prescription.filepath : "",
                fileExtention: data[i].prescription ? data[i].prescription.filepath.split('.')[1] : "",
                is_prescription_approved: data[i].is_prescription_approved,
                delivery_date: data[i].deliveryDate ? data[i].deliveryDate : ""

            })
        }
        return resp
    }

    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }
    handleCancelOrder = (event) => {
        const orderId = event.target.name;
        const mobileno = event.target.id;
        // alert(orderId);
        this.setState({ isShowCancelModal: true, cancelOrderId: orderId, mobileno: mobileno });
        // this.setState({ cancelOrderId: orderId });
        // this.setState({ mobileno: mobileno });

        const stockistState = this.state.stockistState;
        stockistState.isShowCancelModal = true;
        stockistState.cancelOrderId = orderId;
        stockistState.mobileno = mobileno;
        this.props.setStateData(stockistState);


    }
    handleOkClick = () => {
        const orderId = this.state.cancelOrderId;
        const stockistdetails = this.props.stockistdetails;
        const stockist_id = stockistdetails._id;
        const userType = this.props.stockistdetails.userType;
        let details = {
            order_id: orderId,
            user_id: stockist_id,
            user_type: userType,
            reason: this.state.cancelReason,
            mobileno: this.state.mobileno

        };
        let body = {
            stockist_id: stockist_id
        }

        if (this.state.cancelReason != "") {
            OtherService.cancelOrder(details).then(data => {
                console.log(data.data, "cancel order");

                if (data.data.success) {
                    console.log(data.data, "data.data")
                    this.setState({ stockistdetailsList: [] });
                    StockistService.getStockistDetails(body).then(data => {
                        console.log(data.data, "Stockist details 2");

                        if (data.data.success && data.data.data.stockistdetails !== '') {
                            console.log(data.data, "okkkkk");
                            // if(!this.state.isSearch){
                            this.setState({ stockistdetailsList: data.data.data.stockistdetails });

                            const stockistState = this.state.stockistState;
                            stockistState.stockistdetailsList = data.data.data.stockistdetails;
                            this.props.setStateData(stockistState);
                            // }
                        }

                    }).catch(err => {

                        console.log(err, "err")
                    })

                    this.props.hoc.customAlert("Order rejected successfully.", true)

                }
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {
                this.props.hoc.customAlert("Order rejected successfully.", true)
                console.log(err, "err")
            })
        } else {
            this.props.hoc.customAlert("Select reject reason", false);
        }






        // alert(callcenter_id);
    }

    handleRadioClick = (event) => {
        // alert("ok")
        //  alert(event.target.value);

        this.setState({ cancelReason: event.target.value });

        const stockistState = this.state.stockistState;
        stockistState.cancelReason = event.target.value;
        this.props.setStateData(stockistState);
    }

    getDoctors = (doctors) => {

        console.log(doctors, "getdoctors")
        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });


        const stockistState = this.state.stockistState;
        if (stockistState.doctorList.length == 0) {
            stockistState.doctorList = optionsWithAll;
            this.props.setStateData(stockistState);
        }

        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/stockist/track-info-order');
                    this.props.setUserType('stockist');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }
    changeValFromDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
        const stockistState = this.state.stockistState;
        stockistState.fromdate = date;
        this.props.setStateData(stockistState);

    }

    changeValToDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
        const stockistState = this.state.stockistState;
        stockistState.todate = date;
        this.props.setStateData(stockistState);
    }

    searchFilter = (event) => {
        this.setState({ stockistdetailsList: [] });
        const stockistState = this.state.stockistState;
        stockistState.stockistdetailsList = [];
        this.props.setStateData(stockistState);

        const stockistdetails = this.props.stockistdetails;
        const stockist_id = stockistdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedDoctor, setorderStatus } = this.state;

        const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
        const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";

        let details = {
            stockist_id: stockist_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            doctor_id: selectedDoctor,
            orderStatus: setorderStatus
        };
        console.log(details,2222)

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            StockistService.getStockistDetails(details).then(data => {
                console.log(data.data, "Stockist details filter");

                if (data.data.success && data.data.data.stockistdetails !== '') {
                    console.log(data.data, "okkkkk filter");


                    const formatedData = this.formatData(data.data.data.stockistdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "Stockist_Data" + currentDateTime;
                    this.setState({ filename: filename });
                    // if(!this.state.isSearch){
                    const doctors = data.data.data.stockistdetails.map(item => item.doctor);
                    this.getDoctors(doctors);

                    this.setState({ stockistdetailsList: data.data.data.stockistdetails });
                    this.setState({ isSearch: true })


                    const stockistState = this.state.stockistState;
                    stockistState.exportData = formatedData;
                    stockistState.filename = filename;
                    stockistState.stockistdetailsList = data.data.data.stockistdetails ;
                    stockistState.isSearch = true;
                    this.props.setStateData(stockistState);
                    this.getSummary(stockist_id)
                } else {

                    this.getSummary(stockist_id)
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        }




        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
        const stockistState = this.state.stockistState;
        stockistState.selectedDoctor = doctorId;
        this.props.setStateData(stockistState);
    }

    downloadPdf = async (event) => {
        const order_id = event.target.id;

        const downloadUrl = config.serviceUrl + "/images/invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Invoice not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }


        // alert(order_id);
    }

    handleApproveOrder = (event) => {

        this.props.hoc.customAlert('Are you sure you want to approved?', true, "", true, true, () => {

            const orderId = event.target.name;
            const filteredItem = this.state.stockistState.stockistdetailsList.find(item => item.order_id == orderId);
            let details = {
                order_id: orderId,
                stockist_id: filteredItem._id,
                stockist_email: filteredItem.stockist.email,
                mrname: filteredItem.mr.name,
                doctorname: filteredItem.doctor.name,
                customername: filteredItem.customer.name
            }
            console.log(orderId, "filteredItem orderid")
            console.log(this.state.stockistState.stockistdetailsList, "filteredItem alldata");
            console.log(filteredItem, "filteredItem");

            if (orderId) {
                StockistService.approvedPrescription(details).then(data => {
                    console.log(data.data, "approved");

                    if (data.data.success) {
                        console.log(data.data, "okkkkk approved");

                        const stockistdetails = this.props.stockistdetails;

                        let payload = {
                            stockist_id: stockistdetails._id
                        }
                        this.getStockistDetails(payload)
                        this.props.hoc.customAlert("Prescription approved succesfully.", true);
                    }

                }).catch(err => {

                    console.log(err, "err")
                })
            }



        }, () => {

        })

    }

    clearFilter = () => {

        this.setState({
            stockistdetails: null,
            stockistState: null,
            stockistid: "",
            stockistList: [],
            stockistinfo: "",
            stockistdetailsList: [],
            countries: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Stockist_Data",
        })
        const stockist_details = this.props.stockistdetails;
        const stockist_id = stockist_details._id;
        let details = {
            stockist_id: stockist_id
        };

        const stockistDetails = this.state.stockistState;
        stockistDetails.stockistdetails= null
        stockistDetails.stockistState= null
        stockistDetails.stockistid= ""
        stockistDetails.stockistList= []
        stockistDetails.stockistinfo= ""
        stockistDetails.stockistdetailsList= []
        stockistDetails.countries= []
        stockistDetails.towns= []
        stockistDetails.showTowns= false
        stockistDetails.selectedState= ""
        stockistDetails.selectedTown= ""
        stockistDetails.selectedDoctor= ""
        stockistDetails.fromdate= ""
        stockistDetails.todate= ""
        stockistDetails.currentDate= this.getCurrentDate()
        stockistDetails.doctorList= []
        stockistDetails.isSearch= false
        stockistDetails.isShowCancelModal= false
        stockistDetails.cancelOrderId= null
        stockistDetails.cancelReason= ""
        stockistDetails.mobileno= ""
        stockistDetails.summaryDetails= null
        stockistDetails.setorderStatus= ""
        stockistDetails.exportData= null
        stockistDetails.fileName= "Stockist_Data"
        this.props.setStateData(stockistDetails);
        this.getStockistDetails(details)
        this.getSummary(stockist_id)
    }


    render = () => {
        let _data = this.setOrderHistoryData(this.state.stockistState.stockistdetailsList)
        const { exportData, fileName, countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, doctorList, selectedDoctor } = this.state.stockistState;

        const {summaryCols, summaryDetails} = this.state

        console.log(towns, "towns details");
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <StockistHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Stockist Landing page</h2> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{" "}
                        {/* Row end*/}
                        <div className="landing-form-dtls">
                            <div className="row mb-25">
                                <div className="col-md-6">
                                    <h5 className="mb-20">Welcome {this.state.stockistState.stockistinfo.name} to Emrok Application (Stockist)</h5>
                                </div>
                                <div className="col-md-6">
                                    <div className="flotright marginright">
                                        <ExportToExcel apiData={exportData} fileName={fileName} />
                                    </div>
                                </div>

                            </div>




                            <div className="row mt-30 mb-20 ">
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order From Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" value={fromdate} name="fromdate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order To Date</label>
                                    {/* <input type="date" format="dd-mm-yyyy" min={this.state.fromdate} value={todate} name="todate" onChange={(event) => this.changeVal(event)} id className="form-control name-pt-ft" placeholder /> */}
                                    <DatePicker
                                        selected={todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={fromdate}
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // let _registration = this.state.registration
                                                        // _registration.address["state"] = item.name
                                                        // _registration.address["town"] = ""
                                                        this.setState({ selectedState: item.name })
                                                        //console.log(item, "item");
                                                        if (item.name != "All" && item.value != "") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else if (item.value == "") {
                                                            this.getDefaultTown(this.state.stockistState.stockistdetailsList)
                                                            this.setState({ selectedTown: "" })
                                                            const stockistState = this.state.stockistState;
                                                            stockistState.selectedTown = "";
                                                            this.props.setStateData(stockistState);
                                                        }
                                                        else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })

                                                            const stockistState = this.state.stockistState;
                                                            stockistState.towns =[];
                                                            stockistState.selectedTown = "";
                                                            this.props.setStateData(stockistState);
                                                        }
                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        const stockistState = this.state.stockistState;
                                                        stockistState.selectedTown = item.name;
                                                        this.props.setStateData(stockistState);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                </div>

                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Doctors </label>
                                    {
                                        doctorList.length ?
                                            <DropdownDoctor
                                                items={doctorList}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedDoctor: item.id })
                                                        const stockistState = this.state.stockistState;
                                                        stockistState.selectedDoctor = item.id;
                                                        this.props.setStateData(stockistState);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedDoctor}
                                            /> :
                                            <DropdownDoctor
                                                items={doctorList}
                                                value={selectedDoctor}
                                            />
                                    }

                                </div>
                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                                        Clear Filter</button>
                                </div>

                            </div>
                            {/* Summary table start */}
                            <Summary
                                columns={summaryCols}
                                data={[{
                                    prescriptionCount: summaryDetails ? summaryDetails.prescriptionCount || 0 : 0,
                                    totalOrderCount: summaryDetails ? summaryDetails.totalOrderCount || 0 : 0,
                                    // cancelledOrderCount: summaryDetails ? summaryDetails.cancelledOrderCount || 0 : 0,
                                    completeOrderCount: summaryDetails ? summaryDetails.completeOrderCount || 0 : 0,
                                    pendingOrderCount: summaryDetails ? summaryDetails.pendingOrderCount || 0 : 0,
                                    pendingStatusCount: summaryDetails ? summaryDetails.pendingStatusCount || 0 : 0,
                                    pickupInitiatedStatusCount: summaryDetails ? summaryDetails.pickupInitiatedStatusCount || 0 : 0,
                                    deliveredStatusCount: summaryDetails ? summaryDetails.deliveredStatusCount || 0 : 0,
                                }]}
                                clickFn={this.clickFn}
                            />

                            <h5 className="mb-20">Details</h5>
                            <div className="table-responsive">
                                {
                                    _data && _data.length ?
                                        <CustomDatatable
                                            pagination={true}
                                            columns={[
                                                {
                                                    name: 'Order No'
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Doctor Name',
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'MR Name',
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Patient Name',
                                                    // selector: row => row.order_id,

                                                },
                                                {
                                                    name: 'Patient State',
                                                    // selector: row => row.createdAt,

                                                },
                                                {
                                                    name: 'Patient City',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Order Date',
                                                    // selector: row => row.total,

                                                },
                                                // {
                                                //     name: 'Shipment Date',
                                                //     // selector: row => row.total,

                                                // },
                                                {
                                                    name: 'Delivery Date',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Order Value',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Product Name',
                                                    // selector: row => row.total,

                                                }, {
                                                    name: 'Prescription',
                                                    // selector: row => row.total,

                                                },
                                                , {
                                                    name: 'Invoice',
                                                    // selector: row => row.total,

                                                },
                                                {
                                                    name: 'Status',
                                                    // selector: row => row.orderStatus,

                                                },
                                                {
                                                    name: "Action",
                                                }
                                            ]}
                                            data={this.setOrderHistoryData(this.state.stockistState.stockistdetailsList)}
                                            handleTrackOrder={this.handleTrackOrder}
                                            downloadPdf={this.downloadPdf}
                                            pageName="stockistlanding"
                                            handleCancelOrder={this.handleCancelOrder}
                                            handleApproveOrder={this.handleApproveOrder}
                                        />
                                        : <p className="text-center">No Records Found</p>
                                }

                                <OrderCancelModal message="Are you sure want to reject?" handleOkClick={this.handleOkClick} handleRadioClick={this.handleRadioClick} cancelReasonData={this.state.cancelReason} />
                            </div>
                        </div>
                    </div>
                </section>
                <CustomerFooter />



            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        // dispatching plain actions
        // setMr: (mrdetails) => dispatch(MrAction.setMr(mrdetails))
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        setStateData: (stockistState) => dispatch(StockistAction.setStateData(stockistState))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, StockistReducer, CommonReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { stockistdetails, stockistState } = StockistReducer
    const { summaryDetails } = CommonReducer

    console.log(customer, 123);
    console.log(stockistdetails, 123456);
    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        stockistdetails,
        summaryDetails,
        stockistState
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(StockistList)))