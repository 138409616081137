import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';

class CustomLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    return {
      isloading: nextProps.hasOwnProperty("isloading") ? nextProps.isloading : false
    }
  }

  render() {
    return this.state.isloading ? 
     (
      <div style={{
        position: "fixed",
        zIndex: 99999999,
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Loader
          type="Watch" // Choose the loader type (options available in the documentation)
          color="#00BFFF" // Specify the loader color
          height={50} // Set the loader's height (adjust as needed)
          width={50} // Set the loader's width (adjust as needed)
        />
      </div>
    ) : null
  }
}
let mapDispatchToProps = (dispatch) => {
  return {

  }
}
let mapStateToProps = ({ CommonReducer }) => {
  let { isloading } = CommonReducer
  return {
    isloading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomLoader)