import React from "react";
export default function DecrementIcon(props = {}) {
    const svgData = { width: 12, height: 4, fill: "none", ...props }
    return (
        <svg
            
            viewBox="0 0 12 4"
            {...svgData}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_653_10)">
                <path d="M11.357 3.332C11.4414 3.33227 11.5251 3.31585 11.6031 3.28369C11.6812 3.25153 11.7522 3.20427 11.8119 3.14461C11.8717 3.08496 11.919 3.01409 11.9513 2.93607C11.9836 2.85806 12.0001 2.77443 12 2.69V0.643003C12.0003 0.558489 11.9838 0.474757 11.9516 0.396626C11.9194 0.318494 11.872 0.247505 11.8123 0.187745C11.7525 0.127984 11.6815 0.0806309 11.6034 0.0484107C11.5252 0.0161904 11.4415 -0.000260978 11.357 3.13019e-06H0.643003C0.558489 -0.000260978 0.474757 0.0161904 0.396626 0.0484107C0.318494 0.0806309 0.247505 0.127984 0.187745 0.187745C0.127984 0.247505 0.0806309 0.318494 0.0484107 0.396626C0.0161904 0.474757 -0.000260978 0.558489 3.13019e-06 0.643003V2.689C3.13019e-06 3.046 0.287003 3.332 0.643003 3.332H11.357Z" />
            </g>
            <defs>
                <clipPath id="clip0_653_10">
                    <rect width={12} height={4} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}