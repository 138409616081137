import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from '../../../components/layout/CustomerFooter';
import ProductList from '../../../components/Customer/ProductList';
import ProductService from '../../../services/ProductService';
import OrderList from '../../../components/Order/OrderList';
import CustomerService from '../../../services/Customer.service';
import OrderAction from '../../../redux/actions/Order.action';
import OtherService from '../../../services/OtherService';
import Common from '../../../hoc/Common.hoc';

class PlaceOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            discountvalue: "",
            couponList: [],
            couponListFinal: [],
            selectedValue: "",
            couponDiscount: "",
            isShowApplyBtn: true,
            product_quantities: [],
            reload: true,
            paymentOrder: {
                totalPayment: "",
                partialMinPayment: "",
                deliveryPayment: "",
                totalItem: "",
                discountamount: "",
                finalPaymentAfterDiscount: "",
                appliedDiscount: ""
            },
            productQuantity: {

            },
            products: [],
            order: {
                rx: "",
                serialNo: "",
                customer: "",
                products: [{
                    product: "",
                    quantity: "",
                    price: ""
                }],
                total: "",
                deliveryStatus: "",
                orderStatus: ""
            },
            common_products: []
        }
        this.productDecrement = this.productDecrement.bind(this);
        this.productIncrement = this.productIncrement.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            common_products: props.ProductReducer ? props.ProductReducer.common_products ? props.ProductReducer.common_products : [] : []
        }
    }

    // Callback function to update the selectedValue state
    handleSelectChange = (selectedValue) => {
        // alert(selectedValue)
        console.log(selectedValue, "selectedValue");
        // if(selectedValue.length<=7){
        this.setState({ selectedValue });
        // }else{
        //     this.props.hoc.customAlert("Coupon length must be 7 charecter", false)
        //     this.setState({ selectedValue:"" });
        // }

    };

    applyCoupon = () => {


        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, couponList, couponListFinal } = this.state;
        console.log(selectedValue, "selectedValue123");
        console.log(couponList, "couponList");
        const newProducts = products.map((product, i) => {
            return {
                product_id: product._id,
                price: product.price,
                quantity: product_quantities[i].quantity,
                // quantity: product.quantity,
                name: product.name
            }
        })
        const _products = newProducts.filter(product => product.quantity != 0)

        if (!_products.length) {
            this.props.hoc.customAlert("Please select atleast 1 product", false)
        } else if (this.state.selectedValue == "") {
            this.props.hoc.customAlert("Please select valid coupon", false)
        } else {

            const selectedCoupon = couponListFinal.filter(item => item.code === selectedValue)


            const finalPaymentAfterDiscount = paymentOrder.finalPaymentAfterDiscount;
            const couponDiscount = finalPaymentAfterDiscount * (selectedCoupon[0].value / 100)
            const afterCouponDiscount = finalPaymentAfterDiscount - couponDiscount;

            let newPaymentOrder = paymentOrder;
            newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;

            console.log(newPaymentOrder, "newPaymentOrder 123");
            this.setState({ paymentOrder: newPaymentOrder })
            this.setState({ couponDiscount: couponDiscount });
            this.setState({ isShowApplyBtn: false });

            console.log(selectedCoupon[0], "selectedCoupon 123")
            console.log(finalPaymentAfterDiscount, "finalPaymentAfterDiscount 123")
            console.log(couponDiscount, "couponDiscount 123")
            console.log(afterCouponDiscount, "afterCouponDiscount 123")
        }
        // alert(this.state.selectedValue)
    }

    removeCoupon = () => {
        const { paymentOrder, products, product_quantities, productQuantity, selectedValue, couponList, couponDiscount } = this.state;

        const finalPaymentAfterDiscount = paymentOrder.finalPaymentAfterDiscount;
        const afterCouponDiscount = finalPaymentAfterDiscount + couponDiscount;


        let newPaymentOrder = paymentOrder;
        newPaymentOrder['finalPaymentAfterDiscount'] = afterCouponDiscount;

        console.log(finalPaymentAfterDiscount, "remove finalPaymentAfterDiscount");
        console.log(afterCouponDiscount, "remove afterCouponDiscount");
        console.log(newPaymentOrder, "remove newPaymentOrder");
        console.log(couponDiscount, "remove couponDiscount");


        console.log(newPaymentOrder, "newPaymentOrder 123");
        this.setState({ paymentOrder: newPaymentOrder })
        this.setState({ couponDiscount: "" });
        this.setState({ isShowApplyBtn: true });
        this.setState({ selectedValue: "" });

    }

    productPriceCalCulate = () => {
        const { products } = this.state;
        console.log(products, "ooooooo");
        let product_quantities = this.state.product_quantities
        let totalPayment = 0;
        let totalItem = 0;
        let discountamount = 0;
        let finalPaymentAfterDiscount = 0;
        products.forEach((product, i) => {
            const price = parseFloat(product.price);
            totalPayment = totalPayment + price * product_quantities[i]?.quantity;
            // totalPayment = totalPayment + price * product.quantity;
            totalItem = totalItem + product_quantities[i]?.quantity;
            // totalItem = totalItem + product.quantity;
        })
        console.log(totalItem, '<<<<<<<<<<');

        const partialMinPayment = totalPayment * (20 / 100);
        const appliedDiscount = this.state.discountvalue != "" ? this.state.discountvalue : 0;
        if (this.state.discountvalue != "") {

            discountamount = totalPayment * (this.state.discountvalue / 100);
            finalPaymentAfterDiscount = totalPayment - discountamount;
        } else {
            discountamount = 0;
            finalPaymentAfterDiscount = totalPayment;

        }

        const deliveryPayment = totalPayment - partialMinPayment;

        this.setState({ paymentOrder: { totalPayment, partialMinPayment, deliveryPayment, totalItem, discountamount, finalPaymentAfterDiscount, appliedDiscount } })
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevState.products != this.state.products) {
            this.productPriceCalCulate();
        }


    }

    componentDidMount = async () => {
        console.log("12345566");
        // let x = await ProductService.getAllProductsByMaterial()
        let product_quantities = []
        // for (let i = 0; i < this.state.common_products.length; i++) {
        //     product_quantities.push({
        //         item: i + 1,
        //         quantity: 0
        //     })
        // }
        // console.log(this.state.common_products, 666);
        // this.setState({ product_quantities, products: this.state.common_products })
        // console.log(this.state.productQuantity, "yuyuyuy", x);
        let finalData = [];
        // const { productQuantity } = this.state;
        const newProductQuantity = {}
        // const customerData = this.props.CustomerReducer.customer ? this.props.CustomerReducer.customer.prescribedDoctor : "";

        // const stockist_id = customerData ? (customerData.stockist_id ? customerData.stockist_id : "") : "";
        // console.log(customerData, "customerData")
        // let stockistPayload = {
        //     stockist_id: stockist_id
        // }

        // ProductService.getStockistCode(stockistPayload).then(result => {

        //     if (result.data.success) {
        // console.log(result.data.data[0], "stockist details")

        // let plant_id = result.data.data ? result.data.data[0].plant_id : ""
        ProductService.getProductList().then(res => {
            console.log(res, "response1233")
            // return
            if (res && res.data && res.data.success) {
                if (res.data.data) {
                    const products = res.data.data;
                    for (let i = 0; i < products.length; i++) {
                        let product = products[i]
                        console.log(product, 999);
                        newProductQuantity[product._id] = product.quantity;
                    }

                    for (let i = 0; i < products.length; i++) {
                        product_quantities.push({
                            item: i + 1,
                            quantity: 0
                        })
                    }
                    // console.log(newProductQuantity, "newProductQuantity", products);
                    this.setState({ products: products, productQuantity: newProductQuantity, product_quantities })
                    // this.setState({ products: products })
                }
            }
        }).catch(e => {
            console.log("e>>>>>>>>>>>>", e)
        })
        // }
        // console.log(res,"stockist details")

        // });




        OtherService.getDiscountAmount().then(data => {
            if (data.data.success && data.data.data.response.length > 0) {
                // console.log(data.data.data.response[0],"discountdata");
                this.setState({ discountvalue: data.data.data.response[0].discountValue });
            }
        })

        OtherService.getCouponList().then(data => {

            console.log(data, "coupon list");
            if (data.data.success && data.data.data.response.length > 0) {

                for (let index = 0; index < data.data.data.response.length; index++) {
                    finalData[index] = {
                        "name": data.data.data.response[index].code,
                        "label": data.data.data.response[index].code,
                        "value": data.data.data.response[index].code,
                        "id": data.data.data.response[index]._id,
                    };


                }
                // console.log(data.data.data.response[0],"discountdata");
                // this.setState({ couponList: data.data.data.response });
                this.setState({ couponList: finalData });
                this.setState({ couponListFinal: data.data.data.response });
            }
        })

    }

    navigateToPrevious() {
        this.props.navigate(-1);
    }

    placeOrder() {

        const prescription_id = this.props.CustomerReducer.prescription?._id;
        console.log(prescription_id, "prescription");
        // return
        const customer_id = this.props.CustomerReducer.customer?._id;
        const customer = this.props.CustomerReducer.customer;
        // console.log(customer, 3333);
        // return
        const { paymentOrder, products, product_quantities, productQuantity } = this.state;
        console.log(productQuantity, "allproductquantity");
        console.log(products, "allproducts");
        console.log(paymentOrder, "allproducts123");
        console.log(product_quantities, "product_quantities");
        const newProducts = products.map((product, i) => {
            return {
                product_id: product._id,
                price: product.price,
                quantity: product_quantities[i].quantity,
                // quantity: product.quantity,
                name: product.name
            }
        })
        const _products = newProducts.filter(product => product.quantity != 0)

        console.log(_products, "_products");
        const doctorid = customer ? customer.doctor_id : ""
        let submittedData = {
            customer_id,
            total: paymentOrder.finalPaymentAfterDiscount,
            applieddiscount: paymentOrder.appliedDiscount,
            discountAmount: paymentOrder.discountamount,
            totalBeforeDiscount: paymentOrder.totalPayment,
            couponCode: this.state.selectedValue,
            couponDiscount: this.state.couponDiscount,
            products: _products,
            doctor_id: doctorid,
            prescription_id: prescription_id
        }

        console.log(submittedData, "submittedData");
        let all_products = this.state.products
        let all_quantities = {}
        let product_length = all_products.length
        for (let i = 0; i < product_length; i++) {
            all_quantities[`quantity${i + 1}`] = 0
        }
        for (let i = 0; i < _products.length; i++) {
            if (_products.length === 1) {
                all_quantities[`quantity${i + 1}`] = productQuantity[_products[i].product_id];
                for (let j = 0; j < product_length; j++) {
                    if (j !== 0) {
                        all_quantities[`quantity${i + 1}`] = 0
                    }
                }
            }
            else {
                if (_products[i] && productQuantity[_products[i].product_id]) {
                    all_quantities[`quantity${i + 1}`] = productQuantity[_products[i].product_id];
                }
            }
        }
        // let quantity1 = 0;
        // let quantity2 = 0;
        // if (_products.length == 1) {
        //     quantity1 = productQuantity[_products[0].product_id];
        //     quantity2 = 0;
        // } else if (_products.length == 2) {
        //     quantity1 = productQuantity[_products[0].product_id];
        //     quantity2 = productQuantity[_products[1].product_id];
        // }

        console.log(all_quantities, "allquant");

        // alert(quantity1);
        // alert(quantity2);

        console.log(customer, 'customer>>>>><<<<<<')
        //////////////////////////ayan///////////////////////////////////
        // submittedData = {
        //     ...submittedData,
        //     doctor_id: "64d51d951ed9ac3e2948c3f7",
        //     prescription_id: "64d51d951ed9ac3e2948c3e9",
        //     products: [{
        //         product_id: "65249d69d0f0d5b703cc417a",
        //         price: 3280,
        //         quantity: 10,
        //         name: "EMROK - Injection (I.V) 800mg/100ml"
        //     }],
        //     total: 32800,
        //     totalBeforeDiscount: 32800
        // }

        //////////////////////////ayan////////////////////////////////////
        console.log(submittedData, 'bbbbbbbbbbbbbbbbb>>>>><<<<<<')
        // return
        if (!submittedData.products.length) {
            this.props.hoc.customAlert("Please select atleast 1 product", false)

        }
        // else if ((all_quantities['quantity1'] != 0 && all_quantities['quantity1'] <= 50) || (all_quantities['quantity2'] != 0 && all_quantities['quantity2'] <= 50)) {
        //     this.props.hoc.customAlert("Insufficient product stock.", false)
        // }
        else {
            CustomerService.createOrder(submittedData).then(res => {
                if (res && res.data && res.data.success && res.data.data) {

                    console.log("order details", res.data.data[0]);

                    let orderContent = {

                        _id: res.data.data[0]._id
                    }

                    this.props.placeOrder(res.data.data[0]);
                    this.props.navigate('/customer/payment');
                }

            }).catch(err => {
                console.log(err)
            })
        }
        //this.props.navigate('/customer/payment');
    }



    productIncrement = async (productId) => {
        const { products, productQuantity } = this.state;
        // console.log(productQuantity,"productQuantity")
        let product_quantities = this.state.product_quantities
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })
        console.log(products[productIndex], "productdetailss12333");
        console.log("productIndex", products[productIndex].quantity, productQuantity, ">>>>>>")
        console.log(product_quantities, productIndex, product_quantities[productIndex].quantity, 65656565);

        if (products[productIndex].material == "403877") {

            if (product_quantities[productIndex].quantity < productQuantity[productId] && product_quantities[productIndex].quantity < 10) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity += await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }
        } else {

            if (product_quantities[productIndex].quantity < productQuantity[productId] && product_quantities[productIndex].quantity < 5) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity += await 1
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }
        }

    }

    productDecrement = async (productId) => {
        const { products } = this.state;
        let product_quantities = this.state.product_quantities
        console.log(products, 'aaaaaaaaaaaa')
        const newProducts = [...products]
        const productIndex = products.findIndex((product) => {
            return product._id == productId
        })

        if (products[productIndex].material == "403877") {
            if (product_quantities[productIndex].quantity > 0) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 5
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }
        } else {
            if (product_quantities[productIndex].quantity > 0) {
                newProducts[productIndex].quantity = product_quantities[productIndex].quantity -= await 1
                this.setState({ product_quantities, products: newProducts, reload: !this.state.reload })
            }

        }


    }

    changeDropdownValue = (event) => {

        alert(event.value);
    }


    render = () => {
        const { products, productQuantity, paymentOrder, product_quantities, couponList, couponDiscount, isShowApplyBtn } = this.state;
        console.log(products, "kajshdjkhas"); console.log(productQuantity, "kajshdjkhas");

        return (
            <>
                <section className="place-order-container">
                    <div className="container">
                        <CustomerHeader />
                        {/* Row End*/}
                        <div className="row">
                            <div className="col-lg-12">

                                {/* Title end */}

                                {/* Product details content box end*/}

                                {/* Product details content box end*/}
                                {/* commented by ayan */}
                                <ProductList paymentOrder={paymentOrder} products={products} productQuantity={productQuantity} productIncrement={this.productIncrement} productDecrement={this.productDecrement} product_quantities={product_quantities} reload={this.state.reload} />
                            </div>

                            <div className="col-lg-6">
                                {/* commented my ayan */}
                                <OrderList productListCompRef={this.productListCompRef} products={products} paymentOrder={paymentOrder} placeOrder={this.placeOrder} product_quantities={product_quantities} couponList={couponList} selectedValue={this.state.selectedValue} onValueChange={this.handleSelectChange} applyCoupon={this.applyCoupon} couponDiscount={couponDiscount} isShowApplyBtn={isShowApplyBtn} removeCoupon={this.removeCoupon} />
                            </div>
                        </div>
                        {/* Row end*/}
                    </div>
                </section>
                {/* Logout btn top section end */}
                <CustomerFooter />
            </>
        )

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        placeOrder: (order) => dispatch(OrderAction.placeOrder(order))
    }
}

const mapStateToProps = (state) => {
    console.log(state, "kjdskjfksd");
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
        ProductReducer: state.ProductReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(PlaceOrder)))