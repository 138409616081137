import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../../components/Reusable/WithRouter'
import CustomerHeader from '../../components/layout/CustomerHeader'
import config from '../../config/emrok.config'
import OrderPlacedModal from '../../components/Order/OrderPlacedModal'
import ThankYouModal from './ThankYouModal'
import CustomerFooter from '../../components/layout/CustomerFooter'
import Common from '../../hoc/Common.hoc'
import FeedbackService from '../../services/FeedbackService'


class FeedbackList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rating:0,
      message:'',
      rating1:0,
      message1:'',
      category:'',
      details:null // Default rating
    }
  }

  componentDidMount = () => {
    const customer = this.props.CustomerReducer;
    const order = this.props.OrderReducer;
    const feedbackDetails = this.props.details;
    const customer_id = this.props.customer.address._id;
    console.log(this.props.customer.address,'customer id<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');

    let details = {
      customer_id: customer_id
    };

    console.log(customer,"customer from feedback screen");
    console.log(order,"order from feedback screen");

    FeedbackService.getFedbackDetails(details).then(data => {
        console.log(data.data, "feedback details");

        if(data.data.success && data.data.data.mrdetails!==''){
            console.log(data.data,"okkkkk");

                this.setState({mrdetailsList:data.data.data.mrdetails});
        }

        

        // this.setState({mrdetailsList:data.data.data.mrdetails});

    }).catch(err => {

        console.log(err, "err")
    })

    
  }

  handleSubmit = () =>{

    const { rating, message , category ,rating1, message1 } = this.state;

    const id  = this.props.customer.address._id
    console.log(this.state,'>>>>>> Your order');

    console.log('Feedback submitted:', { rating, message , id });

    // this.props.onSubmit({ rating, message });
    
    // this.props.navigate('/customer/feedback-list');
    let fdetails = {

      /* ========== New ============ */

      customer_id: id,
      feedbackType: category,
      products: [
        { 
          product_id: "403876",
          comment: message,
          rating: rating
        },
        { 
          product_id: "403877",
          comment: message1,
          rating: rating1
        },

      ],
      is_myownn: true,

      /* =================== end=================== */
      
    };

    console.log(fdetails, "mr details");

    FeedbackService.feedbackinfo(fdetails).then(data => {
      console.log(data.data, "mr info");

      if(data.data.success && data.data.data.mrinfo!==''){
          console.log(data.data.data.mrinfo[0],"okkkkk");

              this.setState({mrinfo:data.data.data.mrinfo[0]});
      }

      

      // this.setState({mrdetailsList:data.data.data.mrdetails});
      this.props.hoc.customAlertWithClick("Thank you for your feedback",true,"Thank you");

    }).catch(err => {
        
      this.props.hoc.customAlertWithClick("Thank you for your feedback",true,"Thank you");

        console.log(err, "err")
    })

  }
  // handelSubmit = () =>{

  //   this.props.hoc.customAlertWithClick("Thank you for your feedback",true,"Thank you");
  // }

  handleRatingChange = (e) => {
    this.setState({ rating: e.target.value });
    
  };
  handleRatingChange1 = (e) => {
    this.setState({ rating1: e.target.value });
    
  };

  handleInputChange = (e) => {
    this.setState({ category: e.target.value });
  };


  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };
  handleMessageChange1 = (e) => {
    this.setState({ message1: e.target.value });
  };


  render = () => {
    const  { rating , message, rating1 , message1 , category} = this.state;
    return (
      <>
        
        <section className="landing-profl-detls old-review-details pt-4">
            <div className="container">
            <CustomerHeader />
            <div className="landing-form-dtls">
                <h5>Previous Feedback</h5>
                <div className="table-responsive">
                <table
                    id="example"
                    className="table table-striped tbl-chng-pt table-responsive"
                    style={{ width: "100%" }}
                >
                    <thead>
                    <tr>
                        <th width="25%">Product Name</th>
                        <th width="25%">Order ID</th>
                        <th width="25%">Date/Time</th>
                        <th width="25%">Feedback</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="landing-frm-load">
                        <td>EMROK - Injection (I.V) 800mg/100ml</td>
                        <td>ID-12345 </td>
                        <td>07-08-2023 I 6:45 Pm</td>
                        <td>
                        <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when looking at
                            its layout.
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td>EMROK O - Tablets 500mg</td>
                        <td>ID-12345</td>
                        <td>07-08-2023 I 6:45 Pm</td>
                        <td>
                        <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when looking at
                            its layout.
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td>EMROK - Injection (I.V) 800mg/100ml</td>
                        <td>ID-12345</td>
                        <td>07-08-2023 I 6:45 Pm</td>
                        <td>
                        <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when looking at
                            its layout.
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td>EMROK O - Tablets 500mg</td>
                        <td>ID-12345</td>
                        <td>07-08-2023 I 6:45 Pm</td>
                        <td>
                        <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when looking at
                            its layout.
                        </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <CustomerFooter/>
            </div>
            </div>
        </section>
  
 
      </>

        
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
  }
}

// const mapStateToProps = (state) => {
//   return {
//     OrderReducer: state.OrderReducer,
//     CustomerReducer: state.CustomerReducer
//   }
// }

const mapStateToProps = ({ CustomerReducer, OrderReducer, FeedbackReducer }) => {
  const { customer } = CustomerReducer
  const { orders } = OrderReducer
  const { details } = FeedbackReducer
  console.log(customer, 123);
  console.log(details, 123456);
  return {
      // dispatching plain actions
      // OrderListReducer: state.OrderListReducer,
      customer,
      orders,
      details
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(FeedbackList)))